import { Icon } from 'packages/eid-icons'
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useTranslation } from 'react-i18next'
import { Loader } from 'packages/eid-ui'

const useGreenStyles = makeStyles((theme)=>
    createStyles({
        root: (styles) => ({
            cursor: 'pointer',
            position: 'relative',
            height: '48px',
            padding: '8px 12px',
            maxWidth:'120px',
            width:'100%',
            borderRadius: '4px',
            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',

            backgroundColor: theme?.palette?.primary?.main,
            border: `solid 1px ${theme?.palette?.primary?.main}`,

            color: '#fff',
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
                color: 'white',
                cursor: 'hand',
            },
            ...styles,
        }),
        disabled: {
            backgroundColor: '#efeff1 !important',
            color: '#8b909a !important',
            border: 'solid 1px #8b909a !important',
        },
    }),
)

const SaveButton = ({ onClick, disabled, showStartIcon = true, styles, isLoading }) => {
    const { t } = useTranslation()
    const buttonClasses = useGreenStyles(styles)
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            classes={buttonClasses}
            startIcon={
                showStartIcon && !isLoading && (
                    <Icon
                        name="Save"
                        style={{ width: '20px', height: '20px' }}
                        color={disabled ? '#8b909a' : '#fff'}
                        fill={disabled ? '#8b909a' : '#fff'}
                    />
                )
            }
        >
           {isLoading ? <Loader color="#ffffff" /> : t('Save')}
        </Button>
    )
}

export default SaveButton
