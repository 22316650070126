import { Fragment } from 'react'
import { Box, Card, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import DeleteScope from './DeleteScope'
import GenericButton from 'components/Button/GenericButton'
import ItemCard from 'components/ItemCard'
import { mobileScreenWidth } from 'utils'

const Container = styled(Box)({
    display: 'inline-block',
    width: '33%',

    overflow: 'hidden',
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: '16px',
            position: 'unset !important',
            margin: '16px',
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
            borderRadius: '8px !important',
        },
    }),
)

const ScopeCard = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { item: data, loading, attributes } = props
    const history = useHistory()

    if (loading)
        return (
            <Card className={classes.root}>
                <SkeletonLoader />
            </Card>
        )

    return (
        <Container>
            <ItemCard
                item={data}
                attributes={attributes}
                cardStyles={{
                    margin: '14px',
                }}
                leftButton={
                    <GenericButton
                        onClick={() => {
                            history.push(
                                `${history.location.search}&scopeId=${data?.id}`,
                            )
                        }}
                        color="#01ae8f"
                        rootStylesProp={{
                            borderRadius: '4px',
                            width: '170px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                    >
                        {t('Details')}
                    </GenericButton>
                }
                rightButton={
                    <>
                        <span style={{ marginRight: '16px' }} />
                        <DeleteScope
                            item={data}
                            styles={{
                                borderRadius: '4px',
                                padding: '0px 10px',
                            }}
                            showEndIcon={false}
                        />
                        <span style={{ marginRight: '16px' }} />
                        <UIActionsGearButton item={data} noun='ResourceAdminScopes' />
                    </>
                }
            />
        </Container>
    )
}

const SkeletonLoader = () => {
    return (
        <Fragment>
            <Skeleton animation="wave" variant="rectangular" height={80} />
            <Box marginTop={2} padding={2} width="100%">
                <Skeleton variant="rectangular" animation="wave" height={8} />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ height: 96, marginTop: 8 }}
                />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={48}
                    style={{ marginTop: 16 }}
                />
            </Box>
        </Fragment>
    );
}
export default ScopeCard
