import DynamicFieldTypesSelector from 'components/ApplicationBanner/PbacAccessAssignments/ApplicationRoleDefinitionAssignments/ApplicationRoleDefinitionDetails/RoleFieldTypesInfo'
import { useApplicationSettings, useRoleDefinitionFieldTypes } from 'hooks'
import {
    Attribute,
    AttributeLabel,
    AttributeValue,
    Drawer,
} from 'packages/eid-ui'
import bgHeader from '../../../assets/images/bgHeader.png'
import TextFormatter from 'components/TextFormatter'
import { Box, Paper, styled } from '@mui/material'
import { mergeCartAndApiJson } from 'utils'
import { useTranslation } from 'react-i18next'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 4.8rem 0 3.2rem',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '2.2rem',
})

const FieldValuesDrawer = ({
    openDrawer = false,
    onClose,
    assignment,
    assignee,
    isRole,
    onSave,
    existingFieldTypes = null,
    keyForRoleRightId = 'id',
    isEditFieldTypes = false,
    applicationId,
}) => {
    const { data: appConfig } = useApplicationSettings()
    const { data: appRoleRight, isLoading } = useRoleDefinitionFieldTypes(
        isEditFieldTypes ? assignment?.id || null : null,
        assignee?.assigneeId,
        assignment?.[keyForRoleRightId],
        isRole,
        openDrawer,
    )
    const { t } = useTranslation()
    const bindMappedJson = () => {
        const parsedApiResponse = JSON.parse(appRoleRight)
        const parsedObject = Array.isArray(parsedApiResponse)
            ? parsedApiResponse[0]
            : parsedApiResponse
        // Check if multiple
        if (
            parsedObject?.SplitBusinessRequestApprovalPerFieldTypeValue &&
            existingFieldTypes
        ) {
            const cartItems = existingFieldTypes.itemToAdd.cartObj

            const updatedFieldTypes = parsedObject.FieldType.map((item) => {
                const valueType =
                    item?.AzFieldTypeSelectionRuleTypeID === 3
                        ? 'AssignedValues'
                        : 'AssignedValue'
                const itemFromCart = cartItems.filter(
                    (cart) =>
                        JSON.parse(cart.fieldTypeValueJson)[0]?.FieldType[0]
                            .AzFieldTypeID === item.AzFieldTypeID,
                )
                if (itemFromCart?.length) {
                    if (itemFromCart?.length === 1) {
                        return {
                            ...item,
                            [valueType]: JSON.parse(
                                itemFromCart[0].fieldTypeValueJson,
                            )[0]?.FieldType[0]?.[valueType],
                        }
                    } else {
                        let assignedValue = []
                        itemFromCart.forEach((element) => {
                            const assignedValueFromCart = JSON.parse(
                                element.fieldTypeValueJson,
                            )[0]?.FieldType[0]?.[valueType]
                            assignedValue.push(assignedValueFromCart[0])
                        })
                        return {
                            ...item,
                            [valueType]: assignedValue,
                        }
                    }
                } else {
                    return item
                }
            })
            const finalJson = {
                ...parsedObject,
                FieldType: updatedFieldTypes,
            }
            return JSON.stringify(finalJson)
            // Check single and any previosu save values
        } else if (existingFieldTypes) {
            const existingJson = JSON.parse(
                existingFieldTypes.itemToAdd.cartObj[0]?.fieldTypeValueJson,
            )
            const parsedCartObj = Array.isArray(existingJson)
                ? existingJson[0]
                : existingJson

            const combinedJson = mergeCartAndApiJson(
                parsedCartObj,
                parsedObject,
            )

            return JSON.stringify(combinedJson)
        } else {
            return appRoleRight
        }
    }
    return (
        <Drawer
            width="clamp(75%, 1007px, 90%)"
            bgColor="#fbfbfd"
            open={openDrawer}
            toggleDrawer={() => onClose()}
            stickyHeader
            header={
                <HeaderContainer
                    height="9.5rem"
                    bgcolor={appConfig.style.colors.primary?.header}
                    style={{
                        backgroundImage: `url(${bgHeader})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <TextFormatter
                        maxCharacters={385}
                        style={{ textAlign: 'start', fontSize: '2.2rem' }}
                        value={assignment?.friendlyName}
                    />
                </HeaderContainer>
            }
            closeOnOutsideClick
        >
            <Box>
                <Paper
                    style={{
                        padding: '24px 32px',
                        width: 'fit-content',
                        minWidth: '100%',
                    }}
                >
                    <Box>
                        <Box display="flex" flexWrap="wrap">
                            <Box paddingRight="32px">
                                <Attribute orientation="horizontal">
                                    <AttributeLabel>
                                        {t('FriendlyName')}
                                    </AttributeLabel>
                                    <AttributeValue>
                                        {assignment?.friendlyName
                                            ? assignment?.friendlyName
                                            : isRole
                                            ? assignment?.role
                                            : assignment?.appRightFriendlyName}
                                    </AttributeValue>
                                </Attribute>
                                <Attribute orientation="horizontal">
                                    <AttributeLabel>
                                        {t('TechnicalName')}
                                    </AttributeLabel>
                                    <AttributeValue>
                                        {assignment?.name
                                            ? assignment?.name
                                            : isRole
                                            ? assignment?.roleName
                                            : assignment?.appRightName}
                                    </AttributeValue>
                                </Attribute>

                                <Attribute orientation="horizontal">
                                    <AttributeLabel>
                                        {t('Description')}
                                    </AttributeLabel>
                                    <AttributeValue>
                                        {assignment?.description
                                            ? assignment?.description
                                            : assignment?.roleDescription}
                                    </AttributeValue>
                                </Attribute>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
                <DynamicFieldTypesSelector
                    isLoading={isLoading}
                    assigneePerson={assignee}
                    appRoleRight={appRoleRight ? bindMappedJson() : ''}
                    isAppRole={isRole}
                    keyForRoleRightId={keyForRoleRightId}
                    onSave={onSave}
                    applicationId={applicationId}
                />
            </Box>
        </Drawer>
    )
}
export default FieldValuesDrawer
