import { useTranslation } from 'react-i18next'
import { useApplicationLocalRights } from 'hooks'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Box } from '@mui/material'
import { Filters } from 'components'
import { SearchMenuBarFilter } from 'components/WrapperComponents/SearchMenuBar'

export const LocalRights = ({ groupId }) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'name',
            label: t('FriendlyName'),
            styles: {
                width: '296px',
            },
            sortable: true,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-Groups-Details-Overview-LocalRights-List-FriendlyName',
            // },
        },
        {
            name: 'description',
            label: t('Description'),
            styles: {
                width: '290px',
            },
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Overview-LocalRights-List-Description',
            },
        },
        {
            name: 'rightTypeName',
            label: t('RightType'),
            styles: {
                width: '160px',
            },
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Overview-LocalRights-List-RightType',
            },
        },
        {
            name: 'resourceSystemName',
            label: t('ResourceSystem'),
            styles: {
                width: '160px',
            },
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Overview-LocalRights-List-ResourceSystem',
            },
        },
    ]

    const {
        list: localRights,
        loading: localRightsLoading,
        pagination: localRightsPagination,
    } = useApplicationLocalRights(groupId, Boolean(groupId))

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                padding="1.6rem 3.2rem"
                bgcolor="white"
                borderTop="1px solid #eef0f4"
            >
                <Box style={{ width: '24rem' }}>
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <EidTableGrid
                noDataMessage={t('NoRightsFound')}
                pagination={localRightsPagination}
                isLoading={localRightsLoading}
                attributes={getAccessBasedAttributes(attributes)}
                list={localRights}
                tableViewOnly
            />
        </>
    )
}
