import { Box } from '@mui/material'
import { hasValidJson, updateJsonMappingWithApiFormat } from 'packages/core'
import { FieldTypeValuesAssignmentPopup } from 'packages/eid-ui'

const FieldTypeJsonPreviewButton = ({
    data,
    withBorder = true,
    showLabel = false,
}) => {
    const getParsedObject = (apiDataObject) => {
        try {
            const parsedObject = Array.isArray(apiDataObject)
                ? apiDataObject[0]
                : apiDataObject
            return parsedObject
        } catch (err) {}
        return apiDataObject
    }

    return (
        <Box>
            {data?.assignedFieldTypeValuePreviewJson &&
                hasValidJson(
                    updateJsonMappingWithApiFormat(
                        getParsedObject(
                            JSON.parse(data?.assignedFieldTypeValuePreviewJson),
                        ),
                        false,
                    ),
                ) && (
                    <Box>
                        <FieldTypeValuesAssignmentPopup
                            withBorder={withBorder}
                            showLabel={showLabel}
                            fieldTypeValueJson={updateJsonMappingWithApiFormat(
                                getParsedObject(
                                    JSON.parse(
                                        data?.assignedFieldTypeValuePreviewJson,
                                    ),
                                ),
                                false,
                            )}
                        />
                    </Box>
                )}
        </Box>
    )
}

export default FieldTypeJsonPreviewButton
