import PropTypes from 'prop-types'
import { CardContent, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import Attribute from './Attribute'
import { TextFormatter } from 'components'
import DynamicComponents from 'components/DynamicComponents'
import { Fragment } from 'react'
import { Instructions } from 'components/ApplicationBanner/Instructions'
import GenericButton from 'components/Button/GenericButton'
import { Icon } from 'packages/eid-icons'

const useCardContentStyles = makeStyles({
    cardContent: (props) => ({
        position: 'relative',
        padding: '0 20px 0px 20px',
        paddingLeft: props.mobile && '20px',
        marginTop: '20px',
    }),

    divider: {
        backgroundColor: '#e8e8e8',
        margin: '8px 0px',
        height: '0.5px',
    },

    image: {
        width: '100px',
    },
})

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    disableInteractive: false,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const Attributes = ({ item, mobile, attributes }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const contentClasses = useCardContentStyles({ mobile })

    const renderComponent = (a, item, index) => {
        if (a.component.componentName === 'renderer') {
            return <Fragment>{a.component.render(item, index)}</Fragment>
        } else {
            const DynamicComponentToRender =
                DynamicComponents[a.component.componentName]
            return (
                <DynamicComponentToRender
                    attribute={a}
                    data={{ item }}
                    imageClass={contentClasses.image}
                />
            )
        }
    }

    const renderText = (a, item) => {
        return a.name === 'friendlyName' ? (
            <h1 style={{ margin: '0px', color: theme?.palette?.primary?.main }}>
                {item[a.name]}
            </h1>
        ) : (
            <TextFormatter
                value={item[a.name]}
                tooltipProps={tooltipProps}
                style={{ fontSize: '14px' }}
            />
        )
    }
    return (
        <CardContent className={contentClasses.cardContent}>
            {item.instructions && (
                <Instructions
                    title={item.friendlyName}
                    instructions={item.instructions}
                    button={(onClick) => (
                        <GenericButton
                            onClick={() => onClick(true)}
                            color="#8b909a"
                            rootStylesProp={{
                                borderRadius: '4px',
                                width: '73px',
                                height: '32px',
                                position: 'absolute',
                                right: 15,
                                top: 0,
                                fontSize: '14px',
                                padding: '4px',
                            }}
                        >
                            <>
                                {t('More')}
                                <Icon
                                    name="Info"
                                    style={{
                                        width: '14px',
                                        marginLeft: '5px',
                                    }}
                                />
                            </>
                        </GenericButton>
                    )}
                />
            )}

            {attributes
                .filter((a) => a.showInCard !== false)
                .map((a, i) => {
                    const value = (
                        <>
                            {a.component?.componentName
                                ? renderComponent(a, item, i)
                                : renderText(a, item)}
                        </>
                    )
                    return (
                        <Attribute
                            key={a.name}
                            label={a.name === 'friendlyName' ? '' : t(a.label)}
                            value={value}
                            orientation="horizontal"
                            valueProps={{
                                display: 'flex !important',
                                alignItems: 'center',
                                ...a.cardStyles,
                            }}
                            labelProps={{ fontWeight: 'bold !important' }}
                            className={a.className}
                            data-protectedsubcomponent={
                                a.requireAccess?.control
                            }
                        />
                    )
                })}
        </CardContent>
    )
}

Attributes.propTypes = {
    item: PropTypes.object,
    assignmentDetails: PropTypes.object,
    className: PropTypes.string,
}

export default Attributes
