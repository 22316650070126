import useSubcomponents from 'useSubcomponents'
import PeopleLayout from './PeopleLayout'

const allFilters = [
    {
        name: 'peopleLocationFilter',
        requireAccess: 'ResourceAdmin-People-Filter-Location',
    },
    {
        name: 'peopleAdvancedSearch',
        requireAccess: 'ResourceAdmin-People-Filter-AdvancedSearch',
    },
]

const RequestViewAllPeople = () => {
    const { trimControlsBasedOnAccess } = useSubcomponents()

    const filters = trimControlsBasedOnAccess(allFilters).map((f) => f)

    return <PeopleLayout filters={filters} />
}

export { RequestViewAllPeople }
