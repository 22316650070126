import React from 'react'
import { Button as MuiButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from 'packages/eid-ui'

const useStyles = makeStyles(() => ({
    root: (props) => ({
        textTransform: 'capitalize !important',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: props.color,
        boxShadow: 'none',
        minWidth: 'max-content',
        lineHeight: 1,
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: props.color,
            '&::before': {
                borderRadius: 'inherit',
                content: '""',
                position: 'absolute',
                height: '100%',
                width: '100%',
                opacity: '0.17',
                backgroundColor: '#000',
                left: 0,
                top: 0,
            },
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: props.color,
            opacity: 0.7,
        },
        '&:focus': {
            boxShadow: 'none',
        },
        ...props.rootStylesProp,
    }),
    disabled: {
        color: '#fff !important',
    },
    label: {
        zIndex: 1,
    },
}))

const GenericButton = (props) => {
    const {
        loading,
        color,
        disabled,
        onClick,
        children,
        rootStylesProp,
        ...rest
    } = props
    const classes = useStyles({ color, loading, rootStylesProp })

    return (
        <MuiButton
            disabled={disabled}
            classes={classes}
            variant="contained"
            onClick={onClick}
            {...rest}
        >
            {loading ? <Loader color="#ffffff" /> : children}
        </MuiButton>
    )
}
export default GenericButton
