import BooleanValue from 'components/DynamicComponents/BooleanValue'

const tabularAttributes = [
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        hideValue: true,
        label: 'FriendlyName',
        moreInfoIcon: true,
        showInCard: false,
        isHeader: true,

        component: {
            componentName: 'HighlightedClickableLabel',
            queryParam: 'groupId',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        //NOTE : Keeping the commented out code for subcomponent traceability
        // requireAccess: {
        //     control: 'ResourceAdmin-Groups-List-FriendlyName',
        // },
    },

    {
        name: 'name',
        sortable: true,
        sortBy: 'Name',
        label: 'TechnicalName',
        headerCellStyles: {},
        styles: {
            fontSize: '13px',
        },
        className: 'bold',
        requireAccess: {
            control: 'ResourceAdmin-Groups-List-TechnicalName',
        },
    },
    {
        name: 'resourceSystemFriendlyName',
        sortable: true,
        sortBy: 'resourceSystemFriendlyName',
        label: 'ResourceSystem',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-Groups-List-ResourceSystem',
        },
    },

    {
        name: 'groupTypeFriendlyName',
        sortable: true,
        sortBy: 'groupTypeFriendlyName',
        label: 'GroupType',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-Groups-List-GroupType',
        },
    },

    {
        name: 'description',
        sortable: true,
        sortBy: 'description',
        label: 'Description',

        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-Groups-List-Description',
        },
    },
    {
        hideValue: true,
        showInCard: false,
        requireAccess: {
            control: 'ResourceAdmin-Groups-List-Actions',
        },
        component: {
            componentName: 'actionButtons',
        },
    },
]
const detailAttributes = [
    {
        label: 'TechnicalName',
        value: 'name',
    },
    {
        label: 'ResourceSystem',
        value: 'resourceSystemFriendlyName',
    },
    {
        label: 'AccountStore',
        value: 'accountStoreName',
    },
    {
        label: 'HighLevelClassification',
        value: 'highLevelClassification',
    },
    {
        label: 'IsHighSecurityGroup',
        render: (data) => {
            return (
                <BooleanValue
                    data={{ item: data }}
                    attribute={{ name: 'isHighSecurityGroup' }}
                />
            )
        },
    },
    {
        label: 'Description',
        value: 'description',
    },
]
const resultantMembersAttributes = [
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        hideValue: true,
        label: 'FriendlyName',
        moreInfoIcon: true,
        component: {
            componentName: 'HighlightedClickableLabel',
            queryParam: 'groupId',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },

    {
        name: 'disabled',
        sortable: true,
        sortBy: 'disabled',
        label: 'Enabled',
        reverse: true,
        headerCellStyles: {},
        component: {
            componentName: 'BooleanIcon',
        },
    },
    {
        name: 'userType',
        sortable: true,
        sortBy: 'userType',
        label: 'Type',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'lastLogonTime',
        sortable: true,
        sortBy: 'lastLogonTime',
        label: 'LastLogin',
        hideValue: true,
        component: {
            componentName: 'DateTimeGenericComponent',
        },
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'logonName ',
        sortable: true,
        sortBy: 'logonName ',
        label: 'LogonName',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'domainOrDirectory',
        sortable: true,
        sortBy: 'domainOrDirectory',
        label: 'DomainOrDirectory',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'distinguishedName',
        sortable: true,
        sortBy: 'distinguishedName',
        label: 'DistinguishedName',

        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
]
const membershipChangesAttributes = [
    {
        name: 'groupAccountChangeType',
        sortable: true,
        sortBy: 'groupAccountChangeType',
        label: 'ChangeType',
        hideValue: true,
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        component: {
            componentName: 'GroupChangeType',
        },
    },
    {
        name: 'when',
        sortable: true,
        sortBy: 'when',
        label: 'WhenAgo',
        hideValue: true,
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        component: {
            componentName: 'CalculateTimeString',
        },
    },
    {
        name: 'changeSourceName',
        sortable: true,
        sortBy: 'changeSourceName',
        label: 'SourceOfChange',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'accountFriendlyName',
        sortable: true,
        sortBy: 'accountFriendlyName',
        label: 'AccountDisplayName',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'isHighSecurityGroup',
        sortable: true,
        sortBy: 'isHighSecurityGroup',
        label: 'IsHighSecurityGroup',
        component: {
            componentName: 'BooleanValue',
        },

        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'groupAccountStoreFriendlyName',
        sortable: true,
        sortBy: 'groupAccountStoreFriendlyName',
        label: 'AccountDirectory',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
]

const attributes = {
    tableAttributes: tabularAttributes,
    detailAttributes: detailAttributes,
    resultantMembersAttributes,
    membershipChangesAttributes,
}
export default attributes
