import { useEffect, useRef, useState } from 'react'
import { Box, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useApplicationSettings, useApplication } from '../../hooks'
import { useHistory } from 'react-router'
import { isNilOrEmpty, useQuery } from 'packages/core'
import StickyContainer from 'components/StickyContainer'
import { ItemDetails, Button, TextFormatter } from 'components'
import { OverviewSection } from './OverviewSection'
import ApplicationCertificates from 'components/ApplicationBanner/Application Certificates/ApplicationCertificates'
import ApplicationSecrets from 'components/ApplicationBanner/Application Secrets/ApplicationSecrets'
import ApplicationScopes from 'components/ApplicationBanner/Application Scopes/ApplicationScopes'
import AzureApplicationRoles from 'components/ApplicationBanner/AzureApplicationAppRights/ApplicationRights'
import ApplicationPermissions from 'components/ApplicationBanner/API Permissions/Permissions'
import SecretsDrawer from 'components/ApplicationBanner/Application Secrets/SecretsDrawer'
import CertificatesDrawer from 'components/ApplicationBanner/Application Certificates/CertificatesDrawer'
import ScopesDrawer from 'components/ApplicationBanner/Application Scopes/ScopesDrawer'
import AppRightsDrawer from 'components/ApplicationBanner/AzureApplicationAppRights/AppRightsDrawer'
import PermissionsDrawer from 'components/ApplicationBanner/API Permissions/PermissionsDrawer'
import bgHeader from '../../assets/images/bgHeader.png'
import { PageContextProvider } from 'pageContext'
import { getFullImageUrl, imagesDisabled, isApplicationAzureApp } from 'utils'
import { ActionsButton } from './ActionsButton'
import { Icon } from 'packages/eid-icons'
import useSubcomponents from 'useSubcomponents'
import classNames from 'classnames'
import { TokenConfiguration } from 'components/ApplicationBanner/Token Configurations'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 10px',
    overflow: 'visible',
    color: '#ffffff',
    fontSize: '22px',
    textAlign: 'center',
})

const LogoContainer = styled(Box)({
    height: '88px',
    minWidth: '88px',
    boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
    border: 'solid 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const useStyles = makeStyles(
    createStyles({
        actionsButton: {
            margin: '32px 24px',
            float: 'right',
            transition: 'margin 1s',
        },
        actionsButtonShifted: {
            margin: '32px 84px',
        },
    }),
)

const ApplicationDetails = ({ id, onClose }) => {
    const { data: application } = useApplication(id)
    const { data: appConfig } = useApplicationSettings()
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const secretId = query.get('secretId')
    const certificateId = query.get('certificateId')
    const scopeId = query.get('scopeId')
    const appRoleId = query.get('appRoleId')
    const permissionId = query.get('permissionId')
    const [activeTab, setActiveTab] = useState('overview')
    const { controls } = useSubcomponents()
    const [shiftActionsButton, setShiftActionsButton] = useState(false)
    const classes = useStyles()
    const ref = useRef()
    ref.current = shiftActionsButton

    const handleScroll = () => {
        // To shift actions button to left when Scroll to Top button appears i.e.(160px)
        if (window.pageYOffset > 160) {
            if (!ref.current) {
                setShiftActionsButton(true)
            }
        } else {
            if (ref.current) {
                setShiftActionsButton(false)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    if (secretId) {
        return (
            <PageContextProvider key="RequestViewSecrets">
                <SecretsDrawer
                    id={secretId}
                    onClose={() => {
                        query.delete('secretId')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            </PageContextProvider>
        )
    }

    if (certificateId) {
        return (
            <PageContextProvider key="RequestViewCertificates">
                <CertificatesDrawer
                    id={certificateId}
                    onClose={() => {
                        query.delete('certificateId')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            </PageContextProvider>
        )
    }

    if (scopeId) {
        return (
            <PageContextProvider key="RequestViewScopes">
                <ScopesDrawer
                    id={scopeId}
                    onClose={() => {
                        query.delete('scopeId')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            </PageContextProvider>
        )
    }

    if (appRoleId) {
        return (
            <PageContextProvider key="RequestViewAppRoles">
                <AppRightsDrawer
                    id={appRoleId}
                    allowAppRoleDirectAssignment={
                        application?.allowAppRoleDirectAssignment
                    }
                    applicationType={application?.applicationType}
                    onClose={() => {
                        query.delete('appRoleId')
                        history.push(`${history.location.pathname}`)
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                    resourceSystemModuleID={application?.resourceSystemModuleID}
                />
            </PageContextProvider>
        )
    }

    if (permissionId) {
        return (
            <PageContextProvider key="RequestViewPermissions">
                <PermissionsDrawer
                    id={permissionId}
                    onClose={() => {
                        query.delete('permissionId')
                        history.push(`${history.location.pathname}`)
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            </PageContextProvider>
        )
    }

    const canAccessTab = (tab) => {
        return controls.includes(tab.requireAccess?.control)
    }

    const isAzureApplication = isApplicationAzureApp(
        application?.applicationType,
    )
    const allTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
        {
            label: t('ClientSecrets'),
            value: 'secrets',
            icon: <Icon name="ClientSecret" />,
            display: isAzureApplication,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-ClientSecrets',
            },
        },
        {
            label: t('ClientCertificates'),
            value: 'certificates',
            icon: <Icon name="ClientCertificate" />,
            display: isAzureApplication,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-ClientCertificates',
            },
        },
        {
            label: t('Scopes'),
            value: 'scopes',
            icon: <Icon name="Scope" />,
            display: isAzureApplication,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-Scopes',
            },
        },
        {
            label: t('AzureApplicationRoles'),
            value: 'roles',
            icon: <Icon name="AppRole" />,
            display: isAzureApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-AzureApplicationRoles',
            },
        },
        {
            label: t('ApiPermissions'),
            value: 'permissions',
            icon: <Icon name="ApiPermission" />,
            display: isAzureApplication,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-APIPermissions',
            },
        },
        {
            label: t('TokenConfigurations'),
            value: 'tokens',
            icon: <Icon name="Claims" />,
            display: isAzureApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-TokenConfigurations',
            },
        },
        {
            label: t('IAmShopAssignments'),
            value: 'assignments',
            icon: <Icon name="AzureRbacRolesSmall" />,
            display: false, //NOTE : Has been removed from application details. Keeping code for record
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-IAMShopAssignments',
            },
        },
        {
            label: t('IAmShopAssignees'),
            value: 'assignees',
            icon: <Icon name="ResultantMembers" />,
            display: false, //NOTE : Has been removed from application details. Keeping code for record
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-IAMShopAssignees',
            },
        },
    ]

    let imgUrl = !isNilOrEmpty(application?.applicationLogoImageUrl)
        ? getFullImageUrl(application?.applicationLogoImageUrl)
        : getFullImageUrl('/webcdn/Images/AppLogos/Genric-1.png')

    return (
        <Box>
            <StickyContainer
                color="#ffffff"
                styles={{
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Box paddingY="10px" width="100%" maxWidth="260px">
                        <Button.BackButton onClick={() => onClose()}>
                            {t('BackToApplications')}
                        </Button.BackButton>
                    </Box>

                    <HeaderContainer
                        height="80px"
                        textAlign="start"
                        bgcolor={appConfig.style.colors.primary?.header}
                        style={{
                            backgroundImage: `url(${bgHeader})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        {!imagesDisabled && imgUrl && (
                            <LogoContainer>
                                <img
                                    src={imgUrl}
                                    style={{ width: '37px', height: '37px' }}
                                    alt={t('logo')}
                                />
                            </LogoContainer>
                        )}
                        <Box marginLeft="15px">
                            <TextFormatter
                                value={
                                    application?.name ??
                                    application?.description
                                }
                                maxCharacters={25}
                            />
                        </Box>
                    </HeaderContainer>
                </Box>
            </StickyContainer>
            <Box style={{ backgroundColor: '#fbfbfd' }}>
                <ItemDetails.Tabs
                    variant="scrollable"
                    value={activeTab}
                    onChange={(_, value) => setActiveTab(value)}
                >
                    {allTabs
                        .filter((x) => x.display === true)
                        .filter((y) => {
                            if (y.requireAccess) {
                                return canAccessTab(y)
                            } else {
                                return true
                            }
                        })
                        .map((tab, index) => (
                            <ItemDetails.Tabs.Tab
                                key={tab.value + index}
                                value={tab.value}
                                label={tab.label}
                            />
                        ))}
                </ItemDetails.Tabs>
            </Box>
            {activeTab === 'overview' && (
                <OverviewSection applicationId={application?.id} />
            )}
            {activeTab === 'secrets' && (
                <PageContextProvider key="RequestViewSecrets">
                    <ApplicationSecrets
                        azureApplicationId={application?.azureApplicationId}
                    />
                </PageContextProvider>
            )}
            {activeTab === 'certificates' && (
                <PageContextProvider key="RequestViewCertificates">
                    <ApplicationCertificates
                        azureApplicationId={application?.azureApplicationId}
                    />
                </PageContextProvider>
            )}
            {activeTab === 'scopes' && (
                <PageContextProvider key="RequestViewScopes">
                    <ApplicationScopes id={application?.id} />
                </PageContextProvider>
            )}
            {activeTab === 'roles' && (
                <PageContextProvider key="RequestViewAppRoles">
                    <AzureApplicationRoles id={application?.id} />
                </PageContextProvider>
            )}
            {activeTab === 'permissions' && (
                <PageContextProvider key="RequestViewPermissions">
                    <ApplicationPermissions
                        id={application?.azureApplicationId}
                    />
                </PageContextProvider>
            )}
            {activeTab === 'tokens' && (
                <PageContextProvider key="RequestViewClaims">
                    <TokenConfiguration id={application?.azureApplicationId} />
                </PageContextProvider>
            )}
            {application && (
                <Box
                    className={classNames(
                        classes.actionsButton,
                        shiftActionsButton ? classes.actionsButtonShifted : '',
                    )}
                >
                    <ActionsButton item={application} />
                </Box>
            )}
        </Box>
    )
}

export default ApplicationDetails
