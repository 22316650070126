import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { Collapse, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
} from 'packages/core'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import {
    SharedFoldersTable,
    SharedFoldersTableHead,
    SharedFoldersRow,
} from './SharedFoldersTableComponents'
import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'
import {
    useSharedFolder,
    useApplicationSettings,
    useSharedFolders,
} from 'hooks'
import { usePageContext } from 'pageContext'
import { SubPathLevel } from 'appConfiguration'
import useSubcomponents from 'useSubcomponents'
import { SharedFolderDetails } from './Shared Folder Details'
import { useAppState } from 'appContext'

import ResourceMoreInformationTile from 'components/ResourceMoreInformationTile'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'
import { Skeleton } from '@mui/material'
import GenericButton from 'components/Button/GenericButton'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import CardView from 'components/EidTableGrid/CardView'
import { ITEMS_VIEW, RESOURCE_TYPE_NAMES } from 'utils'

const canSeeMoreInfornation = 'ResourceAdmin-SharedFolders-MoreInfo-Control'
const hasAccessToCreateAzureApplicationWorkflow =
    'ResourceAdmin-SharedFolders-CreateAzureApplicationWorkflow-Control'
const actionButtonsControlName = 'ResourceAdmin-SharedFolders-List-Actions'

const SharedFoldersLayout = ({ filters }) => {
    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [filterWidth, setFilterWidth] = useState(0)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [{ devFeaturesEnabled }, appDispatch] = useAppState()
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        rest,
    } = useSharedFolders()

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])
    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? ITEMS_VIEW.CARD : ITEMS_VIEW.LIST

    const { tableAttributes, detailAttributes, accessRequestAttributes } =
        attributes
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()
    const accessBasedAttributesForGroups =
        getAccessBasedAttributes(tableAttributes)

    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('No_SharedFolders_Message'),
        pagination,
        attributes: accessBasedAttributesForGroups,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: CardView,
            renderLeftButton: (item) => {
                return hasAccessToControl(actionButtonsControlName) ? (
                    <Box
                        data-protectedsubcomponent={actionButtonsControlName}
                        width="80%"
                    >
                        <GenericButton
                            onClick={() => {
                                history.push(`?sharedFolderId=${item?.id}`)
                            }}
                            color="#01ae8f"
                            rootStylesProp={{
                                borderRadius: '4px',
                                width: '100%',
                                height: '32px',
                            }}
                        >
                            {t('Details')}
                        </GenericButton>
                    </Box>
                ) : (
                    <></>
                )
            },
            renderRightButton: (item) => {
                return hasAccessToControl(actionButtonsControlName) ? (
                    <Box data-protectedsubcomponent={actionButtonsControlName}>
                        <UIActionsGearButton
                            item={item}
                            noun={'ResourceAdmin'}
                            resourcePath="sharedFolders"
                        />
                    </Box>
                ) : (
                    <></>
                )
            },
        },
        tableViewConfig: {
            TableComponent: SharedFoldersTable,
            TableHeadComponent: SharedFoldersTableHead,
            TableRowComponent: SharedFoldersRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView(ITEMS_VIEW.CARD)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmallScreen])

    const query = useQuery()
    const sharedFolderId = query.get('sharedFolderId')

    useEffect(() => {
        if (!sharedFolderId) {
            appDispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: null,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedFolderId])

    const workflowName = query.get('workflow')
    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig

    const accessibleStaticWorkflows =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) =>
                      w.resourceTypeName ===
                          RESOURCE_TYPE_NAMES.SHARED_FOLDERS &&
                      hasAccessToControl(w.protectedSubcomponentName),
              )
            : []

    const { data: sharedFolder } = useSharedFolder(sharedFolderId)
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const detailPageTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
        {
            label: t('IAmShopAssignments'),
            value: 'assignments',
            icon: <Icon name="AzureRbacRolesSmall" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-Details-Overview-IAmShopAssignments',
            },
        },
        {
            label: t('IAmShopAssignees'),
            value: 'assignees',
            icon: <Icon name="ResultantMembers" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-Details-Overview-IAmShopAssignees',
            },
        },
        {
            label: t('RbacAssignmentsToFolder'),
            value: 'assignmentsFolder',
            icon: <Icon name="ManagementRolesSmall" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-Details-Overview-RbacAssignmentsToFolder',
            },
        },
        {
            label: t('InventoriedPermissions'),
            value: 'inventoriedPermissions',
            icon: <Icon name="Regulations" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-Details-Overview-InventoriedPermissions',
            },
        },
        {
            label: t('AccessManagers(Owners)'),
            value: 'accessManagers',
            icon: <Icon name="ExternalCredential" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-Details-Overview-AccessManagers',
            },
        },
        {
            label: t('DirectAssignedLocations'),
            value: 'directAssignedLocations',
            icon: <Icon name="Claims" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-Details-Overview-DirectAssignedLocations',
            },
        },
        {
            label: t('AccessRequestPolicy'),
            value: 'accessRequestPolicy',
            icon: <Icon name="ClientCertificate" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-Details-Overview-AccessRequestPolicy',
            },
        },
    ]

    if (sharedFolderId) {
        if (isSmallScreen) {
            return (
                <AppLayout
                    totalCount={totalCount}
                    showBackButton={Boolean(sharedFolder)}
                >
                    {!sharedFolder ? (
                        <Fragment>
                            <Skeleton height={40} />
                            <Skeleton height={40} />
                            <Skeleton height={40} />
                        </Fragment>
                    ) : (
                        <SharedFolderDetails
                            tabs={detailPageTabs}
                            sharedFolderId={sharedFolderId}
                            basicAttributes={detailAttributes}
                            accessRequestAttributes={accessRequestAttributes}
                        />
                    )}
                </AppLayout>
            )
        }
        return (
            <AppLayout
                totalCount={totalCount}
                showNavTabs={!sharedFolderId}
                showBackButton={Boolean(sharedFolder)}
            >
                <DesktopContainer>
                    <Box marginBottom="1.6rem" width="100%">
                        {!sharedFolder ? (
                            <Fragment>
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                            </Fragment>
                        ) : (
                            <SharedFolderDetails
                                tabs={detailPageTabs}
                                sharedFolderId={sharedFolderId}
                                basicAttributes={detailAttributes}
                                accessRequestAttributes={
                                    accessRequestAttributes
                                }
                            />
                        )}
                    </Box>
                </DesktopContainer>
            </AppLayout>
        )
    }

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let content
    if (isSmallScreen) {
        content = (
            <SmallScreenContainer>
                <Box display={showFilters ? '' : 'none'}>
                    {mobileFiltersScreen}
                </Box>

                <Box display={!showFilters ? '' : 'none'}>
                    <Box display={''}>
                        <StickyContainer>
                            <Box width="100%">{mobileStickyContentListing}</Box>
                        </StickyContainer>
                        {viewToRender}
                    </Box>
                </Box>
            </SmallScreenContainer>
        )
    } else {
        content = (
            <DesktopContainer>
                <Fragment>
                    {((filters && filters.length > 0) ||
                        hasAccessToControl(canSeeMoreInfornation)) && (
                        <FiltersColumn ref={filterContainer}>
                            <FilterContainer>
                                <Box display="flex">
                                    <Box width="100%">
                                        <Card.CardHeader
                                            collapsible
                                            fontColor="black"
                                            cardTitle={
                                                openFilters
                                                    ? t('Hide_My_Filters')
                                                    : t('Show_My_Filters')
                                            }
                                            handleExpandClick={() =>
                                                setOpenFilters((prev) => !prev)
                                            }
                                            expanded={openFilters}
                                            icon={
                                                <Icon
                                                    name="AdvancedSearch"
                                                    color="#959598"
                                                />
                                            }
                                            style={styles.filterCardHeader}
                                        />
                                    </Box>
                                </Box>
                            </FilterContainer>

                            <Collapse
                                in={openFilters}
                                timeout={300}
                                unmountOnExit
                            >
                                {!sharedFolderId &&
                                    accessibleStaticWorkflows.length > 0 &&
                                    accessibleStaticWorkflows.map((wf) => (
                                        <Box
                                            style={{
                                                margin: '0 0 15px 0px',
                                            }}
                                            data-protectedsubcomponent={
                                                wf.protectedSubcomponentName
                                            }
                                        >
                                            <EidWorkflowButton
                                                title={t(
                                                    wf.displayName_LocaleKey,
                                                )}
                                                name={wf.requestWorkflowName}
                                                path={`/sharedFolders/workflows`}
                                                disabled={workflowName}
                                            />
                                        </Box>
                                    ))}
                                <ResourceMoreInformationTile
                                    moreInfoKey={'SharedFolders_MoreInfo_Html'}
                                    protectedsubcomponent={
                                        canSeeMoreInfornation
                                    }
                                    resourceId={sharedFolderId}
                                />
                                <>
                                    {filters.map((f) => {
                                        const FilterToRender =
                                            Filters.filtersMap[f.name]
                                        return (
                                            <FilterContainer
                                                data-protectedsubcomponent={
                                                    f.requireAccess
                                                }
                                                key={f.name}
                                            >
                                                <FilterToRender
                                                    config={f.config}
                                                />
                                            </FilterContainer>
                                        )
                                    })}
                                </>
                                <ListingPageUIActionByNounAndVerb
                                    noun="ResourceAdmin"
                                    verb="SharedFolder"
                                />
                            </Collapse>
                        </FiltersColumn>
                    )}

                    <ListingGrid>
                        <Box display="flex">
                            <Box width="100%">
                                <Filters.TextSearch />
                            </Box>
                        </Box>
                        <GridContainer
                            filterWidth={filterWidth}
                            openFilters={openFilters}
                        >
                            {viewToRender}
                        </GridContainer>
                    </ListingGrid>
                </Fragment>
            </DesktopContainer>
        )
    }

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showNavTabs={true}
        >
            <Fragment>{content}</Fragment>
        </AppLayout>
    )
}

export default SharedFoldersLayout
