import { Fragment } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import {
    useAccessRequestPolicy,
    useOwnersApproversDeputies,
    usePerson,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { PeopleListCard } from 'components/PeopleListCard/PeopleListCard'
import useSubcomponents from 'useSubcomponents'

const smallScreenWidth = 960
const canSeeOwnersControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-Overview-Owners-Control'
const canSeeApproversControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-Overview-Approvers-Control'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: 'auto',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: theme?.palette?.primary?.main,
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
        attribute: {
            marginTop: '-8px',
        },
        attributeLabel: {
            minWidth: '265px',
        },
        attributeValue: {
            wordBreak: 'unset',
        },
    }),
)

export const Overview = (props) => {
    const { role } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const { data: ownerPerson, isLoading: isLoadingOwner } = usePerson(
        role?.ownerId,
        Boolean(role?.ownerId && role?.ownerIsPerson),
    )
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()
    const attributes = [
        {
            label: 'TechnicalName',
            value: 'name',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-TechnicalName',
            },
        },
        {
            label: 'Type',
            value: 'managementRoleTypeFriendlyName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-Type',
            },
        },
        {
            label: 'SystemType',
            value: 'resourceTypeFriendlyName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-SystemType',
            },
        },
        {
            label: 'Description',
            value: 'description',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-Description',
            },
        },
        {
            label: 'IsHighSecurity',
            render: (data) => {
                return data && data.isHighSecurity ? t('Yes') : t('No')
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-IsHighSecurity',
            },
        },
        {
            label: 'ManagementRoleDefinition',
            value: 'managementRoleDefinitionFriendlyName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-ManagementRoleDefinition',
            },
        },
        {
            label: 'PublishInIAMShop',
            render: (data) => {
                return data && data.requestable ? t('Yes') : t('No')
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-PublishedInIAmShop',
            },
        },
    ]
    const policyAttributes = [
        {
            label: 'AccessRequestPolicy',
            value: 'name',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessRequestPolicy',
            },
        },
        {
            label: 'AllowsTimeConstrainedAccess',
            render: (policy) =>
                policy && policy.isTimeConstrained ? t('Yes') : t('No'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AllowsTimeConstrainedAccess',
            },
        },
        {
            label: 'DefaultAccessDuration',
            value: 'defaultValueInMinutes',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-DefaultAccessDuration',
            },
        },
        {
            label: 'MaxAccessDuration',
            value: 'maximumValueInMinutes',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-MaxAccessDuration',
            },
        },
    ]
    const policy = useAccessRequestPolicy(role && role.requestPolicyId)
    const { data: ownersAndApprovers, isLoading: loading } =
        useOwnersApproversDeputies(role && role.id)

    const roleDetail = (
        <Fragment>
            <Box className={classes.basicInfo}>
                {attributes &&
                    getAccessBasedAttributes(attributes).map((att, i) => {
                        return (
                            <Attribute
                                className={classes.attribute}
                                orientation="horizontal"
                                key={att.value + i}
                            >
                                <AttributeLabel
                                    className={classes.attributeLabel}
                                >
                                    {t(att.label)}
                                </AttributeLabel>
                                <AttributeValue
                                    className={classes.attributeValue}
                                >
                                    {att.render
                                        ? att.render(role)
                                        : role && role[att.value]}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
            </Box>
        </Fragment>
    )
    const policyDetails = (
        <Box>
            {policyAttributes &&
                getAccessBasedAttributes(policyAttributes).map((att, i) => {
                    return (
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                            key={att.value + i}
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t(att.label)}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {att.render
                                    ? att.render(policy)
                                    : policy && policy[att.value]}
                            </AttributeValue>
                        </Attribute>
                    )
                })}
        </Box>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box className={classes.section}>
                    <Box>
                        {roleDetail}
                        {policyDetails}
                    </Box>
                </Box>

                <Box>
                    {hasAccessToControl(canSeeOwnersControlName) && (
                        <Box
                            data-protectedsubcomponent={canSeeOwnersControlName}
                        >
                            <Attribute>
                                <AttributeLabel>
                                    {t('ManagementRoles_Owners')}
                                </AttributeLabel>
                                <PeopleListCard
                                    data={ownersAndApprovers?.owners}
                                    isLoading={loading}
                                    pageSize={3}
                                />
                            </Attribute>
                        </Box>
                    )}
                    <Attribute>
                        <AttributeLabel>{t('ResponsibleParty')}</AttributeLabel>

                        {!isLoadingOwner && ownerPerson ? (
                            <PeopleListCard
                                data={[ownerPerson]}
                                isLoading={isLoadingOwner}
                                pageSize={1}
                            />
                        ) : (
                            <AttributeValue>
                                {role?.ownerFriendlyName || '-'}
                            </AttributeValue>
                        )}
                    </Attribute>
                </Box>

                {hasAccessToControl(canSeeApproversControlName) && (
                    <Box
                        data-protectedsubcomponent={canSeeApproversControlName}
                        className={classes.section}
                    >
                        <Attribute>
                            <AttributeLabel> {t('Approvers')}</AttributeLabel>
                            <PeopleListCard
                                data={ownersAndApprovers?.approvers}
                                isLoading={loading}
                                pageSize={3}
                            />
                        </Attribute>
                    </Box>
                )}
            </Box>
        </Fragment>
    )
    return (
        <>
            <Paper
                style={{
                    padding: '32px',
                    width: 'fit-content',
                    minWidth: '100%',
                }}
            >
                <Box className={classes.section}>{renderContent()}</Box>
            </Paper>
        </>
    )
}
