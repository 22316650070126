import { useState, useEffect } from 'react'
import { Box, Paper } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useIsSmallScreenFalse, useQuery } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { useManagementRole } from 'hooks'
import { ItemDetails } from 'components'
import { LocalRights } from './LocalRights'
import { LocalSensitiveFunctions } from './LocalSensitiveFunctions'
import { LinkedApplications } from './LinkedApplications'
import { MainOverview } from './MainOverview'
import { useTranslation } from 'react-i18next'
import { PageContextProvider } from 'pageContext'
import Eligibility from './Eligibility'
import { useHistory } from 'react-router'
import {
    getAccessibleTabs,
    getTabsBasedOnAccess,
    updateUrlQueryParams,
} from 'utils'
import EligibilityPeople from './EligibilityPeople'
const smallScreenWidth = 960

const useStyles = makeStyles(() =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

const subTabs = [
    {
        name: 'Eligibility',
        value: 'eligibility',
        label: 'Eligibility',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-ManagementRole-Details-Overview-AccessGranted',
        },
    },
    {
        value: 'eligibilityPeople',
        label: 'EligibilityPeople',
        name: 'eligibilityPeople',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-ManagementRole-Details-Overview-EligibilityPeople',
        },
    },
    {
        name: 'Sensitive Functions',
        value: 'localSensitiveFunctions',
        label: 'SensitiveFunctions',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-ManagementRole-Details-Overview-SensitiveFunctions',
        },
    },
    {
        name: 'Rights',
        value: 'localRights',
        label: 'Rights',
        display: true,
        requireAccess: {
            control: 'ResourceAdmin-ManagementRole-Details-Overview-Rights',
        },
    },

    {
        name: 'Applications',
        value: 'applications',
        label: 'Applications',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-ManagementRole-Details-Overview-Applications',
        },
    },
]

export const Overview = (props) => {
    const { managementRoleId, basicAttributes } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const query = useQuery()
    const { hasAccessToControl } = useSubcomponents()
    const history = useHistory()
    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])
    const [subActiveTab, setSubActiveTab] = useState(null)

    const { isLoading: detailsLoading, data: managementRole } =
        useManagementRole(managementRoleId)

    useEffect(() => {
        if (managementRole) {
            const allTabs = getTabsBasedOnAccess(subTabs, hasAccessToControl)
            const queryActiveSubTab =
                query.get('subActiveTab') ||
                (allTabs?.length ? allTabs[0].value : '')
            if (queryActiveSubTab) {
                const activeTabInfo = getAccessibleTabs(
                    query,
                    history,
                    subTabs,
                    null,
                    queryActiveSubTab,
                    hasAccessToControl,
                    'activeTab',
                    'subActiveTab',
                )
                setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
                setSubActiveTab(activeTabInfo.querySubActiveTab)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managementRole])

    return (
        <Box>
            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                }}
            >
                <MainOverview
                    managementRole={managementRole}
                    detailsLoading={detailsLoading}
                    basicAttributes={basicAttributes}
                />
                {allAccessibleTabs.length > 0 && (
                    <Box
                        style={{
                            marginLeft: '-18px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 15,
                        }}
                    >
                        <ItemDetails.Tabs
                            value={subActiveTab}
                            variant={'scrollable'}
                            style={{ border: 'none' }}
                            onChange={(_, value) => {
                                setSubActiveTab(value)
                                updateUrlQueryParams(
                                    query,
                                    history,
                                    [{ key: 'subActiveTab', value: value }],
                                    [],
                                )
                            }}
                        >
                            {allAccessibleTabs.map((tab, index) => {
                                return (
                                    <ItemDetails.Tabs.Tab
                                        data-protectedsubcomponent={
                                            tab.requireAccess?.control
                                        }
                                        key={tab.value + index}
                                        value={tab.value}
                                        label={t(tab.label)}
                                    />
                                )
                            })}
                        </ItemDetails.Tabs>
                    </Box>
                )}
            </Paper>
            {subActiveTab === 'eligibility' && (
                <PageContextProvider key="RequestViewManagementRolesEligbility">
                    {managementRole?.resourceTypeId && (
                        <Eligibility
                            roleId={managementRoleId}
                            resourceTypeId={managementRole?.resourceTypeId}
                            resourceId={managementRole.resourceId}
                        />
                    )}
                </PageContextProvider>
            )}
            {subActiveTab === 'localSensitiveFunctions' && (
                <PageContextProvider key="RequestViewManagementRolesLocalSensitiveFunctions">
                    <LocalSensitiveFunctions
                        managementRoleId={managementRoleId}
                    />
                </PageContextProvider>
            )}
            {subActiveTab === 'localRights' && (
                <PageContextProvider key="RequestViewManagementRolesLocalRights">
                    <LocalRights managementRoleId={managementRoleId} />
                </PageContextProvider>
            )}
            {subActiveTab === 'applications' && (
                <PageContextProvider key="RequestViewManagementRolesLinkedApplications">
                    <LinkedApplications managementRoleId={managementRoleId} />
                </PageContextProvider>
            )}
            {subActiveTab === 'eligibilityPeople' && (
                <PageContextProvider key="RequestViewManagementRolesEligibilityPeople">
                    <EligibilityPeople roleId={managementRoleId} />
                </PageContextProvider>
            )}
        </Box>
    )
}
