import {
    MenuItem,
    ListItemText,
    ListItemIcon,
    Box,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from 'packages/eid-icons'
import { useState } from 'react'
import useSubcomponents from 'useSubcomponents'
import { defaultDataProtectedSubcomponent } from 'utils'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        backgroundColor: props.isSubTabItem
            ? 'rgba(48, 127, 193, 0.07)'
            : 'unset',
        color: props.isSubTabItem ? theme?.palette?.primary?.main : 'black',
        borderLeft: props.isSubTabItem
            ? `2px solid ${theme?.palette?.primary?.main}`
            : 'none',
    }),
    icon: {
        minWidth: '40px !important',
        color: 'black',
    },
    selected: (props) => ({
        backgroundColor: props.isSubTabItem
            ? `${theme?.palette?.primary?.main} !important`
            : 'rgba(48, 127, 193, 0.07) !important',
        borderLeft: `2px solid ${theme?.palette?.primary?.main}`,
        color: props.isSubTabItem ? 'white' : 'black',

        '& img': {
            opacity: 1,
            filter: 'grayscale(0%)',
        },
    }),
}))

const TabsItem = ({
    label,
    icon,
    selected,
    onClick,
    subTabs,
    isSubTabItem,
    onSubTabChange,
    subActiveTab,
    protectedSubcomonent,
}) => {
    const [expandSubTabs, setExpandSubTabs] = useState(true)
    const theme = useTheme()
    const classes = useStyles({
        hasSubTabs: subTabs && subTabs.length > 0,
        isSubTabItem,
    })
    const { hasAccessToControl } = useSubcomponents()
    return (
        <>
            <MenuItem
                onClick={() => {
                    if (selected) {
                        setExpandSubTabs((pre) => !pre)
                    } else {
                        setExpandSubTabs(true)
                        onClick()
                    }
                }}
                classes={classes}
                selected={selected}
                data-protectedsubcomponent={
                    protectedSubcomonent || defaultDataProtectedSubcomponent
                }
            >
                {icon && (
                    <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                )}
                <ListItemText>
                    <Box
                        style={{
                            marginLeft: isSubTabItem ? '40px' : '0px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                            whiteSpace:
                                subTabs && subTabs.length > 0
                                    ? 'normal'
                                    : 'nowrap',
                        }}
                    >
                        <Box style={{ whiteSpace: 'break-spaces' }}>
                            {label}
                        </Box>
                        {subTabs && subTabs.length > 0 && (
                            <Box>
                                <Icon
                                    style={{
                                        height: '16px',
                                        width: '16px',
                                    }}
                                    name={'Arrow'}
                                    direction={
                                        selected && expandSubTabs
                                            ? 'up'
                                            : 'down'
                                    }
                                    color={theme?.palette?.primary?.main}
                                />
                            </Box>
                        )}
                    </Box>
                </ListItemText>
            </MenuItem>
            <Box
                style={{
                    transform: `scaleY(${
                        selected && subTabs && expandSubTabs ? 1 : 0
                    })`,
                    transition: 'transform 0.2s ease-out-in',
                    transformOrigin:
                        selected && subTabs && expandSubTabs ? 'top' : 'bottom',
                    maxHeight:
                        selected && subTabs && expandSubTabs
                            ? 'fit-content'
                            : 0,
                }}
            >
                {subTabs
                    ?.filter(
                        (t) =>
                            t.display &&
                            (!t?.requireAccess?.control ||
                                hasAccessToControl(t?.requireAccess?.control)),
                    )
                    .map((subTab, index) => (
                        <TabsItem
                            key={subTab.label + index}
                            label={subTab.label}
                            selected={subActiveTab === subTab.value}
                            onClick={() => onSubTabChange(subTab.value)}
                            isSubTabItem
                            protectedSubcomonent={
                                subTab?.requireAccess?.control
                            }
                        />
                    ))}
            </Box>
        </>
    )
}

const LeftPaneTabs = ({
    active,
    onChange,
    tabs,
    onSubTabChange,
    subActiveTab,
}) => {
    const { controls } = useSubcomponents()

    const isAccessible = (tab) => {
        return controls.includes(tab.requireAccess?.control)
    }

    return (
        <>
            {tabs &&
                tabs
                    .filter((x) => x.display === true)
                    .filter((y) => {
                        if (y.requireAccess) {
                            return isAccessible(y)
                        } else {
                            return true
                        }
                    })
                    .map((tab, index) => (
                        <TabsItem
                            key={`${tab.value}_${index}`}
                            label={tab.label}
                            onClick={() => {
                                onChange(tab.value)
                            }}
                            selected={active === tab.value}
                            icon={tab.icon}
                            subTabs={tab.subTabs}
                            onSubTabChange={onSubTabChange}
                            subActiveTab={subActiveTab}
                            protectedSubcomonent={tab?.requireAccess?.control}
                        />
                    ))}
        </>
    )
}

export default LeftPaneTabs
