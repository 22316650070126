import React from 'react'
import { ArrowIcon } from 'packages/eid-icons'
import { EidAutocomplete } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'name',
})

const queryParams = {
    take: 500,
}

const getOptionLabel = (option) => option.name

function AccessAssignmentAccessLevels({ value, labelKey, url = '/resourceassignments/resourcetyperoles', excludeOptions, ...rest }) {
    const { t } = useTranslation()
    return (
        <EidAutocomplete.New
            label={t(labelKey)}
            placeholder={t('SelectAccessLevel')}
            url={url}
            queryParams={queryParams}
            dedupingInterval={60000}
            value={value ? value : null}
            getOptionLabel={getOptionLabel}
            endAdornmentComponent={() => (
                <ArrowIcon direction="right" color="#453fbb" />
            )}
            optionComponent={OptionComponent}
            excludeOptions={excludeOptions}
            advancedSearchColumns={[]}
            {...rest}
        />
    )
}

export default AccessAssignmentAccessLevels
