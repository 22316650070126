import { useAxios } from 'packages/core'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'

export const useModalSearchForAssignmentPopup = (
    url,
    queryFilters,
    enableApi,
) => {
    const callApi = useAxios()
    const queryData = { ...queryFilters }
    return useApiWithInfiniteScroll(
        [`MODAL_SEARCH_KEY_UnAssigned`, queryData, url, enableApi],
        (skip) => {
            return callApi({
                url: url,
                method: 'POST',
                data: {
                    ...queryData,
                },
            })
        },
        { enabled: enableApi },
        // using defaultTake param and removing above from data
        queryData?.take ? queryData.take : 5,
        {
            variant: 'rounded',
            siblingCount: 0,
            showPageSelection: false,
            showItemsPerPageSelection: false,
        },
    )
}
