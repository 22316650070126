import React from 'react'
import BusinesRoleAndLocationTree from './BusinesRoleAndLocationTree'

const BusinessLocationAndRoleChildNode = React.memo(
    ({
        item,
        handleSelect,
        value,
        isExpanded,
        highlightText,
        disableAssignableCheck,
    }) => {
        return (
            <BusinesRoleAndLocationTree
                explorer={item}
                handleSelect={handleSelect}
                value={value}
                disableAssignableCheck={disableAssignableCheck}
                isExpanded={isExpanded}
                highlightText={highlightText}
            />
        )
    },
)

export default BusinessLocationAndRoleChildNode
