import { Box, styled, Typography } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAssignRoleDefinitionForPeople,
    useUnassignRoleDefinitionsForPeople,
    useApplicationPeopleByResourceId,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl, imagesDisabled } from 'utils'
import useSubcomponents from 'useSubcomponents'
import { PageContextProvider } from 'pageContext'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'
import Filters from 'components/Filters'
import EditAssignment from 'components/Popups/EditAssignments'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const AssignmentHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})
const RoleSetting = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& button': { height: '32px', lineHeight: '16px' },
})
const canAssignNewRoleDefinitions =
    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-AssignRoleDefinition-Control'
const EditAssignmentPopup = ({
    applicationId,
    data,
    applicationFriendlyName,
    refetch,
}) => {
    const [assignRoles, { isLoading: isAssigning }] =
        useAssignRoleDefinitionForPeople(refetch)
    const [unAssignRoles, { isLoading: isUnAssigning }] =
        useUnassignRoleDefinitionsForPeople(refetch)

    const handleSubmit = (assignedList, unAssignedList) => {
        const unAssignedRights = assignedList?.map((i) => {
            return {
                localRoleId: i.id,
                endDate: i.endDateUtc,
            }
        })
        const assignedIds = unAssignedList.map((i) => i.id)
        if (assignedIds.length > 0) {
            unAssignRoles({
                assigneeIds: [data?.id],
                localRoleIds: assignedIds,
            })
        }
        if (unAssignedRights.length > 0) {
            assignRoles({
                assigneeIds: [data?.id],
                localRolesToAssign: unAssignedRights,
            })
        }
    }
    return (
        <PageContextProvider key={'RoleDefinitionsPeopleEditAssignment'}>
            <EditAssignment
                labelKey={'Edit'}
                submitButtonLabelKey={'Save'}
                assignedUrl={`/api/AzureRoles/GetEligibleAppRolesByApplicationByAssignee`}
                unAssignedUrl={
                    '/api/AzureRoles/GetEligibleAppRolesByApplicationByAssignee'
                }
                targetAssigneeId={applicationId}
                assignedQueryParams={{
                    applicationId,
                    eligibilityAssigneeId: data?.id,
                    isAssigned: true,
                }}
                unAssignedQueryParams={{
                    applicationId,
                    eligibilityAssigneeId: data?.id,
                    isAssigned: false,
                }}
                headerText={{
                    dynamicText: applicationFriendlyName,
                    text: 'ChooseRoleDefinitions',
                }}
                searchPlaceHolder={'SearchFilterRoleDefinitions'}
                onSubmit={handleSubmit}
                submitLoader={isAssigning || isUnAssigning}
                closeOnSubmit={false}
                showAccessRequestPolicy={true}
                fetchRequestPolicyItemWise={true}
                requestPolicyIdProperty="requestPolicyId"
                showStartDate={false}
            />
        </PageContextProvider>
    )
}
const ApplicationRoleDefinitionPeople = ({
    applicationId,
    applicationFriendlyName,
}) => {
    const { t } = useTranslation()

    const queryFilters = {
        showLocalRoles: true,
    }

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationPeopleByResourceId(
            applicationId,
            queryFilters,
        )
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            sortable: false,
            label: 'Assignments',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Assignments',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        <BooleanIcon
                            data={data}
                            attribute={{ name: 'localRoleAssigned' }}
                            nullAsFalse={true}
                        />
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'DisplayName',
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-DisplayName',
            // },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        {!imagesDisabled && (
                            <Avatar src={getFullImageUrl(data.imageThumbUrl)} />
                        )}
                        <Typography
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                        >
                            {data.friendlyName}
                        </Typography>
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'login',
            sortable: true,
            sortBy: 'login',
            label: 'Login',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Login',
            },
        },
        {
            name: 'email',
            sortable: true,
            sortBy: 'email',
            label: 'Email',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Email',
            },
        },
        {
            name: 'enabled',
            sortable: true,
            sortBy: 'enabled',
            label: 'Enabled',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Enabled',
            },

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <Typography
                        style={{ fontSize: '13px', fontWeight: 'normal' }}
                    >
                        {data.enabled ? t('Yes') : t('No')}
                    </Typography>
                ),
            },
        },

        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <RoleSetting>
                            {hasAccessToControl(
                                canAssignNewRoleDefinitions,
                            ) && (
                                <EditAssignmentPopup
                                    applicationId={applicationId}
                                    data={data}
                                    applicationFriendlyName={
                                        applicationFriendlyName
                                    }
                                    refetch={refetch}
                                />
                            )}
                        </RoleSetting>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
            >
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationRoleDefinitionPeople
