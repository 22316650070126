import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    getFullPathFromUrl,
    useIsSmallScreenFalse,
    useQuery,
} from 'packages/core'
import { ListingGrid, GridContainer, Button, Filters } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Icon } from 'packages/eid-icons'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAppManagementRoleAccessGranted,
    useDeleteAssigneeAssignment,
} from 'hooks'
import DateTimeGenericComponent from 'components/DynamicComponents/DateTimeGenericComponent'
import { useHistory } from 'react-router'
import config from 'config'
import DeleteAccessGranted from './DeleteAccessGranted'
import { WarningModal } from 'packages/eid-ui'
import { useState } from 'react'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import StatusInfoMessage from 'components/Popups/StatusMessage/StatusInfoMessage'

const canAddNewAccessAssignmentControlName =
    'ResourceAdmin-ManagementRoleDetails-AccessGranted-AddNewAccessAssignment-Control'
const canDeleteAccess =
    'ResourceAdmin-ManagementRoleDetails-AccessGranted-DeleteAccessAssignment-Control'
const canRemoveMultipleControlName =
    'ResourceAdmin-ManagementRoleDetails-AccessGranted-DeleteAccessAssignment-Control'

const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const useButtonStyles = makeStyles((theme) =>
    createStyles({
        root: () => ({
            height: '40px',
            borderRadius: '4px',
            padding: '0px 15px 0px 10px',
            backgroundColor: theme?.palette?.primary?.main,
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
            },
            '& svg': {
                marginRight: '8px',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

const AccessGranted = ({ managementRoleId, resourceTypeId, resourceId }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)
    const [selected, setSelected] = useState([])
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const query = useQuery()
    const history = useHistory()
    const classes = useButtonStyles(isSmallScreen)
    const [updateInfoMessages, setUpdateInfoMessages] = useState([])

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        refetch,
    } = useAppManagementRoleAccessGranted(managementRoleId, resourceTypeId)

    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteAssigneeAssignment()

    const handleDelete = () => {
        deleteAssignments(selected).then((data) => {
            setSelectedItemsToDelete(null)

            const updatedResponse = data?.data
            if (updatedResponse?.length) {
                setUpdateInfoMessages(updatedResponse)
            }
        })
    }

    const attributes = [
        {
            name: 'resourceTypeRoleFriendlyName',
            sortable: true,
            label: 'AccessLevel',
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ManagementRoleDetails-AccessGranted-AccessLevel',
            // },
        },
        {
            name: 'assignmentLocation',
            sortable: true,
            label: 'AssignmentLocation',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-AssignmentLocation',
            },
        },
        {
            name: 'assignmentDescription',
            sortable: true,
            label: 'AssignmentDescription',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-AssignmentDescription',
            },
        },
        {
            name: 'resourceFriendlyName',
            sortable: true,
            label: 'Resource',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-Resource',
            },
        },
        {
            name: 'resourceTypeFriendlyName',
            sortable: true,
            label: 'ResourceType',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-ResourceType',
            },
        },
        {
            label: 'TimeConstraint',
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-TimeConstraint',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 20 }}>
                            <Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 10,
                                    }}
                                >
                                    <strong>{t('Starts')}:</strong>
                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'startDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 17,
                                        marginTop: '5px',
                                    }}
                                >
                                    <strong>{t('Ends')}:</strong>

                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'endDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                            </Box>
                            {/* TODO: Unhide this when backend functionality is implemented */}
                            {/* <AddTimeConstraint
                                data={data}
                                iconName={'EditFile'}
                                onSave={(data) => {
                                    console.log(data)
                                }}
                                startTimeProp="startDateUtc"
                                endTimeProp="endDateUtc"
                            /> */}
                        </Box>
                    )
                },
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(canDeleteAccess) && (
                            <DeleteAccessGranted
                                item={data}
                                refetch={refetch}
                                handleDeleteCallback={handleDeleteCallback}
                            />
                        )
                    )
                },
            },
        },
    ]

    const handleDeleteCallback = (itemId) => {
        if (selected?.length) {
            setSelected(selected.filter((x) => x !== itemId))
        }
    }

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }
    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.id)
            setSelected(filteredList)
        }
    }

    const selectAllAction = hasAccessToControl(canRemoveMultipleControlName)
        ? {
              render: () => {
                  return (
                      <Box>
                          <BulkRemoveButton
                              selectedCount={selected.length}
                              label={t('RemoveSelectedAccess', {
                                  count: selected.length,
                              })}
                              onClick={handleDeleteBulk}
                              dataProtectedsubcomponent={
                                  canRemoveMultipleControlName
                              }
                          />
                      </Box>
                  )
              },
          }
        : null

    return (
        <Box>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {hasAccessToControl(
                        canAddNewAccessAssignmentControlName,
                    ) && (
                        <Box
                            data-protectedsubcomponent={
                                canAddNewAccessAssignmentControlName
                            }
                        >
                            <Button
                                onClick={() => {
                                    query.set(
                                        'workflowName',
                                        'GrantActorAccessWF',
                                    )
                                    query.set(
                                        'TargetAssigneeId',
                                        managementRoleId,
                                    )
                                    query.set('returnUrl', getFullPathFromUrl())
                                    history.push(
                                        `${
                                            config.APP_SUBPATH
                                        }/managementRoles/workflows?${query.toString()}`,
                                    )
                                }}
                                className={classes.root}
                            >
                                {<Icon name="Plus" color="#fff" />}
                                {t('AddNewAccessAssignment')}
                            </Button>
                        </Box>
                    )}

                    <Box width="240px" display="flex" justifyContent="flex-end">
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '16px 0px',
                        marginTop: '0px',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={list}
                        handleSelectAllClick={handleSelectAllClick}
                        selected={selected}
                        handleClick={handleRowSelection}
                        selectAllAction={selectAllAction}
                        rowSelection={true}
                    />
                </GridContainer>
            </ListingGrid>
            {selectedItemsToDelete && (
                <WarningModal
                    title={t('DeleteSelectedItems')}
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete.length}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceeding={isDeletingAssignment}
                    proceed={handleDelete}
                />
            )}
            {updateInfoMessages?.length ? (
                <StatusInfoMessage
                    messages={updateInfoMessages}
                    title={'DeleteAssignmentsStatus'}
                    handleClose={() => {
                        setUpdateInfoMessages([])
                        setSelected([])
                        refetch()
                    }}
                />
            ) : null}
        </Box>
    )
}

export default AccessGranted
