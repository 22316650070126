import React, { Fragment, createRef, useEffect, useRef } from 'react'
import {
    TableHead,
    TableSortLabel,
    TableRow,
    TableCell,
    Box,
    styled,
    Table,
    useTheme,
    Radio,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Checkbox, Tooltip } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import { TextFormatter } from 'components'
import { Icon } from 'packages/eid-icons'
import DynamicComponents from 'components/DynamicComponents'
import { getMasterLockFieldsWidth, getMasterLockLeftSpace } from 'utils'

const maxCharacters = 100

const useHeaderRowStyles = makeStyles({
    root: (headerRowStyles) => ({
        backgroundColor: '#fbfbfd',
        height: '32px',
        padding: '23px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
        borderBottom: '1px solid',
        ...headerRowStyles,
    }),
})

const useSortLabelStyles = makeStyles((theme) => ({
    root: (sortLabelStyles) => ({
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
        },
        '&:hover': {
            color: `${theme?.palette?.primary?.main} !important`,

            '& svg': {
                color: `${theme?.palette?.primary?.main} !important`,
            },
        },
        ...sortLabelStyles,
    }),

    active: {
        '& svg': {
            color: `${theme?.palette?.primary?.main} !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
}))

const useHeaderCellStyles = makeStyles({
    root: (headerCellStyles) => ({
        color: '#7d7c7c',
        padding: '15px 10px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: 'normal',
        height: '62px',
        fontSize: '13px',
        '&:first-child': {
            paddingLeft: '20px',
        },
        '&:last-child': {
            paddingRight: '20px',
        },
        ...headerCellStyles,
    }),
})
const headercheckbox = {
    padding: '15px 10px',
    paddingLeft: '20px',
    textAlign: 'center',
}

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    disableInteractive: false,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

export const ListTableHead = ({
    attributes,
    data,
    handleSelectAllClick,
    selected,
    rowSelection,
    headerRowStyles,
    headerCellStyles,
    sortLabelStyles,
    onSort,
    showSelectAll,
}) => {
    const { t } = useTranslation()
    const [
        { sortBy, sortOrder, masterPasswordWidth, masterPasswordLeftWidth },
        dispatch,
    ] = usePageContext()
    const headerRowClasses = useHeaderRowStyles(headerRowStyles)
    const headerCellClasses = useHeaderCellStyles(headerCellStyles)
    const sortLabelClasses = useSortLabelStyles(sortLabelStyles)

    const tableHeadingRefs = useRef(
        attributes?.length > 0 ? attributes.map(() => createRef()) : null,
    )

    const handleResize = () => {
        const updatedMasterLockFieldsWidth = getMasterLockFieldsWidth(
            attributes,
            tableHeadingRefs,
        )
        const updatedMasterLockLeftSpace = getMasterLockLeftSpace(
            attributes,
            tableHeadingRefs,
        )

        const masterPasswordOverlayDisturbed =
            masterPasswordWidth !== updatedMasterLockFieldsWidth ||
            masterPasswordLeftWidth !== updatedMasterLockLeftSpace

        if (!masterPasswordOverlayDisturbed) {
            return
        }
        dispatch({
            type: 'SET_MASTER_PASSWORD_WIDTH',
            payload: {
                masterPasswordWidth: updatedMasterLockFieldsWidth,
                masterPasswordLeftWidth: updatedMasterLockLeftSpace,
            },
        })
    }

    const handleSort = (heading) => {
        const sortByName = heading.sortBy ? heading.sortBy : heading.name
        if (onSort) {
            onSort(
                sortByName,
                sortBy !== sortByName
                    ? 'desc'
                    : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                    ? 'desc'
                    : 'asc',
            )
        }
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                sortBy: sortByName,
                sortOrder:
                    sortBy !== sortByName
                        ? 'desc'
                        : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                        ? 'desc'
                        : 'asc',
            },
        })
    }

    const getCorespondingRef = (name) => {
        const itemIndex = attributes.findIndex((x) => x.name === name)
        return itemIndex >= 0 ? tableHeadingRefs.current[itemIndex] : null
    }

    // This will handle the resizing once data is loaded. As Header picks default width and once data loaded the width is getting changed
    useEffect(() => {
        if (data?.length) {
            handleResize()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <TableHead>
            <TableRow classes={headerRowClasses}>
                {rowSelection && (
                    <TableCell padding="checkbox" style={headercheckbox}>
                        {showSelectAll && (
                            <Checkbox
                                icon={<Icon name="CheckBox" />}
                                disabled={!data?.length}
                                checkedIcon={<Icon name="CheckedBox" />}
                                checked={
                                    selected.length > 0 &&
                                    data?.every((item) =>
                                        selected.some((d) => d === item.id),
                                    )
                                }
                                onChange={handleSelectAllClick}
                            />
                        )}
                    </TableCell>
                )}
                {attributes
                    .filter((a) => a.showInTable !== false)
                    .map((heading, index) => {
                        const sortByName = heading.sortBy
                            ? heading.sortBy
                            : heading.name
                        return (
                            <TableCell
                                data-protectedsubcomponent={
                                    heading?.requireAccess?.control
                                        ? heading.requireAccess.control
                                        : 'NotProtected'
                                }
                                key={'pd-head-cell-' + index}
                                classes={headerCellClasses}
                                align="left"
                                style={heading.headerCellStyles}
                                ref={getCorespondingRef(heading.name)}
                            >
                                {!heading.sortable ? (
                                    <span
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        {t(heading.label)}
                                    </span>
                                ) : (
                                    <span style={{ display: 'flex' }}>
                                        <Tooltip
                                            title={
                                                sortBy === sortByName &&
                                                sortOrder === 'desc'
                                                    ? t('SortAscending')
                                                    : t('SortDescending')
                                            }
                                        >
                                            <TableSortLabel
                                                classes={sortLabelClasses}
                                                active={sortBy === sortByName}
                                                direction={
                                                    sortBy === sortByName
                                                        ? sortOrder
                                                        : 'asc'
                                                }
                                                onClick={() =>
                                                    handleSort(heading)
                                                }
                                            >
                                                {t(heading.label)}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </span>
                                )}
                            </TableCell>
                        )
                    })}
            </TableRow>
        </TableHead>
    )
}
const useRowStyles = makeStyles({
    root: {
        width: '150px',
        backgroundColor: '#ffffff',
        borderTopLeftRadius: '8px !important',
        borderTopRightRadius: '8px !important',
        minHeight: '40px',
        border: ' solid 1px #efeff1',

        '&:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
})

const useRowImageStyles = makeStyles({
    image: {
        maxHeight: '48px',
    },
})

const useCellStyles = makeStyles({
    root: () => ({
        padding: '15px 10px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: 'normal',
        height: '62px',
        '&:first-child': {
            paddingLeft: '20px',
        },
        '&:last-child': {
            paddingRight: '20px',
        },
    }),
})

const StyledTable = styled(Table)(({ styles }) => ({
    border: ' solid 1px #efeff1 !important',
    borderCollapse: 'separate',
    ...styles,
}))

const contentCheckbox = {
    padding: '15px 10px',
    paddingLeft: '20px',
    textAlign: 'center',
}

export const ListTable = (props) => <StyledTable {...props} />

export const ListTableRow = ({
    attributes,
    item,
    rowSelection,
    selected,
    handleClick,
    selectionIdentifier,
    showSelectAll,
    highLightSelectedRow,
}) => {
    const rowClasses = useRowStyles()
    const imageClasses = useRowImageStyles()
    const cellClasses = useCellStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const renderComponent = (a, item, index) => {
        if (a.component.componentName === 'renderer') {
            return <Fragment>{a.component.render(item, index)}</Fragment>
        } else {
            const DynamicComponentToRender =
                DynamicComponents[a.component.componentName]
            return (
                <DynamicComponentToRender
                    attribute={a}
                    data={{ item }}
                    imageClass={imageClasses.image}
                />
            )
        }
    }

    const isSelected = (id) => selected.indexOf(id) !== -1
    const disabledStyle = {
        cursor: item?.isAlreadyAssigned === true ? 'not-allowed' : 'pointer',
    }
    const selectedStyles =
        highLightSelectedRow && isSelected(item[selectionIdentifier])
            ? {
                  border: `2px solid ${theme?.palette?.primary?.main}`,
              }
            : {}
    return (
        <React.Fragment>
            <TableRow style={{ ...selectedStyles }} classes={rowClasses}>
                {rowSelection && (
                    <Tooltip
                        title={
                            item?.isAlreadyAssigned
                                ? t('Common_ItemAlreadyInCart')
                                : ''
                        }
                    >
                        <TableCell
                            padding="checkbox"
                            style={{ ...contentCheckbox, ...disabledStyle }}
                        >
                            {showSelectAll ? (
                                <Checkbox
                                    icon={<Icon name="CheckBox" />}
                                    checkedIcon={<Icon name="CheckedBox" />}
                                    checked={isSelected(
                                        item[selectionIdentifier],
                                    )}
                                    onClick={() => handleClick(item)}
                                    disabled={
                                        item?.isAlreadyAssigned ? true : false
                                    }
                                />
                            ) : (
                                <Radio
                                    sx={{ mr: '-1.7rem' }}
                                    icon={<Icon name="Radio" />}
                                    checkedIcon={
                                        <Icon
                                            name="RadioFilled"
                                            color={
                                                theme?.palette?.primary?.main
                                            }
                                        />
                                    }
                                    onClick={() => handleClick(item)}
                                    checked={isSelected(
                                        item[selectionIdentifier],
                                    )}
                                    disabled={
                                        item?.isAlreadyAssigned ? true : false
                                    }
                                />
                            )}
                        </TableCell>
                    </Tooltip>
                )}
                {attributes
                    .filter((a) => a.showInTable !== false)
                    .map((a, index) => {
                        return (
                            <TableCell
                                key={item.id + `${index}`}
                                classes={cellClasses}
                                style={a.styles}
                                link={a.link}
                                url={a.url}
                                sx={{ padding: '23px' }}
                            >
                                <Box
                                    display="block"
                                    alignItems="center"
                                    width={a.styles?.width}
                                >
                                    {!a.hideValue && (
                                        <TextFormatter
                                            value={item[a.name]}
                                            tooltipProps={tooltipProps}
                                            style={{
                                                fontSize: '13px',
                                                ...a.styles,
                                            }}
                                            maxCharacters={maxCharacters}
                                        />
                                    )}

                                    {a.component &&
                                        renderComponent(a, item, index)}
                                </Box>
                            </TableCell>
                        )
                    })}
            </TableRow>
        </React.Fragment>
    )
}

export const WhiteTable = (props) => {
    return (
        <ListTable
            styles={{
                backgroundColor: '#ffffff',
                '& tr:hover': {
                    backgroundColor: '#f7f8fa',
                },
            }}
            {...props}
        />
    )
}

export const BlueTableHeader = (props) => {
    const theme = useTheme()
    return (
        <ListTableHead
            headerRowStyles={{
                fontWeight: 'bold',
                backgroundColor: 'transparent',
                '& tr:hover': {
                    backgroundColor: '#f7f8fa',
                },
            }}
            headerCellStyles={{
                height: 'auto',
                overflowWrap: 'break-word',
                maxWidth: '220px',
                wordBreak: 'normal',
                overflow: 'hidden',
                lineHeight: '20px',
                fontSize: '12px',
                color: `${theme?.palette?.primary?.main} !important`,
                textTransform: 'uppercase',
                fontWeight: 'bold',
                backgroundImage:
                    'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
                backgroundSize: '8.00px 8.00px',
                '& svg': {
                    margin: '0px !important',
                },
            }}
            {...props}
        />
    )
}
