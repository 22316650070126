import React from 'react'
import { Box, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Spinner } from 'packages/eid-ui'

const useStyles = makeStyles({
    root: (props) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        width: props.width,
        height: props.height,
        padding: props.padding,
        backgroundColor: props.disabled ? '#d0d9e2' : props.bgColor,
        border: props.border,
        color: props?.fontColor ? props.fontColor : '#ffffff',
        '&:hover': {
            cursor: props.disabled ? 'initial' : 'pointer',
            backgroundColor: props.hoverColor,
        },
    }),
})

const ActionButton = (props) => {
    const {
        width,
        height,
        padding,
        bgColor,
        hoverColor,
        border,
        fontColor,
        children,
        loading,
        onClick,
        disabled,
        loaderColor,
    } = props
    const classes = useStyles({
        width,
        height,
        padding,
        bgColor,
        border,
        fontColor,
        hoverColor,
        disabled,
    })
    return (
        <Link
            component="button"
            underline="none"
            onClick={disabled ? undefined : onClick}
            style={{ width: width ? width : '100%' }}
        >
            <Box className={classes.root}>
                {loading && <Spinner size={0.8} color={loaderColor} />}
                {!loading && children}
            </Box>
        </Link>
    )
}

ActionButton.defaultProps = {
    width: '100px',
    height: '48px',
    bgColor: '#ffffff',
    border: 'none',
    loaderColor: '#ffffff',
    padding: '0px 8px',
}

export default ActionButton
