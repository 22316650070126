import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { EidButton, WarningModal } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useQuery } from 'packages/core'
import { useDeletePermission } from 'hooks'
import { Button } from 'components'

const DeletePermission = ({ item, drawerView, ...rest }) => {
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()

    const [showConfirm, setConfirm] = useState(false)
    const [deletePermission, { isLoading: isDeleting }] = useDeletePermission()

    const handleDelete = () => {
        deletePermission(item.id).then(() => {
            setConfirm(false)
            query.delete('permissionId')
            history.push(`${history.location.pathname}?${query.toString()}`)
        })
    }

    return (
        <>
            {!drawerView ? (
                <Button.Delete
                    item={item}
                    onClick={() => setConfirm(true)}
                    disabled={item.isExpired}
                    {...rest}
                />
            ) : (
                <Box width="150px" marginLeft={'40px'}>
                    <EidButton.DrawerActionButton
                        disabled={item.isExpired}
                        fontColor="#ffffff"
                        bgColor="#E40000"
                        width="100%"
                        onClick={() => setConfirm(true)}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            height={'12px'}
                            width={'12px'}
                        >
                            <Icon
                                name="DeleteX"
                                fill="#ffffff"
                                color="#ffffff"
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t('Delete')}{' '}
                            </Typography>
                        </Box>
                    </EidButton.DrawerActionButton>
                </Box>
            )}
            <WarningModal
                title={t('Delete') + ' ' + item?.friendlyName}
                description={t('PermissionDeleteMessage')}
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={showConfirm}
                cancel={() => setConfirm(false)}
                proceeding={isDeleting}
                proceed={handleDelete}
            />
        </>
    )
}

DeletePermission.defaultProps = {
    drawerView: false,
}
export default DeletePermission
