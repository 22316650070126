import React from 'react'
import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { PageContextProvider } from 'pageContext'
import { useManagementRole } from 'hooks'
import AddNewAssignment from 'components/Popups/AddNewAssignment/AddNewAssignment'
import { RESOURCE_TYPE_NAMES } from 'utils'

const AddNewGroup = ({ protectedsubcomponent, managementRoleId }) => {
    const { data: managementRole } = useManagementRole(managementRoleId)
    return (
        <Box data-protectedsubcomponent={protectedsubcomponent}>
            <PageContextProvider key={'OtherTypesManagementRoleGroupMembers'}>
                <AddNewAssignment
                    resourceType={RESOURCE_TYPE_NAMES.MANAGEMENT_ROLES}
                    labelKey={'AddGroupAsMember'}
                    searchUrl={`api/ManagementRoles/unassignedMembers/groups/${managementRoleId}`}
                    targetAssigneeId={managementRoleId}
                    targetAssigneeResource={managementRole}
                    searchPlaceHolder="SearchGroups"
                    queryParams={{}}
                    hideDurationSelection={true}
                    startIcon={
                        <Icon
                            name={'Plus'}
                            fill="white"
                            style={{
                                width: '16px',
                                height: '16px',
                                marginTop: '0px',
                                marginRight: '5px',
                            }}
                        />
                    }
                />
            </PageContextProvider>
        </Box>
    )
}

export default AddNewGroup
