import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
    DEFAULT_LOOKUP_ATTRIBUTES,
    DEFAULT_PAGE_SIZE_DETAILS,
    useDebounce,
} from 'packages/core'
import { useEffect, useState } from 'react'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import SelectLookUpControl from '../Components/SelectLookUpControl'
import FieldTypePagination from '../Components/SelectLookUpControl/FieldTypePagination'
import { mergeByProperties, paginate, sortArrayObjects } from 'utils'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: '#fbfbfd',
            margin: '0',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        },
        avatarDetail: {
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            '& p': {
                whiteSpace: 'nowrap',
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#282828',
            },
        },
        avatarContainer: {
            '& >div': {
                height: '30px',
                width: '30px',
            },
        },
    }),
)
const selectionIdentifier = 'id'

const MultiSelectLookupControl = ({
    fieldType,
    isFormValidated,
    valueType,
    isApiSupportedSource,
    useDataHook,
    handleUpdate,
}) => {
    const getUpdatedRefinedList = (sourceVals, assignedVals) => {
        try {
            if (!sourceVals?.length) {
                return []
            }

            const isIdExist = Boolean(sourceVals[0]?.id) || false
            if (isIdExist && !assignedVals) {
                return sourceVals
            }
            const updatedList = sourceVals.map((item, index) => {
                return {
                    ...item,
                    id: index.toString(),
                }
            })
            if (!assignedVals) {
                return updatedList
            } else if (!assignedVals?.length) {
                return []
            }
            const isIdExistForAssigned = Boolean(assignedVals[0]?.id) || false
            if (isIdExistForAssigned) {
                return assignedVals
            }
            // Below mergeByProperties will add the ids to assigned values after matching with ids in source values
            return mergeByProperties(updatedList, assignedVals, 'id')
        } catch (err) {}
    }

    const [attributes, setAttribues] = useState(DEFAULT_LOOKUP_ATTRIBUTES)
    const classes = useStyles()
    const [searchKey, setSearchKey] = useState('')
    const debouncedValue = useDebounce(searchKey)
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_DETAILS)
    const [page, setPage] = useState(1)
    const [sourceValues, setSourceValues] = useState([])
    const [showFieldValues, setShowFieldValues] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [sourceUpdated, setSourceUpdated] = useState(false)
    const [sorting, setSorting] = useState({ sortBy: '', sortOrder: '' })
    const [pageData, setPageData] = useState({
        items: [],
        totalCount: 0,
    })

    const refinedAssignedValue = getUpdatedRefinedList(
        sourceValues,
        fieldType?.[valueType] || [],
    )

    useEffect(() => {
        if (fieldType?.FieldParameters) {
            const fieldParams = JSON.parse(fieldType?.FieldParameters)
            const columns = fieldParams?.ColumnsMapping
            if (columns?.length) {
                const fieldAttribute = columns.map((item) => {
                    return {
                        name: item?.Key,
                        label: item?.DataFieldName,
                    }
                })
                setAttribues(fieldAttribute)
            }
        }
    }, [fieldType?.FieldParameters])

    useEffect(() => {
        if (fieldType?.SourceValues && sourceUpdated) {
            setShowFieldValues(
                fieldType?.SourceValues?.length || searchKey ? true : false,
            )
            setSourceValues(
                getUpdatedRefinedList(fieldType?.SourceValues || [], null),
            )
        }
    }, [fieldType?.SourceValues, searchKey, sourceUpdated])

    useEffect(() => {
        if (isFormValidated && !fieldType?.[valueType]) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        } else {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType?.[valueType]])

    const { data: sourceData, isFetching } = useDataHook(
        fieldType,
        (page - 1) * pageSize,
        pageSize,
        debouncedValue,
        sorting,
    )

    useEffect(() => {
        if (isApiSupportedSource) {
            if (!isFetching) {
                updateSourceValues()
                setSourceUpdated(true)
            }
        } else {
            setShowFieldValues(
                fieldType?.SourceValues?.length || searchKey ? true : false,
            )
            setSourceValues(
                getUpdatedRefinedList(fieldType?.SourceValues || [], null),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApiSupportedSource, isFetching])

    const updateSourceValues = () => {
        const valueToUpdate = {
            ...fieldType,
            SourceValues: sourceData?.data || [],
        }
        handleUpdate(valueToUpdate)
    }

    const handleSort = (sortByName, sortOrder) => {
        if (!isApiSupportedSource) {
            const sortedValues = sortArrayObjects(
                sourceValues,
                sortByName,
                sortOrder,
            )
            setSourceValues(sortedValues)
        } else {
            setSorting({
                sortBy: sortByName,
                sortOrder: sortOrder,
            })
        }
    }

    const handleSelection = (item) => {
        const selectedItems = refinedAssignedValue?.length
            ? [...refinedAssignedValue]
            : []
        let newSelectedItems = []
        const selectedIndex = selectedItems.findIndex(
            (sel) => sel.id === item.id,
        )
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x.id !== item.id
            })
            newSelectedItems = filteredList
        } else {
            newSelectedItems = [...selectedItems, item]
        }

        const valueToUpdate = {
            ...fieldType,
            [valueType]: newSelectedItems,
        }
        handleUpdate(valueToUpdate)
    }

    const handleSearch = (val) => {
        setSearchKey(val)
        setPage(1)
    }

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handleSelectAllClick = () => {
        const selectedItems = refinedAssignedValue?.length
            ? [...refinedAssignedValue]
            : []

        const currentPageItems = pageData?.items ?? []

        const currentPageItemIds = currentPageItems.map(
            (item) => item[selectionIdentifier],
        )

        const areAllCurrentPageItemsSelected = currentPageItemIds.every((id) =>
            selectedItems.some((item) => item[selectionIdentifier] === id),
        )

        let newSelectedItems = []

        if (areAllCurrentPageItemsSelected) {
            // If all current page items are selected, deselect them
            newSelectedItems = selectedItems.filter(
                (item) =>
                    !currentPageItemIds.includes(item[selectionIdentifier]),
            )
        } else {
            // If not all current page items are selected, select them
            const mergedArray = selectedItems.concat(currentPageItems)
            newSelectedItems = Array.from(
                new Set(mergedArray.map((obj) => obj[selectionIdentifier])),
            ).map((id) => {
                return mergedArray.find(
                    (obj) => obj[selectionIdentifier] === id,
                )
            })
        }

        const valueToUpdate = {
            ...fieldType,
            [valueType]: newSelectedItems,
        }
        handleUpdate(valueToUpdate)
    }

    const bindSelectedValue = () => {
        try {
            const selectedItems =
                refinedAssignedValue?.map((item) => {
                    const selectVal = sourceValues?.find(
                        (x) => x.id === item.id,
                    )
                    return selectVal?.id
                }) || []
            return selectedItems?.length ? selectedItems.filter((x) => x) : []
        } catch (err) {
            return []
        }
    }

    useEffect(() => {
        setPageData(getPaginatedItems())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceValues, page, pageSize, searchKey])

    const getPaginatedItems = () => {
        if (isApiSupportedSource) {
            return { items: sourceValues, totalCount: sourceData.totalCount }
        }
        const attributeName = attributes[0].name
        const filtered = sourceValues.filter((item) =>
            item?.[attributeName]
                ?.toLowerCase()
                .includes(searchKey.toLowerCase()),
        )
        return {
            items: paginate(filtered, page, pageSize),
            totalCount: filtered.length,
        }
    }
    return (
        <Box className={classes.root}>
            <SelectLookUpControl
                isLoading={isFetching}
                attributes={attributes}
                showFieldValues={showFieldValues || attributes?.length}
                selectedItems={bindSelectedValue()}
                items={pageData?.items || []}
                title={fieldType?.Name || ''}
                fieldTypeScope={fieldType?.FieldTypeScope}
                multiSelect={true}
                handleRowSelection={handleSelection}
                selectionIdentifier={selectionIdentifier}
                searchKey={searchKey}
                handleSearch={handleSearch}
                handleSelectAllClick={handleSelectAllClick}
                handleSort={handleSort}
                pagination={
                    <FieldTypePagination
                        pageSize={pageSize}
                        page={page}
                        setPageSize={(pageSize) => {
                            setPage(1)
                            setPageSize(pageSize)
                        }}
                        handlePageChange={handlePageChange}
                        numberOfPages={
                            Math.ceil(
                                pageData?.totalCount
                                    ? pageData?.totalCount / pageSize
                                    : 0,
                            ) || 0
                        }
                        totalCount={pageData?.totalCount || 0}
                    />
                }
                isRequired={fieldType?.IsRequired}
                errorMessage={errorMessage}
                isDisabled={fieldType?.IsReadOnly}
            />
        </Box>
    )
}

export default MultiSelectLookupControl
