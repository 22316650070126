import { FC } from 'react'
import { useAzureApplicationOwnersAndDeputies } from 'hooks'
import { OwnersDeputiesList } from './OwnersDeputiesList/OwnersDeputiesList'

export type AzureApplicationOwnersProps = {
    className?: string
    buttonPosition?: 'right' | 'bottom'
    data: any
    pageSize?: number
}

export const AzureApplicationOwners: FC<AzureApplicationOwnersProps> = (
    props,
) => {

    const { data: ownersAndDeputies, isLoading } =
        useAzureApplicationOwnersAndDeputies(props.data.id)

    const data = ownersAndDeputies && ownersAndDeputies.owners

    return (
        <OwnersDeputiesList data={data} isLoading={isLoading} />
    )
}
