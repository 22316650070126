import { Box, styled, useTheme } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAddItemsToCart, useApplicationFieldTypes, useCart } from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import Filters from 'components/Filters'
import BooleanValue from 'components/DynamicComponents/BooleanValue'
import Button from 'components/Button'
import cartHelpers from 'containers/Cart/cartHelpers'
import { Tooltip, WarningModal } from 'packages/eid-ui'
import { useState } from 'react'
import {
    getFullPathFromUrl,
    useIsSmallScreenFalse,
    useQuery,
} from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import { Icon } from 'packages/eid-icons'
import { createStyles, makeStyles } from '@mui/styles'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 0.4rem 1.7rem 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',
    '& svg': {
        color: ' #aab0b4',
        marginRight: '0.3rem',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1) !important',
        border: 'solid 0.1rem #ebebed',
        paddingLeft: '0',
        height: '4rem',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const AddNewFieldTypeButton = styled(Box)({
    '& button': {
        height: '4rem',
        border: 'none',
        padding: '1.2rem',
    },
})

const useButtonStyles = makeStyles((theme) =>
    createStyles({
        root: (isSmallScreen) => ({
            height: '3.2rem',
            borderRadius: '0.4rem',
            padding: '0 1.5rem 0 1rem',
            backgroundColor: theme?.palette?.primary?.main,
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '1.4rem',
            margin: isSmallScreen ? '1.6rem 1.6rem 0 1.6rem' : '',
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
            },
            '& svg': {
                marginRight: '0.8rem',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

const canDeleteFieldType =
    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-DeleteFieldTypeControl'

const canEditFieldType =
    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-EditFieldTypeControl'
const canAddNewFieldTypeToAppRight =
    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-AddFieldTypeControl'

const ApplicationAppRightsFieldTypes = ({
    roleId,
    globalRightId,
    resourceId,
}) => {
    const { t } = useTranslation()
    const dataObject = {
        localRightId: roleId,
        globalRightId: globalRightId,
    }
    const [addItemsToCart, { isLoading }] = useAddItemsToCart()
    const query = useQuery()
    const history = useHistory()
    const { hasAccessToControl } = useSubcomponents()
    const { data: cart } = useCart()
    const [itemToDelete, setItemToDelete] = useState(null)
    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationFieldTypes(dataObject)
    const { getAccessBasedAttributes } = useSubcomponents()
    const isSmallScreen = useIsSmallScreenFalse()
    const classes = useButtonStyles(isSmallScreen)
    const attributes = [
        {
            name: 'fieldTypeFriendlyName',
            sortable: true,
            label: 'FieldTypeFriendlyName',
        },
        {
            name: 'fieldTypeName',
            sortable: true,
            label: 'FieldTypeName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-FieldTypeName',
            },
        },
        {
            name: 'valueFrom',
            sortable: true,
            label: 'ValueFrom',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-ValueFrom',
            },
        },
        {
            name: 'valueTo',
            sortable: true,
            label: 'ValueTo',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-ValueTo',
            },
        },
        {
            name: 'allValuesRange',
            hideValue: true,
            sortable: true,
            label: 'AllValuesInRange',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-AllValuesInRange',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanValue
                        data={{ item }}
                        attribute={{ name: 'allValuesRange' }}
                    />
                ),
            },
        },
        {
            name: 'fieldTypeSelectionRuleName',
            sortable: true,
            label: 'UISelectionControl',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-UISelectionControl',
            },
        },
        {
            name: 'allowSelectionOfAnyValues',
            hideValue: true,
            sortable: true,
            label: 'AllowSelectionOfAnyValues',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-AllowSelectionOfAnyValues',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanValue
                        data={{ item }}
                        attribute={{ name: 'allowSelectionOfAnyValues' }}
                    />
                ),
            },
        },
        {
            name: 'isRequired',
            hideValue: true,
            sortable: true,
            label: 'IsRequired',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-IsRequired',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanValue
                        data={{ item }}
                        attribute={{ name: 'isRequired' }}
                    />
                ),
            },
        },
        {
            name: 'fieldTypeScope',
            sortable: true,
            label: 'FieldTypeScope',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypes-FieldTypeScope',
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            {hasAccessToControl(canEditFieldType) && (
                                <Tooltip
                                    title={
                                        alreadyInCart(data)
                                            ? t('Common_ItemAlreadyInCart')
                                            : ''
                                    }
                                >
                                    <Box
                                        data-protectedsubcomponent={
                                            canEditFieldType
                                        }
                                    >
                                        <Button.Edit
                                            onClick={() => {
                                                query.set(
                                                    'workflowName',
                                                    'ConfigureApplicationAuthorizationFieldType',
                                                )
                                                query.set(
                                                    'SelectedResourceID',
                                                    `${resourceId}`,
                                                )
                                                query.set(
                                                    'returnUrl',
                                                    getFullPathFromUrl(),
                                                )
                                                query.delete('applicationId')
                                                query.delete('activeTab')
                                                query.delete('subActiveTab')
                                                query.delete('appRoleId')
                                                query.delete('drawerActiveTab')
                                                history.push(
                                                    `${
                                                        config.APP_SUBPATH
                                                    }/applications/workflows?${query.toString()}`,
                                                )
                                            }}
                                            showEndIcon={false}
                                            disabled={alreadyInCart(data)}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                            {hasAccessToControl(canDeleteFieldType) && (
                                <Tooltip
                                    title={
                                        alreadyInCart(data)
                                            ? t('Common_ItemAlreadyInCart')
                                            : ''
                                    }
                                >
                                    <Box
                                        marginLeft={'1.6rem'}
                                        data-protectedsubcomponent={
                                            canDeleteFieldType
                                        }
                                    >
                                        <Button.Delete
                                            showEndIcon={false}
                                            disabled={alreadyInCart(data)}
                                            onClick={() => {
                                                setItemToDelete(data)
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                    )
                },
            },
        },
    ]

    const alreadyInCart = (item) => {
        const isAlreadyInCart = cartHelpers.isItemAlreadyInCart(
            cart,
            item?.fieldTypeId,
        )
        return isAlreadyInCart
    }

    const handleDelete = () => {
        const itemToRemove = cartHelpers.removeFieldType({
            targetAssigneeId: roleId,
            resource: itemToDelete,
            resourceTypeId: itemToDelete?.fieldTypeResourceTypeId,
            resourceTypeName: itemToDelete?.fieldTypeResourceTypeName,
        })
        addItemsToCart([itemToRemove]).then(() => {
            setItemToDelete(null)
            refetch()
        })
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="1.6rem 3.2rem"
            >
                <Box>
                    {hasAccessToControl(canAddNewFieldTypeToAppRight) && (
                        <AddNewFieldTypeButton
                            data-protectedsubcomponent={
                                canAddNewFieldTypeToAppRight
                            }
                        >
                            <Button
                                onClick={() => {
                                    query.set(
                                        'workflowName',
                                        'ConfigureApplicationAuthorizationFieldType',
                                    )
                                    query.set(
                                        'SelectedResourceID',
                                        `${resourceId}`,
                                    )
                                    query.set('returnUrl', getFullPathFromUrl())
                                    query.delete('applicationId')
                                    query.delete('activeTab')
                                    query.delete('subActiveTab')
                                    query.delete('appRoleId')
                                    query.delete('drawerActiveTab')
                                    history.push(
                                        `${
                                            config.APP_SUBPATH
                                        }/applications/workflows?${query.toString()}`,
                                    )
                                }}
                                className={classes.root}
                            >
                                {<Icon name="Plus" color="#fff" />}
                                {t('AddNewFieldType')}
                            </Button>
                        </AddNewFieldTypeButton>
                    )}
                </Box>
                <Box width="24rem">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
            {itemToDelete && (
                <WarningModal
                    title={
                        t('Delete') + ' ' + itemToDelete?.fieldTypeFriendlyName
                    }
                    description={t('GenericDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    cancel={() => setItemToDelete(null)}
                    proceed={handleDelete}
                    proceeding={isLoading}
                    open={itemToDelete}
                />
            )}
        </Box>
    )
}

export default ApplicationAppRightsFieldTypes
