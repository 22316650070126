import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Avatar, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { imagesDisabled, getFullImageUrl } from 'utils'
import { useTranslation } from 'react-i18next'

export interface IPersonDetailsProps {
    size?: any
    person?: any
    enabled?: boolean
    loading?: boolean
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '8px',
            marginTop: '8px',
            alignItems: 'center',
            wordBreak: 'break-word',
        },
        name: {
            fontSize: '14px',
            color: '#00000',
            lineHeight: 1.14,
            fontWeight: 'bold',
        },

        showMoreButtonContainer: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 'bold',
            color: theme?.palette?.primary?.main,
            cursor: 'pointer',
        },
        avatarContainer: {
            marginRight: '10px',
            marginLeft: '5px',
            '& >div': {
                height: '30px',
                width: '30px',
            },
        },
        iconsContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '2px',
            marginLeft: '-4px',
            '& >svg': {
                margin: '0px 4px',
                height: '14px',
                width: '14px',
            },
        },
    }),
)

export const PersonDetails: FC<IPersonDetailsProps> = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { size, person, enabled, loading } = props

    return (
        <Box className={classes.root}>
            {!imagesDisabled && (
                <Box className={classes.avatarContainer}>
                    <Avatar
                        size={size}
                        src={getFullImageUrl(person.imageThumbUrl)}
                        style={{
                            boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
                        }}
                    />
                </Box>
            )}

            <Box>
                {person.friendlyName && (
                    <Typography className={classes.name}>
                        {person.friendlyName}
                    </Typography>
                )}

                {!imagesDisabled && (
                    <Box className={classes.iconsContainer}>
                        {person.email ? (
                            <a href={`mailto:${person.email}`}>
                                <Tooltip
                                    title={`mailto:${person.email}`}
                                    disableInteractive={false}
                                >
                                    <div>
                                        <Icon name="EmailRound" />
                                    </div>
                                </Tooltip>
                            </a>
                        ) : (
                            <Tooltip
                                title={`${
                                    loading
                                        ? t('Loading')
                                        : t('No_Email_Tooltip')
                                }`}
                                disableInteractive={false}
                            >
                                <div>
                                    {!enabled || loading ? (
                                        <Icon name="EmailRound" />
                                    ) : (
                                        <Icon
                                            name="EmailRound"
                                            style={{
                                                color: 'B4B4B4',
                                                cursor: 'default',
                                            }}
                                        />
                                    )}
                                </div>
                            </Tooltip>
                        )}
                        {person.telephone ? (
                            <a href={`tel:${person.telephone}`}>
                                <Tooltip
                                    title={person.telephone}
                                    style={{ marginLeft: '5px' }}
                                    disableInteractive={false}
                                >
                                    <div>
                                        <Icon name="PhoneRound" />
                                    </div>
                                </Tooltip>
                            </a>
                        ) : (
                            <Tooltip
                                title={`${
                                    loading
                                        ? t('Loading')
                                        : t('No_Phone_Tooltip')
                                }`}
                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                                disableInteractive={false}
                            >
                                <div>
                                    {!enabled || loading ? (
                                        <Icon name="PhoneRound" />
                                    ) : (
                                        <Icon
                                            name="PhoneRound"
                                            style={{
                                                color: 'B4B4B4',
                                                cursor: 'default',
                                            }}
                                        />
                                    )}
                                </div>
                            </Tooltip>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    )
}
