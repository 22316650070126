import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { HorizontalTabs } from 'packages/eid-ui'
import { DropdownMenu } from 'components/DropdownMenu'
import { useRouteOptions } from 'hooks'
import config from 'config'
import { SubPathLevel } from 'appConfiguration'
import { useTranslation } from 'react-i18next'
import EidButton from 'packages/eid-ui/EidButton'
import { useQuery } from 'packages/core'
import { useIsSmallScreenFalse } from 'packages/core'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
        marginTop: '8px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme?.palette?.primary?.main,
        height: '4px',
        padding: '0 6px',
        boxSizing: 'content-box',
        marginLeft: '-6px',
        marginBottom: '0px',
        zIndex: 1,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
        letterSpacing: '0.022em',
    },
    '& .MuiTab-root': {
        fontSize: '14px',
        letterSpacing: '0.3px',
        margin: '-2px 0px 0 8px',
        transition: 'color 0.15s ease-in-out',

        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))

const useStyles = makeStyles(
    createStyles({
        navBarContainer: {
            height: '80px',
            marginBottom: '-4px',
            position: 'relative',
            whiteSpace: 'nowrap',

            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: 0,
                top: '-4px',
                width: '100%',
                height: 'calc(100% - 2px)',
                borderBottom: '1px solid #d8d8dd',
            },

            '&::after': {
                content: '""',
                display: 'block',
                width: '200%',
                // transform: 'translateX(-50%)',
                height: '16px',
                backgroundImage:
                    'linear-gradient(to top, rgba(238, 240, 244, 0), rgba(238, 240, 244, 0.9))',
                position: 'absolute',
                left: '-50%',
                bottom: '-10px',
            },
        },
        cartMenu: {
            display: 'flex',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
        mobileCartMenu: {
            display: 'flex',
            position: 'absolute',
            top: '25px',
            right: '8px',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
    }),
)

const SubHeader = ({
    showNavTabs = true,
    subHeaderButton,
    viewSwitcher,
    totalCount,
    showBackButton = false,
    backButtonLabel = 'Back',
}) => {
    const { routeOptions } = useRouteOptions()
    const { t } = useTranslation()
    const history = useHistory()
    const query = useQuery()
    const returnUrl = query.get('returnUrl')
    const {
        location: { pathname },
    } = history
    const isSmallScreen = useIsSmallScreenFalse()
    const classes = useStyles()

    const areaName = pathname.split('/')[1 + SubPathLevel]

    let selectedRoute
    let selectedParentRoute

    for (let i = 0; i < routeOptions.length; i++) {
        const foundRoute = routeOptions[i].children.find((c) => {
            return (
                (config.APP_SUBPATH + c.route).toLowerCase() ===
                pathname.toLowerCase()
            )
        })
        if (foundRoute) {
            selectedRoute = foundRoute
            selectedParentRoute = routeOptions[i]
            break
        }
    }
    const selectedTab = pathname.split('/')[2 + SubPathLevel]
        ? pathname.split('/')[2 + SubPathLevel]
        : 'viewAll'

    const getPreviousPathFromUrl = () => {
        return returnUrl || `${config.APP_SUBPATH}${selectedRoute.route}`
    }

    return (
        <Box
            height={isSmallScreen ? '128px' : '80px'}
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'start' : 'center'}
            justifyContent="space-between"
            width="100%"
            className={isSmallScreen ? '' : [classes.navBarContainer]}
        >
            <Box
                display="flex"
                paddingLeft={isSmallScreen ? '8px' : '0px'}
                justifyContent="space-between"
            >
                {/* Drop Down Menu Here */}
                <DropdownMenu
                    routeOptions={routeOptions}
                    selectedRoute={selectedRoute}
                    selectedParentRoute={selectedParentRoute}
                    totalCount={totalCount}
                />
            </Box>

            {subHeaderButton}

            {showNavTabs && (
                <SubHeaderTabs
                    variant="standard"
                    value={selectedTab}
                    onChange={(_, value) => {
                        if (value === 'viewAll') {
                            history.push(`${config.APP_SUBPATH}/${areaName}`)
                        } else {
                            history.push(
                                `${config.APP_SUBPATH}/${areaName}/${value}`,
                            )
                        }
                    }}
                >
                    <HorizontalTabs.Tab
                        key={1}
                        value="viewAll"
                        label={t('Common_ViewAll')}
                    />
                    <HorizontalTabs.Tab
                        key={2}
                        value="workflows"
                        label={t('Workflows')}
                    />
                </SubHeaderTabs>
            )}
            <Box minWidth="102px" display="flex" justifyContent="right">
                {showBackButton ? (
                    <EidButton.BackButton
                        previousRoute={getPreviousPathFromUrl()}
                        label={t(backButtonLabel)}
                    />
                ) : (
                    viewSwitcher
                )}
            </Box>
        </Box>
    )
}

export default SubHeader
