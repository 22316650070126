import { FC, useState } from 'react'
import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useResourceOwners } from 'hooks'
import { Skeleton } from '@mui/material';
import { PersonDetails } from './PersonDetails'
import classNames from 'classnames'
import { paginate } from 'utils'
import { Pagination } from 'packages/eid-ui'

export type OwnersProps = {
    className?: any
    applicationId: string
    buttonPosition?: 'right' | 'bottom'
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: { position: 'relative' },

        buttonPositionRightTop: {},

        content: {
            display: 'flex',
        },

        defaultPerson: {},
        disableDefaultPerson: {
            opacity: 0.4,
        },

        ownersContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '150px',
            overflow: 'auto',
            padding: '5px 4px',
        },

        collapsibleContent: {
            marginTop: '10px',
            position: 'relative',
            backgroundColor: '#f5f6f7',
            borderRadius: '8px',
            border: 'solid 1px #f9f9f9',
            backdropFilter: 'blur(2px)',
            width: '250px',
        },

        personContainer: {
            padding: '0px 12px',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: '#ffffff',
                '& p': {
                    color: '#000000',
                    fontWeight: 'bold',
                },
            },
        },
    }),
)

export const Owners: FC<OwnersProps> = (props) => {
    const classes = useStyles()

    const { data, isLoading } = useResourceOwners(props.applicationId)

    const [page, setPage] = useState(1)

    const pageSize = 5

    const paginatedData = data && paginate(data, page, pageSize)

    const numberOfPages = data ? Math.ceil(data.length / pageSize) : 0

    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }

    if (isLoading) {
        return (
            <Box paddingRight={4}>
                <Skeleton height={40} />
            </Box>
        )
    } else {
        if (data?.length === 0) {
            return <Box paddingLeft={'4px'}>-</Box>
        }

        return (
            <Box className={classNames(classes.root, props.className)}>
                <Box className={classes.collapsibleContent}>
                    <Box className={classes.ownersContainer}>
                        {data &&
                            paginatedData.map((o: any, index: any) => (
                                <Box
                                    className={classes.personContainer}
                                    key={o.id + index}
                                >
                                    <PersonDetails size="medium" person={o} />
                                </Box>
                            ))}
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        justifyContent="center"
                        padding="5px 11px"
                    >
                        <Pagination
                            count={numberOfPages}
                            size="small"
                            siblingCount={0}
                            page={page}
                            onChange={handlePageChange}
                            variant={'rounded'}
                        />
                    </Box>
                </Box>
            </Box>
        )
    }
}
