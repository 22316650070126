import DummyPasswordBox from './DummyPasswordBox'

const CredentialLockedBox = ({ data }) => {
    const { item } = data

    if (!item.canCheckIn && !item.canCheckOut) {
        return null
    } else {
        return <DummyPasswordBox data={data} />
    }
}

export default CredentialLockedBox
