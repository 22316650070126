import { useState } from 'react'
import {
    Box,
    styled,
    Typography,
    Paper,
    MenuItem,
    ListItemIcon,
    ListItemText,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Loader } from 'packages/eid-ui'
import { useQuery, useIsSmallScreenFalse, getFullPathFromUrl } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useUiActionsByResource } from 'hooks'
import config from 'config'

const useHeaderStyles = makeStyles({
    root: {
        fontSize: '14px !important',
        position: 'relative',
        minHeight: '40px',
        borderLeft: '2px solid transparent',
        borderRadius: '4px',
        textTransform: 'uppercase',
        lineHeight: '20px',
        fontWeight: 'bold',
        padding: '0px',
    },
    icon: {
        minWidth: '35px',
        color: 'black',
        '& svg': {
            height: '24px',
            width: '24px',
        },
    },
    selected: {
        backgroundColor: 'transparent !important',
        color: '#fff',
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#000000 !important',
        overflow: 'hidden',
        fontSize: '14px',
        fontWeight: 'bold',
        '& svg': {
            margin: '0px !important',
        },
    },
})

const useMenuStyles = makeStyles((theme) => ({
    root: {
        fontSize: '14px',
        color: theme?.palette?.primary?.main,
        position: 'relative',
        minHeight: '40px',
        borderLeft: '2px solid transparent',
        whiteSpace: 'normal',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
            borderLeft: `2px solid ${theme?.palette?.primary?.main}`,
            '& svg': {
                opacity: 1,
                filter: 'grayscale(0%) ',
                color: 'blue',
            },
        },
    },
    icon: {
        minWidth: '20px',
        color: '#aab0b4',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    noActionMessage: {
        minWidth: '40px',
        fontSize: '14px',
        padding: '10px',
    },
}))

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

const ActionsList = ({ item }) => {
    const classes = useMenuStyles()
    const { t } = useTranslation()

    const { data, isLoading } = useUiActionsByResource(item.id)
    const query = useQuery()
    const history = useHistory()

    const handleClick = (name) => {
        query.set('workflowName', name)
        query.set('returnUrl', getFullPathFromUrl())
        query.set('SelectedResourceID', `${item.resourceId}`)
        history.push(
            `${config.APP_SUBPATH}/applications/workflows?${query.toString()}`,
        )
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Paper>
                    {data && data.length > 0 ? (
                        data.map((d) => (
                            <MenuItem
                                onClick={() =>
                                    handleClick(d.requestWorkflowName)
                                }
                                classes={classes}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <Icon
                                        name="Arrow"
                                        direction="right"
                                        color="#aab0b4"
                                    />
                                </ListItemIcon>
                                {d.friendlyName}
                            </MenuItem>
                        ))
                    ) : (
                        <Typography className={classes.noActionMessage}>
                            {t('NoActionFound')}
                        </Typography>
                    )}
                </Paper>
            )}
        </>
    )
}

export const UIActionsList = ({ item }) => {
    const classes = useHeaderStyles()
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const theme = useTheme()
    if (!isSmallScreen) {
        return (
            <>
                <PromptContainer>
                    <Box
                        width="172px"
                        display="flex"
                        flexDirection="column"
                        zIndex={1}
                    >
                        <Box className={classes.sectionHeader}>
                            {t('AvailableActions')}
                        </Box>
                        <Icon
                            style={{ marginTop: '20px' }}
                            name="SettingsDouble"
                            height="150px"
                            width="150px"
                            color="#D2D2D9"
                        />
                    </Box>
                    <Box width="auto" paddingLeft="16px">
                        <ActionsList item={item} />
                    </Box>
                </PromptContainer>
            </>
        )
    } else {
        const header = (
            <Box marginBottom="10px">
                <MenuItem
                    classes={classes}
                    selected={open}
                    onClick={() => {
                        setOpen((prev) => !prev)
                    }}
                >
                    <ListItemIcon className={classes.icon}>
                        <Icon name="Actions" color="#919193" />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.sectionHeader }}>
                        {t('AvailableActions')}
                    </ListItemText>

                    <Typography style={{ display: 'inherit' }}>
                        <Icon
                            name="Arrow"
                            direction={open ? 'up' : 'down'}
                            color={open ? theme?.palette?.primary?.main : 'black'}
                        />
                    </Typography>
                </MenuItem>
            </Box>
        )

        return (
            <Box padding={'20px'}>
                {header}
                {open && (
                    <>
                        <ActionsList item={item}></ActionsList>
                    </>
                )}
            </Box>
        )
    }
}
