import { Fragment } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import ChangeAccessRequestPolicy from './ChangeAccessRequestPolicy'
import { smallScreenWidth } from 'utils'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '15.6rem',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            width: 'auto',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
        heading: {
            fontWeight: 'bold',
            fontSize: '1.7rem',
            paddingBottom: '0.8rem',
            color: '#1b1f1f',
        },
        attributeLabel: {
            fontWeight: '500',
            color: ' #9b9b9b',
        },
        attributeValue: {
            fontSize: '1.4rem',
        },
    }),
)

export const MainAccessRequestPolicyView = (props) => {
    const classes = useStyles()
    const {
        accessRequestAttributes: { timeRestriction, general },
        detailsLoading,
        acccessRequestPolicy,
        resourceId,
        refetch,
    } = props
    const { t } = useTranslation()

    const bindValue = (value) => {
        if (typeof value == 'boolean') {
            return value ? t('Yes') : t('No')
        }
        return value
    }

    const requestAccessGeneralDetails = (
        <Box>
            <Typography className={classes.heading} variant="h2">
                {t('General')}
            </Typography>
            <Box className={classes.basicInfo}>
                {general &&
                    general.map((att, i) => {
                        return (
                            <Attribute key={att.value + i}>
                                <AttributeLabel
                                    className={classes.attributeLabel}
                                >
                                    {t(att.labelKey)}
                                </AttributeLabel>
                                <AttributeValue
                                    className={classes.attributeValue}
                                >
                                    {acccessRequestPolicy &&
                                        (bindValue(
                                            acccessRequestPolicy[att.value],
                                        ) ??
                                            '-')}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
            </Box>
        </Box>
    )
    const requestAccessTimeRestrictionDetails = (
        <Box>
            <Typography className={classes.heading} variant="h2">
                {t('TimeRestriction')}
            </Typography>
            {timeRestriction &&
                timeRestriction
                    .filter((att) => !att.firstPane)
                    .map((att, i) => {
                        return (
                            <Attribute key={att.value + i}>
                                <AttributeLabel
                                    className={classes.attributeLabel}
                                >
                                    {t(att.labelKey)}
                                </AttributeLabel>
                                <AttributeValue
                                    className={classes.attributeValue}
                                >
                                    {acccessRequestPolicy &&
                                        (bindValue(
                                            acccessRequestPolicy[att.value],
                                        ) ??
                                            '-')}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
        </Box>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box className={classes.section}>
                    {requestAccessGeneralDetails}
                </Box>
                <Box className={classes.section}>
                    {requestAccessTimeRestrictionDetails}
                </Box>
                <Box
                    className={classes.section}
                    style={{ justifyContent: 'flex-end' }}
                >
                    <ChangeAccessRequestPolicy
                        resourceId={resourceId}
                        refetch={refetch}
                    />
                </Box>
            </Box>
        </Fragment>
    )

    return (
        <>
            {detailsLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Fragment>
            )}
        </>
    )
}
