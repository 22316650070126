import React from 'react'
import Button from './index'
import { Box, Typography } from '@mui/material'
import { usePageContext } from 'pageContext'
import { getAppliedFiltersValues } from 'utils'

const styles = {
    normal: {
        minWidth: '160px',
        width: '100%',
        backgroundColor: '#ffffff',
        color: '#363636',
        border: 'solid 1px #d2d2d9',

        '& >span': { display: 'flex', flexDirection: 'column' },
        '&:hover': {
            backgroundColor: '#ffffff',
            opacity: 0.95,
        },
        '&:active': {
            backgroundColor: '#ffffff',
        },
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },

    label: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 1.15,
    },
    subLabel: {
        fontSize: '11px',
        color: '#8b909a',
        lineHeight: 1.15,
    },
    count: {
        position: 'absolute',
        top: '10px',
        right: '12px',
        fontSize: '12px',
        borderRadius: '50%',
        backgroundColor: 'rgb(48,127,193)',
        height: '14px',
        width: '18px',
        color: '#fff',
        paddingBottom: '19px',
    },
}

const FiltersButton = ({ children, label, subLabel, ...rest }) => {
    const [state] = usePageContext()
    const appliedFiltersValues = getAppliedFiltersValues(state)

    return (
        <Button
            style={styles.normal}
            size="medium"
            disabledStyles={styles.disabled}
            {...rest}
        >
            {!children ? (
                <>
                    <Typography style={styles.label}>{label}</Typography>

                    <Typography style={styles.subLabel}>{subLabel}</Typography>

                    {appliedFiltersValues && appliedFiltersValues.length > 0 && (
                        <Box style={styles.count}>
                            <span>{appliedFiltersValues.length}</span>
                        </Box>
                    )}
                </>
            ) : (
                children
            )}
        </Button>
    )
}

export default FiltersButton
