import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { Collapse, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
} from 'packages/core'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import {
    GroupsTable,
    GroupsTableHead,
    GroupsRow,
} from './GroupsTableComponents'
import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'
import { useGroup, useApplicationSettings, useGroups } from 'hooks'
import { usePageContext } from 'pageContext'
import { GroupDetails } from './GroupsDetails'
import { SubPathLevel } from 'appConfiguration'
import useSubcomponents from 'useSubcomponents'
import ResourceMoreInformationTile from 'components/ResourceMoreInformationTile'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'
import { useAppState } from 'appContext'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import GenericButton from 'components/Button/GenericButton'
import CardView from 'components/EidTableGrid/CardView'
import { ITEMS_VIEW, RESOURCE_TYPE_NAMES } from 'utils'

const canSeeMoreInfornation = 'ResourceAdmin-Groups-MoreInfo-Control'
const actionButtonsControlName = 'ResourceAdmin-Groups-List-Actions'

const GroupsLayout = ({ filters }) => {
    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [, appDispatch] = useAppState()
    const history = useHistory()

    const [filterWidth, setFilterWidth] = useState(0)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        rest,
    } = useGroups()

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )

    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])
    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? ITEMS_VIEW.CARD : ITEMS_VIEW.LIST
    const { tableAttributes, detailAttributes } = attributes
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()
    const accessBasedAttributesForGroups =
        getAccessBasedAttributes(tableAttributes)

    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('No_AppRoles_Message'),
        pagination,
        attributes: accessBasedAttributesForGroups,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: CardView,
            renderLeftButton: (item) => {
                return hasAccessToControl(actionButtonsControlName) ? (
                    <Box
                        data-protectedsubcomponent={actionButtonsControlName}
                        width="80%"
                    >
                        <GenericButton
                            onClick={() => {
                                history.push(`?groupId=${item?.id}`)
                            }}
                            color="#01ae8f"
                            rootStylesProp={{
                                borderRadius: '4px',
                                width: '100%',
                                height: '32px',
                            }}
                        >
                            {t('Details')}
                        </GenericButton>
                    </Box>
                ) : (
                    <></>
                )
            },
            renderRightButton: (item) => {
                return hasAccessToControl(actionButtonsControlName) ? (
                    <Box data-protectedsubcomponent={actionButtonsControlName}>
                        <UIActionsGearButton
                            item={item}
                            noun={'ResourceAdmin'}
                            resourcePath="groups"
                        />
                    </Box>
                ) : (
                    <></>
                )
            },
        },
        tableViewConfig: {
            TableComponent: GroupsTable,
            TableHeadComponent: GroupsTableHead,
            TableRowComponent: GroupsRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView(ITEMS_VIEW.CARD)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmallScreen])

    const query = useQuery()

    const workflowName = query.get('workflow')
    const groupId = query.get('groupId')
    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig

    const accessibleStaticWorkflows =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) =>
                      w.resourceTypeName === RESOURCE_TYPE_NAMES.GROUPS &&
                      hasAccessToControl(w.protectedSubcomponentName),
              )
            : []

    const { data: group } = useGroup(groupId)

    useEffect(() => {
        if (!groupId) {
            appDispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: null,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId])

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const wfParams = []
    const detailPageTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
        {
            label: t('AccountAsMembers'),
            value: 'members',
            icon: <Icon name="ViewProfile" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-Members',
            },
        },
        {
            label: t('MembershipChanges'),
            value: 'membershipchanges',
            icon: <Icon name="MembershipChanges" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-MembershipChanges',
            },
        },
        {
            label: t('ResultantMembers'),
            value: 'resultantmembers',
            icon: <Icon name="ResultantMembers" />,
            display: false,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-ResultantMembers',
            },
        },
        {
            label: t('RbacAssignments'),
            value: 'rbacassignments',
            icon: <Icon name="Scope" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-RbacAssignments',
            },
        },
        {
            label: t('NestedGroupMembers'),
            value: 'nestedgroupmembers',
            icon: <Icon name="ClaimTab" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-NestedGroupMembers',
            },
        },
        {
            label: t('AccessManagers(Owners)'),
            value: 'accessManagers',
            icon: <Icon name="ExternalCredential" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-Overview-AccessManagers',
            },
        },
        {
            label: t('DirectAssignedLocations'),
            value: 'directAssignedLocations',
            icon: <Icon name="Claims" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-DirectAssignedLocations',
            },
        },

        {
            label: t('AccessRequestPolicy'),
            value: 'accessRequestPolicy',
            icon: <Icon name="ClientCertificate" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-AccessRequestPolicy',
            },
        },
    ]
    if (group) {
        wfParams.push(`SelectedResourceID=${group.resourceId}`)
        if (isSmallScreen) {
            return (
                <AppLayout
                    totalCount={totalCount}
                    showBackButton={Boolean(group)}
                >
                    <GroupDetails
                        tabs={detailPageTabs}
                        groupId={groupId}
                        basicAttributes={detailAttributes}
                    />
                </AppLayout>
            )
        }
        return (
            <AppLayout
                totalCount={totalCount}
                showNavTabs={!groupId}
                showBackButton={Boolean(group)}
            >
                <DesktopContainer>
                    {
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <GroupDetails
                                tabs={detailPageTabs}
                                groupId={groupId}
                                basicAttributes={detailAttributes}
                            />
                        </Box>
                    }
                </DesktopContainer>
            </AppLayout>
        )
    }

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let content
    if (isSmallScreen) {
        content = (
            <SmallScreenContainer>
                <Box display={showFilters ? '' : 'none'}>
                    {mobileFiltersScreen}
                </Box>

                <Box display={!showFilters ? '' : 'none'}>
                    <Box display={''}>
                        <StickyContainer>
                            <Box width="100%">{mobileStickyContentListing}</Box>
                        </StickyContainer>
                        {viewToRender}
                    </Box>
                </Box>
            </SmallScreenContainer>
        )
    } else {
        content = (
            <DesktopContainer>
                {groupId ? (
                    <Box paddingRight={'8px'} marginBottom="16px" width="100%">
                        <GroupDetails groupId={groupId} />
                    </Box>
                ) : (
                    <Fragment>
                        {((filters && filters.length > 0) ||
                            hasAccessToControl(canSeeMoreInfornation)) && (
                            <FiltersColumn ref={filterContainer}>
                                <FilterContainer>
                                    <Box display="flex">
                                        <Box width="100%">
                                            <Card.CardHeader
                                                collapsible
                                                fontColor="black"
                                                cardTitle={
                                                    openFilters
                                                        ? t('Hide_My_Filters')
                                                        : t('Show_My_Filters')
                                                }
                                                handleExpandClick={() =>
                                                    setOpenFilters(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                expanded={openFilters}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                                style={styles.filterCardHeader}
                                            />
                                        </Box>
                                    </Box>
                                </FilterContainer>

                                <Collapse
                                    in={openFilters}
                                    timeout={300}
                                    unmountOnExit
                                >
                                    {!groupId &&
                                        accessibleStaticWorkflows.length > 0 &&
                                        accessibleStaticWorkflows.map((wf) => (
                                            <Box
                                                style={{
                                                    margin: '0 0 15px 0px',
                                                }}
                                                data-protectedsubcomponent={
                                                    wf.protectedSubcomponentName
                                                }
                                            >
                                                <EidWorkflowButton
                                                    title={t(
                                                        wf.displayName_LocaleKey,
                                                    )}
                                                    name={
                                                        wf.requestWorkflowName
                                                    }
                                                    path={`/groups/workflows`}
                                                    disabled={workflowName}
                                                />
                                            </Box>
                                        ))}
                                    <ResourceMoreInformationTile
                                        protectedsubcomponent={
                                            canSeeMoreInfornation
                                        }
                                        moreInfoKey={'Groups_MoreInfo_Html'}
                                        resourceId={groupId}
                                    />
                                    <>
                                        {filters.map((f) => {
                                            const FilterToRender =
                                                Filters.filtersMap[f.name]
                                            return (
                                                <FilterContainer
                                                    data-protectedsubcomponent={
                                                        f.requireAccess
                                                    }
                                                    key={f.name}
                                                >
                                                    <FilterToRender
                                                        config={f.config}
                                                    />
                                                </FilterContainer>
                                            )
                                        })}
                                    </>
                                    <ListingPageUIActionByNounAndVerb
                                        noun="ResourceAdmin"
                                        verb="Group"
                                    />
                                </Collapse>
                            </FiltersColumn>
                        )}

                        <ListingGrid>
                            <Box display="flex">
                                <Box width="100%">
                                    <Filters.TextSearch />
                                </Box>
                                {/* More Filters Here */}
                            </Box>
                            <GridContainer
                                filterWidth={filterWidth}
                                openFilters={openFilters}
                            >
                                {viewToRender}
                            </GridContainer>
                        </ListingGrid>
                    </Fragment>
                )}
            </DesktopContainer>
        )
    }

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showNavTabs={true}
        >
            <Fragment>{content}</Fragment>
        </AppLayout>
    )
}

export default GroupsLayout
