import { useTranslation } from 'react-i18next'
import { Box, Paper } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    useClaimsByClaimsMappingPolicyId,
    useClaimsMappingPolicies,
} from 'hooks'
import { usePageContext } from 'pageContext'
import { useDebounce, useIsSmallScreenFalse } from 'packages/core'
import { useEffect, useState } from 'react'
import { Loader } from 'packages/eid-ui'
import Policy from './Policy'
import Search from 'components/Filters/Search'
import { smallScreenWidth } from 'utils'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'

const useStyles = makeStyles(() =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

export const ClaimsMappingPolicies = ({ id, applicationResourceId }) => {
    const { t } = useTranslation()
    const [, dispatch] = usePageContext()
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreenFalse()
    const { getAccessBasedAttributes } = useSubcomponents()

    const { list: policy, loading: policyLoading } =
        useClaimsMappingPolicies(id)

    const [searchKey, setSearchKey] = useState('')

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }

    const debouncedSearchValue = useDebounce(searchKey)

    let queryData = debouncedSearchValue

    const attributes = [
        {
            name: 'name',
            label: t('Claim'),
            isHeader: true,
            showInCard: false,
            styles: {
                width: '160px',
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-Application-Token-ClaimsMappingPolicy-List-Claim',
            // },
        },
        {
            name: 'description',
            label: t('Description'),
            styles: {
                width: '160px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Token-ClaimsMappingPolicy-List-Description',
            },
        },
        {
            name: 'jwtClaimType',
            label: t('JWTClaimType'),
            styles: {
                width: '160px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Token-ClaimsMappingPolicy-List-JWTClaimType',
            },
        },
        {
            name: 'samlClaimType',
            label: t('SAMLClaimType'),
            styles: {
                width: '290px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Token-ClaimsMappingPolicy-List-SAMLClaimType',
            },
        },
        {
            name: 'samlNameFormat',
            label: t('SAMLNameFormat'),
            styles: {
                width: '160px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Token-ClaimsMappingPolicy-List-SAMLNameFormat',
            },
        },
    ]

    const {
        list: claimsMappingPolicies,
        loading: claimsMappingPoliciesLoading,
        pagination: claimsMappingPolicesPagination,
    } = useClaimsByClaimsMappingPolicyId(
        policy?.length > 0 && policy[0]?.id,
        policy?.length > 0,
        queryData ?? '',
    )

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
    }, [queryData])

    return (
        <>
            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                {policyLoading && <Loader height={80} />}
                {!policyLoading && (
                    <Policy
                        policyId={policy?.length > 0 ? policy[0].id : null}
                        applicationResourceId={applicationResourceId}
                    />
                )}
                <Box
                    style={{
                        paddingBottom: '17px',
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    {!isSmallScreen && (
                        <Box width="308px" display="flex" alignItems="center">
                            <Search
                                handleSearch={handleSearchChange}
                                placeholder={t('SearchAndFilter')}
                                value={searchKey ? searchKey : ''}
                                handleClear={handleClear}
                                outlined
                            />
                        </Box>
                    )}
                </Box>
            </Paper>

            <EidTableGrid
                noDataMessage={t('NoDataMessage')}
                pagination={claimsMappingPolicesPagination}
                isLoading={
                    policy?.length > 0 &&
                    policy[0]?.id &&
                    claimsMappingPoliciesLoading
                }
                attributes={getAccessBasedAttributes(attributes)}
                list={
                    policy?.length > 0 && policy[0]?.id
                        ? claimsMappingPolicies
                        : []
                }
            />
        </>
    )
}
