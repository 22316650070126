import { Box, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TextLabel = ({ item, value }) => {
    const { t } = useTranslation()
    const renderValue = () => {
        if (!item?.valueType) {
            return value
        } else if (item?.valueType === 'dateTime') {
            return moment.utc(value).clone().local().format('L LT')
        }
    }
    return (
        <Box
            style={{
                display: item?.orientation === 'horizontal' ? 'flex' : 'block',
                gap: 10,
            }}
        >
            <Typography variant="subtitle1" style={{ ...item?.labelStyle }}>
                {t(item?.localeKey).toUpperCase()}
            </Typography>
            <Typography
                style={{
                    ...item?.valueStyle,
                }}
            >
                {value ? (
                    <>{renderValue(value)}</>
                ) : (
                    <span style={{ color: 'rgb(180, 180, 180)' }}>-</span>
                )}
            </Typography>
        </Box>
    )
}

export default TextLabel
