import { Box, styled, Typography } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useApplicationPeopleByResourceId } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl, imagesDisabled } from 'utils'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from 'components/ApplicationBanner/CreateWorkflowButton'
import { ApplicationTypes } from 'utils'
import { PageContextProvider } from 'pageContext'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'
import Filters from 'components/Filters'
import EditAssignmentsWithFieldTypes from 'components/Popups/EditAssignmentsWithFieldTypes'
import DialogHeader from 'components/Popups/EditAssignmentsWithFieldTypes/DialogHeader'
import { useState } from 'react'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const AssignmentHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})

const DisplayNameHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})
const RoleSetting = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& button': { height: '32px' },
})

const AssignRightButton = styled(Box)({
    '& button': {
        width: '150px',
    },
})

const EditAssignmentButton = ({
    applicationId,
    applicationType,
    resourceId,
    assignee,
    resourceSystemModuleID,
    applicationFriendlyName,
}) => {
    const { t } = useTranslation()
    const [openAssigneeModal, setOpenAssigneeModal] = useState(false)

    if (
        applicationType === ApplicationTypes.PbacHasAppResourcesWithoutFieldType
    ) {
        return (
            <CreateWorkflowButton
                label={t('AssignRights')}
                workflowName="AssignAZLocalRightScope"
                resourceId={resourceId}
                selectedAssigneeId={assignee?.id}
            />
        )
    }
    return (
        <PageContextProvider key={'ApplicationRightsPeopleEditAssignment'}>
            <DialogHeader
                labelKey={'Edit'}
                headerText={{
                    dynamicText: applicationFriendlyName,
                    text: 'AssignRights',
                }}
                setShowAssignmentModal={setOpenAssigneeModal}
                showAssignmentModal={openAssigneeModal}
            >
                <EditAssignmentsWithFieldTypes
                    showAssignmentModal={openAssigneeModal}
                    handleClose={() => setOpenAssigneeModal(false)}
                    resourceType={'Applications'}
                    assignedUrl={`api/LocalRights/appRoles/membersByApplicationID`}
                    unAssignedUrl={`api/LocalRights/GetEligibleAppRightsByApplicationByAssignee`}
                    selectedApplicationId={applicationId}
                    searchPlaceHolder="SearchRights"
                    assignedQueryParams={{
                        resourceSystemModuleID: resourceSystemModuleID,
                        assigneeId: assignee?.id,
                    }}
                    unAssignedQueryParams={{
                        applicationId,
                        eligibilityAssigneeId: assignee?.id,
                    }}
                    showAccessRequestPolicy={true}
                    fetchRequestPolicyItemWise={true}
                    showStartDate={false}
                    fieldTypeProps={{
                        assignee: assignee,
                        isRole: false,
                        keyForRoleRightId: 'localRightId',
                    }}
                    requireRemappingInAssignedList={true}
                    newMappingForAssignedList={{
                        friendlyName: 'appRightFriendlyName',
                        name: 'appRightName',
                    }}
                />
            </DialogHeader>
        </PageContextProvider>
    )
}

const ApplicationAppRightsPeople = ({
    applicationId,
    applicationType,
    resourceId,
    resourceSystemModuleID,
    applicationFriendlyName,
}) => {
    const { t } = useTranslation()

    const queryFilters = {
        showLocalRights: true,
    }

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationPeopleByResourceId(applicationId, queryFilters)

    const { canAssignApplicationAppRightToPeople, getAccessBasedAttributes } =
        useSubcomponents()

    const attributes = [
        {
            name: 'assignments',
            sortable: false,
            label: 'Assignments',
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        <BooleanIcon
                            data={data}
                            attribute={{ name: 'localRightAssigned' }}
                            nullAsFalse={true}
                        />
                    </AssignmentHeader>
                ),
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRightPeople-Assignments',
            },
        },
        {
            hideValue: true,
            showInCard: false,
            showInTable: false,
            image: true,
            component: {
                componentName: 'ResourceImage',
                name: 'imageThumbUrl',
                defaultUrl: '/webcdn/Images/AppLogos/Genric-1.png',
            },
        },
        {
            name: 'friendlyName',
            sortable: false,
            label: 'DisplayName',
            isHeader: true,
            showInCard: false,
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <DisplayNameHeader>
                        {!imagesDisabled && (
                            <Avatar src={getFullImageUrl(data.imageThumbUrl)} />
                        )}
                        <Typography
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                        >
                            {data.friendlyName}
                        </Typography>
                    </DisplayNameHeader>
                ),
            },
            //
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRightPeople-DisplayName',
            // },
        },
        {
            name: 'login',
            sortable: true,
            label: 'Login',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRightPeople-Login',
            },
        },
        {
            name: 'company',
            sortable: true,
            label: 'Company',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRightPeople-Company',
            },
        },
        {
            name: 'email',
            sortable: true,
            label: 'Email',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRightPeople-Email',
            },
        },

        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-AppRightPeople-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <RoleSetting>
                            {canAssignApplicationAppRightToPeople && (
                                <AssignRightButton
                                    data-protectedsubcomponent={
                                        canAssignApplicationAppRightToPeople
                                    }
                                >
                                    <EditAssignmentButton
                                        applicationId={applicationId}
                                        assignee={data}
                                        applicationType={applicationType}
                                        resourceId={resourceId}
                                        refetch={refetch}
                                        resourceSystemModuleID={
                                            resourceSystemModuleID
                                        }
                                        applicationFriendlyName={
                                            applicationFriendlyName
                                        }
                                    />
                                </AssignRightButton>
                            )}
                            <UIActionsGearButton item={data} noun='ResourceAdminPbacAccessAssignments' />
                        </RoleSetting>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
            >
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationAppRightsPeople
