import { Box, Collapse } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { usePeopleDetails, usePeopleDynamicFormData } from 'hooks'
import { useSessionStorage } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import BasicInfoCard from './BasicInfoCard'
import OutOfOfficeCard from './OutOfOfficeCard'
import { Card } from 'packages/eid-ui'
import { LeftPaneActions } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Skeleton } from '@mui/material'
import { Fragment } from 'react'
import DynamicInfoSection from './DynamicInfoSection'

const firstWrap = 960
const secondWrap = 760

const canSeeBasicInfoCardName = 'ResourceAdmin-People-Detail-BasicInfo'
const canSeeOutOfOfficeCardName = 'ResourceAdmin-People-Detail-OutOfOffice'
const canSeeActionsPaneName = 'ResourceAdmin-People-Detail-Actions'

const Item = withStyles({
    root: {
        marginBottom: '40px',
    },
})(({ classes, children }) => {
    return <Box classes={classes}>{children}</Box>
})

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        [theme.breakpoints.down(secondWrap)]: {
            flexDirection: 'column',
        },
    },
    leftColumn: {
        flex: '30%',
        [theme.breakpoints.down(firstWrap)]: {
            flex: '50%',
        },
    },
    rightColumn: (openControls) => ({
        flex: '70%',
        marginLeft: openControls ? '7.5px' : '0px',
        transition: 'margin .40s ease-in-out',
        [theme.breakpoints.down(firstWrap)]: {
            flex: '50%',
        },
    }),
    rightColumns: {
        width: '50%',
        [theme.breakpoints.down(firstWrap)]: {
            width: '100%',
        },
    },
    editProfileButton: {
        backgroundColor: '#ffffff',
        height: '50px',
        borderRadius: '6px',
        marginBottom: '16px',
        paddingLeft: '12px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: '30%',
    },
    filterCardHeader: {
        borderRadius: '5px',
        height: '40px',
    },
    Divider: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    detailsButtonsection: {
        cursor: 'poiinter',
        display: 'inline-flex',
        gap: '20px',
        alignItems: 'center',
        padding: '20px',
        marginTop: '10px',
        width: 'fit-content',
        '& button': {
            maxWidth: '100% !important',
            width: 'fit-content',
            display: 'inline-flex',
        },
    },
    editPersonHeader: {
        background: theme?.palette?.primary?.main,
        padding: '20px',
        borderRadius: '5px 5px 0 0',
        marginBottom: '10px',
        '& p': {
            fontSize: '18px',
            color: '#fff',
        },
    },
    editPersonSection: {
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        background: '#fff',
        borderRadius: '5px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: '1',
        width: '100%',
    },
}))

const PeopleDetails = ({ peopleId }) => {
    const {
        data: personDetails,
        isLoading: personDetailsLoading,
        refetch,
    } = usePeopleDetails(peopleId)
    const { data: dynamicFormData, isLoading } = usePeopleDynamicFormData(
        personDetails?.id,
        personDetails?.resourceTypeId,
    )

    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()

    const config = [
        {
            name: 'basicInfo',
            type: 'profile',
            editable: true,
            showEditOption: true,
            fields: [
                {
                    name: 'firstName',
                    type: 'InputField',
                    label: 'FirstName',
                    required: false,
                    style: {
                        fontWeight: 'bold',
                        color: '',
                        fontSize: '24px',
                        textDecoration: '',
                        width: 180,
                    },
                },
                {
                    name: 'lastName',
                    type: 'InputField',
                    label: 'LastName',
                    required: false,
                    style: {
                        fontWeight: '',
                        color: '',
                        textDecoration: '',
                        fontSize: '24px',
                        width: 180,
                    },
                },
                {
                    name: 'jobTitle',
                    type: 'InputField',
                    label: 'JobTitle',
                    required: false,
                    style: { width: 180 },
                },
            ],
        },
        {
            name: 'outOfOffice',
            type: 'general',
            editable: false,
            showEditOption: false,
            fields: [
                {
                    name: 'oofStartDate',
                },
                {
                    name: 'oofEndDate',
                },
                {
                    name: 'oofInternalMsg',
                },
                {
                    name: 'oofAudience',
                },
                {
                    name: 'oofStatus',
                },
                {
                    name: 'isOutOfOffice',
                },
                {
                    name: 'oofExternalMsg',
                },
            ],
        },
    ]

    const [openControls, setOpenControls] = useSessionStorage(
        'MYCONTROLS_EXPANDED',
        true,
    )

    const classes = useStyles(openControls)

    return (
        <Box style={{ marginLeft: '10px' }} width="100%">
            <Card.CardHeader
                collapsible
                fontColor="black"
                cardTitle={openControls ? t('HidePane') : t('ShowPane')}
                handleExpandClick={() => setOpenControls((prev) => !prev)}
                expanded={openControls}
                icon={<Icon name="AdvancedSearch" color="#959598" />}
                style={{
                    borderRadius: '5px',
                    height: '40px',
                    margin: '0px 0px 16px 0px',
                    width: '30%',
                }}
            />

            <Box display="flex" className={classes.mainContainer}>
                <Collapse
                    in={openControls}
                    timeout={300}
                    unmountOnExit
                    className={classes.leftColumn}
                >
                    {personDetailsLoading ? (
                        <Fragment>
                            <Skeleton height={20} />
                            <Skeleton height={20} />
                            <Skeleton height={20} />
                        </Fragment>
                    ) : personDetails ? (
                        <Item>
                            {hasAccessToControl(canSeeBasicInfoCardName) && (
                                <Box
                                    data-protectedsubcomponent={
                                        canSeeBasicInfoCardName
                                    }
                                >
                                    <BasicInfoCard
                                        personGuid={peopleId}
                                        config={config.find(
                                            (c) => c.name === 'basicInfo',
                                        )}
                                        personDetails={personDetails}
                                    />
                                </Box>
                            )}
                            {hasAccessToControl(canSeeOutOfOfficeCardName) && (
                                <Box
                                    data-protectedsubcomponent={
                                        canSeeOutOfOfficeCardName
                                    }
                                >
                                    <OutOfOfficeCard
                                        oofInfo={personDetails}
                                        config={config.find(
                                            (c) => c.name === 'outOfOffice',
                                        )}
                                    />
                                </Box>
                            )}
                            {hasAccessToControl(canSeeActionsPaneName) && (
                                <Box
                                    data-protectedsubcomponent={
                                        canSeeActionsPaneName
                                    }
                                    marginTop="10px"
                                >
                                    <LeftPaneActions
                                        resource={personDetails}
                                        resourceType="People"
                                        activeTab="overview"
                                    />
                                </Box>
                            )}
                        </Item>
                    ) : (
                        <></>
                    )}
                </Collapse>
                <div className={classes.rightColumn}>
                    <DynamicInfoSection
                        personDetails={personDetails}
                        classes={classes}
                        dynamicFormData={dynamicFormData?.dynamicFields}
                        isLoading={isLoading}
                        personDetailsLoading={personDetailsLoading}
                        refetch={refetch}
                    />
                </div>
            </Box>
        </Box>
    )
}

export default PeopleDetails
