import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreenFalse } from 'packages/core'
import { useResourceOwners } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Fragment } from 'react'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})
const canSeeOwnersControlName =
    'ResourceAdmin-ApplicationDetails-ApplicationSecret-Owners-Control'
export const MoreInformationSection = ({ secret }) => {
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreenFalse()
        ? 'vertical'
        : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useResourceOwners(
        hasAccessToControl(canSeeOwnersControlName) ? secret.id : null,
    )

    return (
        <>
            {hasAccessToControl(canSeeOwnersControlName) ? (
                <Box data-protectedsubcomponent={canSeeOwnersControlName}>
                    {ownersLoading ? (
                        <ItemDetails.AttributesContainer>
                            <PaddedDiv>
                                <ItemDetails.AttributesLoader />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>
                    ) : (
                        <ItemDetails.AttributesContainer bottomPadding="0">
                            <PaddedDiv>
                                <ItemDetails.Attribute
                                    label={t('AppSecrets_Owners')}
                                    value={<PeopleList list={owners} />}
                                    orientation={attributeOrientation}
                                />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>
                    )}
                </Box>
            ) : (
                <></>
            )}
        </>
    )
}
