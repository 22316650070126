import { Box, Typography, styled, Dialog, useTheme } from '@mui/material'
import { Button } from 'components'
import { Autocomplete, Select, WithCloseButton } from 'packages/eid-ui'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    CloseIconeBox,
    InputBox,
    ModalFooter,
} from 'components/Popups/EditAssignments'
import {
    useAddIamShopAssignee,
    useAllowedAssignmentTypes,
    useApplicationRolesAll,
    useBusinessLocationsTree,
    useBusinessRolesTree,
    useManagementRolesAll,
    useManagementRolesAndDefination,
    useQueryBasedCollection,
    useRequestAssigneeTypesByResourceTypeId,
} from 'hooks'
import { Skeleton } from '@mui/material'
import { useDebounce } from 'packages/core'
import BusinesRoleAndLocationTree from 'components/BusinesRoleAndLocationTree/BusinesRoleAndLocationTree'
import { RESOURCE_TYPES } from 'utils'

const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '830px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const AssigneesModal = styled(Box)({
    maxWidth: '700px',
    width: '100%',
    background: '#fff',
    border: 'solid 1px #ebebed',
    padding: '40px',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})

const SelectedItemIndicator = styled(WithCloseButton)({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '360px',
    minHeight: '40px',
})

const FieldsLabel = styled('label')({
    padding: '0 0 4px 8px',
    lineHeight: 1.5,
    fontSize: '12px',
    display: 'block',
    color: ' #b4b4b4',
    textTransform: 'uppercase',
})

const Divider = styled(Box)({
    background: '#d8d8d8 !important',
    height: '1px',
    margin: '24px 0',
})

const RoleBoxCard = styled(Box)({
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
        color: '#000',
        height: '40px',
        '&::placeholder': {
            color: '#b4b4b4',
        },
    },
})

const RoleBoxCardSection = styled(Box)({
    width: '100%',
    maxWidth: '48% !important',
    marginBottom: '15px',
})
const RoleBoxCardInner = styled(Box)({
    maxHeight: '200px',
    overflowY: 'auto',
    width: '100%',
    marginTop: '24px',
})

const PermissionLevels = ({
    value,
    resourceTypeId,
    permissionLevelProps,
    onChange,
    resourceId,
}) => {
    const { t } = useTranslation()

    const { data: permissionLevels } = useRequestAssigneeTypesByResourceTypeId(
        resourceTypeId,
        resourceId,
    )
    const { permissionLabelProp } = permissionLevelProps
    const getOptionLabel = (option) => option[permissionLabelProp]

    return (
        <InputBox>
            <FieldsLabel>{t('IAmShopPermissionLevel')}</FieldsLabel>
            <Select
                value={value}
                options={permissionLevels?.length > 0 ? permissionLevels : []}
                placeholder={t('GetPermissionLevel')}
                getOptionLabel={getOptionLabel}
                onChange={onChange}
                required
            />
        </InputBox>
    )
}

const MIN_CHARACTERS_REQUIRED_TO_SEARCH = 3

const AddIAMShopAssignee = ({ sharedFolder, refetch }) => {
    const [searchedRole, setSearchedRole] = useState('')
    const [searchedLocation, setSearchedLocation] = useState('')
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedRole, setSelectedRole] = useState(null)
    const [openAssigneeModal, setOpenAssigneeModal] = useState(false)
    const [policyAssignee, setPolicyAssignee] = useState('')
    const [permissionLevel, setPermissionLevel] = useState('')
    const [selectedItem, setSelectedItem] = useState(null)
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([])
    const { t } = useTranslation()
    const theme = useTheme()
    const { data: allowedAssigneeType } =
        useAllowedAssignmentTypes(openAssigneeModal)

    const [addNewAssigneToFolder, { isLoading: assigningAsignee }] =
        useAddIamShopAssignee(sharedFolder?.id)

    const debouncedSearchRolValue = useDebounce(searchedRole)
    const debouncedSearchLocationValue = useDebounce(searchedLocation)
    const { data: businessRoles, isLoading: businessRoleLoading } =
        useBusinessRolesTree(
            policyAssignee?.id === 'Business Role and Location',
            debouncedSearchRolValue?.length >= MIN_CHARACTERS_REQUIRED_TO_SEARCH
                ? debouncedSearchRolValue
                : '',
        )
    const { data: locationTree, isLoading: businessLocationLoading } =
        useBusinessLocationsTree(
            policyAssignee?.id === 'Business Role and Location',
            debouncedSearchLocationValue?.length >=
                MIN_CHARACTERS_REQUIRED_TO_SEARCH
                ? debouncedSearchLocationValue
                : '',
        )

    const [
        getApplicationRolesAll,
        { data: applicationRolesAll, isLoading: applicationRolesLoading },
    ] = useApplicationRolesAll()

    const [
        getManagementRolesAll,
        { data: managementRolesAll, isLoading: managementRoleLoading },
    ] = useManagementRolesAll()
    const [
        getManagementRolesAndDefination,
        {
            data: managementRolesAndDefination,
            isLoading: managementRoleAndDefinationLoading,
        },
    ] = useManagementRolesAndDefination()
    const [
        getQueryBasedCollection,
        { data: queryBasedCollection, isLoading: queryBasedCollectionLoading },
    ] = useQueryBasedCollection()

    const getOptionLabel = (option) => option.value

    const handlePolicyAssigneChange = (evt) => {
        setPolicyAssignee(evt)
        setSelectedItem(null)
        setAutoCompleteOptions([])
        if (evt?.id === RESOURCE_TYPES.GROUP) {
            getApplicationRolesAll()
        }
        if (evt?.id === RESOURCE_TYPES.MANAGEMENTROLE) {
            getManagementRolesAll()
        }
        if (evt?.id === RESOURCE_TYPES.MANAGEMENTROLEDEFINITION) {
            getManagementRolesAndDefination()
        }
        if (evt?.id === RESOURCE_TYPES.QUERYBASEDCOLLECTION) {
            getQueryBasedCollection()
        }
    }

    useEffect(() => {
        if (
            applicationRolesAll &&
            policyAssignee?.id === RESOURCE_TYPES.GROUP
        ) {
            setAutoCompleteOptions(bindAutoCompleteOptions(applicationRolesAll))
        }
        if (
            managementRolesAll &&
            policyAssignee?.id === RESOURCE_TYPES.MANAGEMENTROLE
        ) {
            setAutoCompleteOptions(bindAutoCompleteOptions(managementRolesAll))
        }
        if (
            managementRolesAndDefination &&
            policyAssignee?.id === RESOURCE_TYPES.MANAGEMENTROLEDEFINITION
        ) {
            setAutoCompleteOptions(
                bindAutoCompleteOptions(managementRolesAndDefination),
            )
        }
        if (
            queryBasedCollection &&
            policyAssignee?.id === RESOURCE_TYPES.QUERYBASEDCOLLECTION
        ) {
            setAutoCompleteOptions(
                bindAutoCompleteOptions(queryBasedCollection),
            )
        }
    }, [
        applicationRolesAll,
        managementRolesAll,
        policyAssignee,
        managementRolesAndDefination,
        queryBasedCollection,
    ])

    const bindAutoCompleteOptions = (data) => {
        if (!data?.length) {
            return []
        }

        return data.map((item) => {
            const optionObj = {
                id: item.id,
                friendlyName: item.friendlyName,
            }

            if (item.hasOwnProperty('accountStoreName')) {
                optionObj.accountStoreName = item.accountStoreName
            }

            return optionObj
        })
    }

    const handlePermissionLevelChange = (evt) => {
        setPermissionLevel(evt)
    }

    const bindOptionsForAllowedAssigneeType = () => {
        if (!allowedAssigneeType?.length) {
            return []
        }

        return allowedAssigneeType
            .filter((item) => item.name !== 'Person')
            .map((item) => {
                return {
                    id: item.name,
                    value: item.name,
                }
            })
    }

    const bindAutoCompleteLabel = () => {
        if (policyAssignee?.id === RESOURCE_TYPES.BUSINESSROLEANDLOCATION) {
            return 'SelectedBusinessRoleandLocation'
        }
        if (policyAssignee?.id === RESOURCE_TYPES.MANAGEMENTROLE) {
            return 'SelectManagementRole'
        }
        if (policyAssignee?.id === RESOURCE_TYPES.MANAGEMENTROLEDEFINITION) {
            return 'SelectManagementRoleDefinition'
        }
        if (policyAssignee?.id === RESOURCE_TYPES.QUERYBASEDCOLLECTION) {
            return 'SelectQueryBasedCollection'
        }
        if (policyAssignee?.id === RESOURCE_TYPES.GROUP) {
            return 'SelectGroupToReceivePolicy'
        }
        if (policyAssignee?.id === RESOURCE_TYPES.PERSON) {
            return 'SelectPeople'
        }
        return 'SelectAssigneeType'
    }

    const [autocompleteInputValue, setAutocompleteInputValue] = useState('')

    const handleAutocompleteInputChange = (_, value) => {
        setAutocompleteInputValue(value)
    }

    const debouncedInputValue = useDebounce(autocompleteInputValue)

    useEffect(() => {
        if (policyAssignee?.id === RESOURCE_TYPES.GROUP) {
            getApplicationRolesAll(debouncedInputValue)
        }
        if (policyAssignee?.id === RESOURCE_TYPES.MANAGEMENTROLE) {
            getManagementRolesAll(debouncedInputValue)
        }
        if (policyAssignee?.id === RESOURCE_TYPES.MANAGEMENTROLEDEFINITION) {
            getManagementRolesAndDefination(debouncedInputValue)
        }
        if (policyAssignee?.id === RESOURCE_TYPES.QUERYBASEDCOLLECTION) {
            getQueryBasedCollection(debouncedInputValue)
        }
    }, [debouncedInputValue])

    const handleLocationSelect = (val) => {
        setSelectedLocation(val)
    }

    const handleRoleSelect = (val) => {
        setSelectedRole(val)
    }

    const handleAddAssignee = () => {
        let dataObject = {}
        if (policyAssignee?.id === 'Business Role and Location') {
            dataObject = {
                orgRoleId: selectedRole,
                orgZoneId: selectedLocation,
                resourceAccessRequestAssigneeTypeId: permissionLevel.id,
            }
        } else {
            dataObject = {
                assigneeId: selectedItem.id,
                resourceAccessRequestAssigneeTypeId: permissionLevel.id,
            }
        }
        addNewAssigneToFolder(dataObject).then(() => {
            setSearchedLocation('')
            setSearchedRole('')
            setPolicyAssignee(null)
            setPermissionLevel(null)
            setSelectedItem(null)
            setOpenAssigneeModal(false)
            if (refetch) {
                refetch()
            }
        })
    }

    const handleClose = () => {
        setSearchedLocation('')
        setSearchedRole('')
        setPolicyAssignee(null)
        setPermissionLevel(null)
        setSelectedItem(null)
        setOpenAssigneeModal(false)
    }

    const checkIfButtonIsDiabled = () => {
        if (
            permissionLevel &&
            (selectedItem || (selectedRole && selectedLocation))
        ) {
            return false
        }
        return true
    }

    return (
        <>
            <Button
                onClick={() => setOpenAssigneeModal(true)}
                style={{
                    borderRadius: '4px',
                    padding: '0px 20px',
                    backgroundColor: theme?.palette?.primary?.main,
                    border: 'solid 1px #01ae8f',
                    color: '#ffffff',
                    fontSize: '14px',
                    '&:hover': {
                        backgroundColor: theme?.palette?.primary?.main,
                    },
                }}
                startIcon={
                    <Icon
                        name={'Plus'}
                        fill="white"
                        style={{
                            width: '1.6rem',
                            height: '1.6rem',
                        }}
                    />
                }
            >
                {t('AddNewIAmShopAssignees')}
            </Button>
            <CustomDialog
                open={openAssigneeModal}
                onClose={() => handleClose()}
                title={t('')}
            >
                <AssigneesModal>
                    <CloseIconeBox onClick={() => handleClose()}>
                        <Icon
                            name="Close"
                            width="16px"
                            height="16px"
                            color="#959598"
                        />
                    </CloseIconeBox>

                    <Typography variant="h2">
                        {t('AddNewIAmShopAssignees')}
                    </Typography>

                    <Box marginTop="30px">
                        <InputBox>
                            <PermissionLevels
                                value={permissionLevel}
                                permissionLevelProps={{
                                    permissionLabelProp:
                                        'localizedFriendlyName',
                                    permissionIdetifierProp: 'id',
                                }}
                                onChange={(e) => handlePermissionLevelChange(e)}
                                resourceTypeId={sharedFolder?.resourceTypeId}
                                resourceId={sharedFolder?.id}
                            />
                        </InputBox>
                        <Divider />
                        <InputBox>
                            <FieldsLabel>
                                {t('TypeOfAssigneeForPolicy')}
                            </FieldsLabel>
                            <Select
                                value={policyAssignee}
                                options={bindOptionsForAllowedAssigneeType()}
                                getOptionLabel={getOptionLabel}
                                onChange={(e) => handlePolicyAssigneChange(e)}
                                placeholder={t('TypeOfAssigneeForPolicy')}
                            ></Select>
                        </InputBox>
                        <Divider />
                        {policyAssignee &&
                        policyAssignee.id !== 'Business Role and Location' ? (
                            <>
                                {selectedItem ? (
                                    <>
                                        <FieldsLabel>
                                            {t(bindAutoCompleteLabel())}
                                        </FieldsLabel>
                                        <SelectedItemIndicator
                                            onClose={() =>
                                                setSelectedItem(null)
                                            }
                                        >
                                            <Typography
                                                style={{
                                                    wordBreak: 'break-all',
                                                }}
                                                variant="body1"
                                            >
                                                {selectedItem.friendlyName}
                                            </Typography>
                                        </SelectedItemIndicator>
                                    </>
                                ) : (
                                    <Autocomplete
                                        label={t(bindAutoCompleteLabel())}
                                        placeholder={t(bindAutoCompleteLabel())}
                                        options={autoCompleteOptions}
                                        loading={
                                            applicationRolesLoading ||
                                            managementRoleLoading ||
                                            businessRoleLoading ||
                                            managementRoleAndDefinationLoading ||
                                            queryBasedCollectionLoading
                                        }
                                        loadingText=""
                                        noOptionsText=""
                                        multiple={false}
                                        onChange={(_, selectedItem) =>
                                            setSelectedItem(selectedItem)
                                        }
                                        value={selectedItem}
                                        getOptionLabel={(option) =>
                                            option.friendlyName
                                        }
                                        filterOptions={undefined}
                                        style={{
                                            maxWidth: '360px',
                                        }}
                                        showOptionSubLabel={
                                            policyAssignee?.id === 'Group'
                                        }
                                        getOptionSubLabel={(option) =>
                                            option.accountStoreName
                                        }
                                        onInputChange={
                                            handleAutocompleteInputChange
                                        }
                                        inputValue={autocompleteInputValue}
                                    />
                                )}
                                <Divider />
                            </>
                        ) : null}

                        {policyAssignee?.id === 'Business Role and Location' ? (
                            <>
                                <RoleBoxCard>
                                    <RoleBoxCardSection>
                                        <InputBox>
                                            <FieldsLabel>
                                                {t('SelectBusinessRole')}
                                            </FieldsLabel>
                                            <input
                                                type="text"
                                                placeholder={t(
                                                    'SearchBusinessRole',
                                                )}
                                                value={searchedRole}
                                                onChange={(e) =>
                                                    setSearchedRole(
                                                        e.target.value,
                                                    )
                                                }
                                            ></input>
                                        </InputBox>
                                        <Box>
                                            <RoleBoxCardInner>
                                                {businessRoleLoading ? (
                                                    <Skeleton height={20} />
                                                ) : (
                                                    <BusinesRoleAndLocationTree
                                                        explorer={businessRoles}
                                                        handleSelect={
                                                            handleRoleSelect
                                                        }
                                                        value={selectedRole}
                                                        isExpanded={
                                                            searchedRole?.length >=
                                                            MIN_CHARACTERS_REQUIRED_TO_SEARCH
                                                        }
                                                        highlightText={
                                                            searchedRole
                                                        }
                                                    />
                                                )}
                                            </RoleBoxCardInner>
                                        </Box>
                                    </RoleBoxCardSection>
                                    <RoleBoxCardSection>
                                        <InputBox>
                                            <FieldsLabel>
                                                {t('SelectLocation')}
                                            </FieldsLabel>
                                            <input
                                                type="text"
                                                placeholder={t(
                                                    'SearchLocation',
                                                )}
                                                value={searchedLocation}
                                                onChange={(e) =>
                                                    setSearchedLocation(
                                                        e.target.value,
                                                    )
                                                }
                                            ></input>
                                        </InputBox>
                                        <Box>
                                            <RoleBoxCardInner>
                                                {businessLocationLoading ? (
                                                    <Skeleton height={20} />
                                                ) : (
                                                    <BusinesRoleAndLocationTree
                                                        explorer={locationTree}
                                                        handleSelect={
                                                            handleLocationSelect
                                                        }
                                                        value={selectedLocation}
                                                        isExpanded={
                                                            searchedLocation?.length >=
                                                            MIN_CHARACTERS_REQUIRED_TO_SEARCH
                                                        }
                                                        highlightText={
                                                            searchedLocation
                                                        }
                                                    />
                                                )}
                                            </RoleBoxCardInner>
                                        </Box>
                                    </RoleBoxCardSection>
                                </RoleBoxCard>
                            </>
                        ) : null}

                        <ModalFooter>
                            <Button
                                loading={assigningAsignee}
                                disabled={checkIfButtonIsDiabled()}
                                backgroundColor={theme?.palette?.primary?.main}
                                onClick={() => handleAddAssignee()}
                                disabledStyles={{
                                    backgroundColor: '#8b909a !important',
                                    border: 'solid 1px #8b909a !important',
                                }}
                            >
                                {t('AddNewIAmShopAssignees')}
                                <ArrowSmallIcon
                                    color="#fff"
                                    fill="#fff"
                                    direction="right"
                                />
                            </Button>
                            <Button onClick={() => handleClose()}>
                                {t('Cancel')}
                                <ArrowSmallIcon
                                    color={theme?.palette?.primary?.main}
                                    fill={theme?.palette?.primary?.main}
                                    direction="right"
                                />
                            </Button>
                        </ModalFooter>
                    </Box>
                </AssigneesModal>
            </CustomDialog>
        </>
    )
}

export default AddIAMShopAssignee
