import { Box, styled, Typography } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useApplicationPeopleByResourceId } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl, imagesDisabled } from 'utils'
import useSubcomponents from 'useSubcomponents'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'
import AssignUnAssignRoleDefinition from '../AssignUnAssignRoleDefinitions'
import Filters from 'components/Filters'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const AssignmentHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})

const DisplayNameHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})

const RoleSetting = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& button': { height: '32px' },
})

const AssignRightButton = styled(Box)({
    '& button': {
        width: '150px',
    },
})

const RoleDefinitionPeople = ({
    applicationId,
    applicationType,
    resourceId,
    resourceSystemModuleID,
}) => {
    const { t } = useTranslation()

    const queryFilters = {
        showLocalRoles: true,
    }

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationPeopleByResourceId(applicationId, queryFilters)
    const { canAssignApplicationAppRightToPeople } = useSubcomponents()

    const attributes = [
        {
            name: 'assignments',
            sortable: false,
            sortBy: 'assignments',
            label: 'Assignments',

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <AssignmentHeader>
                        <BooleanIcon
                            data={{ item }}
                            attribute={{ name: 'localRoleAssigned' }}
                        />
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'DisplayName',

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <DisplayNameHeader>
                        {!imagesDisabled && (
                            <Avatar src={getFullImageUrl(data.imageThumbUrl)} />
                        )}
                        <Typography
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                        >
                            {data.friendlyName}
                        </Typography>
                    </DisplayNameHeader>
                ),
            },
        },
        {
            name: 'login',
            sortable: true,
            sortBy: 'login',
            label: 'Login',
        },
        {
            name: 'email',
            sortable: true,
            sortBy: 'email',
            label: 'Email',
        },
        {
            name: 'enabled',
            sortable: true,
            sortBy: 'enabled',
            label: 'Enabled',

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <Typography
                        style={{ fontSize: '13px', fontWeight: 'normal' }}
                    >
                        {data.enabled ? 'Yes' : 'No'}
                    </Typography>
                ),
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <RoleSetting>
                            {canAssignApplicationAppRightToPeople && (
                                <AssignRightButton
                                    data-protectedsubcomponent={
                                        canAssignApplicationAppRightToPeople
                                    }
                                >
                                    <AssignUnAssignRoleDefinition
                                        item={data}
                                        roleId={applicationId}
                                        refetch={refetch}
                                        applicationType={applicationType}
                                        resourceId={resourceId}
                                        resourceSystemModuleID={
                                            resourceSystemModuleID
                                        }
                                    />
                                </AssignRightButton>
                            )}
                        </RoleSetting>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
                padding="16px 32px"
            >
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={attributes}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default RoleDefinitionPeople
