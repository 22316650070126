import React from 'react'
import { Layout, ScrollToTopButton } from 'packages/eid-ui'
import AppHeader from './AppHeader'
import AppGlobalMenu from './AppGlobalMenu'
import { useApplicationSettings } from 'hooks'
import SubHeader from 'components/SubHeader'
import { useIsSmallScreenFalse } from 'packages/core'
import { mobileScreenWidth, tabletScreenWidth } from 'utils'
import { Box, styled } from '@mui/material'
import ScrollToTop from 'react-scroll-up'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { headerHeight } from 'utils'

const subHeaderHeight = 70

const SubHeaderContainer = styled(Box)(
    ({ hideHeader, hideSideNavigation, isSmallScreen }) => ({
        position: 'fixed',
        top: hideHeader ? 0 : headerHeight,
        width: '100%',
        height: '80px',
        display: 'flex',
        alignItems: 'end',
        paddingTop: '0',
        paddingRight: isSmallScreen ? '0px' : '20px',
        paddingLeft: isSmallScreen
            ? '0px'
            : hideSideNavigation
            ? '25px'
            : '90px',
        backgroundColor: 'transparent',
        zIndex: 1099,

        '&::before': {
            content: '""',
            display: 'block',
            backgroundColor: 'rgb(238, 240, 244)',
            position: 'absolute',
            left: 0,
            top: '-4px',
            width: '100%',
            height: 'calc(100% + 1px)',
        },
    }),
)

const createStyles = (
    headerConfig,
    hideHeader,
    hideSideNavigation,
    isSmallScreen,
) => {
    return {
        rootStyles: {
            // position: 'relative', NOTE: commenting this to fix an error but not sure any ripple effects so just leaving this line here

            //'ms-overflow-y': 'scroll !important',
            [`@media (max-width:${mobileScreenWidth})`]: {
                // TODO: uncomment this when we enable mobile layout
                // top: hideHeader ? '0' : headerConfig.mobile?.height,
            },
            [`@media (min-width:${tabletScreenWidth})`]: {
                // TODO: uncomment this when we enable mobile layout
                // top: hideHeader ? '0' : headerConfig.desktop?.height,
            },
            '@media (min-width:960px)': {
                top: `calc(${
                    hideHeader ? '0px' : headerConfig.desktop?.height
                } + ${subHeaderHeight}px) !important`,
            },
        },

        rootMenuStyles: {
            top: hideHeader ? '0' : headerConfig.desktop?.height,

            // '@media (max-width:1024px)': {
            //     minWidth: '60px',
            // },
            // '@media (max-width:1024px) and (orientation: landscape)': {
            //     minWidth: '80px',
            // },
        },
        contentContainerStyles: {
            ...(!isSmallScreen && hideSideNavigation && { marginLeft: '17px' }),

            // TODO: delete this when we enable mobile layout
            [`@media (min-width:${tabletScreenWidth})`]: {
                marginLeft: '80px !important',
            },
        },
    }
}

const scrollToTopButtonStyles = { bottom: 20, right: 20, zIndex: 2 }
const AppLayout = ({
    children,
    totalCount,
    viewSwitcher,
    showNavTabs = true,
    showBackButton = false,
}) => {
    const { t } = useTranslation()
    const { data: appConfig } = useApplicationSettings()
    const isSmallScreen = useIsSmallScreenFalse()

    const headerConfig = appConfig.style.header
    const hideHeader = appConfig.style.header.hide
    const hideSideNavigation = appConfig.style.hideSideNavigation
    const styles = createStyles(
        headerConfig,
        hideHeader,
        hideSideNavigation,
        isSmallScreen,
    )

    const scrollToTopComponent = (
        <ScrollToTop showUnder={160} style={scrollToTopButtonStyles}>
            <ScrollToTopButton size="medium" label={t('BackToTop')} />
        </ScrollToTop>
    )

    return (
        <Layout
            header={!hideHeader && <AppHeader />}
            globalMenu={
                <AppGlobalMenu hideSideNavigation={hideSideNavigation} />
            }
            rootMenuStyles={styles.rootMenuStyles}
            rootStyles={styles.rootStyles}
            contentContainerStyles={styles.contentContainerStyles}
            subHeader={
                !isSmallScreen && (
                    <SubHeaderContainer
                        hideHeader={hideHeader}
                        hideSideNavigation={hideSideNavigation}
                    >
                        <SubHeader
                            showNavTabs={showNavTabs}
                            totalCount={totalCount}
                            viewSwitcher={viewSwitcher}
                            showBackButton={showBackButton}
                        />
                    </SubHeaderContainer>
                )
            }
            children={children}
            scrollToTopComponent={
                config.SHOW_SCROLL_TO_TOP && scrollToTopComponent
            }
        />
    )
}

export default AppLayout
