import { useAxios } from 'packages/core'
import { useNotification } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import { queryCache, useMutation, useQuery } from 'react-query'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
export const GROUPS_KEY = 'GROUPS'

export const useGroup = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [GROUPS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationroles/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}
export const useGroups = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        status,
        itemType,
        accountStore,
        accountStoreUsageType,
        ownedBy,
        location,
        businessFunction,
        rights,
        application,
        shouldLoadTags,
        advancedSearchTags,
        showOnlyAzureApplications,
        groupType,
        highLevelClassification,
        enableOwnedByApiCall,
    } = state
    const ownerPersonId = ownedBy?.id
    const description = state['advancedSearch.forms.description']
    const friendlyName = state['advancedSearch.forms.friendlyName']
    const technicalName = state['advancedSearch.forms.name']
    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (accountStoreUsageType) {
        queryData.accountStoreUsageTypeId = accountStoreUsageType.id
    }
    if (accountStore) {
        queryData.accountStoreId = accountStore.id
    }
    if (application) {
        queryData.applicationId = application.id
    }
    if (location) {
        queryData.locationId = location.id
    }
    if (businessFunction) {
        queryData.azGlobalFunctions = businessFunction
            .map((x: any) => x.id)
            .join()
    }
    if (rights) {
        queryData.rights = rights.id
    }
    if (shouldLoadTags) {
        queryData.includeTags = true
    }

    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }
    const advancedSearch = []
    if (description) {
        advancedSearch.push({
            name: 'Description',
            type: 'string',
            value: description,
        })
    }
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }
    if (technicalName) {
        advancedSearch.push({
            name: 'name',
            type: 'string',
            value: technicalName,
        })
    }

    queryData.advancedSearch = advancedSearch
    if (groupType) {
        queryData.groupTypeId = groupType.id
    }
    if (highLevelClassification) {
        queryData.highLevelClassificationId = highLevelClassification.id
    }
    const endpoint = ownerPersonId
        ? `/api/ApplicationRoles/owned/${ownerPersonId}`
        : `api/ApplicationRoles/all`

    return useApiWithInfiniteScroll(
        [
            GROUPS_KEY,
            'LIST_MY_GROUPSS',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            status,
            itemType,
            advancedSearch,
            application,
            location,
            businessFunction,
            rights,
            accountStore,
            accountStoreUsageType,
            advancedSearchTags,
            shouldLoadTags,
            showOnlyAzureApplications,
            queryData.groupTypeId,
            queryData.highLevelClassificationId,
            enableOwnedByApiCall,
            ownerPersonId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    showOnlyAzureApplications,
                },
            })
        },
        {
            enabled: enableOwnedByApiCall,
        },
    )
}
export const useResultantMembers = (id: any, queryFilters: any) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (queryFilters.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }
    if (queryFilters.sorting) {
        queryData.sortBy = queryFilters.sorting.sortBy
        queryData.desc =
            queryFilters.sorting.sortOrder === 'desc' ? true : false
    }

    const endpoint = `/api/applicationroles/resultantmembers/${id}`

    return useApiWithInfiniteScroll(
        [GROUPS_KEY, 'LIST_MY_RESULTANT_MEMBERS', id, queryData],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}
export const useGroupTypes = () => {
    const callApi = useAxios()
    return useQuery(
        [GROUPS_KEY, 'GROUP_TYPES'],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationroles/groupTypes`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
export const useHighLevelClassifications = () => {
    const callApi = useAxios()
    return useQuery(
        [GROUPS_KEY, 'HIGH_LEVEL_CLASSIFICATIONS'],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationroles/highLevelClassifications`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
export const useGroupMembers = (id: any, isResultant: boolean) => {
    const callApi = useAxios()
    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = isResultant
        ? `/api/ApplicationRoles/resultantmembers/${id}`
        : `/api/ApplicationRoles/members/${id}`

    return useApiWithInfiniteScroll(
        [`${GROUPS_KEY}_MEMBERS`, id, queryData, endpoint, isResultant],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}
export const refreshApplicationRoleMembersListing = (
    applicationRoleId: any,
) => {
    queryCache.invalidateQueries(
        (c) =>
            c.queryKey.includes(`${GROUPS_KEY}_MEMBERS`) &&
            c.queryKey.includes(applicationRoleId),
    )
}
export const useDeleteGroupMember = (applicationRoleId: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (personIds) =>
            callApi({
                method: 'POST',
                url: `/api/ApplicationRoles/delete`,
                data: {
                    groupId: applicationRoleId,
                    accountsToLeaveIDs: personIds,
                },
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('PersonsDeleted'))
                refreshApplicationRoleMembersListing(applicationRoleId)
            },
            onError: () => {
                showWarningMessage(t('PersonsNotDeleted'))
            },
        },
    )
}
export const useApplicationLocalRights = (id: any, isEnabled: true) => {
    const callApi = useAxios()
    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    return useApiWithInfiniteScroll(
        [GROUPS_KEY, 'LOCAL_RIGHTS', id, queryData, isEnabled],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/lookups/localRights/${id}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: isEnabled,
        },
    )
}
export const useApplicationSensitiveFunctions = (id: any, isEnabled: true) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    return useApiWithInfiniteScroll(
        [GROUPS_KEY, 'SENSITIVE_FUNCTIONS', id, queryData, isEnabled],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/RiskFunctions/byAssigneeId/${id}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: isEnabled,
        },
    )
}
export const useMembershipChanges = (id: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/applicationroles/accountchangehistory/${id}`

    return useApiWithInfiniteScroll(
        [`${GROUPS_KEY}_LIST_MY_MEMBERSHIP_CHANGES`, id, queryData],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const usePeople = (id: any, enable: boolean) => {
    const callApi = useAxios()
    return useQuery(
        [GROUPS_KEY, 'PEOPLE', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/people?personId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && enable,
        },
    )
}

export const useOwnersAndApprovers = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [GROUPS_KEY, id, 'OWNERS_AND_APPROVERS'],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applicationroles/OwnersAndApprovers/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useNestedGroupMembers = (id: any) => {
    const callApi = useAxios()
    const queryData: any = {}
    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    return useApiWithInfiniteScroll(
        [`${GROUPS_KEY}_NESTED_GROUP_MEMBERS`, id, queryData],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/applicationroles/members/group/${id}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useGroupsRbacAssignments = (id: any, resourceTypeId: any) => {
    const callApi = useAxios()

    const queryData: any = {}
    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/AssigneeAssignments/accessGrantedResources/${id}/${resourceTypeId}`

    return useApiWithInfiniteScroll(
        [
            `${GROUPS_KEY}_LIST_MY_MEMBERSHIP_CHANGES`,
            id,
            queryData,
            resourceTypeId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useGroupsEligibiltyPeople = (
    resourceId: any,
    eligibilityTypes: any,
) => {
    const callApi = useAxios()
    const queryData: any = {
        resourceId: resourceId,
    }
    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    if (eligibilityTypes?.length > 0) {
        queryData.eligibilityTypeIds = eligibilityTypes
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    return useApiWithInfiniteScroll(
        [GROUPS_KEY, 'ELIGIBILITY_PEOPLE', queryData],
        (skip: any, take: any) => {
            return callApi({
                url: `api/people/GetResultantPeopleByResourceByEligibilityType`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: Boolean(resourceId),
        },
    )
}

export const useDeleteEligibilityPeopleAssignments = (refetch: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (ids) =>
            callApi({
                method: 'POST',
                // TODO: Need to change endpoint when backend is ready
                url: ``,
                data: ids,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('AssignmentDeleted'))
                refetch()
            },
            onError: () => {
                showWarningMessage(t('AssignmentNotDeleted'))
            },
        },
    )
}
