import { Box, styled, Typography } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useApplicationPeopleByResourceId,
    useAssignAppRights,
    useUnAssignAppRights,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl } from 'utils'
import useSubcomponents from 'useSubcomponents'
import { PageContextProvider } from 'pageContext'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'
import Filters from 'components/Filters'
import EditAssignment from 'components/Popups/EditAssignments'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const AssignmentHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})
const RoleSetting = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& button': { height: '32px' },
})

const AssignRightButton = styled(Box)({
    '& button': {
        width: '150px',
    },
})

const EditAssignmentPopup = ({
    applicationId,
    data,
    applicationFriendlyName,
    refetch,
}) => {
    const [assignRights, { isLoading: isAssigning }] =
        useAssignAppRights(refetch)
    const [unAssignRights, { isLoading: isUnAssigning }] =
        useUnAssignAppRights(refetch)

    const handleSubmit = (assignedList, unAssignedList) => {
        const unAssignedRights = assignedList.map((i) => {
            return {
                localRightId: i.id,
                endDate: i.endDateUtc,
            }
        })
        const assignedIds = unAssignedList.map((i) => i.id)
        if (unAssignedRights.length > 0) {
            assignRights({ assigneeId: data?.id, roles: unAssignedRights })
        }
        if (assignedIds.length > 0) {
            unAssignRights({ assigneeId: data?.id, roleIds: assignedIds })
        }
    }
    return (
        <PageContextProvider key={'ApplicationAppRightPeopleAddAssignment'}>
            <EditAssignment
                resourceType={'Applications'}
                labelKey={'Edit'}
                submitButtonLabelKey={'Save'}
                assignedUrl={`api/LocalRights/GetEligibleAppRightsByApplicationByAssignee`}
                unAssignedUrl={`api/LocalRights/GetEligibleAppRightsByApplicationByAssignee`}
                targetAssigneeId={applicationId}
                searchPlaceHolder="SearchRights"
                assignedQueryParams={{
                    applicationId,
                    eligibilityAssigneeId: data?.id,
                    isAssigned: true,
                }}
                unAssignedQueryParams={{
                    applicationId,
                    eligibilityAssigneeId: data?.id,
                    isAssigned: false,
                }}
                headerText={{
                    dynamicText: applicationFriendlyName,
                    text: 'ChooseAppRights',
                }}
                onSubmit={handleSubmit}
                submitLoader={isAssigning || isUnAssigning}
                showAssignmentModal={false}
                showAccessRequestPolicy={true}
                fetchRequestPolicyItemWise={true}
                showStartDate={false}
            />
        </PageContextProvider>
    )
}
const ApplicationAppRightsPeople = ({
    applicationId,
    applicationFriendlyName,
}) => {
    const { t } = useTranslation()

    const queryData = {
        showLocalRights: true,
    }

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationPeopleByResourceId(
            applicationId,
            queryData,
        )

    const { canAssignApplicationAppRightToPeople, getAccessBasedAttributes } =
        useSubcomponents()

    const attributes = [
        {
            name: 'assignments',
            sortable: false,
            sortBy: 'assignments',
            label: 'Assignments',

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        <BooleanIcon
                            data={data}
                            attribute={{ name: 'localRightAssigned' }}
                            nullAsFalse={true}
                        />
                    </AssignmentHeader>
                ),
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsPeople-Assignments',
            },
        },
        {
            hideValue: true,
            showInCard: false,
            showInTable: false,
            image: true,
            component: {
                componentName: 'ResourceImage',
                name: 'imageThumbUrl',
                defaultUrl: '/webcdn/Images/AppLogos/Genric-1.png',
            },
        },
        {
            name: 'friendlyName',
            sortable: false,
            sortBy: 'friendlyName',
            label: 'DisplayName',
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-AppRightsPeople-DisplayName',
            // },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        <Avatar src={getFullImageUrl(data.imageThumbUrl)} />
                        <Typography
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                        >
                            {data.friendlyName}
                        </Typography>
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'login',
            sortable: true,
            sortBy: 'login',
            label: 'Login',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsPeople-Login',
            },
        },
        {
            name: 'company',
            sortable: true,
            sortBy: 'company',
            label: 'Company',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsPeople-Company',
            },
        },
        {
            name: 'email',
            sortable: true,
            sortBy: 'email',
            label: 'Email',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsPeople-Email',
            },
        },
        {
            name: 'enabled',
            sortable: true,
            sortBy: 'enabled',
            label: 'Enabled',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsPeople-Enabled',
            },

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <Typography
                        style={{ fontSize: '13px', fontWeight: 'normal' }}
                    >
                        {data.enabled ? 'Yes' : 'No'}
                    </Typography>
                ),
            },
        },

        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsPeople-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <RoleSetting>
                            {canAssignApplicationAppRightToPeople && (
                                <AssignRightButton
                                    data-protectedsubcomponent={
                                        canAssignApplicationAppRightToPeople
                                    }
                                >
                                    <EditAssignmentPopup
                                        applicationId={applicationId}
                                        data={data}
                                        applicationFriendlyName={
                                            applicationFriendlyName
                                        }
                                        refetch={refetch}
                                    />
                                </AssignRightButton>
                            )}
                        </RoleSetting>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
            >
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationAppRightsPeople
