import { useTranslation } from 'react-i18next'
import TextField from './TextField'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { isNilOrEmpty } from 'packages/core'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import moment from 'moment'

const useStyles = makeStyles((theme)=> ({
    label: {
        color: '#b4b4b4',
        fontSize: '9px',
        textTransform: 'uppercase',
        width: '30px',
        position: 'absolute',
        left: '8px',
        top: '4px',
        zIndex: 50,
    },
    dueDateInputContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
            '& input': {
                cursor: 'pointer',
                borderColor: theme?.palette?.primary?.main,
            },
        },
    },
}))

const DueDate = ({ dueDate, setDueDate }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const value = dueDate ? dueDate.clone().local() : null
    const label = t('Common_DueDate')

    return (
        <div className={classes.dueDateInputContainer}>
            <NativeDatePicker
                minDate={moment(new Date()).format('YYYY-MM-DD')}
                value={value?.format('YYYY-MM-DD')}
                handleChange={(e) => {
                    if (isNilOrEmpty(e)) {
                        setDueDate(null)
                    } else {
                        const date = moment(e)
                        const dateToSet = date
                            .clone()
                            .endOf('day')
                            .set('milliseconds', 0)
                            .utc()
                        setDueDate(dateToSet)
                    }
                }}
            >
                <Box className={classes.dueDateInputContainer}>
                    {isNilOrEmpty(value) && (
                        <Box component="p" className={classes.label}>
                            {label}
                        </Box>
                    )}
                    <TextField
                        type="text"
                        margin="none"
                        readOnly
                        value={value ? value.local().format('L') : ''}
                        style={{
                            backgroundColor: '#ffffff',
                        }}
                    />
                    <Box position="absolute" right="8px" top="16px">
                        <Icon
                            name="Tasks"
                            width={19}
                            height={20}
                            color="#959598"
                        />
                    </Box>
                </Box>
            </NativeDatePicker>
        </div>
    )
}

export default DueDate
