import { FC, useState } from 'react'
import { Theme, InputBase, Paper, InputBaseProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Icon } from 'packages/eid-icons'
import { IconButton } from 'packages/eid-ui'
import { mobileScreenWidth } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (error) => ({
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
            fontSize: '18px',
            border: error ? 'solid 1px red' : 'solid 1px #d8d8dd',
            [`@media (max-width:${mobileScreenWidth})`]: {
                width: 'auto',
            },
        }),
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            fontSize: '20px',
            color: '#8b909a',
            width: '100%',
            '& input::placeholder': {
                fontSize: '18px',
            },
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        iconButtonText: {
            margin: 0,
            padding: 0,
            color: '#bdbdbd',
            fontSize: '10px',
            marginBottom: '-4px',
        },
    }),
)

export interface PasswordInputProps extends InputBaseProps {}

export const PasswordInput: FC<PasswordInputProps> = (props) => {
    const { error, ...rest } = props
    const classes = useStyles(error)

    const [showPassword, setShow]: any = useState(false)

    return (
        <Paper className={classes.root}>
            <IconButton className={classes.iconButton} size="large">
                <Icon
                    style={{ cursor: 'default' }}
                    name="Lock"
                    color="#D2D2D2"
                />
            </IconButton>
            <form
                name="masterPasswordInputForm"
                style={{ width: '100%' }}
                onSubmit={(e) => e.preventDefault()}
            >
                <InputBase
                    className={classes.input}
                    placeholder="Master Password"
                    type={showPassword ? 'text' : 'password'}
                    {...rest}
                />
            </form>
            <IconButton
                className={classes.iconButton}
                onClick={() => setShow((prev: any) => !prev)}
                size="large"
            >
                <div>
                    <p className={classes.iconButtonText}>
                        {showPassword ? 'Hide' : 'Show'}
                    </p>
                    <Icon name="View" color="#d2d2d2" />
                </div>
            </IconButton>
        </Paper>
    )
}
