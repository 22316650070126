import React, { useState } from 'react'
import { Header, Dropdown, Loader } from 'packages/eid-ui'
import { LanguageIcon } from 'packages/eid-icons'
import {
    useCurrentPerson,
    useAvailableLocales,
    useUpdatePersonLocale,
} from 'hooks'
import config from 'config'
import { useTranslation } from 'react-i18next'

const LanguagesList = () => {
    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()
    const locale =
        currentPerson && currentPerson.languageCode
            ? currentPerson.languageCode
            : 'en'

    const { isLoading, data: availableLocales } = useAvailableLocales()

    const [currentLocale, setCurrentLocale] = useState(locale)

    const [updateLocale] = useUpdatePersonLocale()

    const changeLocale = (locale) => {
        setCurrentLocale(locale.languageCode)
        updateLocale({
            id: locale.id,
        })
    }

    return (
        <>
            {isLoading ? (
                <Loader height="0.5in" />
            ) : availableLocales.length === 0 ? (
                t('NoLanguagesAvailable')
            ) : (
                availableLocales.map((locale, index) => (
                    <Dropdown.LanguageMenuItem
                        onClick={() => changeLocale(locale)}
                        selected={currentLocale === locale.languageCode}
                        key={`${locale.nameOriginal}_{index}`}
                        avatarSrc={`${config.BASE_EID_URL}${locale.imageUrl}`}
                        displayName={locale.nameOriginal}
                    />
                ))
            )}
        </>
    )
}

const LanguageMenu = ({ showCone, display, ...rest }) => {
    const [open, setOpen] = useState(false)

    return (
        <Header.PopupMenu
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            displayIcon={display}
            menuHeight="45vh"
            showCone={showCone}
            {...rest}
        >
            {open && <LanguagesList />}
        </Header.PopupMenu>
    )
}

LanguageMenu.defaultProps = {
    display: <LanguageIcon width="24px" height="24px" color="#959598" />,
}

export default LanguageMenu
