import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Paper, styled } from '@mui/material'

import { ItemDetails } from 'components'
import { Skeleton } from '@mui/material'
import useSubcomponents from 'useSubcomponents'
import {
    defaultDataProtectedSubcomponent,
    getAccessibleTabs,
    updateUrlQueryParams,
} from 'utils'

import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'

import DeleteFieldType from './DeleteFieldType'
import { AttributeSections } from 'components/AttributeSections'
import FieldTypeValues from './DrawerTabs/FieldTypeValues'
import { PageContextProvider } from 'pageContext'

const canDeleteAppOwnedFieldType =
    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-Actions-Delete'

const SectionContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: 'auto',
})
const AppOwnedFieldTypeDetails = ({ fieldType, resourceId, applicationId }) => {
    const overviewAttributes = [
        [
            {
                label: 'FieldTypeFriendlyName',
                name: 'friendlyName',
            },
            {
                label: 'Name',
                name: 'name',
            },
            {
                label: 'UISelectionControl',
                name: 'selectionRuleName',
            },
            {
                label: 'ValueFrom',
                name: 'valueFrom',
            },
            {
                label: 'ValueTo',
                name: 'valueTo',
            },
            {
                label: 'AllValuesInRange',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.allValuesRange ? t('Yes') : t('No')
                    },
                },
            },
            {
                label: 'CustomDataSource',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.lookupSupported ? t('Yes') : t('No')
                    },
                },
            },
        ],
    ]
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const { hasAccessToControl } = useSubcomponents()

    const APP_OWNED_FIELD_TYPES_TABS = [
        {
            value: 'fieldTypeValues',
            label: 'FieldTypeValues',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationOwnedFieldTypeValuesTab-Control',
            },
            display: true,
        },
    ]

    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])
    const [activeTab, setActiveTab] = useState('fieldTypeValues')

    useEffect(() => {
        if (resourceId !== null && resourceId !== undefined) {
            const activeTabInfo = getAccessibleTabs(
                query,
                history,
                APP_OWNED_FIELD_TYPES_TABS,
                query.get('drawerActiveTab'),
                null,
                hasAccessToControl,
                'drawerActiveTab',
                '',
            )
            setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
            setActiveTab(activeTabInfo.activeTab)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceId])

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        updateUrlQueryParams(
            query,
            history,
            [{ key: 'drawerActiveTab', value: tab }],
            [],
        )
    }

    return (
        <Fragment>
            {/* Overview section start*/}
            {!fieldType ? (
                <Box>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={8}
                        style={{ marginTop: 8 }}
                    />
                </Box>
            ) : (
                <Box>
                    <Paper
                        style={{
                            padding: '0px',
                            width: 'fit-content',
                            minWidth: '100%',
                        }}
                    >
                        <SectionContainer>
                            <AttributeSections
                                data={fieldType}
                                attributes={overviewAttributes}
                                orientation={'horizontal'}
                            />
                        </SectionContainer>
                    </Paper>
                    {hasAccessToControl(canDeleteAppOwnedFieldType) && (
                        <DeleteButton
                            data-protectedsubcomponent={
                                canDeleteAppOwnedFieldType
                            }
                        >
                            <DeleteFieldType
                                item={fieldType}
                                applicationId={applicationId}
                                drawerView={true}
                            />
                        </DeleteButton>
                    )}
                </Box>
            )}

            <div style={{ height: '100%' }}>
                <ItemDetails.Tabs
                    variant="standard"
                    value={activeTab}
                    onChange={(_, value) => handleTabChange(value)}
                    sx={{
                        padding: '0 30px',
                    }}
                >
                    {allAccessibleTabs.map((item, index) => (
                        <ItemDetails.Tabs.Tab
                            key={`rights-tab-${index}`}
                            value={item.value}
                            label={t(item.label)}
                            data-protectedsubcomponent={
                                item?.requireAccess?.control ||
                                defaultDataProtectedSubcomponent
                            }
                        />
                    ))}
                </ItemDetails.Tabs>

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '90%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        {activeTab === 'fieldTypeValues' && (
                            <PageContextProvider key="RequestViewDetailsFieldTypeValues">
                                <FieldTypeValues fieldType={fieldType} />
                            </PageContextProvider>
                        )}
                    </Box>
                </Box>
            </div>
            {/* App rights tabs end */}
        </Fragment>
    )
}

export default AppOwnedFieldTypeDetails

const DeleteButton = styled(Box)({
    padding: '40px 32px 45px',
    '& div': {
        marginLeft: '0',
        gap: '10px',
    },
    '& button': {
        width: '132px !important',
    },
})
