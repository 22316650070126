import { useAcceptEmailFrom } from 'hooks'
import { useTranslation } from 'react-i18next'
import { AttributeSections } from 'components/AttributeSections'
import { Box, FormControlLabel, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Checkbox } from 'packages/eid-ui'
import { useEffect, useState } from 'react'
import { Button } from 'components'
const useFormControlStyles = makeStyles((theme) => ({
    label: {
        fontSize: '13px',
    },
    value: {
        marginLeft: '14px',
    },
    button: {
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        padding: '4px 16px 4px 13px',
        borderRadius: '4px',
        outline: 'none',
        height: '32px',
        fontSize: '14px',
        backgroundColor: theme?.palette?.primary?.main,
        color: 'white',
        width: '120px',
        '&:hover': {
            backgroundColor: '#1473c3',
            color: 'white',
        },
    },
}))
export const AcceptEmailFrom = (props) => {
    const { mailBoxId } = props
    const { data, isLoading } = useAcceptEmailFrom(mailBoxId)
    const classes = useFormControlStyles()
    const { t } = useTranslation()
    const [acceptAll, setAcceptAll] = useState(false)
    useEffect(() => {
        if (data) {
            setAcceptAll(data.data?.acceptEmailFrom)
        }
    }, [data])
    const attributes = [
        // NOTE: Changing the structure of attributes to divide them in sections as per design
        [
            {
                name: 'acceptEmailFrom',
                label: 'AcceptEmailFrom',
                component: {
                    componentName: 'renderer',
                    render: (data) => {
                        return (
                            <FormControlLabel
                                classes={{ label: classes.label }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={(e) => {
                                            const checked = e.target.checked
                                            setAcceptAll(checked)
                                        }}
                                        checked={acceptAll}
                                    />
                                }
                                label={t('AcceptFromAllSenders')}
                            />
                        )
                    },
                },
            },
        ],
    ]

    return (
        <>
            <Paper style={{ paddingRight: 10 }}>
                <AttributeSections
                    data={data?.data}
                    isLoading={isLoading}
                    attributes={attributes}
                    valueClassName={classes.value}
                />
            </Paper>
            <Box style={{ marginTop: '20px' }}>
                <Button
                    onClick={() => {
                        console.log(acceptAll)
                    }}
                    style={{
                        height: '48px !important',
                    }}
                    className={classes.button}
                    disabledStyles={{
                        backgroundColor: '#aab0b4 !important',
                        color: '#ffffff !important',
                        borderColor: '#aab0b4 !important',
                    }}
                    disabled={
                        acceptAll === data?.data?.acceptEmailFrom || isLoading
                    }
                >
                    {t('Save')}
                </Button>
            </Box>
        </>
    )
}
