import { ArrowIcon, Icon } from 'packages/eid-icons'
import { Box, useTheme } from '@mui/material'
import { EidAutocomplete } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { useRef, useState } from 'react'
import { AdvancePeopleFinder } from './advancePeopleFinder'
import { AdvancePeopleFinderPost } from './advancePeopleFinderPost'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    cdnUrl: config.BASE_EID_URL,
})

const queryParams = {
    take: 10,
}

const getOptionLabel = (option) => option.friendlyName

function AdvancePersonFinder({
    label,
    value,
    url = '/api/People/',
    advanceSearch = false,
    onChange,
    queryParams,
    method = 'POST',
    placeholder = '',
    ...rest
}) {
    const { t } = useTranslation()
    const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false)
    const theme = useTheme()
    const ref = useRef(null)
    const queryParameters = {
        take: 10,
        ...queryParams,
    }

    return (
        <>
            <Box
                style={{
                    display: advanceSearch ? 'flex' : 'block',
                    alignItems: advanceSearch && 'end',
                }}
            >
                <EidAutocomplete.New
                    label={label ? label : t('SelectPerson')}
                    placeholder={placeholder ? placeholder : t('SelectPerson')}
                    url={url}
                    queryParams={queryParameters}
                    dedupingInterval={60000}
                    value={value ? value : null}
                    getOptionLabel={getOptionLabel}
                    optionComponent={OptionComponent}
                    onChange={onChange}
                    inputWrapperStyles={{
                        width: advanceSearch ? '26rem' : '100%',
                        boxShadow: '0 2px 4px 0 rgba(48, 127, 193, 0.15)',
                    }}
                    {...rest}
                />
                {advanceSearch && (
                    <div
                        ref={ref}
                        style={{
                            height: '4rem',
                            width: '4rem',
                            zIndex: openAdvanceSearch && 1400,
                            borderRadius: '0.4rem',
                            border: `0.1rem solid ${theme?.palette?.primary?.main}`,
                            backgroundColor: theme?.palette?.primary?.main,
                            padding: '1.2rem',
                            marginLeft: '2rem',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setOpenAdvanceSearch((p) => !p)
                        }}
                    >
                        {openAdvanceSearch ? (
                            <Icon
                                name={'SimpleUncheck'}
                                color="white"
                                style={{ width: '1.4rem', height: '1.4rem' }}
                            />
                        ) : (
                            <Icon name={'AdvancedSearch'} color="white" />
                        )}
                    </div>
                )}
            </Box>
            {method === 'POST' ? (
                <AdvancePeopleFinderPost
                    onClose={() => setOpenAdvanceSearch(false)}
                    open={openAdvanceSearch}
                    onChange={onChange}
                    url={url}
                    topPosition={ref?.current?.getBoundingClientRect().top}
                    rightPosition={ref?.current?.getBoundingClientRect().right}
                    queryParams={queryParams}
                />
            ) : (
                <AdvancePeopleFinder
                    onClose={() => setOpenAdvanceSearch(false)}
                    open={openAdvanceSearch}
                    onChange={onChange}
                    url={url}
                    topPosition={ref?.current?.getBoundingClientRect().top}
                    rightPosition={ref?.current?.getBoundingClientRect().right}
                />
            )}
        </>
    )
}

export default AdvancePersonFinder
