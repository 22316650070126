import { Box, Typography, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'packages/eid-ui'
import { StartDateTimePicker } from 'components/TimeConstrainedControl/StartDateTimePicker'
import { EndDateTimePicker } from 'components/TimeConstrainedControl/EndDateTimePicker'

const useStyles = makeStyles((theme) => ({
    NewAccessAssignmentModal: {
        '& h2': {
            fontSize: '18px',
            color: '#1b1f1f',
            fontWeight: '600',
        },
    },
    ConstraintModalHeader: {
        padding: '32px',
    },
    AssignmentModalHeader: {
        padding: '32px',
    },
    AssignmentModalHeading: {
        padding: '16px 32px',
        borderTop: '1px solid #0000002e !important',
        borderBottom: '1px solid #0000002e !important',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%) !important',
        backgroundSize: '8.00px 8.00px !important',
    },
    AssignmentModalInner: {
        padding: '20px 32px',
    },
    AssignmentModalFooter: {
        padding: '24px 32px',
        boxShadow: '0 3px 7px 4px rgba(0, 0, 0, 0.08)',
        '& button': {
            border: `solid 1px ${theme?.palette?.primary?.main}`,
            padding: '9px 24px',
            '&:nth-child(1)': {
                background: theme?.palette?.primary?.main,
                color: '#fff',
                maxWidth: '240px',
                '& svg': {
                    marginRight: '15px',
                },
            },
            '&:nth-child(2)': {
                color: theme?.palette?.primary?.main,
                marginLeft: '30px',
                maxWidth: '90px',
            },
        },
    },
    AssignmentTimeInner: {
        padding: '20px 32px',
        paddingTop: '0',

        '& .textfield': {
            maxWidth: '140px !important',
        },
    },
    validationMessage: {
        marginLeft: '120px',
        fontSize: '12px',
        color: theme.palette.error.main,
        width: '50% !important',
    },
}))

const TimeConstraintComp = ({
    handleEndDate,
    handleStartDate,
    selectedStartDate,
    selectedEndDate,
    hasInvalidDates,
    validationMessage,
    timeConstrained,
    handleTimeConstraint,
}) => {
    const { t } = useTranslation()
    const classes = useStyles({})

    return (
        <Box
            style={{
                boxShadow: '0 3px 7px 4px rgba(0, 0, 0, 0.08)',
            }}
        >
            <Box className={classes.AssignmentModalInner}>
                <Box marginLeft="20px">
                    <FormControlLabel
                        onChange={() => handleTimeConstraint(!timeConstrained)}
                        control={<Checkbox checked={timeConstrained} />}
                        label={t('TemporaryAccessGMTUTCTime')}
                    />
                </Box>
            </Box>
            <Box>
                {timeConstrained && (
                    <Box className={classes.AssignmentTimeInner}>
                        <Box display="flex" alignItems="center">
                            <Typography
                                style={{
                                    marginRight: '20px',
                                    fontSize: '14px',
                                    color: '#5d6870',
                                    width: '100px',
                                }}
                            >
                                {t('AccessBegins')}:
                            </Typography>
                            <StartDateTimePicker
                                selectedStartDate={selectedStartDate}
                                setSelectedStartDate={handleStartDate}
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginTop="20px"
                        >
                            <Typography
                                style={{
                                    marginRight: '20px',
                                    fontSize: '14px',
                                    color: '#5d6870',
                                    width: '100px',
                                }}
                            >
                                {t('AccessEnds')}:
                            </Typography>
                            <EndDateTimePicker
                                selectedEndDate={selectedEndDate}
                                setSelectedEndDate={handleEndDate}
                            />
                        </Box>
                        {hasInvalidDates && validationMessage && (
                            <Box className={classes.validationMessage}>
                                {validationMessage}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default TimeConstraintComp
