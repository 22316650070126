import { Box } from '@mui/material'
import { useAppState } from 'appContext'
import { Card } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import GenericFilter from '../EidFilter/GenericFilter'

const UNCPathFilter = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()
    const [appState] = useAppState()

    return (
        <Card
            cardTitle={t('UNCPath')}
            collapsible
            expanded={Boolean(state.uncPath)}
        >
            <Box
                padding="0"
                style={{
                    borderTop: 'solid 1px #efeff1',
                    backgroundColor: '#fbfbfd',
                }}
            >
                <Box padding="20px 20px 0px 20px">
                    <GenericFilter
                        type="autocomplete"
                        placeholder={t('SelectUNCPath')}
                        label={t('SelectUNCPath')}
                        primitiveType={true}
                        optionConfig={{
                            type: 'label',
                            labelProp: 'value',
                        }}
                        url="/api/SharedFolders/uncPaths"
                        queryParams={{
                            take: 25,
                        }}
                        contextProp="uncPath"
                        debounceInterval="500"
                        dedupingInterval="60000"
                        state={state}
                        appState={appState}
                    />
                </Box>
            </Box>
        </Card>
    )
}

export default UNCPathFilter
