import { useState, useEffect } from 'react'
import { Box, Paper } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MainOverview } from './MainOverview'
import { useSharedFolder } from 'hooks'
import { useIsSmallScreenFalse, useQuery } from 'packages/core'
import { ItemDetails } from 'components'
import useSubcomponents from 'useSubcomponents'
import { useTranslation } from 'react-i18next'
import { PageContextProvider } from 'pageContext'
import Eligibility from './Eligibility'
import EligibilityPeople from './EligibiltyPeople'
import { useHistory } from 'react-router'
import {
    getAccessibleTabs,
    getTabsBasedOnAccess,
    updateUrlQueryParams,
} from 'utils'
const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

const subTabs = [
    {
        value: 'eligibility',
        label: 'Eligibility',
        name: 'eligibility',
        display: true,
        requireAccess: {
            control: 'ResourceAdmin-Applications-Details-Overview-Eligibility',
        },
    },
    {
        value: 'eligibilityPeople',
        label: 'EligibilityPeople',
        name: 'eligibilityPeople',
        display: true,
        requireAccess: {
            control: 'ResourceAdmin-Groups-Details-Overview-Eligibility',
        },
    },
]

export const Overview = (props) => {
    const { sharedFolderId, basicAttributes } = props

    const { isLoading: detailsLoading, data: sharedFolder } =
        useSharedFolder(sharedFolderId)

    const query = useQuery()
    const { t } = useTranslation()
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreenFalse()
    const [subActiveTab, setSubActiveTab] = useState(null)
    const [accessTabs, setAccessTabs] = useState([])
    const { hasAccessToControl } = useSubcomponents()
    const history = useHistory()

    useEffect(() => {
        if (sharedFolder) {
            const allTabs = getTabsBasedOnAccess(subTabs, hasAccessToControl)
            const queryActiveSubTab =
                query.get('subActiveTab') ||
                (allTabs?.length ? allTabs[0].value : '')
            if (queryActiveSubTab) {
                const activeTabInfo = getAccessibleTabs(
                    query,
                    history,
                    subTabs,
                    null,
                    queryActiveSubTab,
                    hasAccessToControl,
                    'activeTab',
                    'subActiveTab',
                )
                setAccessTabs(activeTabInfo.allAccessibleTabs)
                setSubActiveTab(activeTabInfo.querySubActiveTab)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedFolder])

    return (
        <Box>
            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <MainOverview
                    sharedFolder={sharedFolder}
                    detailsLoading={detailsLoading}
                    basicAttributes={basicAttributes}
                />
                {accessTabs.length > 0 && (
                    <Box
                        style={{
                            marginLeft: '-18px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <ItemDetails.Tabs
                            value={subActiveTab}
                            variant={'scrollable'}
                            style={{ border: 'none' }}
                            onChange={(_, value) => {
                                setSubActiveTab(value)
                                updateUrlQueryParams(
                                    query,
                                    history,
                                    [{ key: 'subActiveTab', value: value }],
                                    [],
                                )
                            }}
                        >
                            {accessTabs.map((tab, index) => {
                                return (
                                    <ItemDetails.Tabs.Tab
                                        data-protectedsubcomponent={
                                            tab?.requireAccess
                                        }
                                        key={tab.value + index}
                                        value={tab.value}
                                        label={t(tab.label)}
                                    />
                                )
                            })}
                        </ItemDetails.Tabs>
                    </Box>
                )}
            </Paper>

            {subActiveTab === 'eligibilityPeople' && (
                <PageContextProvider key="RequestViewGroupsEligibilityPeople">
                    <EligibilityPeople roleId={sharedFolder?.id} />
                </PageContextProvider>
            )}
            {subActiveTab === 'eligibility' && (
                <PageContextProvider key="RequestViewGroupsEligbility">
                    <Eligibility
                        roleId={sharedFolder?.id}
                        resourceTypeId={sharedFolder?.resourceTypeId}
                        resourceId={sharedFolder?.resourceId}
                    />
                </PageContextProvider>
            )}
        </Box>
    )
}
