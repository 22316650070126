import { ASSIGNMENT_TYPES } from 'utils'

const cartHelpers = {
    isItemAlreadyInCart: (cart, resourceId, locationId, returnItem) => {
        if (cart && resourceId) {
            let item = []
            if (locationId) {
                item = cart.cartItems.filter(
                    (el) =>
                        el.requestableResourceId === resourceId &&
                        el.locationId === locationId,
                )
            } else {
                item = cart.cartItems.filter(
                    (el) => el.requestableResourceId === resourceId,
                )
            }

            if (item && item.length > 0) {
                if (returnItem) {
                    return item
                }
                return true
            }
        }
        if (returnItem) {
            return []
        }
        return false
    },

    assignResourceToAssignee: ({
        targetAssigneeId,
        assignmentType,
        resource,
        selectedList,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
        identifier = 'id',
        resourceTypeRoleId,
        requestableResourceId,
        resourceFriendlyName,
        resourceTypeId,
        resourceTypeName,
    }) => {
        let itemsToAddToCart = []
        selectedList.forEach((item) => {
            const itemToAdd = {
                // Type of assignment (Add or Remove)
                assignmentType,
                // Target resource id for which we are shopping for
                targetAssigneeId: targetAssigneeId,
                // What we are adding to cart
                requestableResourceId:
                    requestableResourceId || item[identifier],
                resource_FriendlyName:
                    resourceFriendlyName || item.friendlyName,
                // For resouce we are dealing with
                resourceTypeId: resourceTypeId || item?.resourceTypeId,
                resourceType_Name:
                    resourceTypeName || item?.resourceTypeName || 'Person',
                resourceTypeRoleId: resourceTypeRoleId,
                // resourceTypeRole_FriendlyName: item.friendlyName,
                resourceType_FriendlyName: item?.resourceTypeFriendlyName,
            }

            if (timeConstrained) {
                if (!endDateUtc) {
                    return undefined
                }
                itemToAdd.timeConstraintActive = true
                itemToAdd.startDateUtc = startDateUtc
                itemToAdd.endDateUtc = endDateUtc
            } else {
                if (startDateUtc) {
                    itemToAdd.startDateUtc = startDateUtc
                }
                if (endDateUtc) {
                    itemToAdd.endDateUtc = endDateUtc
                }
                itemToAdd.timeConstraintActive =
                    itemToAdd.endDateUtc && itemToAdd.startDateUtc
                        ? true
                        : false
            }
            itemsToAddToCart.push(itemToAdd)
        })

        return itemsToAddToCart
    },

    removeAssignmentToCartItem: ({
        resource,
        requestableResourceIdentifier = 'assigneeId',
        targetAssigneeId,
        selectedList,
        resourceTypeRoleIdentifier = 'id',
        assignmentType = ASSIGNMENT_TYPES.REMOVE,
    }) => {
        let itemsToAddToCart = []
        selectedList.forEach((resourceAssignment) => {
            const itemToAdd = {
                assignmentType,
                requestableResourceId:
                    resourceAssignment[requestableResourceIdentifier],
                resource_FriendlyName: resourceAssignment.friendlyName,
                resourceTypeId: resource.resourceTypeId,
                resourceType_Name: resource.resourceTypeName,
                resourceTypeRoleId:
                    resourceAssignment[resourceTypeRoleIdentifier],
                // resourceTypeRole_FriendlyName: resourceAssignment.friendlyName,
                targetAssigneeId: targetAssigneeId,
            }
            itemsToAddToCart.push(itemToAdd)
        })

        return itemsToAddToCart
    },

    addRemoveBusinessRoleAndLocation: ({
        assignmentType,
        targetAssigneeId,
        businessRole,
        locationId,
        title,
    }) => {
        const itemToAdd = {
            assignmentType: assignmentType,
            requestableResourceId: businessRole.id,
            resource_FriendlyName: title,
            locationId: locationId,
            resourceTypeId: businessRole?.resourceTypeId,
            resourceType_Name: businessRole?.resourceTypeName,
            targetAssigneeId: targetAssigneeId,
        }

        return itemToAdd
    },

    removeSingleAssignment: ({
        targetAssigneeId,
        resource,
        identifier = 'id',
    }) => {
        const itemToAdd = {
            assignmentType: ASSIGNMENT_TYPES.REMOVE,
            requestableResourceId: resource[identifier],
            resource_FriendlyName: resource.friendlyName,
            resourceTypeId: resource?.resourceTypeId,
            resourceType_Name: resource?.resourceType_Name,
            targetAssigneeId: targetAssigneeId,
            resourceType_FriendlyName: resource?.resourceTypeFriendlyName,
        }

        return itemToAdd
    },

    addRemoveAccessAssignmentToFolder: ({
        assignmentType,
        resource,
        accessAssignment,
        validFrom,
        validTo,
    }) => {
        const itemToAdd = {
            assignmentType: assignmentType,
            targetAssigneeId: resource.id,
            requestableResourceId: accessAssignment?.assigneeId, //PersonID
            resource_FriendlyName: accessAssignment?.resourceFriendlyName,
            resourceTypeId: accessAssignment?.resourceTypeId,
            resourceType_Name: accessAssignment?.resourceTypeFriendlyName,
            resourceTypeRoleId: accessAssignment?.resourceTypeRoleId,
        }

        if (validFrom) {
            itemToAdd.startDateUtc = validFrom
        }
        if (validTo) {
            itemToAdd.endDateUtc = validTo
        }
        itemToAdd.timeConstraintActive =
            itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false

        return itemToAdd
    },
    applicationsFieldTypeJsonCartItem: ({
        targetResourceId,
        assignmentType,
        application,
        assigneePerson,
        targetAssigneeId,
        fieldTypeValueJson,
    }) => {
        if (!assigneePerson) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: targetResourceId,
            resource_FriendlyName: assigneePerson.assignee,
            resourceTypeId: assigneePerson.assigneeResourceTypeId,
            resourceType_Name: assigneePerson.assigneeResourceTypeFriendlyName,
            resourceTypeRoleId: application.id,
            resourceTypeRole_FriendlyName: application.friendlyName,
            targetAssigneeId: targetAssigneeId,
            fieldTypeValueJson,
            assignmentId: assigneePerson?.id,
            itemType: assigneePerson?.itemType,
        }

        return itemToAdd
    },
    removeFieldType: ({
        targetAssigneeId,
        resource,
        identifier = 'id',
        resourceTypeId,
        resourceTypeName,
        requestableResourceId,
        resourceFriendlyName,
        resourceTypeRoleId,
    }) => {
        const itemToAdd = {
            assignmentType: ASSIGNMENT_TYPES.REMOVE,
            resourceTypeRoleId: resourceTypeRoleId || resource[identifier],
            requestableResourceId:
                requestableResourceId || resource['fieldTypeId'],
            resourceTypeId: resourceTypeId,
            resourceType_Name: resourceTypeName,
            targetAssigneeId: targetAssigneeId,
            resource_FriendlyName:
                resourceFriendlyName || resource?.fieldTypeFriendlyName,
        }

        return itemToAdd
    },
    removeMembersToCartItem: ({
        resource,
        requestableResourceIdentifier = 'accountId',
        targetAssigneeId,
        selectedList,
        assignmentType = ASSIGNMENT_TYPES.REMOVE,
        friendlyNameIdentifier="accountFriendlyName"
    }) => {
        let itemsToAddToCart = []
        selectedList.forEach((resourceAssignment) => {
            const itemToAdd = {
                assignmentType,
                requestableResourceId:
                    resourceAssignment[requestableResourceIdentifier],
                resource_FriendlyName: resourceAssignment[friendlyNameIdentifier],
                resourceTypeId: resource.resourceTypeId,
                resourceType_Name: 'Person',

                targetAssigneeId: targetAssigneeId,
            }
            itemsToAddToCart.push(itemToAdd)
        })

        return itemsToAddToCart
    },

}



export default cartHelpers
