import { useTranslation } from 'react-i18next'

const BooleanValue = ({ data, attribute }) => {
    const { t } = useTranslation()
    const item = data?.item ? data.item : data
    return (
        <>
            {item && item[attribute.name] === null
                ? '-'
                : item[attribute.name]
                ? t('Yes')
                : t('No')}
        </>
    )
}
export default BooleanValue
