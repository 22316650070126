import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { Avatar, Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl, imagesDisabled } from 'utils'

const useStyles = makeStyles((theme) => ({
    selectPeopleArea: {
        background: `${theme?.palette.common.white} !important`,
        padding: '0.8rem',
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        margin: '0 0 0.8rem 0',
        borderRadius: '0.4rem',
        minHeight: '4rem',
        scrollbarWidth: 'none',
        '&:hover': {
            background: '#efeff1 !important',
            '& .MuiBox-root': {
                background: '#efeff1 !important',
            },
        },
        '& .MuiBox-root': {
            background: `${theme?.palette.common.white} !important`,
            maxWidth: 'fit-content !important',
        },

        '& span': {
            fontSize: '1.2rem',
            color: `${theme?.palette.grey['900']} !important`,
            width: '17.5rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
        },
        '& p': {
            color: `${theme?.palette.grey['900']}`,
        },
        '& h2': {
            fontSize: '1.4rem !important',
            color: '#5d6870 !important',
            fontWeight: '600',
            width: '19rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            userSelect: 'none',
        },
        '& svg': {
            width: '1.2rem',
            height: '1.2rem',
        },
        '& .MuiAvatar-circular': {
            width: '1.6rem !important',
            height: '1.6rem !important',
            borderRadius: '50%',
            marginRight: '0.8rem',
        },
    },
    selectedPeopleArea: {
        background: `${theme?.palette.primary.main} !important`,

        '&:hover': {
            backgroundImage: `${theme?.palette.tertiary.gradient} !important`,
            '& .MuiBox-root': {
                background: 'transparent !important',
            },
        },
        '& .MuiBox-root': {
            background: `${theme?.palette.primary.main} !important`,
        },
        '& h2': {
            color: `${theme?.palette.common.white} !important`,
        },
        '& span': {
            color: `${theme?.palette.common.white} !important`,
        },
        '& p': {
            color: `${theme?.palette.common.white} !important`,
        },
        '& .MuiAvatar-circular': {
            width: '1.6rem !important',
            height: '1.6rem !important',
            borderRadius: '50%',
            marginRight: '0.8rem',
        },
    },
    disableMember: {
        background: '#e4e4ec !important',
        cursor: 'default',
        '& .MuiBox-root': {
            background: '#e4e4ec !important',
        },
        '& h2': {
            color: '#5d6870 !important',
        },

        '&:hover': {
            background: '#e4e4ec !important',
            '& .MuiBox-root': {
                background: '#e4e4ec !important',
            },
        },
    },
    alreadyAddedMember: {
        background: '#f3f3f5 !important',
        cursor: 'default',
        '& .MuiBox-root': {
            background: '#f3f3f5 !important',
        },
        '& h2': {
            color: '#3b454d !important',
        },
        '& span': {
            color: `${theme?.palette.grey['900']} !important`,
        },
        '&:hover': {
            background: '#f3f3f5 !important',
            '& .MuiBox-root': {
                background: '#f3f3f5 !important',
            },
        },
    },
}))

const AssignmentItem = ({
    isSelectedAttribute,
    isAlreadyAddedToCart,
    item,
    index,
    handleSelectPeople,
    handleDoubleClick = () => {
        return
    },
    isRightSide = false,
    secondaryAttributes,
    disabled = false,
}) => {
    const classes = useStyles({})
    const { t } = useTranslation()

    const bindSecondaryText = () => {
        let secondaryText = ''

        secondaryAttributes.forEach((element) => {
            if (item[element]) {
                if (element === 'startDateUtc' || element === 'endDateUtc') {
                    let time = moment(item[element])
                        .clone()
                        .local()
                        .format('lll')
                    secondaryText = secondaryText
                        ? `${secondaryText} - ${time}`
                        : time
                } else {
                    secondaryText = secondaryText
                        ? `${secondaryText} - ${item[element]}`
                        : item[element]
                }
            }
        })
        return secondaryText
    }

    const bindSecondaryAttributes = () => {
        let secondaryComponent = (
            <Box marginTop={'0.2rem'}>
                {secondaryAttributes.map((att) => {
                    return (
                        <>
                            {att === 'startDateUtc' || att === 'endDateUtc' ? (
                                <Box display="flex">
                                    <Box
                                        width="1.6rem"
                                        style={{ marginRight: '0.8rem' }}
                                    >
                                        <Icon
                                            name="Clock"
                                            color={
                                                isSelectedAttribute.isSelected
                                                    ? '#fff'
                                                    : '#8b909a'
                                            }
                                        />
                                    </Box>
                                    <Typography
                                        style={{
                                            fontSize: '1.2rem',
                                            marginRight: '0.4rem',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {att === 'startDateUtc'
                                            ? 'Starts:'
                                            : 'Ends:'}
                                    </Typography>
                                    <Typography variant="p">
                                        {moment(item[att])
                                            .clone()
                                            .local()
                                            .format('lll')}
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography
                                    variant="p"
                                    style={{
                                        marginLeft: item?.hasOwnProperty(
                                            'imageThumbUrl',
                                        )
                                            ? '2.4rem'
                                            : 0,
                                        userSelect: 'none',
                                    }}
                                >
                                    {item[att]}
                                </Typography>
                            )}
                        </>
                    )
                })}
            </Box>
        )

        return secondaryComponent
    }

    const getTooltip = () => {
        if (isSelectedAttribute?.alreadyAdded) {
            return t('ItemMovedToTheRight')
        }
        if (isAlreadyAddedToCart) {
            return t('Common_ItemAlreadyInCart')
        }
        if (disabled) {
            return t('CannotUnAssignItemMessage')
        }
    }
    const checkifDisabled = () => isAlreadyAddedToCart || disabled
    return (
        <Box
            style={{ userSelect: 'none' }}
            className={classNames(classes.selectPeopleArea, {
                [classes.selectedPeopleArea]:
                    isSelectedAttribute?.isSelected &&
                    !isSelectedAttribute?.alreadyAdded,
                [classes.disableMember]:
                    isAlreadyAddedToCart ||
                    disabled ||
                    isSelectedAttribute?.alreadyAdded,
                [classes.alreadyAddedMember]: isSelectedAttribute?.alreadyAdded,
            })}
            key={`search_item-${index}`}
            onClick={() => {
                if (
                    !checkifDisabled() ||
                    !(isSelectedAttribute?.alreadyAdded && isRightSide)
                ) {
                    handleSelectPeople(item)
                }
            }}
            onDoubleClick={(e) => {
                if (
                    !checkifDisabled() ||
                    !(isSelectedAttribute?.alreadyAdded && isRightSide)
                ) {
                    handleDoubleClick(e, item)
                }
            }}
        >
            <Box
                style={{
                    maxWidth: '19rem',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Tooltip
                    title={
                        <>
                            {checkifDisabled() ||
                            isSelectedAttribute?.alreadyAdded ? (
                                getTooltip()
                            ) : (
                                <>
                                    <Typography
                                        style={{
                                            fontSize: '1.4rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {item?.friendlyName}
                                    </Typography>
                                    {bindSecondaryText() && (
                                        <Typography
                                            style={{
                                                fontSize: '1.2rem',
                                            }}
                                        >
                                            {bindSecondaryText()}
                                        </Typography>
                                    )}
                                </>
                            )}
                        </>
                    }
                >
                    <Box>
                        <Box display={'flex'}>
                            {item?.hasOwnProperty('imageThumbUrl') &&
                                !imagesDisabled && (
                                    <Avatar
                                        size={'small'}
                                        src={getFullImageUrl(
                                            item?.imageThumbUrl,
                                        )}
                                    />
                                )}

                            <Typography variant="h2">
                                {item?.friendlyName}
                            </Typography>
                        </Box>
                        {bindSecondaryAttributes()
                            ? bindSecondaryAttributes()
                            : null}
                    </Box>
                </Tooltip>
            </Box>
            <Box>
                <>
                    {isSelectedAttribute?.alreadyAdded && (
                        <Icon
                            name="Check"
                            color={
                                isSelectedAttribute?.alreadyAdded
                                    ? '#8b909a'
                                    : '#fff'
                            }
                        />
                    )}
                </>
            </Box>
        </Box>
    )
}

export default AssignmentItem
