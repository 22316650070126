import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, styled } from '@mui/material'
import {
    useAddItemsToCart,
    useCart,
    useConfigSettings,
    useResourceOwnersPaginated,
} from 'hooks'
import useSubcomponents from 'useSubcomponents'
import { PageContextProvider } from 'pageContext'
import { useIsSmallScreenFalse } from 'packages/core'
import { Avatar, Tooltip, useNotification, WarningModal } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { getFullImageUrl, imagesDisabled } from 'utils'
import { Button, Filters, GridContainer, ListingGrid } from 'components'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import AddNewAssignment from 'components/Popups/AddNewAssignment/AddNewAssignment'
import cartHelpers from 'containers/Cart/cartHelpers'

const DeleteButton = styled(Box)({
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '1rem',
    '& button': {
        padding: '0.8rem 1.2rem',
        borderRadius: '0.4rem',
        border: 'solid 0.1rem #ff4d5f',
        backgroundColor: '#fff',
    },
})

const canRemoveMultipleMembersControlName =
    'ResourceAdmin-ApplicationDetails-AccessManagers-RemoveMultipleAccessManagers-Control'
const canDeleteAccessManagerControlName =
    'ResourceAdmin-ApplicationDetails-AccessManagers-DeleteAccessManager-Control'
const canAddNewAccessManagerControlName =
    'ResourceAdmin-ApplicationDetails-AccessManagers-AddAccessManagers-Control'
const resourceTypeRoleIdConfigName =
    'Application_AccessManagers_ResourceTypeRoleId'
export const AccessManagers = ({ resource }) => {
    const { t } = useTranslation()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const isSmallScreen = useIsSmallScreenFalse()
    const { showWarningMessage } = useNotification()
    const [addItemsToCart, { isLoading: addingToCart }] = useAddItemsToCart()
    const { data: cart } = useCart()

    const [selected, setSelected] = useState([])
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useResourceOwnersPaginated(resource?.id)

    const [getResourceTypeRoleId, { isLoading: isConfigLoading }] =
        useConfigSettings(resourceTypeRoleIdConfigName)

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x.id === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x.id !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredListNotAssigned = list.filter((item) => {
                if (!checkIfAlreadyAddedToCart(item?.id)) {
                    return item
                }
            })
            if (
                containsAll(filteredListNotAssigned, selected) &&
                containsAll(selected, filteredListNotAssigned)
            ) {
                setSelected([])
            } else {
                setSelected(filteredListNotAssigned)
            }
        }
    }
    const containsAll = (arr1, arr2) =>
        arr2.every((arr2Item) => arr1.includes(arr2Item))

    const checkIfAlreadyAddedToCart = (itemRequestableResourceId) => {
        return cartHelpers.isItemAlreadyInCart(cart, itemRequestableResourceId)
    }

    const handleSingleDelete = (item) => {
        setSelectedItemsToDelete([item])
    }

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }

    const postAdd = () => {
        setSelectedItemsToDelete(null)
        setSelected([])
    }

    const handleConfirmDelete = () => {
        getResourceTypeRoleId(resourceTypeRoleIdConfigName)
            .then((data) => {
                const itemsToDelete = selectedItemsToDelete.map((item) => {
                    return {
                        ...item,
                        resourceTypeRoleId_ConfigName: data.value,
                    }
                })
                const cartItems = cartHelpers.removeAssignmentToCartItem({
                    targetAssigneeId: resource?.id,
                    resource: resource,
                    selectedList: itemsToDelete,
                    requestableResourceIdentifier: 'id',
                    resourceTypeRoleIdentifier: 'resourceTypeRoleId_ConfigName',
                })

                addItemsToCart(cartItems).then(() => postAdd())
            })
            .catch((err) => {
                showWarningMessage(t('ErrorDeletingAccessAssignments'))
            })
    }

    const selectAllAction = hasAccessToControl(
        canRemoveMultipleMembersControlName,
    )
        ? {
              render: () => {
                  return (
                      <BulkRemoveButton
                          selectedCount={selected.length}
                          label={t('RemoveSelectedAccessManagers', {
                              count: selected.length,
                          })}
                          onClick={handleDeleteBulk}
                          dataProtectedsubcomponent={
                              canRemoveMultipleMembersControlName
                          }
                      />
                  )
              },
          }
        : null

    const attributes = [
        {
            label: 'DisplayName',
            name: 'friendlyName',
            isHeader: true,
            showInCard: false,
            sortable: true,
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 10 }}>
                            {!imagesDisabled && (
                                <Avatar
                                    src={getFullImageUrl(data.imageThumbUrl)}
                                />
                            )}
                            <Typography style={{ fontSize: '1.3rem' }}>
                                {data.friendlyName}
                            </Typography>
                        </Box>
                    )
                },
            },
        },
        {
            hideValue: true,
            showInCard: false,
            showInTable: false,
            image: true,
            component: {
                componentName: 'ResourceImage',
                name: 'imageThumbUrl',
                defaultUrl: '/webcdn/Images/AppLogos/Genric-1.png',
            },
        },
        {
            label: 'Login',
            name: 'login',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AccessManagers-Login',
            },
        },
        {
            label: 'Email',
            name: 'email',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AccessManagers-Email',
            },
        },
        {
            label: 'BusinessRoleAndLocation',
            name: 'businessRoleAndLocationName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AccessManagers-BusinessRoleAndLocation',
            },
        },
        {
            label: 'Department',
            name: 'department',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AccessManagers-Department',
            },
        },
        {
            label: 'Title',
            name: 'title',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AccessManagers-Title',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control: canDeleteAccessManagerControlName,
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(
                            canDeleteAccessManagerControlName,
                        ) && (
                            <Tooltip
                                title={
                                    checkIfAlreadyAddedToCart(data?.id)
                                        ? t('Common_ItemAlreadyInCart')
                                        : ''
                                }
                            >
                                <DeleteButton>
                                    <Button.Delete
                                        showEndIcon={false}
                                        data-protectedsubcomponent={
                                            canDeleteAccessManagerControlName
                                        }
                                        onClick={() => handleSingleDelete(data)}
                                        disabled={checkIfAlreadyAddedToCart(
                                            data?.id,
                                        )}
                                    />
                                </DeleteButton>
                            </Tooltip>
                        )
                    )
                },
            },
        },
    ]

    return (
        <>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="space-between"
                    height="4.0rem"
                >
                    {hasAccessToControl(canAddNewAccessManagerControlName) && (
                        <PageContextProvider
                            key={'AddNewAccessManagerAssignment'}
                        >
                            <AddNewAssignment
                                labelKey={'AddNewAccessManager'}
                                submitButtonLabelKey={'AddNewAccessManager'}
                                searchUrl={`api/people`}
                                targetAssigneeId={resource?.id}
                                searchPlaceHolder={'SearchAndFilter'}
                                resourceTypeRoleId_ConfigName={
                                    resourceTypeRoleIdConfigName
                                }
                                startIcon={
                                    <Icon
                                        name={'Plus'}
                                        fill="white"
                                        style={{
                                            width: '1.6rem',
                                            height: '1.6rem',
                                            marginRight: '0.5rem',
                                        }}
                                    />
                                }
                            />
                        </PageContextProvider>
                    )}

                    <Box
                        style={{
                            margin: isSmallScreen
                                ? '2.8rem 1.6rem'
                                : '0 0 0 auto',
                            width: isSmallScreen ? 'auto' : '30.8rem',
                        }}
                    >
                        <Filters.TextSearch outlined />
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '1.6rem 0',
                        marginTop: '0',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        selectAllAction={selectAllAction}
                        rowSelection={hasAccessToControl(
                            canRemoveMultipleMembersControlName,
                        )}
                        handleSelectAllClick={handleSelectAllClick}
                        selected={selected.map((s) => s.id)}
                        handleClick={handleRowSelection}
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={
                            (list?.length &&
                                list.map((item) => {
                                    return {
                                        ...item,
                                        isAlreadyAssigned:
                                            checkIfAlreadyAddedToCart(item?.id),
                                    }
                                })) ||
                            []
                        }
                    />
                </GridContainer>
            </ListingGrid>
            {selectedItemsToDelete && (
                <WarningModal
                    title={
                        selectedItemsToDelete.length === 1
                            ? `${t('DeleteMembers')} ${
                                  selectedItemsToDelete[0]?.friendlyName
                              }`
                            : t('DeleteSelectedItems')
                    }
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceed={handleConfirmDelete}
                    proceeding={addingToCart || isConfigLoading}
                />
            )}
        </>
    )
}
