const checkoutHistoryCardAttributes = [
    {
        name: 'businessRequestName',
        label: 'BusinessRequest',
        redirectToOtherMS: {
            microservice: 'myTasks',
            path: '/myRequests/businessRequests',
            queryParams: [{ key: 'id', value: 'businessRequestId' }],
        },
    },
    {
        name: 'businessRequestItemName',
        label: 'BusinessRequestItem',
        redirectToOtherMS: {
            microservice: 'myTasks',
            path: '/myRequests/businessRequestItems',
            queryParams: [{ key: 'id', value: 'businessRequestItemId' }],
        },
    },
    {
        name: 'startTimeUtc',
        label: 'StartTime',
        component: {
            componentName: 'DateTimeGenericComponent',
        },
    },
    {
        name: 'endTimeUtc',
        label: 'EndTime',
        component: {
            componentName: 'DateTimeGenericComponent',
        },
    },
    {
        name: 'actualCheckedOutTimeUtc',
        label: 'UserCheckedOutTime',
        component: {
            componentName: 'DateTimeGenericComponent',
        }
    },
    {
        name: 'checkedOutByUserId',
        label: 'CheckedOutBy',
        component: {
            componentName: 'PersonComponent',
        },
        textFormatterStyle: {
            overflow: 'visible',
        }
    }
]

export default checkoutHistoryCardAttributes
