import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ItemDetails } from 'components'
import { Skeleton } from '@mui/material'
import { Overview } from './Overview'
import DeleteAppManagementRole from './DeleteAppManagementRole'
import AppRights from './AppRights'
import RoleDefinitions from './RoleDefinitions'
import AccessGranted from './AccessGranted'
import Members from './Members'
import AppManagementRolesEligibility from './Eligibility'
import AppManagementRolesSensitiveFunctions from './SensitiveFunctions'
import useSubcomponents from 'useSubcomponents'
import { PageContextProvider } from 'pageContext'
import { useAppState } from 'appContext'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import {
    getAccessibleTabs,
    RESOURCE_TYPE_NAMES,
    updateUrlQueryParams,
} from 'utils'

const useStyles = makeStyles(() =>
    createStyles({
        deleteBtn: {
            padding: '40px 32px 45px',
            '& div': {
                marginLeft: '0',
                gap: '10px',
            },
            '& button': {
                width: '132px !important',
            },
        },
        itemTabMenuBar: {
            padding: '0 30px',
        },
    }),
)

const canDeleteAppManagementRoleControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-Delete-Control'

const AppManagementRoleDetails = ({
    role,
    loading,
    applicationResourceId,
    allowAppManagementRoleDirectAssignment,
}) => {
    const [, dispatch] = useAppState()

    useEffect(() => {
        if (role) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: role.id,
                    targetAssigneeFriendlyName:
                        role?.friendlyName ??
                        t(RESOURCE_TYPE_NAMES.MANAGEMENT_ROLES),
                    targetAssignee_ResourceTypeFriendlyName: t(
                        RESOURCE_TYPE_NAMES.MANAGEMENT_ROLES,
                    ),
                },
            })
            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: role.id,
                    targetAssigneeFriendlyName:
                        role?.friendlyName ??
                        t(RESOURCE_TYPE_NAMES.MANAGEMENT_ROLES),
                    targetAssignee_ResourceTypeFriendlyName: t(
                        RESOURCE_TYPE_NAMES.MANAGEMENT_ROLES,
                    ),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role, dispatch])

    const APP_MANAGEMENTROLES_TABS = [
        {
            value: 'appRights',
            label: 'AppRights',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AppRightsTab-Control',
            },
        },
        {
            value: 'roleDefinitions',
            label: 'RoleDefinitions',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-RoleDefinitionsTab-Control',
            },
        },
        {
            value: 'accessGranted',
            label: 'AccessGranted',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-RBACTab-Control',
            },
        },
        {
            value: 'members',
            label: 'PeopleAsMembers',
            display: allowAppManagementRoleDirectAssignment,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-MembersTab-Control',
            },
        },
        {
            value: 'eligibility',
            label: 'Eligibility',
            display: allowAppManagementRoleDirectAssignment,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-EligibilityTab-Control',
            },
        },
        {
            value: 'sensitiveFunctions',
            label: 'SensitiveFunctions',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-SensitiveFunctionsTab-Control',
            },
        },
    ]

    const { t } = useTranslation()
    const classes = useStyles()
    const query = useQuery()
    const history = useHistory()
    const { hasAccessToControl } = useSubcomponents()
    const [activeTab, setActiveTab] = useState('')
    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])

    useEffect(() => {
        if (
            applicationResourceId !== null &&
            applicationResourceId !== undefined
        ) {
            const activeTabInfo = getAccessibleTabs(
                query,
                history,
                APP_MANAGEMENTROLES_TABS,
                query.get('drawerActiveTab'),
                null,
                hasAccessToControl,
                'drawerActiveTab',
                '',
            )
            setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
            setActiveTab(activeTabInfo.activeTab)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowAppManagementRoleDirectAssignment])

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        updateUrlQueryParams(
            query,
            history,
            [{ key: 'drawerActiveTab', value: tab }],
            [],
        )
    }

    return (
        <Fragment>
            {!role && loading ? (
                <Box>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={8}
                        style={{ marginTop: 8 }}
                    />
                </Box>
            ) : (
                <Box>
                    <Box>
                        <Overview role={role} />
                    </Box>
                    {hasAccessToControl(
                        canDeleteAppManagementRoleControlName,
                    ) && (
                        <Box className={classes.deleteBtn}>
                            <DeleteAppManagementRole
                                item={role}
                                drawerView={true}
                            />
                        </Box>
                    )}
                    <div style={{ height: '100%' }}>
                        <ItemDetails.Tabs
                            variant="standard"
                            value={activeTab}
                            onChange={(_, value) => handleTabChange(value)}
                            className={classes.itemTabMenuBar}
                        >
                            {allAccessibleTabs?.length &&
                                allAccessibleTabs.map((item, index) => (
                                    <ItemDetails.Tabs.Tab
                                        key={`app-management-roles-tab-${index}`}
                                        value={item.value}
                                        label={t(item.label)}
                                    />
                                ))}
                        </ItemDetails.Tabs>

                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '90%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                                {activeTab === 'appRights' && (
                                    <PageContextProvider key="RequestViewAppManagementRolesDetailsAppRights">
                                        <AppRights
                                            roleId={role?.id}
                                            applicationResourceId={
                                                applicationResourceId
                                            }
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'roleDefinitions' && (
                                    <PageContextProvider key="RequestViewAppManagementRolesDetailsRoleDefinitions">
                                        <RoleDefinitions
                                            applicationResourceId={
                                                applicationResourceId
                                            }
                                            roleId={role?.id}
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'accessGranted' && (
                                    <PageContextProvider key="RequestViewAppManagementRolesDetailsAccessGranted">
                                        <AccessGranted
                                            roleId={role?.id}
                                            resourceTypeId={
                                                role?.resourceTypeId
                                            }
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'members' && (
                                    <PageContextProvider key="RequestViewAppManagementRolesDetailsMembers">
                                        <Members
                                            roleId={role?.id}
                                            requestPolicyId={
                                                role?.requestPolicyId
                                            }
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'eligibility' && (
                                    <PageContextProvider key="RequestViewAppManagementRolesDetailsEligibility">
                                        <AppManagementRolesEligibility
                                            roleId={role?.id}
                                            resourceTypeId={
                                                role?.resourceTypeId
                                            }
                                            resourceId={role?.resourceId}
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'sensitiveFunctions' && (
                                    <PageContextProvider key="RequestViewAppManagementRolesDetailsSensitiveFunctions">
                                        <AppManagementRolesSensitiveFunctions
                                            roleId={role?.id}
                                        />
                                    </PageContextProvider>
                                )}
                            </Box>
                        </Box>
                    </div>
                </Box>
            )}
        </Fragment>
    )
}

export default AppManagementRoleDetails
