import { Box, Typography, styled, useTheme } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAllCarts, useApplication, useAssignmentsByApplication } from 'hooks'
import { PageContextProvider } from 'pageContext'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from 'components/ApplicationBanner/CreateWorkflowButton'
import Filters from 'components/Filters'
import AssigneePerson from 'components/AssigneePerson/AssigneePerson'
import { Button } from 'components'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import { ApplicationTypes } from 'utils'
import { Fragment, useState } from 'react'
import ApplicationRoleDefinitionDrawer from './ApplicationRoleDefinitionDetails'
import DeleteApplicationRoleRightAssignment from './DeleteRoleRightAssignment'
import FieldTypeJsonPreviewButton from 'components/FieldTypeJsonPreview'
import DateTimeGenericComponent from 'components/DynamicComponents/DateTimeGenericComponent'
import ButtonWithDropdown from 'components/ButtonWithDropdown'
import { AssignRolesToPerson } from 'components/AssignRolesToResources/AssignRolesToPerson'
import { AssignRolesToManagementRole } from 'components/AssignRolesToResources/AssignRolesToManagementRole'
const hasAccessToAssignRolesToResources =
    'ResourceAdmin-AddApplicationAppRolesAssignments-Control'
const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const ButtonsBox = styled(Box)({
    display: 'flex',
    gap: '1rem',
})
const RoleAssignContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-between',
})
const FilerSearchContainer = styled(Box)({
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
})

const ApplicationRoleDefinitionAssignments = ({
    resourceSystemModuleID,
    resourceId,
    applicationType,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const query = useQuery()
    const history = useHistory()
    const { data: carts } = useAllCarts()
    const appRoleId = query.get('roleId')
    const applicationId = query.get('applicationId')
    const { data: application } = useApplication(applicationId)
    const { list, loading, loadingMore, pagination } =
        useAssignmentsByApplication(resourceSystemModuleID)

    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()

    const attributes = [
        {
            name: 'assignee',
            sortable: true,
            label: 'Assignee',
            hideValue: true,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-PbacAssignments-RoleDefinitions-Assignee',
            // },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <AssigneePerson
                            assigneeName={data.assignee}
                            imageUrl={data.personImageThumbUrl}
                            assigneeType={data.type}
                        />
                    )
                },
            },
        },
        {
            name: 'role',
            sortable: true,
            label: 'Role',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-RoleDefinitions-Role',
            },
        },
        {
            name: 'assignedTo',
            sortable: true,
            label: 'Description',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-RoleDefinitions-AssignedTo',
            },
        },
        {
            name: 'tenant',
            sortable: true,
            label: 'Tenant',
            styles: {
                textWrap: 'nowrap',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-RoleDefinitions-Tenant',
            },
        },

        {
            name: 'startDateUtc',
            sortable: true,
            label: 'StartDate',
            styles: {
                color: '#282828',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <DateTimeGenericComponent
                        data={{ item }}
                        attribute={{ name: 'startDateUtc' }}
                    />
                ),
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-RoleDefinitions-StartDate',
            },
        },
        {
            name: 'endDateUtc',
            sortable: true,
            label: 'EndDate',
            styles: {
                color: '#282828',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <DateTimeGenericComponent
                        data={{ item }}
                        attribute={{ name: 'endDateUtc' }}
                    />
                ),
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-RoleDefinitions-EndDate',
            },
        },

        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAssignments-RoleDefinitions-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <ButtonsBox>
                            <FieldTypeJsonPreviewButton data={data} />
                            <Box>
                                {(applicationType ===
                                    ApplicationTypes.PbacHasAppResourcesWithFieldType ||
                                    applicationType ===
                                        ApplicationTypes.PBACHasNoAppResourceWithFieldType ||
                                    applicationType ===
                                        ApplicationTypes.AzureAppWithPbac) && (
                                    <Button
                                        onClick={() => {
                                            query.set('roleId', data?.id)
                                            history.push(
                                                `${
                                                    history.location.pathname
                                                }?${query.toString()}`,
                                            )
                                        }}
                                        style={{
                                            borderRadius: '4px',
                                            backgroundColor:
                                                theme?.palette?.primary?.main,
                                            color: '#ffffff',
                                            fontSize: '14px',
                                            height: '100%',
                                            '&:hover': {
                                                backgroundColor:
                                                    theme?.palette?.primary
                                                        ?.main,
                                            },
                                        }}
                                    >
                                        <Box style={{ display: 'flex' }}>
                                            {t('Edit')}
                                        </Box>
                                    </Button>
                                )}
                            </Box>
                            <DeleteApplicationRoleRightAssignment
                                item={data}
                                carts={carts}
                                application={application}
                                keyForAssignee={'role'}
                                keyForRoleRightId={'localRoleId'}
                            />
                        </ButtonsBox>
                    )
                },
            },
        },
    ]
    const [assignmentModalValue, setAssignmentModalValue] = useState('')
    const assignRolesDropdownOptions = [
        {
            label: 'AssignToPerson',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRolesAssignment-AssignToPerson',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToPerson')
            },
        },
        {
            label: 'AssignToManagementRole',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRolesAssignment-AssignToManagementRole',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToManagementRole')
            },
        },
    ]

    const getOptionsToRender = () => {
        return assignRolesDropdownOptions.filter(
            (d) => d.display && hasAccessToControl(d?.requireAccess?.control),
        )
    }

    return (
        <Fragment>
            <Box>
                <RoleAssignContainer>
                    {hasAccessToControl(hasAccessToAssignRolesToResources) && (
                        <Box
                            data-protectedsubcomponent={
                                hasAccessToAssignRolesToResources
                            }
                        >
                            {getOptionsToRender().length > 0 && (
                                <ButtonWithDropdown
                                    label={'AssignAppRoles'}
                                    options={getOptionsToRender()}
                                />
                            )}
                        </Box>
                    )}

                    <FilerSearchContainer>
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </FilerSearchContainer>
                </RoleAssignContainer>

                <TableOverView>
                    <EidTableGrid
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={loading}
                        isLoadingMore={loadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={list}
                    />
                </TableOverView>
            </Box>
            {appRoleId && list?.length && (
                <PageContextProvider key="RequestViewAppRole">
                    <ApplicationRoleDefinitionDrawer
                        assigneePerson={list.find((x) => x.id === appRoleId)}
                        keyForAssignee={'role'}
                        keyForRoleRightId={'localRoleId'}
                        isRole={true}
                        onClose={() => {
                            query.delete('roleId')
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}
            {assignmentModalValue === 'AssignToPerson' && (
                <AssignRolesToPerson
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={applicationId}
                    resourceSystemModuleID={resourceSystemModuleID}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                />
            )}
            {assignmentModalValue === 'AssignToManagementRole' && (
                <AssignRolesToManagementRole
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={applicationId}
                    resourceSystemModuleID={resourceSystemModuleID}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                />
            )}
        </Fragment>
    )
}

export default ApplicationRoleDefinitionAssignments
