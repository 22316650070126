import { useState, useEffect } from 'react'
import { Box, Paper } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { MainOverview } from './MainOverview'
import {
    useApplicationsByClaimsMappingPolicyId,
    useClaimsByClaimsMappingPolicyId,
} from 'hooks'
import { useIsSmallScreenFalse } from 'packages/core'
import { ItemDetails } from 'components'
import useSubcomponents from 'useSubcomponents'
import { useTranslation } from 'react-i18next'
import { PageContextProvider } from 'pageContext'
import { Claims } from './Claims'
import { Applications } from './Applications'
import { smallScreenWidth } from 'utils'

const useStyles = makeStyles(() =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

export const PolicyOverview = ({ policy }) => {
    const { t } = useTranslation()
    const [callApi, setCallApi] = useState(true)
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreenFalse()

    const {
        hasAccessToSeeApplicationsByClaimsMappingPolicy,
        hasAccessToSeeClaimsByClaimsMappingPolicy,
    } = useSubcomponents()

    const subTabs = [
        {
            name: 'Claims',
            value: 'claims',
            label: 'Claims',
            display: hasAccessToSeeClaimsByClaimsMappingPolicy,
        },
        {
            name: 'Applications',
            value: 'applications',
            label: 'Applications',
            display: hasAccessToSeeApplicationsByClaimsMappingPolicy,
        },
    ]

    const subTabsAccess = subTabs.filter((f) => f.display).map((f) => f.value)

    const [subActiveTab, setSubActiveTab] = useState(
        subTabsAccess && subTabsAccess.length > -1 ? subTabsAccess[0] : null,
    )
    const { list: claims, totalCount: claimsCount } =
        useClaimsByClaimsMappingPolicyId(
            policy.id,
            subTabsAccess.indexOf('claims') > -1 &&
                Boolean(callApi || subActiveTab === 'claims'),
        )

    const { list: applications, totalCount: applicationsCount } =
        useApplicationsByClaimsMappingPolicyId(
            policy.id,
            subTabsAccess.indexOf('applications') > -1 &&
                Boolean(callApi || subActiveTab === 'applications'),
        )

    useEffect(() => {
        if (callApi && claims && applications) {
            setCallApi(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims, applications])

    const getTabCount = (tab) => {
        switch (tab) {
            case 'claims': {
                return claimsCount
            }
            case 'applications': {
                return applicationsCount
            }
            default: {
                return 0
            }
        }
    }

    return (
        <Box>
            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '16px',
                    paddingBottom: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                <MainOverview policy={policy} />
                {subTabsAccess.length > 0 && (
                    <Box
                        style={{
                            marginLeft: '-18px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <ItemDetails.Tabs
                            value={subActiveTab}
                            variant={'scrollable'}
                            style={{ border: 'none' }}
                            onChange={(_, value) => {
                                setSubActiveTab(value)
                            }}
                        >
                            {subTabs
                                .filter(
                                    (x) => subTabsAccess.indexOf(x.value) > -1,
                                )
                                .map((tab, index) => {
                                    return (
                                        <ItemDetails.Tabs.Tab
                                            key={tab.value + index}
                                            value={tab.value}
                                            label={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            marginTop: 5,
                                                            marginRight: 5,
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        {t(tab.label)}
                                                        <Box
                                                            paddingX="7px"
                                                            paddingY="10px"
                                                            style={{
                                                                color:
                                                                    subActiveTab ===
                                                                    tab.value
                                                                        ? '#fff'
                                                                        : '#8f8f8f',
                                                                backgroundColor:
                                                                    subActiveTab ===
                                                                    tab.value
                                                                        ? '#2e7aba'
                                                                        : '#e7e7e7',
                                                            }}
                                                            className={
                                                                classes.totalCountLabel
                                                            }
                                                        >
                                                            {getTabCount(
                                                                tab.value,
                                                            )}
                                                        </Box>
                                                    </span>
                                                </div>
                                            }
                                        />
                                    )
                                })}
                        </ItemDetails.Tabs>
                    </Box>
                )}
            </Paper>
            {subActiveTab === 'claims' &&
                hasAccessToSeeClaimsByClaimsMappingPolicy && (
                    <PageContextProvider key="RequestViewClaimsMappingPolicyClaims">
                        <Claims policyId={policy.id} />
                    </PageContextProvider>
                )}
            {subActiveTab === 'applications' &&
                hasAccessToSeeApplicationsByClaimsMappingPolicy && (
                    <PageContextProvider key="RequestViewClaimsMappingPolicyApplications">
                        <Applications policyId={policy.id} />
                    </PageContextProvider>
                )}
        </Box>
    )
}
