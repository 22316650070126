import { useEffect, useState } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper, styled } from '@mui/material'
import { useMailbox } from 'hooks'
import { LeftPaneTabs, LeftPaneActions } from 'components'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import useSubcomponents from 'useSubcomponents'
import { useAppState } from 'appContext'
import { PageContextProvider } from 'pageContext'
import MailboxAccess from './MailboxAccess'
import AllowedDeniedCountries from './AllowedDeniedCountries'
import AccessManagers from './AccessManagers'
import { MailboxSettings } from './MailboxSettings'
import { QuotaSettings } from './QuotaSettings'
import { SendReceiveLimits } from './SendReceiveLimits'
import { AcceptEmailFrom } from './AcceptEmailFrom'
import { RejectEmailFrom } from './RejectEmailFrom'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import { getAccessibleTabs, RESOURCE_TYPE_NAMES, updateUrlQueryParams } from 'utils'
import { Overview } from './Overview'
import { AccessRequestPolicy } from 'components/AccessRequestPolicy/AccessRequestPolicy'
import { DirectAssignedLocations } from './DirectAssignedLocations'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        nameContainer: {
            width: '100%',
            minHeight: '50px',
            marginBottom: '20px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            overflow: 'hidden',
            fontSize: '22px',
            fontWeight: 'bold',
            borderRadius: '8px',
            color: '#3b454d',
            wordBreak: 'break-word',
            padding: '5px',
        },

        tabsContainer: {
            width: '100%',
            borderRadius: '0px',
            marginBottom: '20px',
        },
        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
    }),
)

export const TabsColumn = styled(Box)({
    display: 'flex',
    minWidth: '340px',
    flexDirection: 'column',
    margin: '0px 12px 0px 10px',
    marginRight: '20px',
    width: '30%',
    flex: '36%',
})

export const MailboxDetails = ({ mailBoxId }: any) => {
    const classes = useStyles()
    const { hasAccessToControl } = useSubcomponents()
    const [{ devFeaturesEnabled }]: any = useAppState()
    const { t } = useTranslation()
    const history = useHistory()
    const { data: mailbox, isLoading, refetch } = useMailbox(mailBoxId)

    const allTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
        {
            label: t('MailboxAccess'),
            value: 'mailboxAccess',
            icon: <Icon name="AzureRbacRolesSmall" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-MailboxAccess-Control',
            },
            subTabs: [
                {
                    label: t('InventoriedPermissions'),
                    value: 'inventoriedPermissions',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Mailboxes-Details-AppRights-Control',
                    },
                    display: true,
                },
                {
                    label: t('Assignments'),
                    value: 'assignments',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Mailboxes-Details-Assignments-Control',
                    },
                    display: true,
                },
            ],
        },
        {
            label: t('MailboxSettings'),
            value: 'mailboxSettings',
            icon: <Icon name="ResultantMembers" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-MailboxSettings-Control',
            },
        },
        {
            label: t('QuotaSettings'),
            value: 'quotaSettings',
            icon: <Icon name="Regulations" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-QuotaSettings-Control',
            },
        },
        {
            label: t('SendAndReceiveLimits'),
            value: 'limits',
            icon: <Icon name="AzureRbacRolesSmall" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-SendAndReceiveLimits-Control',
            },
        },
        {
            label: t('AcceptEmailFrom'),
            value: 'accept',
            icon: <Icon name="SimpleCheck" />,
            display: devFeaturesEnabled,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-AcceptEmailFrom-Control',
            },
        },
        {
            label: t('RejectEmailFrom'),
            value: 'reject',
            icon: <Icon name="SimpleUncheck" />,
            display: devFeaturesEnabled,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-RejectEmailFrom-Control',
            },
        },
        {
            label: t('CountriesAllowedOrDeniedAccess'),
            value: 'countries',
            icon: <Icon name="Capability" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-CountriesAllowed-Control',
            },
        },
        {
            label: t('AccessManagersOrOwners'),
            value: 'managers',
            icon: <Icon name="CredentialsSmall" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-AccessManagers-Control',
            },
        },
        {
            label: t('DirectAssignedLocations'),
            value: 'directAssignedLocations',
            icon: <Icon name="Claims" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Mailboxes-Details-DirectAssignedLocations',
            },
        },
        {
            label: t('AccessRequestPolicy'),
            value: 'accessRequestPolicy',
            icon: <Icon name="ClientCertificate" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Mailboxes-Details-AccessRequestPolicy',
            },
        },
    ]

    const query = useQuery()
    const [, dispatch]: any = useAppState()

    const [activeTab, setActiveTab] = useState(null as any)
    const [subActiveTab, setSubActiveTab] = useState(null as any)
    const [allAccessibleTabs, setAllAccessibleTabs] = useState<any[]>([])

    useEffect(() => {
        if (mailbox) {
            const queryActiveTab = query.get('activeTab')
            const queryActiveSubTab = query.get('subActiveTab')
            const activeTabInfo = getAccessibleTabs(
                query,
                history,
                allTabs,
                queryActiveTab,
                queryActiveSubTab,
                hasAccessToControl,
                'activeTab',
                'subActiveTab',
            )
            setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
            setSubActiveTab(activeTabInfo.querySubActiveTab)
            setActiveTab(activeTabInfo.activeTab)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailbox])

    const handleTabChange = (tab: any) => {
        const tabObj = allAccessibleTabs.filter((t) => t.value === tab)[0]
        const subTab = tabObj && tabObj.subTabs ? tabObj.subTabs[0].value : null
        setSubActiveTab(subTab)

        setActiveTab(tab)
        updateUrlQueryParams(
            query,
            history,
            subTab
                ? [
                      { key: 'activeTab', value: tab },
                      { key: 'subActiveTab', value: subTab },
                  ]
                : [{ key: 'activeTab', value: tab }],
            !subTab ? ['subActiveTab'] : [],
        )
    }
    const handleSubTabChange = (subTab: any) => {
        setSubActiveTab(subTab)
        updateUrlQueryParams(
            query,
            history,
            [{ key: 'subActiveTab', value: subTab }],
            ['returnedFromWorkflow'],
        )
    }
    useEffect(() => {
        if (mailBoxId && mailbox?.id) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: mailBoxId,
                    targetAssigneeFriendlyName:
                        mailbox?.friendlyName ?? t(RESOURCE_TYPE_NAMES.MAILBOXES),
                    targetAssignee_ResourceTypeFriendlyName: t(RESOURCE_TYPE_NAMES.MAILBOXES),
                },
            })

            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: mailBoxId,
                    targetAssigneeFriendlyName:
                        mailbox?.friendlyName ?? t(RESOURCE_TYPE_NAMES.MAILBOXES),
                    targetAssignee_ResourceTypeFriendlyName: t(RESOURCE_TYPE_NAMES.MAILBOXES),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailBoxId, mailbox?.id])

    return (
        <>
            <Box display="flex">
                <TabsColumn>
                    <Paper className={classes.nameContainer}>
                        {mailbox?.friendlyName}
                    </Paper>
                    <Paper className={classes.tabsContainer}>
                        <LeftPaneTabs
                            active={activeTab}
                            subActiveTab={subActiveTab}
                            onChange={handleTabChange}
                            tabs={allAccessibleTabs}
                            onSubTabChange={handleSubTabChange}
                        />
                    </Paper>
                    <LeftPaneActions
                        resource={mailbox}
                        resourceType="mailboxes"
                        activeTab={activeTab}
                    />
                </TabsColumn>
                <Box className={classes.root}>
                    {activeTab === 'overview' && (
                        <Paper style={{ paddingRight: 10 }}>
                            <Overview mailboxId={mailBoxId} />
                        </Paper>
                    )}
                    {activeTab === 'mailboxAccess' && (
                        <PageContextProvider
                            key={'MailboxDetailsInventoriedPermissions'}
                        >
                            <MailboxAccess
                                mailBoxId={mailBoxId}
                                subActiveTab={subActiveTab}
                                mailbox={mailbox}
                            />
                        </PageContextProvider>
                    )}
                    {activeTab === 'countries' && (
                        <PageContextProvider key={'MailboxDetailsCountries'}>
                            <AllowedDeniedCountries mailBoxId={mailBoxId} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'managers' && (
                        <PageContextProvider key={'MailboxAccessManagers'}>
                            <AccessManagers mailbox={mailbox} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'mailboxSettings' && (
                        <PageContextProvider key={'MailboxSettings'}>
                            <Paper style={{ paddingRight: 10 }}>
                                <MailboxSettings mailbox={mailbox} />
                            </Paper>
                        </PageContextProvider>
                    )}
                    {activeTab === 'quotaSettings' && (
                        <PageContextProvider key={'QuotaSettings'}>
                            <Paper style={{ paddingRight: 10 }}>
                                <QuotaSettings mailbox={mailbox} />
                            </Paper>
                        </PageContextProvider>
                    )}
                    {activeTab === 'limits' && (
                        <PageContextProvider key={'SendAndReceiveLimits'}>
                            <Paper style={{ paddingRight: 10 }}>
                                <SendReceiveLimits mailbox={mailbox} />
                            </Paper>
                        </PageContextProvider>
                    )}
                    {activeTab === 'accept' && (
                        <PageContextProvider key={'AcceptEmailsFrom'}>
                            <AcceptEmailFrom mailBoxId={mailBoxId} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'reject' && (
                        <PageContextProvider key={'RejectEmailsFrom'}>
                            <RejectEmailFrom mailBoxId={mailBoxId} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'directAssignedLocations' && (
                        <PageContextProvider key="DirectAssignedLocations">
                            <DirectAssignedLocations id={mailBoxId} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'accessRequestPolicy' && (
                        <PageContextProvider key="AccessRequestPolicy">
                            <AccessRequestPolicy
                                resourceId={mailBoxId}
                                requestPolicyId={mailbox?.requestPolicyId}
                                refetch={refetch}
                            />
                        </PageContextProvider>
                    )}
                </Box>
            </Box>
        </>
    )
}
