import { PageContextProvider } from 'pageContext'
import Assignments from './Assignments'
import InventoriedPermissions from './InventoriedPermissions'

const MailboxAccess = ({ mailBoxId, subActiveTab, mailbox }) => {
    const renderContent = () => {
        if (subActiveTab === 'inventoriedPermissions') {
            return (
                <PageContextProvider
                    key={'MailboxAccess-InventoriedPermissions'}
                >
                    <InventoriedPermissions mailBoxId={mailBoxId} />
                </PageContextProvider>
            )
        } else if (subActiveTab === 'assignments') {
            return (
                <PageContextProvider key={'MailboxAccess-Assignments'}>
                    <Assignments mailBoxId={mailBoxId} mailbox={mailbox} />
                </PageContextProvider>
            )
        }
        return <></>
    }
    return renderContent()
}

export default MailboxAccess
