import React from 'react'
import { ArrowIcon } from 'packages/eid-icons'
import { EidAutocomplete } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { imagesDisabled } from 'utils'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    cdnUrl: config.BASE_EID_URL,
    disableImage: imagesDisabled,
})

const queryParams = {
    take: 10,
}

const getOptionLabel = (option) => option.friendlyName

const advancedSearchColumns = [
    { name: 'FriendlyName', type: 'string' },
    { name: 'Login', type: 'string' },
    { name: 'Email', type: 'string' },
]

function PeopleSearch({ value, labelKey = 'SomeoneElse',  url = '/api/people', excludeOptions, ...rest }) {
    const { t } = useTranslation()
    return (
        <EidAutocomplete.New
            label={t(labelKey)}
            placeholder={t('SelectPerson')}
            url={url}
            queryParams={queryParams}
            dedupingInterval={60000}
            value={value ? value : null}
            getOptionLabel={getOptionLabel}
            endAdornmentComponent={() => (
                <ArrowIcon direction="right" color="#453fbb" />
            )}
            optionComponent={OptionComponent}
            excludeOptions={excludeOptions}
            advancedSearchColumns={advancedSearchColumns}
            {...rest}
        />
    )
}

export default PeopleSearch
