import { Box, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Icon } from 'packages/eid-icons'
import Search from 'packages/eid-ui/GenericHeader/HeaderSearch'
import { useTranslation } from 'react-i18next'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    InfoMessageForWrongJson,
    RequiredFieldErrorMessage,
    RequiredLabel,
} from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        marginTop: '20px',
        padding: '0px 1.6rem 1.3rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h3': {
            fontSize: '16px',
            fontWeight: '600',
        },
        '& p': {
            fontSize: '12px',
            color: '#5d6870',
            fontWeight: 'normal',
        },
    },
    headerSearch: {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        borderRadius: '4px',
        maxWidth: '240px',
        width: '100%',

        '&:hover, &:focus': {
            borderColor: theme?.palette?.primary?.main,
        },

        '& input': {
            height: '2.7rem',
        },
        '& svg': {
            color: '#aab0b4',
            width: '18px !important',
            height: '18px !important',
        },
    },
    fieldList: {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',

        '& .MuiList-root': {
            columnCount: '2',
        },
        '& .MuiTableCell-body': {
            padding: '22px 10px',
        },

        '& .MuiListItem-root': {
            padding: '10px 10px',
            borderBottom: '1px solid #efeff1',
            margin: '0',
            borderTop: '1px solid #efeff1',
        },
        '& p': {
            fontSize: '13px !important',
            fontWeight: '500',
            color: '#3b454d !important',
        },
        '& table': {
            borderCollapse: 'collapse',
        },
    },
    selectedText: {
        color: `${theme?.palette?.success?.main} !important`,
        fontWeight: 'normal',
    },
    disabledStyle: {
        pointerEvents: 'none',
        opacity: '.6',
        cursor: 'not-allowed',
    },
}))

const SelectLookUpControl = ({
    title,
    attributes,
    selectedItems,
    items,
    multiSelect = true,
    selectionIdentifier,
    isLoading = false,
    handleSearch,
    searchKey,
    pagination,
    handleSelectAllClick,
    handleRowSelection,
    rowSelection = true,
    isRequired = false,
    errorMessage = '',
    isDisabled = false,
    fieldTypeScope,
    showFieldValues,
    handleSort,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Box>
            <Box className={classes.header}>
                <Box
                    display="flex"
                    alignItems="flex-start"
                    style={{
                        gap: '15px',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                width: !showFieldValues ? '23.2rem' : 'auto',
                            }}
                            variant="h3"
                        >
                            {title}{' '}
                            {fieldTypeScope && <span>({fieldTypeScope})</span>}
                        </Typography>
                        {isRequired && <RequiredLabel />}
                    </Box>
                    {showFieldValues ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            style={{
                                gap: '8px',
                            }}
                        >
                            {selectedItems.length ? (
                                <Icon
                                    name="Check"
                                    color={theme?.palette?.success?.main}
                                    width="12px"
                                    height="14px"
                                />
                            ) : null}
                            <Typography
                                className={classNames({
                                    [classes.selectedText]:
                                        selectedItems.length,
                                })}
                            >{`${selectedItems.length} ${t(
                                'Selected',
                            )}`}</Typography>
                        </Box>
                    ) : (
                        <InfoMessageForWrongJson />
                    )}
                </Box>
                {/* Search */}
                {showFieldValues && (
                    <Box
                        className={classNames(classes.headerSearch, {
                            [classes.disabledStyle]: isDisabled,
                        })}
                    >
                        <Search
                            styleData={{}}
                            value={searchKey}
                            handleSearch={(val) => handleSearch(val)}
                            placeholder={t('QuickSearch')}
                        />
                    </Box>
                )}
            </Box>
            {showFieldValues && (
                <Box
                    className={classNames(classes.fieldList, {
                        [classes.disabledStyle]: isDisabled,
                    })}
                >
                    <Box>
                        <EidTableGrid
                            noDataMessage={t('NoDataMessage')}
                            isLoading={isLoading}
                            attributes={attributes}
                            pagination={pagination}
                            list={items || []}
                            handleSelectAllClick={handleSelectAllClick}
                            selected={selectedItems}
                            handleClick={handleRowSelection}
                            rowSelection={rowSelection}
                            showSelectAll={multiSelect}
                            highLightSelectedRow={true}
                            selectionIdentifier={selectionIdentifier}
                            onSort={handleSort}
                            errorMessage={
                                Boolean(errorMessage) && (
                                    <Box paddingLeft={'2rem'}>
                                        <RequiredFieldErrorMessage
                                            errorMsgKey={errorMessage}
                                        />
                                    </Box>
                                )
                            }
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default SelectLookUpControl
