import config from 'config'
export const configuration = {}

// import computers from './computers'
export const SubPathLevel = config.APP_SUBPATH
    ? config.APP_SUBPATH.split('/').length - 1
    : 0

const appConfiguration = {}

export default appConfiguration
