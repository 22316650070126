import { PageContextProvider } from 'pageContext'
import React, { Fragment } from 'react'
import OtherTypesOfManagementRoleMembersBusinessRoleAndLocations from './BusinessRoleAndLocations/BusinessRoleAndLocations'
import OtherTypesOfManagementRoleMembersGroups from './Groups/Groups'
import OtherTypesOfManagementRoleMembersSetGroups from './SetGroups/SetGroups'

const OtherTypesOfManagementRoleMembers = ({
    managementRoleId,
    selectedTab,
}) => {
    return (
        <Fragment>
            {selectedTab === 'groups' && (
                <PageContextProvider key="OtherMembersGroups">
                    <OtherTypesOfManagementRoleMembersGroups
                        managementRoleId={managementRoleId}
                    />
                </PageContextProvider>
            )}
            {selectedTab === 'setGroups' && (
                <PageContextProvider key="OtherMembersSetGroups">
                    <OtherTypesOfManagementRoleMembersSetGroups
                        managementRoleId={managementRoleId}
                    />
                </PageContextProvider>
            )}
            {selectedTab === 'businessRoleAndLocation' && (
                <PageContextProvider key="OtherMemberBusinessRoleAndLocations">
                    <OtherTypesOfManagementRoleMembersBusinessRoleAndLocations
                        managementRoleId={managementRoleId}
                    />
                </PageContextProvider>
            )}
        </Fragment>
    )
}

export default OtherTypesOfManagementRoleMembers
