import { useQuery } from 'react-query'
import config from 'config'
import { useAxios } from 'packages/core'

const SESSION_TIMEOUT_KEY_PREFIX = 'SESSION_TIMEOUT'
const DEFAULT_TIMEOUT = 600000 // 10 minutes

export const useSessionTimeout = () => {
    const callApi = useAxios()

    //Check config.LOGIN_SESSION_TIMEOUT has a valid number
    const defaultTimeout =
        !isNaN(config.LOGIN_SESSION_TIMEOUT) &&
        parseInt(config.LOGIN_SESSION_TIMEOUT, 10) > 0
            ? config.LOGIN_SESSION_TIMEOUT
            : DEFAULT_TIMEOUT

    const { isError, data } = useQuery(
        `${SESSION_TIMEOUT_KEY_PREFIX}`,
        async () => {
            try {
                const response = await callApi({
                    method: 'GET',
                    url: 'api/Configuration/timeout',
                })
                return response.data * 60 * 1000
            } catch (error) {
                return defaultTimeout
            }
        },
    )
    if (isError) {
        return { data: defaultTimeout }
    }
    return { data }
}

export default useSessionTimeout
