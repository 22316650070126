import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { Collapse, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
} from 'packages/core'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import {
    ManagementRolesTable,
    ManagementRolesTableHead,
    ManagementRolesRow,
} from './ManagementRolesTableComponents'
import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'
import {
    useManagementRole,
    useApplicationSettings,
    useManagementRoles,
} from 'hooks'
import { usePageContext } from 'pageContext'
import { ManagementRoleDetails } from './Management Role Details'
import { SubPathLevel } from 'appConfiguration'
import useSubcomponents from 'useSubcomponents'
import { useAppState } from 'appContext'
import ResourceMoreInformationTile from 'components/ResourceMoreInformationTile'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'
import GenericButton from 'components/Button/GenericButton'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import CardView from 'components/EidTableGrid/CardView'
import { ITEMS_VIEW, RESOURCE_TYPE_NAMES } from 'utils'

const canSeeMoreInfornation = 'ResourceAdmin-ManagementRoles-MoreInfo-Control'
const actionButtonsControlName = 'ResourceAdmin-ManagementRole-List-Actions'

const ManagementRolesLayout = ({ filters }) => {
    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const query = useQuery()
    const [state, dispatch] = usePageContext()
    const [filterWidth, setFilterWidth] = useState(0)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [, appDispatch] = useAppState()
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        rest,
    } = useManagementRoles()

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])

    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? ITEMS_VIEW.CARD : ITEMS_VIEW.LIST

    const { tableAttributes, detailAttributes } = attributes
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()
    const accessBasedAttributesForGroups =
        getAccessBasedAttributes(tableAttributes)

    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('No_ManagementRoles_Message'),
        pagination,
        attributes: accessBasedAttributesForGroups,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: CardView,
            renderLeftButton: (item) => {
                return hasAccessToControl(actionButtonsControlName) ? (
                    <Box
                        data-protectedsubcomponent={actionButtonsControlName}
                        width="80%"
                    >
                        <GenericButton
                            onClick={() => {
                                history.push(`?managementRoleId=${item?.id}`)
                            }}
                            color="#01ae8f"
                            rootStylesProp={{
                                borderRadius: '4px',
                                width: '100%',
                                height: '32px',
                            }}
                        >
                            {t('Details')}
                        </GenericButton>
                    </Box>
                ) : (
                    <></>
                )
            },
            renderRightButton: (item) => {
                return hasAccessToControl(actionButtonsControlName) ? (
                    <Box data-protectedsubcomponent={actionButtonsControlName}>
                        <UIActionsGearButton
                            item={item}
                            noun={'ResourceAdmin'}
                            resourcePath="managementRoles"
                        />
                    </Box>
                ) : (
                    <></>
                )
            },
        },
        tableViewConfig: {
            TableComponent: ManagementRolesTable,
            TableHeadComponent: ManagementRolesTableHead,
            TableRowComponent: ManagementRolesRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView(ITEMS_VIEW.CARD)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmallScreen])

    const managementRoleId = query.get('managementRoleId')

    useEffect(() => {
        if (!managementRoleId) {
            appDispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: null,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managementRoleId])

    const workflowName = query.get('workflow')
    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig

    const accessibleStaticWorkflows =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) =>
                      w.resourceTypeName ===
                          RESOURCE_TYPE_NAMES.MANAGEMENT_ROLES &&
                      hasAccessToControl(w.protectedSubcomponentName),
              )
            : []

    const { data: managementRole } = useManagementRole(managementRoleId)
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const wfParams = []

    const detailPageTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
        {
            label: t('PeopleAsMembers'),
            value: 'peopleMembers',
            icon: <Icon name="ViewProfile" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-ManagementRole-Details-PeopleAsMembers',
            },
        },
        {
            label: t('AllMembers'),
            value: 'allMembers',
            icon: <Icon name="AllMembers" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-ManagementRole-Details-AllMembers',
            },
        },
        {
            label: t('ManagementRolesAsMembers'),
            value: 'managementRolesAsMembers',
            icon: <Icon name="Scope" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRole-Details-ManagementRolesAsMembers',
            },
        },

        {
            label: t('OtherTypesofManagementRoleMembers'),
            value: 'otherTypesofManagementRoleMembers',
            icon: <Icon name="AzureLicense" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRole-Details-OtherTypesofManagementRoleMembers',
            },
            subTabs: [
                {
                    label: t('Groups'),
                    value: 'groups',
                    display: true,
                    requireAccess: {
                        control:
                            'ResourceAdmin-ManagementRole-OtherTypesofManagementRoleMembers-Groups',
                    },
                },
                {
                    label: t('SetGroups'),
                    value: 'setGroups',
                    display: true,
                    requireAccess: {
                        control:
                            'ResourceAdmin-ManagementRole-OtherTypesofManagementRoleMembers-SetGroups',
                    },
                },
                {
                    label: t('BusinessRoleAndLocation'),
                    value: 'businessRoleAndLocation',
                    display: true,
                    requireAccess: {
                        control:
                            'ResourceAdmin-ManagementRole-OtherTypesofManagementRoleMembers-BusinessRoleAndLocation',
                    },
                },
            ],
        },
        {
            label: t('DirectAccessGranted'),
            value: 'accessGranted',
            icon: <Icon name="MembershipPolicy" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-ManagementRole-Details-AllMembers',
            },
        },
        {
            label: t('TotalAccessGranted'),
            value: 'totalAccessGranted',
            icon: <Icon name="Regulations" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRole-Details-TotalAccessGranted',
            },
        },
        {
            label: t('ManagementRoleGrantedByManagementRole'),
            value: 'managementRoleGrantedByManagementRole',
            icon: <Icon name="RoleDefinition" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRole-Details-ManagementRoleGrantedByManagementRole',
            },
        },
        {
            label: t('AccessManagers(Owners)'),
            value: 'accessManagers',
            icon: <Icon name="ExternalCredential" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRole-Details-Overview-AccessManagers',
            },
        },
        {
            label: t('DirectAssignedLocations'),
            value: 'directAssignedLocations',
            icon: <Icon name="Claims" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRole-Details-DirectAssignedLocations',
            },
        },
        {
            label: t('AccessRequestPolicy'),
            value: 'accessRequestPolicy',
            icon: <Icon name="ClientCertificate" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRole-Details-AccessRequestPolicy',
            },
        },
    ]

    if (managementRole) {
        wfParams.push(`SelectedResourceID=${managementRole.resourceId}`)
        if (isSmallScreen) {
            return (
                <AppLayout
                    totalCount={totalCount}
                    showBackButton={Boolean(managementRole)}
                >
                    <ManagementRoleDetails
                        tabs={detailPageTabs}
                        managementRoleId={managementRoleId}
                        basicAttributes={detailAttributes}
                    />
                </AppLayout>
            )
        }
        return (
            <AppLayout
                totalCount={totalCount}
                showNavTabs={!managementRoleId}
                showBackButton={Boolean(managementRole)}
            >
                <DesktopContainer>
                    {managementRole && (
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <ManagementRoleDetails
                                tabs={detailPageTabs}
                                managementRoleId={managementRoleId}
                                basicAttributes={detailAttributes}
                            />
                        </Box>
                    )}
                </DesktopContainer>
            </AppLayout>
        )
    }

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let content
    if (isSmallScreen) {
        content = (
            <SmallScreenContainer>
                <Box display={showFilters ? '' : 'none'}>
                    {mobileFiltersScreen}
                </Box>

                <Box display={!showFilters ? '' : 'none'}>
                    <Box display={''}>
                        <StickyContainer>
                            <Box width="100%">{mobileStickyContentListing}</Box>
                        </StickyContainer>
                        {viewToRender}
                    </Box>
                </Box>
            </SmallScreenContainer>
        )
    } else {
        content = (
            <DesktopContainer>
                {managementRoleId ? (
                    <Box paddingRight={'8px'} marginBottom="16px" width="100%">
                        <ManagementRoleDetails
                            tabs={detailPageTabs}
                            managementRoleId={managementRoleId}
                            basicAttributes={detailAttributes}
                        />
                    </Box>
                ) : (
                    <Fragment>
                        {((filters && filters.length > 0) ||
                            hasAccessToControl(canSeeMoreInfornation)) && (
                            <FiltersColumn ref={filterContainer}>
                                <FilterContainer>
                                    <Box display="flex">
                                        <Box width="100%">
                                            <Card.CardHeader
                                                collapsible
                                                fontColor="black"
                                                cardTitle={
                                                    openFilters
                                                        ? t('Hide_My_Filters')
                                                        : t('Show_My_Filters')
                                                }
                                                handleExpandClick={() =>
                                                    setOpenFilters(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                expanded={openFilters}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                                style={styles.filterCardHeader}
                                            />
                                        </Box>
                                    </Box>
                                </FilterContainer>

                                <Collapse
                                    in={openFilters}
                                    timeout={300}
                                    unmountOnExit
                                >
                                    {!managementRoleId &&
                                        accessibleStaticWorkflows.length > 0 &&
                                        accessibleStaticWorkflows.map((wf) => (
                                            <Box
                                                style={{
                                                    margin: '0 0 15px 0px',
                                                }}
                                                data-protectedsubcomponent={
                                                    wf.protectedSubcomponentName
                                                }
                                            >
                                                <EidWorkflowButton
                                                    title={t(
                                                        wf.displayName_LocaleKey,
                                                    )}
                                                    name={
                                                        wf.requestWorkflowName
                                                    }
                                                    path={`/managementRoles/workflows`}
                                                    disabled={workflowName}
                                                />
                                            </Box>
                                        ))}
                                    <ResourceMoreInformationTile
                                        protectedsubcomponent={
                                            canSeeMoreInfornation
                                        }
                                        moreInfoKey={
                                            'ManagementRoles_MoreInfo_Html'
                                        }
                                        resourceId={managementRoleId}
                                    />
                                    <>
                                        {filters.map((f) => {
                                            const FilterToRender =
                                                Filters.filtersMap[f.name]
                                            return (
                                                <FilterContainer
                                                    data-protectedsubcomponent={
                                                        f.requireAccess
                                                    }
                                                    key={f.name}
                                                >
                                                    <FilterToRender
                                                        config={f.config}
                                                    />
                                                </FilterContainer>
                                            )
                                        })}
                                    </>
                                    <ListingPageUIActionByNounAndVerb
                                        noun="ResourceAdmin"
                                        verb="ManagementRole"
                                    />
                                </Collapse>
                            </FiltersColumn>
                        )}

                        <ListingGrid>
                            <Box display="flex">
                                <Box width="100%">
                                    <Filters.TextSearch />
                                </Box>
                                {/* More Filters Here */}
                            </Box>
                            <GridContainer
                                filterWidth={filterWidth}
                                openFilters={openFilters}
                            >
                                {viewToRender}
                            </GridContainer>
                        </ListingGrid>
                    </Fragment>
                )}
            </DesktopContainer>
        )
    }

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showNavTabs={true}
        >
            <Fragment>{content}</Fragment>
        </AppLayout>
    )
}

export default ManagementRolesLayout
