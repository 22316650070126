import { Box, styled, Typography, useTheme } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useApplicationPeopleByResourceId } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl, imagesDisabled } from 'utils'
import useSubcomponents from 'useSubcomponents'
import { ApplicationTypes } from 'utils'
import config from 'config'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import { Button, Filters } from 'components'
import { PageContextProvider } from 'pageContext'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'
import EditAssignmentsWithFieldTypes from 'components/Popups/EditAssignmentsWithFieldTypes'
import DialogHeader from 'components/Popups/EditAssignmentsWithFieldTypes/DialogHeader'
import { useState } from 'react'

const TableOverView = styled(Box)({
    padding: '1.6rem 0rem',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const AssignmentHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})

const RoleSetting = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '16px',
    '& button': { height: '32px', lineHeight: '16px' },
})
const canAssignNewRoleDefinitions =
    'ResourceAdmin-PbacApplicationDetails-RoleDefinitions-People-AssignRoleDefinition-Control'

const EditAssignmentButton = ({
    applicationId,
    data,
    applicationType,
    resourceId,
    applicationFriendlyName,
    resourceSystemModuleID,
}) => {
    const query = useQuery()
    const history = useHistory()
    const theme = useTheme()

    const { t } = useTranslation()

    const [openAssigneeModal, setOpenAssigneeModal] = useState(false)

    const AssignmentWorkflow = (
        <Button
            onClick={() => {
                query.set('workflowName', 'AssignAzLocalRoleScope')
                query.set('returnUrl', getFullPathFromUrl())
                query.set('SelectedResourceID', resourceId)
                query.set('SelectedAssigneeID', data.id)

                history.push(
                    `${
                        config.APP_SUBPATH
                    }/applications/workflows?${query.toString()}`,
                )
            }}
            style={{
                borderRadius: '4px',
                padding: '0px 20px',
                backgroundColor: theme?.palette?.primary?.main,
                border: `solid 1px ${theme?.palette?.primary?.main}`,
                color: '#ffffff',
                fontSize: '14px',
                '&:hover': {
                    backgroundColor: theme?.palette?.primary?.main,
                },
            }}
        >
            {t('RoleDefinitions')}
        </Button>
    )

    const AssignmentPopup = (
        <PageContextProvider
            key={'RoleDefinitionSharedEditAssignment' + applicationId}
        >
            <DialogHeader
                labelKey={'Edit'}
                headerText={{
                    dynamicText: applicationFriendlyName,
                    text: 'ChooseRoleDefinitions',
                }}
                setShowAssignmentModal={setOpenAssigneeModal}
                showAssignmentModal={openAssigneeModal}
            >
                <EditAssignmentsWithFieldTypes
                    showAssignmentModal={openAssigneeModal}
                    handleClose={() => setOpenAssigneeModal(false)}
                    assignedUrl={`/api/AzureRoles/assignmentsByApplication/${resourceSystemModuleID}`}
                    unAssignedUrl={`/api/AzureRoles/GetEligibleAppRolesByApplicationByAssignee`}
                    selectedApplicationId={applicationId}
                    unAssignedQueryParams={{
                        applicationId,
                        eligibilityAssigneeId: data?.id,
                    }}
                    assignedQueryParams={{
                        assigneeId: data?.id,
                    }}
                    searchPlaceHolder={'SearchFilterRoleDefinitions'}
                    showAccessRequestPolicy={true}
                    fetchRequestPolicyItemWise={true}
                    requestPolicyIdProperty="requestPolicyId"
                    showStartDate={false}
                    fieldTypeProps={{
                        assignee: data,
                        isRole: true,
                        keyForRoleRightId: 'localRoleId',
                    }}
                    requireRemappingInAssignedList={true}
                    newMappingForAssignedList={{
                        friendlyName: 'role',
                    }}
                />
            </DialogHeader>
        </PageContextProvider>
    )

    if (
        applicationType === ApplicationTypes.PbacHasAppResourcesWithoutFieldType
    ) {
        return AssignmentWorkflow
    } else {
        return AssignmentPopup
    }
}

const ApplicationRoleDefinitionPeople = ({
    applicationId,
    applicationFriendlyName,
    applicationType,
    resourceId,
    resourceSystemModuleID,
}) => {
    const { t } = useTranslation()

    const queryFilters = {
        showLocalRoles: true,
    }

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationPeopleByResourceId(applicationId, queryFilters)

    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            sortable: false,
            sortBy: 'assigned',
            label: 'Assignments',

            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-RoleDefinitions-People-Assignments',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        <BooleanIcon
                            data={data}
                            attribute={{ name: 'localRoleAssigned' }}
                            nullAsFalse={true}
                        />
                    </AssignmentHeader>
                ),
            },
        },
        {
            hideValue: true,
            showInCard: false,
            showInTable: false,
            image: true,
            component: {
                componentName: 'ResourceImage',
                name: 'imageThumbUrl',
                defaultUrl: '/webcdn/Images/AppLogos/Genric-1.png',
            },
        },
        {
            name: 'friendlyName',
            sortable: true,
            label: 'DisplayName',
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-PbacApplicationDetails-RoleDefinitions-People-DisplayName',
            // },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        {!imagesDisabled && (
                            <Avatar src={getFullImageUrl(data.imageThumbUrl)} />
                        )}
                        <Typography
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                        >
                            {data.friendlyName}
                        </Typography>
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'login',
            sortable: true,
            label: 'Login',
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-RoleDefinitions-People-Login',
            },
        },
        {
            name: 'email',
            sortable: true,
            label: 'Email',
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-RoleDefinitions-People-Email',
            },
        },

        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-RoleDefinitions-People-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <RoleSetting>
                            {hasAccessToControl(
                                canAssignNewRoleDefinitions,
                            ) && (
                                <EditAssignmentButton
                                    applicationId={applicationId}
                                    data={data}
                                    applicationType={applicationType}
                                    resourceId={resourceId}
                                    applicationFriendlyName={
                                        applicationFriendlyName
                                    }
                                    refetch={refetch}
                                    resourceSystemModuleID={
                                        resourceSystemModuleID
                                    }
                                />
                            )}
                        </RoleSetting>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
            >
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationRoleDefinitionPeople
