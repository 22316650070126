import { Box, styled } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    useDeleteEligibilityAssignments,
    useEligibilityAssignments,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import {
    getFullPathFromUrl,
    useIsSmallScreenFalse,
    useQuery,
} from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useHistory } from 'react-router'
import config from 'config'
import { Filters, Button } from 'components'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { ELIGIBILITY_ASSIGNMENT_TYPES, ELIGIBILITY_TYPES } from 'utils'
import { useState } from 'react'
import { usePageContext } from 'pageContext'
import { Select, WarningModal } from 'packages/eid-ui'
import { SearchMenuBarFilter } from 'components/WrapperComponents/SearchMenuBar'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
const useButtonStyles = makeStyles((theme) =>
    createStyles({
        root: (isSmallScreen) => ({
            height: '4rem !important',
            borderRadius: '4px',
            padding: '0px 15px 0px 10px',
            backgroundColor: theme?.palette?.primary?.main,
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            margin: isSmallScreen ? '16px 16px 0px 16px' : '',
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
            },
            '& svg': {
                marginRight: '8px',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)
const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const canConfigureEligibilityControlName =
    'ResourceAdmin-ManagementRoleDetails-Eligibility-ConfigureEligibility-Control'
const canDeleteEligibilityAssignments =
    'ResourceAdmin-ManagementRoleDetails-Eligibility-DeleteEligibility-Control'
const canRemoveMultipleAssignmentsControlName =
    'ResourceAdmin-ManagementRoleDetails-Eligibility-DeleteMultipleEligibility-Control'

const Eligibility = ({ roleId, resourceTypeId, resourceId }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const [eligibilityType, setEligibilityType] = useState(null)

    const dataObject = {
        AssignmentPointId: roleId,
        ResourceTypeId: resourceTypeId,
        EligibilityTypeId: eligibilityType?.id ? eligibilityType.id : null,
        EligibilityAssignmentTypeId: ELIGIBILITY_ASSIGNMENT_TYPES.DIRECT,
    }
    const [{}, dispatch] = usePageContext()

    const query = useQuery()
    const history = useHistory()
    const classes = useButtonStyles(isSmallScreen)

    const { list, loading, pagination, refetch } =
        useEligibilityAssignments(dataObject)
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [selected, setSelected] = useState([])
    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteEligibilityAssignments(refetch)
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)
    const attributes = [
        {
            name: 'eligibilityType',
            label: t('EligibilityType'),
            sortable: true,

            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ManagementRoleDetails-Eligibility-EligibilityType',
            // },
        },
        {
            name: 'assigneeType',
            label: t('AssigneeType'),
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-AssigneeType',
            },
        },
        {
            name: 'assigneeFriendlyName',
            label: t('Assignee'),
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-Assignee',
            },
        },
        {
            name: 'resourceTypeFriendlyName',
            label: t('ResourceType'),
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-ResourceType',
            },
        },

        {
            name: 'eligibilityAssignmentType',
            label: t('AssignmentType'),
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-AssignmentType',
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box display={'flex'} justifyContent={'end'}>
                            {hasAccessToControl(
                                canDeleteEligibilityAssignments,
                            ) && (
                                <DeleteButton>
                                    <Button.Delete
                                        showEndIcon={false}
                                        onClick={() => handleSingleDelete(data)}
                                    />
                                </DeleteButton>
                            )}
                        </Box>
                    )
                },
            },
        },
    ]
    const handleDelete = () => {
        deleteAssignments(selectedItemsToDelete).then(() => {
            setSelectedItemsToDelete(null)
            setSelected([])
        })
    }
    const handleSingleDelete = (data) => {
        setSelectedItemsToDelete([data?.id])
    }

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }
    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.id)
            setSelected(filteredList)
        }
    }

    const selectAllAction = hasAccessToControl(
        canRemoveMultipleAssignmentsControlName,
    )
        ? {
              render: () => {
                  return (
                      <Box style={{ paddingLeft: '3.2rem' }}>
                          <BulkRemoveButton
                              selectedCount={selected.length}
                              label={t('RemoveSelectedAssignments', {
                                  count: selected.length,
                              })}
                              onClick={handleDeleteBulk}
                              dataProtectedsubcomponent={
                                  canRemoveMultipleAssignmentsControlName
                              }
                          />
                      </Box>
                  )
              },
          }
        : null

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="16px 32px"
                bgcolor="white"
                borderTop="1px solid #eef0f4"
            >
                {hasAccessToControl(canConfigureEligibilityControlName) && (
                    <Box
                        data-protectedsubcomponent={
                            canConfigureEligibilityControlName
                        }
                    >
                        <Button
                            onClick={() => {
                                query.delete('managementRoleId')
                                query.set(
                                    'workflowName',
                                    'ManageManagementRoleWizard',
                                )
                                query.set('SelectedResourceID', resourceId)
                                query.set('WizardAction', 'edititshopsetting')
                                query.set('returnUrl', getFullPathFromUrl())
                                history.push(
                                    `${
                                        config.APP_SUBPATH
                                    }/managementRoles/workflows?${query.toString()}`,
                                )
                            }}
                            className={classes.root}
                        >
                            {<Icon name="EditFile" color="#fff" />}
                            {t('ConfigureEligibility')}
                        </Button>
                    </Box>
                )}

                <Box
                    style={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            width: '150px',
                            height: '4rem',
                            marginTop: '-1.6rem',
                        }}
                    >
                        <Select
                            options={ELIGIBILITY_TYPES}
                            onChange={(data) => {
                                dispatch({
                                    type: 'SET_PAGE',
                                    payload: 1,
                                })
                                setEligibilityType(data)
                            }}
                            value={eligibilityType}
                            getOptionLabel={(option) => t(option.label)}
                            placeholder={t('EligibilityType')}
                            nullableText={t('All')}
                        />
                    </Box>
                    <Box>
                        <SearchMenuBarFilter style={{ width: '24rem' }}>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
            </Box>

            <EidTableGrid
                selectAllAction={selectAllAction}
                rowSelection={true}
                handleSelectAllClick={handleSelectAllClick}
                selected={selected}
                handleClick={handleRowSelection}
                noDataMessage={t('NoDataMessage')}
                pagination={
                    <Box style={{ marginRight: '3.2rem' }}>{pagination}</Box>
                }
                isLoading={loading}
                attributes={getAccessBasedAttributes(attributes)}
                list={list}
                tableViewOnly
            />
            {selectedItemsToDelete && (
                <WarningModal
                    title={t('DeleteEligibilityAssignments')}
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete.length}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceeding={isDeletingAssignment}
                    proceed={handleDelete}
                />
            )}
        </Box>
    )
}

export default Eligibility
