import { useApplicationSettings, useApplicationCertificate } from 'hooks'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Drawer, Loader } from 'packages/eid-ui'
import { useIsSmallScreenFalse } from 'packages/core'
import StickyContainer from 'components/StickyContainer'
import { TextFormatter, Button } from 'components'
import bgHeader from '../../../assets/images/bgHeader.png'
import CertificateDetails from './CertificateDetails'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
})

const CertificatesDrawer = ({ id, onClose }) => {
    const { data: certificate } = useApplicationCertificate(id)
    const { data: appConfig } = useApplicationSettings()
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()

    if (isSmallScreen) {
        return (
            <Box>
                <StickyContainer
                    color="#ffffff"
                    styles={{
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box paddingY="10px" width="100%" maxWidth="260px">
                            <Button.BackButton onClick={() => onClose()}>
                                {t('BackToCertificates')}
                            </Button.BackButton>
                        </Box>

                        <HeaderContainer
                            height="80px"
                            textAlign="start"
                            bgcolor={appConfig.style.colors.primary?.header}
                            style={{
                                backgroundImage: `url(${bgHeader})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <TextFormatter
                                value={
                                    certificate?.friendlyName ??
                                    certificate?.description
                                }
                                maxCharacters={75}
                            />
                        </HeaderContainer>
                    </Box>
                </StickyContainer>
                {!certificate && <Loader />}
                {certificate && (
                    <CertificateDetails certificate={certificate} />
                )}
            </Box>
        )
    } else {
        return (
            <Drawer
                width="768px"
                bgColor="#fbfbfd"
                open={true}
                toggleDrawer={() => onClose()}
                stickyHeader
                header={
                    <HeaderContainer
                        height="95px"
                        bgcolor={appConfig.style.colors.primary?.header}
                        style={{
                            backgroundImage: `url(${bgHeader})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <TextFormatter
                            value={
                                certificate?.friendlyName ??
                                certificate?.description
                            }
                            maxCharacters={385}
                            style={{ textAlign: 'start', fontSize: '22px' }}
                        />
                    </HeaderContainer>
                }
                closeOnOutsideClick
            >
                {!certificate && <Loader />}
                {certificate && (
                    <CertificateDetails certificate={certificate} />
                )}
            </Drawer>
        )
    }
}

export default CertificatesDrawer
