import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom'
import { DeleteCredential, MasterPasswordLock } from 'components'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import DynamicComponents from 'components/DynamicComponents'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import GenericButton from 'components/Button/GenericButton'
import { mobileScreenWidth } from 'utils'
import useSubcomponents from 'useSubcomponents'

const Container = styled(Box)({
    display: 'inline-block',
    width: '33%',

    overflow: 'hidden',
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: '16px',
            position: 'unset !important',
            margin: '16px',
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
            borderRadius: '8px !important',
        },
        actionArea: {
            width: '100%',
            display: 'flex',
            marginTop: '35px',
            marginBottom: '35px',
        },

        borderBox: {
            marginBottom: '-16px',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0)) !important',
            borderImageSlice: `${1} !important`,
            borderBottom: '1px solid !important',
            opacity: '0.25',
        },
        friendlyName: {
            fontWeight: 'bold',
            fontSize: '14px',
        },
    }),
)

const canDeleteCertificateControlName =
    'ResourceAdmin-ApplicationDetails-ClientCertificates-DeleteCertificate-Control'

const CertificateCard = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { item: data, loading, attributes } = props
    const history = useHistory()
    const { hasAccessToControl } = useSubcomponents()

    const lockedDivRef = useRef(null)
    const [masterPasswordHeight, setMasterPasswordHeight] = useState(0)

    useEffect(() => {
        let observer

        if (lockedDivRef.current) {
            const updateHeight = () => {
                setMasterPasswordHeight(lockedDivRef.current.offsetHeight)
            }

            // Initialize ResizeObserver
            observer = new ResizeObserver(updateHeight)
            observer.observe(lockedDivRef.current)

            // Update height initially in case the initial size is not zero
            updateHeight()
        }
        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [])

    const hidePublicKey = !data.canCheckIn && !data.canCheckOut

    const hideRemainingTime = !data.canCheckIn

    const hideMasterLock = data.isExpired

    if (loading)
        return (
            <Card className={classes.root}>
                <SkeletonLoader />
            </Card>
        )

    const renderComponent = (componentName, item) => {
        const attribute = attributes.find(
            (a) => a.component?.componentName === componentName,
        )

        if (componentName === 'actionButtons') {
            return (
                <Box display={'flex'} width={'100%'}>
                    <GenericButton
                        onClick={() => {
                            history.push(
                                `${history.location.search}&certificateId=${item?.id}`,
                            )
                        }}
                        color="#01ae8f"
                        rootStylesProp={{
                            borderRadius: '4px',
                            width: '50%',
                            height: '32px',
                            fontSize: '14px',
                        }}
                    >
                        {t('Details')}
                    </GenericButton>
                    {hasAccessToControl(canDeleteCertificateControlName) && (
                        <Box
                            style={{ marginLeft: '16px' }}
                            data-protectedsubcomponent={
                                canDeleteCertificateControlName
                            }
                        >
                            <DeleteCredential
                                item={item}
                                styles={{
                                    borderRadius: '4px',
                                    padding: '0px 10px',
                                }}
                                showEndIcon={false}
                            />
                        </Box>
                    )}
                    <Box style={{ marginLeft: '16px' }}>
                        <UIActionsGearButton item={item} noun='ResourceAdminCertificates' />
                    </Box>
                </Box>
            )
        } else {
            const DynamicComponentToRender = DynamicComponents[componentName]
            return (
                <DynamicComponentToRender
                    attribute={attribute}
                    data={{ item }}
                />
            )
        }
    }

    return (
        <Container>
            <Card className={classes.root}>
                <Attribute style={{ marginTop: 10 }}>
                    <AttributeValue className={classes.friendlyName}>
                        {data.friendlyName}
                    </AttributeValue>
                </Attribute>

                <Attribute style={{ marginTop: 15 }}>
                    <AttributeLabel>{t('Owner')}</AttributeLabel>
                    <AttributeValue>
                        {renderComponent('ApplicationOwner', data)}
                    </AttributeValue>
                </Attribute>
                <div style={{ position: 'relative' }}>
                    {!hideMasterLock && (
                        <MasterPasswordLock
                            style={{
                                height: masterPasswordHeight,
                                width: '110%',
                                marginLeft: '-20px',
                            }}
                        />
                    )}
                    <div ref={lockedDivRef}>
                        {!hidePublicKey && (
                            <Attribute>
                                <AttributeLabel>
                                    {t('PublicKey')}
                                </AttributeLabel>
                                <AttributeValue>
                                    {renderComponent(
                                        'CredentialLockedBox',
                                        data,
                                    )}
                                </AttributeValue>
                            </Attribute>
                        )}
                        <Attribute>
                            <AttributeLabel>{t('Status')}</AttributeLabel>
                            <AttributeValue>
                                <Box width="100px">
                                    {renderComponent(
                                        'CredentialStatusButton',
                                        data,
                                    )}
                                </Box>
                            </AttributeValue>
                        </Attribute>
                    </div>
                </div>
                {!hideRemainingTime && (
                    <Box>
                        <AttributeLabel>{t('RemainingTime')}</AttributeLabel>
                        <AttributeValue>
                            {renderComponent('CredentialRemainingTime', data)}
                        </AttributeValue>
                    </Box>
                )}
                <Box className={classes.actionArea}>
                    {renderComponent('actionButtons', data)}
                </Box>

                <Box className={classes.borderBox} />
            </Card>
        </Container>
    )
}

const SkeletonLoader = () => {
    return (
        <Fragment>
            <Skeleton animation="wave" variant="rectangular" height={80} />
            <Box marginTop={2} padding={2} width="100%">
                <Skeleton variant="rectangular" animation="wave" height={8} />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ height: 96, marginTop: 8 }}
                />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={48}
                    style={{ marginTop: 16 }}
                />
            </Box>
        </Fragment>
    );
}
export default CertificateCard
