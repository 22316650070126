import { useTheme } from '@mui/material'
import React from 'react'
import Button from './index'

const SearchButton = ({ ...rest }) => {
    const theme = useTheme()
    const styles = {
        normal: {
            minWidth: '87px',
            backgroundColor: theme?.palette?.primary?.main,
            color: '#ffffff',
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
                opacity: 0.95,
            },
            '&:active': {
                backgroundColor: theme?.palette?.primary?.main,
            },
        },
        disabled: {
            backgroundColor: '#aab0b4 !important',
            color: '#ffffff !important',
        },
    }
    return (
        <Button
            style={styles.normal}
            size="medium"
            disabledStyles={styles.disabled}
            {...rest}
        />
    )
}

export default SearchButton
