import { Box, styled } from '@mui/material'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useApplicationAppRightsAssignments,
    useDeleteApplicationAppRightsAssignments,
} from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Filters } from 'components'
import { WarningModal } from 'packages/eid-ui'
import AssigneePerson from 'components/AssigneePerson/AssigneePerson'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from 'components/ApplicationBanner/CreateWorkflowButton'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const ApplicationAppRightsAssignments = ({
    resourceSystemModuleID,
    resourceId,
}) => {
    const { t } = useTranslation()
    const [selectedIds, setSelectedIds] = useState([])
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)

    const {
        list: myList,
        loading,
        loadingMore,
        pagination,
    } = useApplicationAppRightsAssignments(
        `/api/LocalRights/appRoles/membersByApplicationID`,
        resourceSystemModuleID,
    )

    const list = myList?.map((i, index) => {
        return { id: index, ...i }
    })
    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteApplicationAppRightsAssignments(resourceSystemModuleID)

    const {
        canAddApplicationAppRightAssignments,
        canBulkDeleteApplicationAppRightAssignments,
        canSelectApplicationAppRightAssignments,
        hasAccessToControl,
        getAccessBasedAttributes,
    } = useSubcomponents()

    const attributes = [
        {
            name: 'assignee',
            sortable: true,
            sortBy: 'assignee',
            label: 'Assignee',
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <AssigneePerson
                            assigneeName={data.assignee}
                            imageUrl={data.personImageThumbUrl}
                            assigneeType={data.value}
                        />
                    )
                },
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-AppRightsAssignments-Assignee',
            // },
        },
        {
            name: 'appRightFriendlyName',
            sortable: true,
            sortBy: 'appRightFriendlyName',
            label: 'AppRight',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsAssignments-AppRight',
            },
        },
        {
            name: 'rightType',
            sortable: true,
            sortBy: 'rightType',
            label: 'RightType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsAssignments-RightType',
            },
        },
        {
            name: 'assignedTo',
            sortable: true,
            sortBy: 'assignedTo',
            label: 'AssignedTo',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsAssignments-AssignedTo',
            },
        },

        {
            name: 'resourceSystem',
            sortable: true,
            sortBy: 'resourceSystem',
            label: 'ResourceSystem',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsAssignments-ResourceSystem',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRights-Assignments-DeleteApplicationAppRightAssignments-Control',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <DeleteButton>
                            <Button.Delete
                                showEndIcon={false}
                                onClick={() => handleSingleDelete(data)}
                            />
                        </DeleteButton>
                    )
                },
            },
        },
    ]

    const handleSingleDelete = (item) => {
        setSelectedItemsToDelete([item])
    }

    const handleDeleteConfirm = () => {
        deleteAssignments(selectedItemsToDelete).then(() => {
            setSelectedIds([])
            setSelectedItemsToDelete(null)
        })
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selectedIds]
        const selectedIndex = selectedIds.findIndex((id) => id === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelectedIds(filteredList)
        } else {
            setSelectedIds([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selectedIds.length === list.length) {
            setSelectedIds([])
        } else {
            const filteredList = list.map((item) => item.id)

            setSelectedIds(filteredList)
        }
    }

    const selectAllAction = canBulkDeleteApplicationAppRightAssignments
        ? {
              style: {
                  padding: '0px !important',
              },
              render: () => {
                  return (
                      <BulkRemoveButton
                          selectedCount={selectedIds.length}
                          label={t('RemoveSelectedAssignments', {
                              count: selectedIds.length,
                          })}
                          onClick={handleBulkAction}
                          dataProtectedsubcomponent={
                              canBulkDeleteApplicationAppRightAssignments
                          }
                      />
                  )
              },
          }
        : null

    const handleBulkAction = () => {
        const selectedItems = list.filter((i) => selectedIds.includes(i.id))
        setSelectedItemsToDelete(selectedItems)
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
            >
                {canAddApplicationAppRightAssignments && (
                    <Box
                        data-protectedsubcomponent={
                            canAddApplicationAppRightAssignments
                        }
                    >
                        <CreateWorkflowButton
                            label={t('AddNewAppRightAssignment')}
                            workflowName="AssignAzLocalRightScope"
                            resourceId={resourceId}
                        />
                    </Box>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    selectAllAction={selectAllAction}
                    rowSelection={canSelectApplicationAppRightAssignments}
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selectedIds}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes).filter(
                        (x) =>
                            !x?.requireAccess?.control ||
                            hasAccessToControl(x?.requireAccess?.control),
                    )}
                    list={list}
                />
            </TableOverView>
            {selectedItemsToDelete && (
                <WarningModal
                    title={
                        selectedItemsToDelete.length === 1
                            ? `${t('Delete')} ${
                                  selectedItemsToDelete[0]?.assignee
                              }`
                            : t('DeleteSelectedItems')
                    }
                    description={
                        selectedItemsToDelete.length === 1
                            ? t('AppRoleDeleteMessage')
                            : t('AppRoleDeleteSelectedMessage')
                    }
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete}
                    cancel={() => {
                        setSelectedItemsToDelete(null)
                    }}
                    proceeding={isDeletingAssignment}
                    proceed={handleDeleteConfirm}
                />
            )}
        </Box>
    )
}

export default ApplicationAppRightsAssignments
