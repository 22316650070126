import { useAxios } from 'packages/core'
import { usePageContext } from 'pageContext'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import { queryCache, useMutation, useQuery } from 'react-query'
import { useNotification } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
export const MAILBOXES_KEY = 'Mailboxes'

export const useMailboxes = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        sortOrder,
        sortBy,
        ownedBy,
        accountStoreUsageType,
        mailboxType,
        advancedSearchTags,
        accountStore,
        mailBoxTypeId,
    } = state
    const friendlyName = state['advancedSearch.forms.friendlyName']
    const logonName = state['advancedSearch.forms.logonName']
    const email = state['advancedSearch.forms.primaryEmailAddress']
    const alias = state['advancedSearch.forms.alias']
    const callApi = useAxios()
    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (mailboxType) {
        queryData.mailboxType = mailboxType
    }

    if (mailBoxTypeId) {
        queryData.exchangeMailboxTypeId = mailBoxTypeId.id
    }
    if (ownedBy) {
        queryData.ownerPersonId = ownedBy.id
    }

    if (accountStoreUsageType) {
        queryData.accountStoreUsageTypeId = accountStoreUsageType.id
    }
    if (accountStore) {
        queryData.accountStoreId = accountStore.id
    }
    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }

    const advancedSearch = []
    if (friendlyName) {
        advancedSearch.push({
            name: 'friendlyName',
            type: 'string',
            value: friendlyName,
        })
    }
    if (email) {
        advancedSearch.push({
            name: 'primaryEmailAddress',
            type: 'string',
            value: email,
        })
    }
    if (alias) {
        advancedSearch.push({
            name: 'Alias',
            type: 'string',
            value: alias,
        })
    }
    if (logonName) {
        advancedSearch.push({
            name: 'logonName',
            type: 'string',
            value: logonName,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            MAILBOXES_KEY,
            'LIST_MY_MAILBOXES',
            searchTerm,
            sortBy,
            sortOrder,
            advancedSearch,
            ownedBy,
            advancedSearchTags,
            mailboxType,
            accountStoreUsageType,
            accountStore,
            mailBoxTypeId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `api/mailbox/all`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useMailbox = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [MAILBOXES_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/MailBox/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useMailBoxInventoriedPermissions = (exchangeMailboxId: any) => {
    const callApi = useAxios()
    const [state]: any = usePageContext()

    const queryData: any = {}

    const { searchTerm, sortOrder, sortBy } = state
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    return useApiWithInfiniteScroll(
        [
            `${MAILBOXES_KEY}_INVENTORIED_PERMISSIONS`,
            exchangeMailboxId,
            searchTerm,
            sortBy,
            sortOrder,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/MailBox/inventoriedPermissions`,
                method: 'POST',
                data: {
                    skip,
                    take,
                    exchangeMailboxId,
                    ...queryData,
                },
            })
        },
    )
}
export const refreshAllowedDeniedCountries = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes(`${MAILBOXES_KEY}_COUNTRIES_ALLOWED_DENIED_ACCESS`),
    )
}
export const useMailBoxAllowedDeniedCountries = (id: any) => {
    const callApi = useAxios()
    const [state]: any = usePageContext()
    const queryData: any = {}
    const { searchTerm, sortOrder, sortBy } = state
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    return useApiWithInfiniteScroll(
        [
            `${MAILBOXES_KEY}_COUNTRIES_ALLOWED_DENIED_ACCESS`,
            id,
            searchTerm,
            sortBy,
            sortOrder,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `api/mailbox/AllowedDeniedCountries/${id}`,
                method: 'POST',
                data: {
                    skip,
                    take,
                    ...queryData,
                },
            })
        },
    )
}

export const useGetAllCountries = (enabled: boolean, resourceId: any) => {
    const callApi = useAxios()

    return useApiWithInfiniteScroll(
        [`${MAILBOXES_KEY}_ALL_NATIONS`, enabled, resourceId],
        (skip: any, take: any) => {
            return callApi({
                url: `api/mailbox/unassigned/nations/${resourceId}`,
                method: 'POST',
                data: {
                    skip,
                    take: 500,
                },
            })
        },
        {
            enabled: enabled && Boolean(resourceId),
        },
    )
}

export const useAcceptEmailFrom = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [MAILBOXES_KEY, 'SEND_RECEIVE_LIMITS', id],
        () =>
            callApi({
                method: 'GET',
                url: ``,
                // TODO: using test data remove once api is up
            }).catch(() => {
                return {
                    data: {
                        acceptEmailFrom: true,
                    },
                }
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useRejectEmailFrom = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [MAILBOXES_KEY, 'SEND_RECEIVE_LIMITS', id],
        () =>
            callApi({
                method: 'GET',
                url: ``,
                // TODO: using test data remove once api is up
            }).catch(() => {
                return {
                    data: {
                        rejectEmailFrom: false,
                    },
                }
            }),
        {
            enabled: Boolean(id),
        },
    )
}
//NOTE: Creating new hook for mailboxes for accessLevelMembers
// Because the old one is using local query filter
export const useMailboxAssignments = (resourceId: any) => {
    const callApi = useAxios()
    const [state]: any = usePageContext()

    const queryData: any = {}
    const { searchTerm, sortOrder, sortBy } = state
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    return useApiWithInfiniteScroll(
        [
            `${MAILBOXES_KEY}_MAILBOX_ASSIGNMENTS`,
            resourceId,
            sortBy,
            sortOrder,
            searchTerm,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/ResourceAssignments/accessLevelsMembers`,
                method: 'POST',
                data: {
                    resourceId,
                    skip,
                    take,
                    ...queryData,
                },
            })
        },
    )
}
export const useAssignmentsToMailbox = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: ``,
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('AssignmentSuccessful'))
            },
            onError: () => {
                showWarningMessage(t('ErrorOccurred'))
            },
        },
    )
}
export const useManageNationPersistence = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `api/MailBox/manageNationPersistence`,
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('OperationSuccessfullyCompleted'))
                refreshAllowedDeniedCountries()
            },
            onError: () => {
                showWarningMessage(t('ErrorOccurred'))
            },
        },
    )
}
export const useDeleteCountry = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (allowedDeniedNationId) =>
            callApi({
                method: 'DELETE',
                url: `/api/MailBox/deleteAllowedDeniedNation/${allowedDeniedNationId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CountryDeleted'))
                refreshAllowedDeniedCountries()
            },
            onError: () => {
                showWarningMessage(t('CountryNotDeleted'))
            },
        },
    )
}
