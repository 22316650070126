import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import { useIsSmallScreenFalse } from 'packages/core'
import { useApplicationScopes } from 'hooks'
import { CardViewLoader, Filters, Button } from 'components'
import ScopeCard from './ScopesCard'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../CreateWorkflowButton'
import { useHistory } from 'react-router'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import DeleteScope from './DeleteScope'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})

const ApplicationScopes = ({ id, resourceId }) => {
    const { t } = useTranslation()
    const { canAddScope, getAccessBasedAttributes } = useSubcomponents()
    const history = useHistory()
    const isSmallScreen = useIsSmallScreenFalse()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationScopes(id)

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            isHeader: true,
            showInCard: false,
            label: 'Scope',
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control: 'ResourceAdmin-Application-Scope-List-Scope',
            // },
        },
        {
            name: 'consentType',
            sortable: true,
            label: 'WhoCanConsent',
            requireAccess: {
                control: 'ResourceAdmin-Application-Scope-List-WhoCanConsent',
            },
        },
        {
            name: 'adminConsentDisplayName',
            sortable: true,
            label: 'AdminConsentDisplayName',
            requireAccess: {
                control: 'ResourceAdmin-Application-Scope-List-AdminConsentDisplayName',
            },
        },
        {
            name: 'userConsentDisplayName',
            label: 'UserConsentDisplayName',
            sortable: true,
            requireAccess: {
                control: 'ResourceAdmin-Application-Scope-List-UserConsentDisplayName',
            },
        },
        {
            hideValue: true,
            showInCard: false,
            requireAccess: {
                control: 'ResourceAdmin-Application-Scope-List-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            <Button.Details
                                item={item}
                                onClick={() => {
                                    history.push(
                                        `${history.location.search}&scopeId=${item?.id}`,
                                    )
                                }}
                            />
                            <span style={{ marginRight: '10px' }} />
                            <DeleteScope item={item} />
                            <span style={{ marginRight: '10px' }} />

                            <UIActionsGearButton item={item}  noun='ResourceAdminScopes' />
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
            >
                {canAddScope && (
                    <Box data-protectedsubcomponent={canAddScope}>
                        <CreateWorkflowButton
                            label={t('AddScope')}
                            workflowName="CreateAzureAppScope"
                            resourceId={resourceId}
                        />
                    </Box>
                )}
                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                    cardViewConfig={{
                        LoadingIndicatorComponent: CardViewLoader,
                        CardComponent: ScopeCard,
                    }}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationScopes
