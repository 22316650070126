import { Button } from 'components'
import { useUnassignRoleDefinitionsForPeople } from 'hooks'
import { WarningModal } from 'packages/eid-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const UnAssignAssignment = ({ assignmentId, refetch, friendlyName }) => {
    const { t } = useTranslation()
    const [showConfirm, setConfirm] = useState(false)

    const [unAssignRoles, { isLoading: isUnAssigning }] =
        useUnassignRoleDefinitionsForPeople(refetch)

    const handleDelete = () => {
        setConfirm(false)
        unAssignRoles({ assigneeLocalRoleScopeIds: [assignmentId] })
    }

    return (
        <>
            <Button.Delete
                loading={isUnAssigning}
                onClick={() => setConfirm(true)}
            />
            <WarningModal
                title={`${t('Delete')} ${friendlyName}`}
                description={t('AssignmentDeleteMessage')}
                yesLabel={t('Yes')}
                noLabel={t('No')}
                open={showConfirm}
                cancel={() => setConfirm(false)}
                proceeding={isUnAssigning}
                proceed={handleDelete}
            />
        </>
    )
}

export default UnAssignAssignment
