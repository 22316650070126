import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { useIsSmallScreenFalse } from 'packages/core'
import { useApplicationRoles } from 'hooks'
import { ListingGrid, GridContainer, Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useHistory } from 'react-router'
import DeleteAzureApplicationRight from './DeleteAzureApplicationRight'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { CreateWorkflowButton } from 'components/ApplicationBanner/CreateWorkflowButton'
import { RightTypes } from 'utils'

const ApplicationRights = ({
    applicationId,
    resourceId,
    isPbacApplication = false,
}) => {
    const { t } = useTranslation()

    const history = useHistory()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const canAddAzureApplicationRole = hasAccessToControl(
        'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppRights-AddAzureApplicationRole-Control',
    )

    const canAddPbacApplicationRole = hasAccessToControl(
        `ResourceAdmin-ApplicationDetails-PbacDefinitions-AppRights-AddPbacApplicationRole-Control`,
    )

    const canDeleteAureApplicationRole = hasAccessToControl(
        `ResourceAdmin-ApplicationDetails-PbacDefinitions-AppRights-DeletePbacApplicationRole-Control`,
    )

    const canSeeDetailButtonControlName = hasAccessToControl(
        `ResourceAdmin-ApplicationDetails-PbacDefinitions-AppRights-DetailsPbacApplicationRole-Control`,
    )
    const canSeeUIActionsButtonControlName = hasAccessToControl(
        `ResourceAdmin-ApplicationDetails-PbacDefinitions-AppRights-UIActionsPbacApplicationRole-Control`,
    )

    const { list, loading, loadingMore, pagination } = useApplicationRoles(
        applicationId,
        RightTypes.ApplicationRight,
    )

    const rolesAttributes = [
        {
            name: 'friendlyName',
            sortable: true,
            label: 'DisplayName',
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-PbacApplicationDetails-AppRights-DisplayName',
            // },
        },
        {
            name: 'description',
            sortable: true,
            label: 'Description',
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-AppRights-Description',
            },
        },
        {
            name: 'pbacResourceTypeFriendlyName',
            sortable: true,
            label: 'ResourceType',
            display: isPbacApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-AppRights-ResourceType',
            },
        },
        {
            name: 'pbacApprovalRight',
            sortable: true,
            label: 'ApprovalAppRightName',
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-AppRights-ApprovalAppRightName',
            },
        },
        {
            name: 'rightAllowedAssigeeTypeFriendlyName',
            sortable: true,
            label: 'AllowedMemberTypes',
            display: !isPbacApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-AppRights-AllowedMemberTypes',
            },
        },
        {
            name: 'name',
            label: 'Value',
            sortable: true,
            display: !isPbacApplication,
            requireAccess: {
                control: 'ResourceAdmin-PbacApplicationDetails-AppRights-Value',
            },
        },
        {
            name: 'fulfillmentGroupFriendlyName',
            label: 'FulfillmentGroup',
            sortable: true,
            display: !isPbacApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-AppRights-FulfillmentGroup',
            },
        },
        {
            name: 'publishedInIIShop',
            label: 'PublishInIAMShop',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-AppRights-PublishInIAMShop',
            },
            component: {
                componentName: 'renderer',
                render: (data) =>
                    data && data.publishedInIIShop ? t('Yes') : t('No'),
            },
        },
        {
            name: 'accessRequestPolicyFriendlyName',
            sortable: true,
            label: 'AccessRequestPolicy',
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-AppRights-AccessRequestPolicy',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-PbacApplicationDetails-AppRights-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <Box
                            style={{ display: 'flex', justifyContent: 'end' }}
                            data-protectedsubcomponent={
                                'ResourceAdmin-PbacApplicationDetails-AppRights-Actions'
                            }
                        >
                            {canSeeDetailButtonControlName && (
                                <Box
                                    data-protectedsubcomponent={
                                        canSeeDetailButtonControlName
                                    }
                                >
                                    <Button.Details
                                        item={item}
                                        onClick={() => {
                                            history.push(
                                                `${history.location.search}&appRoleId=${item?.id}`,
                                            )
                                        }}
                                    />
                                </Box>
                            )}
                            <span style={{ marginRight: '10px' }} />
                            {canDeleteAureApplicationRole &&
                                item?.isDeletable && (
                                    <Box
                                        data-protectedsubcomponent={
                                            canDeleteAureApplicationRole
                                        }
                                    >
                                        <DeleteAzureApplicationRight
                                            identifier="globalRightId"
                                            item={item}
                                        />
                                    </Box>
                                )}
                            <span style={{ marginRight: '10px' }} />
                            {canSeeUIActionsButtonControlName && (
                                <Box
                                    data-protectedsubcomponent={
                                        canSeeUIActionsButtonControlName
                                    }
                                >
                                    <UIActionsGearButton
                                        item={item}
                                        noun={'ResourceAdminPbacDefinitions'}
                                    />
                                </Box>
                            )}
                        </Box>
                    )
                },
            },
        },
    ]

    const isSmallScreen = useIsSmallScreenFalse()

    const showAzureWorkflowButton =
        canAddAzureApplicationRole && !isPbacApplication
    const showPbacWorkflowButton =
        canAddPbacApplicationRole && isPbacApplication

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                {(showAzureWorkflowButton || showPbacWorkflowButton) && (
                    <Box
                        data-protectedsubcomponent={
                            showAzureWorkflowButton
                                ? canAddAzureApplicationRole
                                : canAddPbacApplicationRole
                        }
                    >
                        <CreateWorkflowButton
                            label={t('AddApplicationAppRights')}
                            workflowName={
                                showAzureWorkflowButton
                                    ? 'CreateAzureAppAppRole'
                                    : 'OnboardAzLocalRight'
                            }
                            resourceId={resourceId}
                            selectedApplicationId={applicationId}
                            iconComponent={
                                <Icon
                                    name={'Plus'}
                                    fill="white"
                                    style={{
                                        width: '1.6rem',
                                        height: '1.6rem',
                                        marginTop: '0.3rem',
                                        marginRight: '0.5rem',
                                    }}
                                />
                            }
                        />
                    </Box>
                )}

                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    attributes={getAccessBasedAttributes(
                        rolesAttributes,
                    ).filter((a) => !a.hasOwnProperty('display') || a.display)}
                    rowSelection={false}
                    pagination={pagination}
                    list={list}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default ApplicationRights
