import {
    Box,
    styled,
    Grid,
    Paper,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { Loader, Avatar, WithCloseButton } from 'packages/eid-ui'
import { isNilOrEmpty } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { usePageContext } from 'pageContext'
import config from 'config'
import { useCheckOutCredentialsRecords } from 'hooks'
import PeopleSearch from 'components/Filters/PeopleSearch'
import CheckoutHistoryCard from 'components/CheckoutHistoryCard'
import DateTimePicker from 'components/DateTimePicker'
import checkoutHistoryCardAttributes from './checkoutHistoryCardAttributes'
import { imagesDisabled } from 'utils'

const smallScreenWidth = 960
const useStyles = makeStyles(() =>
    createStyles({
        parentContainer: {
            background: '#fff',
            padding: '24px',
        },
        cardsGrid: {
            marginTop: '24px',
        },
        filtersBox: {
            display: 'flex',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        startDateFilterBox: {
            maxWidth: '264px',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                maxWidth: 'none',
            },
        },
        endDateFilterBox: {
            maxWidth: '264px',
            margin: '0px 0px 0px 20px',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                maxWidth: 'none',
                margin: '16px 0px 0px 0px',
            },
        },
        checkedOutByBox: {
            marginTop: '24px',
            width: '290px',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                width: 'auto',
            },
        },
        formControlLabel: {
            margin: '0px 0px 0px 24px',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                margin: '16px 0px 0px 0px',
            },
        },
        noHistoryMessage: {
            marginTop: '24px',
            fontSize: '14px',
        },
    }),
)

const SelectedPersonIndicator = styled(WithCloseButton)({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
})

const PersonLabel = styled('label')({
    padding: '0 0 10px 8px',
    lineHeight: '11px',
    fontSize: '10px',
    display: 'block',
    color: ' #b4b4b4',
    textTransform: 'uppercase',
})

export const CheckoutHistory = ({ secret }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [state, dispatch] = usePageContext()
    const queryData = {}

    if (state.startDateFilter) {
        queryData.StartDateAfter = state.startDateFilter
    }
    if (state.endDateFilter) {
        queryData.EndDateBefore = state.endDateFilter
    }
    if (state.activeCheckOut) {
        queryData.activeCheckOut = state.activeCheckOut
    }
    if (state.activeCheckOutBy) {
        queryData.targetPersonId = state.activeCheckOutBy.id
    }

    const {
        list: history,
        loading: historyLoading,
        pagination,
    } = useCheckOutCredentialsRecords(secret.id, queryData)

    return (
        <Box className={classes.parentContainer}>
            <Box className={classes.filtersBox}>
                <DateTimePicker
                    showLabelWithin
                    disableFuture
                    boxStyle={{ className: classes.startDateFilterBox }}
                    label={t('StartDate')}
                    value={
                        isNilOrEmpty(state['startDateFilter'])
                            ? null
                            : state['startDateFilter'].clone().local()
                    }
                    onChange={(date) => {
                        const dateToSet = date.clone()
                        dateToSet.startOf('day')
                        dateToSet.utc()

                        dispatch({
                            type: 'SET_PROP',
                            payload: {
                                key: 'startDateFilter',
                                value: dateToSet,
                            },
                        })
                    }}
                    handleClear={() =>
                        dispatch({
                            type: 'SET_PROP',
                            payload: {
                                key: 'startDateFilter',
                                value: null,
                            },
                        })
                    }
                />
                <DateTimePicker
                    showLabelWithin
                    boxStyle={{
                        className: classes.endDateFilterBox,
                    }}
                    disableFuture
                    minDate={
                        state['startDateFilter']
                            ? state['startDateFilter']
                            : null
                    }
                    label={t('EndDate')}
                    value={
                        isNilOrEmpty(state['endDateFilter'])
                            ? null
                            : state['endDateFilter'].clone().local()
                    }
                    onChange={(date) => {
                        const dateToSet = date.clone()
                        dateToSet.startOf('day')
                        dateToSet.utc()

                        dispatch({
                            type: 'SET_PROP',
                            payload: {
                                key: 'endDateFilter',
                                value: dateToSet,
                            },
                        })
                    }}
                    handleClear={() =>
                        dispatch({
                            type: 'SET_PROP',
                            payload: {
                                key: 'endDateFilter',
                                value: null,
                            },
                        })
                    }
                />
                <FormControlLabel
                    className={classes.formControlLabel}
                    control={
                        <Checkbox
                            checked={state.activeCheckOut}
                            icon={<Icon name="CheckBox" />}
                            checkedIcon={<Icon name="CheckedBox" />}
                            onChange={() =>
                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: 'activeCheckOut',
                                        value: !state.activeCheckOut,
                                    },
                                })
                            }
                        />
                    }
                    label={
                        <Typography style={{ fontSize: '14px' }}>
                            {t('CheckedOut')}
                        </Typography>
                    }
                />
            </Box>
            <Box className={classes.checkedOutByBox}>
                {state.activeCheckOutBy ? (
                    <>
                        <PersonLabel>{t('CheckedOutBy')}</PersonLabel>
                        <SelectedPersonIndicator
                            onClose={() => {
                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: 'activeCheckOutBy',
                                        value: null,
                                    },
                                })
                            }}
                        >
                            {!imagesDisabled && (
                                <Box style={{ marginRight: '21px' }}>
                                    <Avatar
                                        src={`${config.BASE_EID_URL}${state.activeCheckOutBy.imageThumbUrl}`}
                                    />
                                </Box>
                            )}
                            <Typography
                                style={{
                                    wordBreak: 'break-all',
                                }}
                                variant="body1"
                            >
                                {state.activeCheckOutBy.friendlyName}
                            </Typography>
                        </SelectedPersonIndicator>
                    </>
                ) : (
                    <PeopleSearch
                        value={state.activeCheckOutBy}
                        labelComponent={() => (
                            <PersonLabel>{t('CheckedOutBy')}</PersonLabel>
                        )}
                        onChange={(_, value) =>
                            dispatch({
                                type: 'SET_PROP',
                                payload: {
                                    key: 'activeCheckOutBy',
                                    value: value,
                                },
                            })
                        }
                    />
                )}
            </Box>

            {historyLoading ? (
                <div style={{ alignSelf: 'center' }}>
                    <Loader />
                </div>
            ) : history.length === 0 ? (
                <Typography className={classes.noHistoryMessage}>
                    {t('NoCheckOutHistoryFound')}
                </Typography>
            ) : (
                <Paper className={classes.cardsGrid}>
                    <Grid
                        container
                        spacing={2}
                        style={{ justifyContent: 'start' }}
                    >
                        {history.map((item, index) => (
                            <CheckoutHistoryCard
                                key={index}
                                item={item}
                                attributes={checkoutHistoryCardAttributes}
                            />
                        ))}
                    </Grid>
                    <Box
                        display="flex"
                        marginTop={'20px'}
                        justifyContent="center"
                    >
                        {pagination}
                    </Box>
                </Paper>
            )}
        </Box>
    )
}
