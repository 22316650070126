import { useTranslation } from 'react-i18next'
import { AttributeSections } from 'components/AttributeSections'

export const QuotaSettings = (props) => {
    const { t } = useTranslation()
    const attributes = [
        // NOTE: Changing the structure of attributes to divide them in sections as per design
        [
            {
                label: 'UseDefaultQuota',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.useDefaultQuota ? t('True') : t('False')
                    },
                },
            },
            {
                name: 'sizeInMb',
                label: 'SizeInMb',
            },
        ],
        [
            {
                name: 'issueWarningAt',
                label: 'IssueWarningAtKb',
            },
            {
                name: 'prohibitSendAt',
                label: 'ProhibitSendAtKb',
            },
            {
                name: 'prohibitSendAndReceiveAt',
                label: 'ProhibitSendAndReceiveAt',
            },
        ],
    ]

    const { mailbox } = props

    return <AttributeSections data={mailbox} attributes={attributes} />
}
