import { Box } from '@mui/material'
import { Card } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import GenericFilter from '../EidFilter/GenericFilter'

const BusinessFunctionFilter = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()

    return (
        <Card
            cardTitle={t('BusinessFunctions')}
            collapsible
            expanded={Boolean(state.businessFunction)}
        >
            <Box
                padding="0"
                style={{
                    borderTop: 'solid 1px #efeff1',
                    backgroundColor: '#fbfbfd',
                }}
            >
                <Box padding="20px 20px 0px 20px">
                    <GenericFilter
                        type="checklist"
                        searchable="true"
                        searchPlaceholder={t('Search')}
                        url="/api/Lookups/businessFunctions"
                        basedOn={{
                            type: 'optional',
                            contextProp: 'location',
                            valueProp: 'id',
                            queryParamName: 'locationId',
                        }}
                        dedupingInterval="1800000"
                        debounceInterval="500"
                        contextProp="businessFunction"
                        selectedValueIndicatorProp="friendlyName"
                        state={state}
                        showPagination={true}
                    />
                </Box>
            </Box>
        </Card>
    )
}

export default BusinessFunctionFilter
