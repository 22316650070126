import PropTypes from 'prop-types'
import { Card, Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import Attributes from './Attributes'
import { Button } from 'components'
import useSubcomponents from 'useSubcomponents'

const useCardStyles = makeStyles({
    root: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
        border: '1px solid #00000036 !important',
        borderRadius: '8px !important',
        ...props.cardStyles,
    }),
    lineGlow: {
        width: '100%',
        height: '1px',
        opacity: 0.25,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
    checkInButton: {
        width: '60px',
        padding: '15px 0px 20px 20px',
    },
})

const CheckoutHistoryCard = (props) => {
    const { item, cardStyles, attributes } = props
    const { canCheckInActiveCheckouts } = useSubcomponents()
    const cardClasses = useCardStyles({ cardStyles })

    return (
        <Grid item xs={12} md={4}>
            <Card classes={cardClasses}>
                <Attributes item={item} attributes={attributes} />
                {canCheckInActiveCheckouts && item.activeCheckedOut && (
                    <Box
                        className={cardClasses.checkInButton}
                        data-protectedsubcomponent={canCheckInActiveCheckouts}
                    >
                        <Button.CheckIn
                            credentialId={item.credentialId}
                            checkOutId={item.id}
                            canCheckIn={true}
                        />
                    </Box>
                )}
            </Card>
            <div className={cardClasses.lineGlow}></div>
        </Grid>
    )
}

CheckoutHistoryCard.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
}

export default CheckoutHistoryCard
