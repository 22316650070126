import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'packages/eid-ui'
import ReactHtmlParser from 'react-html-parser'
import DomPurify from 'dompurify'
import useSubcomponents from 'useSubcomponents'

const ResourceMoreInformationTile = ({
    resourceId,
    moreInfoKey,
    protectedsubcomponent,
}) => {
    const { t } = useTranslation()
    const [parsedHtmlMoreInfo, setParsedHtmlMoreInfo] = useState('')
    const { hasAccessToControl } = useSubcomponents()
    useEffect(() => {
        if (moreInfoKey) {
            try {
                setParsedHtmlMoreInfo(
                    ReactHtmlParser(
                        DomPurify.sanitize(t(moreInfoKey), {
                            ADD_ATTR: ['target'],
                        }),
                    ),
                )
            } catch (error) {}
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moreInfoKey])

    return (
        <Fragment>
            {hasAccessToControl(protectedsubcomponent) && parsedHtmlMoreInfo && (
                <Box
                    data-protectedsubcomponent={protectedsubcomponent}
                    style={{
                        margin: '0 0 15px 0px',
                    }}
                >
                    <Card
                        cardTitle={t('More_Information')}
                        icon={<Icon name="MoreInfo" />}
                        collapsible
                        expanded
                        disabled={resourceId}
                    >
                        <Box
                            padding="0px 10px"
                            maxWidth="350px"
                            overflow="scroll"
                        >
                            {parsedHtmlMoreInfo}
                        </Box>
                    </Card>
                </Box>
            )}
        </Fragment>
    )
}

export default ResourceMoreInformationTile
