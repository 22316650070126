import { Fragment, useEffect } from 'react'
import MailboxesLayout from './MailboxesLayout'
import { useAuthState } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { usePageContext } from 'pageContext'

const allFilters = [
    {
        name: 'OwnedByFilter',
        requireAccess: 'ResourceAdmin-Mailboxes-Filter-OwnedBy',
    },
    {
        name: 'targetSystemFilter',
        requireAccess: 'ResourceAdmin-Mailboxes-Filter-ResourceSystem',
    },
    {
        name: 'mailboxType',
        requireAccess: 'ResourceAdmin-Mailboxes-Filter-MailboxType',
    },
    {
        name: 'advancedSearch',
        requireAccess: 'ResourceAdmin-Mailboxes-Filter-AdvancedSearch',
        config: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'friendlyName',
                    type: 'text',
                    label: 'FriendlyName',
                    placeholder: 'FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                },
                {
                    name: 'logonName',
                    type: 'text',
                    label: 'Logon',
                    placeholder: 'Logon',
                    contextProp: 'advancedSearch.forms.logonName',
                },
                {
                    name: 'primaryEmailAddress',
                    type: 'text',
                    label: 'Email',
                    placeholder: 'Email',
                    contextProp: 'advancedSearch.forms.primaryEmailAddress',
                },
                {
                    name: 'alias',
                    type: 'text',
                    label: 'Alias',
                    placeholder: 'Alias',
                    contextProp: 'advancedSearch.forms.alias',
                },
            ],
            tags: {
                title: 'Common_Tags',
                colorCode: {
                    primary: '#f81983',
                    secondary: '#fee8f2',
                },
                contextProp: 'advancedSearchTags',
            },
        },
    },
]

const RequestViewAllMailboxes = () => {
    const [, dispatch] = usePageContext()

    const [{ currentUserId, friendlyName }] = useAuthState()

    const { trimControlsBasedOnAccess } = useSubcomponents()

    const filters = trimControlsBasedOnAccess(allFilters).map((f) => f)
    const hasOwnedByFilterAccess =
        filters.map((f) => f.name).indexOf('OwnedByFilter') >= 0
    useEffect(() => {
        if (!hasOwnedByFilterAccess) {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'ownedBy',
                    value: {
                        id: currentUserId,
                        friendlyName: friendlyName,
                    },
                },
            })
        }
    }, [currentUserId])
    return (
        <Fragment>
            <MailboxesLayout filters={filters} />
        </Fragment>
    )
}

export { RequestViewAllMailboxes }
