import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@mui/material'
import Box from '@mui/material/Box'
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import ItemHeader from './ItemHeader'
import Attributes from './Attributes'
import { getFullImageUrl } from 'utils'
import { isNilOrEmpty } from 'packages/core'

const useCardStyles = makeStyles({
    root: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px !important',
        position: 'relative',
        overflow: 'visible',
        boxShadow: 'none !important',
        border: 'none !important',
        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            overflow: 'visible',
            zIndex: 4,
            '&:not(:hover)': {
                zIndex: 2,
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
    lineGlow: {
        width: '100%',
        height: '1px',
        opacity: 0.25,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
})

const styles = {
    card: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        position: 'relative',
        overflow: 'visible',

        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            overflow: 'visible',

            zIndex: 4,

            '&:not(:hover)': {
                zIndex: 2,
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
}

const ItemCard = withStyles(styles)((props) => {
    const {
        item,
        cardStyles,
        attributes,
        leftButton,
        rightButton,
        showImage = false,
    } = props

    const cardClasses = useCardStyles({ cardStyles })
    const headerAttribute = attributes.find((x) => x.isHeader)
    const imageAttribute = attributes.find(
        (i) => i.component?.componentName === 'ResourceImage',
    )
    let imgUrl
    if (imageAttribute && showImage) {
        imgUrl = !isNilOrEmpty(item[imageAttribute.component.name])
            ? getFullImageUrl(item[imageAttribute.component.name])
            : getFullImageUrl(imageAttribute.component.defaultUrl)
    }

    return (
        <Card classes={cardClasses} role="button">
            <ItemHeader title={item[headerAttribute?.name]} url={imgUrl} />

            <Attributes item={item} attributes={attributes} />

            {(leftButton || rightButton) && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    margin="20px 0px"
                    padding="0px 20px"
                >
                    {leftButton}
                    {rightButton}
                </Box>
            )}
            <div className={cardClasses.lineGlow}></div>
        </Card>
    )
})

ItemCard.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
}

export default ItemCard
