import { Box, styled } from '@mui/material'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAllCarts,
    useApplication,
    useApplicationAppRightsAssignments,
    useAddItemsToCart,
} from 'hooks'
import { PageContextProvider } from 'pageContext'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AssigneePerson from '../../../AssigneePerson/AssigneePerson'
import { Button, Filters } from 'components'
import { Tooltip, WarningModal } from 'packages/eid-ui'
import useSubcomponents from 'useSubcomponents'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import FieldTypeValueDrawer from './FieldTypeValueDetailsDrawer'
import ButtonWithDropdown from 'components/ButtonWithDropdown'
import { RESOURCE_TYPE_NAMES } from 'utils'
import cartHelpers from 'containers/Cart/cartHelpers'
import { AssignRightsToPerson } from 'components/AssignRightsToResources/AssignRightsToPerson'
import { AssignRightsToGroup } from 'components/AssignRightsToResources/AssignRightsToGroup'
import { AssignRightsToManagementRole } from 'components/AssignRightsToResources/AssignToManagementRole'
import { AssignRightsToBusinessRoleLocation } from 'components/AssignRightsToResources/AssignToBusinessRoleLocation'
import { AssignRightsToSetGroup } from 'components/AssignRightsToResources/AssignRightsToSetGroup'
import FieldTypeJsonPreviewButton from 'components/FieldTypeJsonPreview'

const canEditFieldTypeValues =
    'ResourceAdmin-ApplicationDetails-AppRightDetails-FieldTypeValues-EditFieldTypeControl'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',
    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const ApplicationAssignments = ({ role, roleId, resourceSystemModuleID }) => {
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const { data: carts } = useAllCarts()
    const showSubdrawer = query.get('showSubDrawer')
    const azGlobalRightID = query.get('azGlobalRightID')
    const applicationId = query.get('applicationId')
    const { data: application } = useApplication(applicationId)

    const {
        canAddApplicationAppRightAssignments,
        canBulkDeleteApplicationAppRightAssignments,
        canSelectApplicationAppRightAssignments,
        hasAccessToControl,
    } = useSubcomponents()
    const [selected, setSelected] = useState([])
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)

    const { list, loading, loadingMore, pagination } =
        useApplicationAppRightsAssignments(
            `/api/LocalRights/appRoles/allMembersByAppRight/${roleId}`,
        )

    const alreadyInCart = (item) => {
        try {
            const cart =
                carts?.find(
                    (cart) =>
                        cart.targetAssigneeId === item.localRightId &&
                        cart.targetAssigneeFriendlyName ===
                            item?.appRightFriendlyName &&
                        cart.targetAssignee_ResourceTypeFriendlyName ===
                            t(RESOURCE_TYPE_NAMES.LOCALRIGHT),
                ) || null
            let itemCart =
                cart?.cartItems?.filter(
                    (cItem) => cItem.requestableResourceId === item?.assigneeId,
                ) || null
            itemCart = itemCart.filter((x) => x?.assignmentId === item?.id)
            return itemCart?.length ? true : false
        } catch (err) {}
        return false
    }

    const allAssignments =
        (list?.length &&
            list.map((item) => {
                return {
                    ...item,
                    isAlreadyAssigned: alreadyInCart(item),
                }
            })) ||
        []

    const [addItemsToCart, { isLoading: addingToCart }] = useAddItemsToCart()

    const [assignee, setAssignee] = useState(null)
    const [showAssignmentModal, setShowAssignmentModal] = useState(false)

    const attributes = [
        {
            name: 'assignee',
            sortable: true,
            sortBy: 'assignee',
            label: 'Assignee',
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <AssigneePerson
                            assigneeName={data.assignee}
                            imageUrl={data.personImageThumbUrl}
                            assigneeType={data.value}
                        />
                    )
                },
            },
        },
        {
            name: 'appRightFriendlyName',
            sortable: true,
            sortBy: 'appRightFriendlyName',
            label: 'AppRight',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'rightType',
            sortable: true,
            sortBy: 'rightType',
            label: 'RightType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'assignedTo',
            sortable: true,
            sortBy: 'assignedTo',
            label: 'AssignedTo',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'application',
            sortable: true,
            sortBy: 'application',
            label: 'Application',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },

        {
            name: 'resourceSystem',
            sortable: true,
            sortBy: 'resourceSystem',
            label: 'ResourceSystem',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRightsDetails-Assignments-DeleteApplicationAppRightAssignments-Control',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '1rem',
                            }}
                        >
                            <FieldTypeJsonPreviewButton data={data} />
                            {hasAccessToControl(canEditFieldTypeValues) && (
                                <Tooltip
                                    title={
                                        alreadyInCart(data)
                                            ? t('Common_ItemAlreadyInCart')
                                            : ''
                                    }
                                >
                                    <Box
                                        data-protectedsubcomponent={
                                            canEditFieldTypeValues
                                        }
                                    >
                                        <Button.Edit
                                            onClick={() => {
                                                query.set(
                                                    'azGlobalRightID',
                                                    `${data?.id}`,
                                                )
                                                query.set('showSubDrawer', true)
                                                history.push(
                                                    `${
                                                        history.location
                                                            .pathname
                                                    }?${query.toString()}`,
                                                )
                                            }}
                                            showEndIcon={false}
                                            disabled={alreadyInCart(data)}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                            <Box>
                                <Tooltip
                                    title={
                                        alreadyInCart(data)
                                            ? t('Common_ItemAlreadyInCart')
                                            : ''
                                    }
                                >
                                    <DeleteButton>
                                        <Button.Delete
                                            showEndIcon={false}
                                            onClick={() =>
                                                handleSingleDelete(data)
                                            }
                                            disabled={alreadyInCart(data)}
                                        />
                                    </DeleteButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    )
                },
            },
        },
    ]

    const handleSingleDelete = (item) => {
        setSelectedItemsToDelete([item])
    }

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }

    const preAdd = (selectedItemsToDelete) => {
        let itemsToAdd = []
        selectedItemsToDelete.forEach((item) => {
            let itemToAdd = cartHelpers.applicationsFieldTypeJsonCartItem({
                targetResourceId: item?.assigneeId,
                assignmentType: 'Remove',
                application: application,
                assigneePerson: item,
                targetAssigneeId: item?.localRightId,
                fieldTypeValueJson: undefined,
            })

            if (itemToAdd) {
                itemsToAdd.push(itemToAdd)
            }
        })

        return itemsToAdd
    }

    const handleConfirmDelete = () => {
        try {
            const itemsToAdd = preAdd(selectedItemsToDelete)
            if (itemsToAdd === undefined) return

            addItemsToCart(itemsToAdd).then(() => {
                setSelectedItemsToDelete(null)
                setSelected([])
            })
        } catch (err) {}
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x.id === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x.id !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            setSelected(allAssignments.filter((x) => !x.isAlreadyAssigned))
        }
    }

    const handleDetailsClose = () => {
        try {
            query.delete('azGlobalRightID')
            query.delete('showSubDrawer')
            history.push(`${history.location.pathname}?${query.toString()}`)
        } catch (err) {}
    }

    const selectAllAction = canBulkDeleteApplicationAppRightAssignments
        ? {
              style: {
                  padding: '0px 32px',
              },
              render: () => {
                  return (
                      <BulkRemoveButton
                          selectedCount={selected.length}
                          label={t('RemoveSelectedAssignments', {
                              count: selected.length,
                          })}
                          onClick={handleDeleteBulk}
                          dataProtectedsubcomponent={
                              canBulkDeleteApplicationAppRightAssignments
                          }
                      />
                  )
              },
          }
        : null
    const [assignmentModalValue, setAssignmentModalValue] = useState('')
    const assignRightsDropdownOptions = [
        {
            label: 'AssignToPerson',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToPerson',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToPerson')
            },
        },
        {
            label: 'AssignToManagementRole',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToManagementRole',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToManagementRole')
            },
        },
        {
            label: 'AssignToGroup',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToGroup',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToGroup')
            },
        },
        {
            label: 'AssignToBusinessRoleLocation',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToBusinessRoleLocation',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToBusinessRoleLocation')
            },
        },
        {
            label: 'AssignToSetGroup',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRightsAssignment-AssignToSetGroup',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToSetGroup')
            },
        },
    ]

    const getOptionsToRender = () => {
        return assignRightsDropdownOptions.filter(
            (d) => d.display && hasAccessToControl(d?.requireAccess?.control),
        )
    }
    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 32px"
            >
                <Box>
                    {canAddApplicationAppRightAssignments && (
                        <PageContextProvider key="AddNewIAmShopAssignments">
                            {getOptionsToRender().length > 0 && (
                                <ButtonWithDropdown
                                    label={'AssignAppRight'}
                                    options={getOptionsToRender()}
                                />
                            )}
                        </PageContextProvider>
                    )}
                </Box>
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    selectAllAction={selectAllAction}
                    rowSelection={
                        canSelectApplicationAppRightAssignments || false
                    }
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected.map((s) => s.id)}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={attributes.filter(
                        (x) =>
                            !x?.requireAccess?.control ||
                            hasAccessToControl(x?.requireAccess?.control),
                    )}
                    list={allAssignments}
                />
            </TableOverView>
            {showSubdrawer && list?.length && (
                <FieldTypeValueDrawer
                    onClose={() => handleDetailsClose()}
                    assigneePerson={list.find((x) => x.id === azGlobalRightID)}
                />
            )}

            {selectedItemsToDelete && (
                <WarningModal
                    title={
                        selectedItemsToDelete.length === 1
                            ? `${t('Delete')} ${
                                  selectedItemsToDelete[0]?.assignee
                              }`
                            : t('DeleteSelectedItems')
                    }
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceeding={addingToCart}
                    proceed={handleConfirmDelete}
                />
            )}
            {assignmentModalValue === 'AssignToPerson' && (
                <AssignRightsToPerson
                    dynamictext={application?.friendlyName}
                    resourceId={roleId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/LocalRights/appRoles/allMembersByAppRight/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
            {assignmentModalValue === 'AssignToGroup' && (
                <AssignRightsToGroup
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/LocalRights/appRoles/allMembersByAppRight/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
            {assignmentModalValue === 'AssignToManagementRole' && (
                <AssignRightsToManagementRole
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/LocalRights/appRoles/allMembersByAppRight/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
            {assignmentModalValue === 'AssignToBusinessRoleLocation' && (
                <AssignRightsToBusinessRoleLocation
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/LocalRights/appRoles/allMembersByAppRight/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
            {assignmentModalValue === 'AssignToSetGroup' && (
                <AssignRightsToSetGroup
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/LocalRights/appRoles/allMembersByAppRight/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
        </Box>
    )
}

export default ApplicationAssignments
