import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import { useIsSmallScreenFalse } from 'packages/core'
import { ListingGrid, GridContainer, Filters } from 'components'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAppManagementRoleTotalAccessGranted } from 'hooks'

export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))

const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const TotalAccessGranted = ({ managementRoleId }) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useAppManagementRoleTotalAccessGranted(managementRoleId)

    const isSmallScreen = useIsSmallScreenFalse()

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'Name',
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ManagementRoleDetails-TotalAccessGranted-FriendlyName',
            // },
        },
        {
            name: 'resourceTypeName',
            sortable: true,
            sortBy: 'resourceTypeName',
            label: 'ResourceType',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-TotalAccessGranted-ResourceType',
            },
        },
        {
            name: 'resourceTypeRoleName',
            sortable: true,
            sortBy: 'resourceTypeRoleName',
            label: 'ResourceTypeRole',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-TotalAccessGranted-ResourceTypeRole',
            },
        },
        {
            name: 'resourceSystemFriendlyName',
            sortable: true,
            sortBy: 'resourceSystemFriendlyName',
            label: 'ResourceSystem',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-TotalAccessGranted-ResourceSystem',
            },
        },
    ]

    return (
        <Box>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box></Box>
                    <Box width="240px" display="flex" justifyContent="flex-end">
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '16px 0px',
                        marginTop: '0px',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={list}
                    />
                </GridContainer>
            </ListingGrid>
        </Box>
    )
}

export default TotalAccessGranted
