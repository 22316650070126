import { Box, styled } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAddItemsToCart, useAppOwnedFieldTypes, useCart } from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import Filters from 'components/Filters'
import BooleanValue from 'components/DynamicComponents/BooleanValue'
import Button from 'components/Button'
import cartHelpers from 'containers/Cart/cartHelpers'
import { Tooltip, WarningModal } from 'packages/eid-ui'
import { useState } from 'react'
import {
    getFullPathFromUrl,
    useIsSmallScreenFalse,
    useQuery,
} from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import { Icon } from 'packages/eid-icons'
import { createStyles, makeStyles } from '@mui/styles'
import { GridContainer, ListingGrid } from 'components/Listing'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'

const AddNewAppOwnedFieldTypeButton = styled(Box)({
    '& button': {
        height: '4rem',
        border: 'none',
        padding: '1.2rem',
    },
})

const useButtonStyles = makeStyles((theme) =>
    createStyles({
        root: (isSmallScreen) => ({
            height: '3.2rem',
            borderRadius: '0.4rem',
            padding: '0 1.5rem 0 1rem',
            backgroundColor: theme?.palette?.primary?.main,
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '1.4rem',
            margin: isSmallScreen ? '1.6rem 1.6rem 0 1.6rem' : '',
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
            },
            '& svg': {
                marginRight: '0.8rem',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

const canDeleteAppOwnedFieldType =
    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-Actions-Delete'

const canSeeDetailForAppOwnedFieldType =
    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-Actions-Detail'

const canAddNewAppOwnedFieldType =
    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-Actions-Add'

const canSeeUIActionsButtonControlName =
    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-Actions-UIActions'

const AppOwnedFieldTypes = ({ resourceSystemModuleId, applicationId }) => {
    const { t } = useTranslation()
    const [addItemsToCart, { isLoading }] = useAddItemsToCart()
    const query = useQuery()
    const history = useHistory()
    const { hasAccessToControl } = useSubcomponents()
    const { data: cart } = useCart()
    const [itemToDelete, setItemToDelete] = useState(null)

    const { list, loading, loadingMore, pagination, refetch } =
        useAppOwnedFieldTypes(
            resourceSystemModuleId,
            Boolean(resourceSystemModuleId),
        )

    const { getAccessBasedAttributes } = useSubcomponents()
    const isSmallScreen = useIsSmallScreenFalse()
    const classes = useButtonStyles(isSmallScreen)
    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            label: 'FieldTypeFriendlyName',
        },
        {
            name: 'name',
            sortable: true,
            label: 'Name',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-FieldTypeName',
            },
        },
        {
            name: 'selectionRuleName',
            sortable: true,
            label: 'UISelectionControl',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-UISelectionControl',
            },
        },
        {
            name: 'valueFrom',
            sortable: true,
            label: 'ValueFrom',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-ValueFrom',
            },
        },
        {
            name: 'valueTo',
            sortable: true,
            label: 'ValueTo',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-ValueTo',
            },
        },
        {
            name: 'allValuesRange',
            hideValue: true,
            sortable: true,
            label: 'AllValuesInRange',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-AllValuesInRange',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanValue
                        data={{ item }}
                        attribute={{ name: 'allValuesRange' }}
                    />
                ),
            },
        },
        {
            name: 'lookupSupported ',
            hideValue: true,
            sortable: true,
            label: 'CustomDataSource',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-AppOwnedFieldTypes-LookupSupported',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanValue
                        data={{ item }}
                        attribute={{ name: 'lookupSupported' }}
                    />
                ),
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            {hasAccessToControl(
                                canSeeDetailForAppOwnedFieldType,
                            ) && (
                                <Box
                                    data-protectedsubcomponent={
                                        canSeeDetailForAppOwnedFieldType
                                    }
                                >
                                    <Button.Details
                                        item={data}
                                        onClick={() => {
                                            history.push(
                                                `${history.location.search}&fieldTypeId=${data?.id}`,
                                            )
                                        }}
                                    />
                                </Box>
                            )}

                            {hasAccessToControl(canDeleteAppOwnedFieldType) && (
                                <Tooltip
                                    title={
                                        alreadyInCart(data)
                                            ? t('Common_ItemAlreadyInCart')
                                            : ''
                                    }
                                >
                                    <Box
                                        marginLeft={'1.6rem'}
                                        data-protectedsubcomponent={
                                            canDeleteAppOwnedFieldType
                                        }
                                    >
                                        <Button.Delete
                                            showEndIcon={false}
                                            disabled={alreadyInCart(data)}
                                            onClick={() => {
                                                setItemToDelete(data)
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                            {hasAccessToControl(
                                canSeeUIActionsButtonControlName,
                            ) && (
                                <Box
                                    marginLeft={'1.6rem'}
                                    data-protectedsubcomponent={
                                        canSeeUIActionsButtonControlName
                                    }
                                >
                                    <UIActionsGearButton
                                        item={data}
                                        noun={'ResourceAdminPbacDefinitions'}
                                    />
                                </Box>
                            )}
                        </Box>
                    )
                },
            },
        },
    ]

    const alreadyInCart = (item) => {
        const isAlreadyInCart = cartHelpers.isItemAlreadyInCart(cart, item?.id)
        return isAlreadyInCart
    }

    const handleDelete = () => {
        const itemToRemove = cartHelpers.removeFieldType({
            targetAssigneeId: applicationId,
            resource: itemToDelete,
            resourceTypeId: itemToDelete?.resourceTypeId,
            resourceTypeName: itemToDelete?.resourceTypeFriendlyName,
            requestableResourceId: itemToDelete?.id,
            resourceFriendlyName: itemToDelete?.friendlyName,
        })
        addItemsToCart([itemToRemove]).then(() => {
            setItemToDelete(null)
            refetch()
        })
    }

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                <Box>
                    {hasAccessToControl(canAddNewAppOwnedFieldType) && (
                        <AddNewAppOwnedFieldTypeButton
                            data-protectedsubcomponent={
                                canAddNewAppOwnedFieldType
                            }
                        >
                            <Button
                                onClick={() => {
                                    query.set(
                                        'workflowName',
                                        'OnboardAzFieldType',
                                    )
                                    query.set(
                                        'SelectedApplicationID',
                                        `${applicationId}`,
                                    )
                                    query.set('returnUrl', getFullPathFromUrl())
                                    query.delete('applicationId')
                                    query.delete('activeTab')
                                    query.delete('subActiveTab')
                                    history.push(
                                        `${
                                            config.APP_SUBPATH
                                        }/applications/workflows?${query.toString()}`,
                                    )
                                }}
                                className={classes.root}
                            >
                                {<Icon name="Plus" color="#fff" />}
                                {t('AddNewAppOwnedFieldType')}
                            </Button>
                        </AddNewAppOwnedFieldTypeButton>
                    )}
                </Box>
                <Box
                    style={{
                        margin: isSmallScreen ? '1.6rem' : '0 0 0 auto',
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
            {itemToDelete && (
                <WarningModal
                    title={t('Delete') + ' ' + itemToDelete?.friendlyName}
                    description={t('GenericDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    cancel={() => setItemToDelete(null)}
                    proceed={handleDelete}
                    proceeding={isLoading}
                    open={itemToDelete}
                />
            )}
        </ListingGrid>
    )
}

export default AppOwnedFieldTypes
