import { Box } from '@mui/material'
import TextFieldWithSubmitButton from './TextFieldWithSubmitButton'
import { FC, useState } from 'react'
import { isWhitespace } from 'packages/core'
import { useTargetPerson, useUpdateCartItemComment } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { getNumberOrDefault } from 'utils'
import config from 'config'

export const ITEM_COMMENT_MAX_LENGTH = getNumberOrDefault(
    config.ITEM_COMMENT_MAX_LENGTH,
    500,
)

export const ITEM_COMMENT_MIN_LENGTH = getNumberOrDefault(
    config.ITEM_COMMENT_MIN_LENGTH,
    0,
)

export const CartItemComment: FC<any> = (props) => {
    const { t } = useTranslation()

    const { onCommentChange, item, containsRisks } = props

    const [updateCartItemComment]: any = useUpdateCartItemComment()

    const [targetPerson] = useTargetPerson()

    const [comment, setComment] = useState(item.comment ?? '')

    const [focused, setFocused] = useState(false)

    const handleSubmit = () => {
        immediatelySubmit(comment, true)
    }

    const immediatelySubmit = (comm: any, isSubmit: boolean) => {
        let newComment = comm
        if (!newComment) {
            newComment = null
        }

        if (getDecisionCommentValidationMessage(comment, containsRisks).length > 0 && isSubmit)
            return
        if (item.comment !== newComment) {
            updateCartItemComment({
                cartItemId: item.id,
                comment: newComment,
                targetAssigneeId: item.targetAssigneeId,
            })
        }
        setFocused(false)
    }

    const getDecisionCommentValidationMessage = (
        comment: any,
        containsRisk = false,
    ) => {
        if (containsRisk && !Boolean(comment)) {
            return t('Common_JustificationCommentRequired')
        }
        if (comment?.length > ITEM_COMMENT_MAX_LENGTH) {
            return t('ItemCommentMaxLength', {
                length: ITEM_COMMENT_MAX_LENGTH,
            })
        }
        if (isWhitespace(comment)) {
            return t('WhiteSpaceNotAllowed')
        }
        if (comment?.length < ITEM_COMMENT_MIN_LENGTH) {
            return t('ItemCommentMinLength', {
                length: ITEM_COMMENT_MIN_LENGTH,
            })
        }

        return ''
    }

    const justificationCommentErrorMessage =
        getDecisionCommentValidationMessage(comment, containsRisks).length >
            0 && (
            <Box display="flex" color="#d0021b" fontSize="12px" my="13px">
                {containsRisks && (
                    <Box>
                        <Icon name="CriticalRisk" width="16" height="16" />
                    </Box>
                )}
                <Box marginLeft="8px">
                    {getDecisionCommentValidationMessage(
                        comment,
                        containsRisks,
                    )}
                </Box>
            </Box>
        )

    const commentField = (
        <Box minHeight="86px">
            <TextFieldWithSubmitButton
                fullWidth
                multiline
                rowsMax={5}
                maxCharacters={ITEM_COMMENT_MAX_LENGTH}
                margin="none"
                placeholder={t('Common_AddComment')}
                value={comment}
                onChange={(newValue: any) => {
                    setComment(newValue)
                    onCommentChange(item.id, newValue)
                }}
                onSubmit={handleSubmit}
                onBlur={handleSubmit}
                showSubmitButton={focused}
                onFocus={() => {
                    setFocused(true)
                }}
                immediatelySubmit={immediatelySubmit}
                errorMessageComponent={justificationCommentErrorMessage}
                required={containsRisks}
                error={
                    getDecisionCommentValidationMessage(comment, containsRisks)
                        .length > 0
                }
            />
        </Box>
    )

    return <>{commentField}</>
}
