import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useMembershipChanges } from 'hooks'
import GroupChangeType from 'components/DynamicComponents/GroupChangeType'
import CalculateTimeString from 'components/DynamicComponents/CalculateTimeString'
import BooleanValue from 'components/DynamicComponents/BooleanValue'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Filters } from 'components'

export const MembershipChanges = ({ groupId }) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        pagination,
    } = useMembershipChanges(groupId)

    const attributes = [
        {
            label: t('ChangeType'),
            name: 'groupAccountChangeType',
            sortable: true,
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <GroupChangeType
                        data={{ item }}
                        attribute={{ name: 'groupAccountChangeType' }}
                    />
                ),
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-Groups-Details-MembershipChanges-List-ChangeType',
            // },
        },
        {
            label: t('WhenAgo'),
            name: 'when',
            sortable: true,
            sortBy: 'modifiedDate',
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <CalculateTimeString
                        data={{ item }}
                        attribute={{ name: 'when' }}
                    />
                ),
            },
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-MembershipChanges-List-WhenAgo',
            },
        },
        {
            label: t('SourceOfChange'),
            name: 'changeSourceName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-MembershipChanges-List-SourceOfChange',
            },
        },
        {
            label: t('SourceAssignmentForMembership'),
            name: 'sourceAssignmentForMembership',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-MembershipChanges-List-SourceAssignmentForMembership',
            },
        },
        {
            label: t('SourceBusinessRequest'),
            name: 'sourceBusinessRequest',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-MembershipChanges-List-SourceBusinessRequest',
            },
        },
        {
            name: 'accountFriendlyName',
            label: t('AccountDisplayName'),
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-MembershipChanges-List-AccountDisplayName',
            },
        },
        {
            name: 'isHighSecurityGroup',
            label: t('IsHighSecurityGroup'),
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-MembershipChanges-List-IsHighSecurityGroup',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanValue
                        data={{ item }}
                        attribute={{ name: 'isHighSecurityGroup' }}
                    />
                ),
            },
        },
        {
            name: 'groupAccountStoreFriendlyName',
            label: t('AccountDirectory'),
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-MembershipChanges-List-AccountDirectory',
            },
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                paddingBottom="16px"
            >
                <Box width="308px" display="flex" alignItems="center">
                    <Filters.TextSearch outlined />
                </Box>
            </Box>

            <EidTableGrid
                noDataMessage={t('NoDataMessage')}
                pagination={pagination}
                isLoading={isLoading}
                attributes={getAccessBasedAttributes(attributes)}
                list={list}
            />
        </>
    )
}
