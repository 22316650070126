export const useRouteOptions = () => {
    const routeOptions = [
        {
            name: 'Applications',
            title: 'Applications',
            icon: 'ApplicationsSmall',
            route: '/applications',
            requireAccess: 'ResourceAdmin-Applications-Page',
            children: [
                {
                    title: 'View All',
                    route: '/applications',
                },
                {
                    title: 'WorkFlows',
                    route: '/applications/workflows',
                },
                {
                    title: 'claimsMappingPolicyWorkFlows',
                    route: '/claimsMappingPolicy/workflows',
                },
            ],
        },
        {
            name: 'Groups',
            title: 'Groups',
            icon: 'ApplicationRolesSmall',
            route: '/groups',
            requireAccess: 'ResourceAdmin-Groups-Page',

            children: [
                {
                    title: 'View All',
                    route: '/groups',
                },
                {
                    title: 'WorkFlows',
                    route: '/groups/workflows',
                },
            ],
        },
        {
            name: 'Management Roles',
            title: 'ManagementRoles',
            icon: 'ManagementRolesSmall',
            route: '/managementRoles',
            requireAccess: 'ResourceAdmin-ManagementRoles-Page',

            children: [
                {
                    title: 'View All',
                    route: '/managementRoles',
                },
                {
                    title: 'WorkFlows',
                    route: '/managementRoles/workflows',
                },
            ],
        },
        {
            name: 'Shared Folders',
            title: 'SharedFolders',
            icon: 'SharedFoldersSmall',
            route: '/sharedFolders',
            requireAccess: 'ResourceAdmin-SharedFolders-Page',

            children: [
                {
                    title: 'View All',
                    route: '/sharedFolders',
                },
                {
                    title: 'WorkFlows',
                    route: '/sharedFolders/workflows',
                },
            ],
        },
        {
            name: 'Mailboxes',
            title: 'Mailboxes',
            icon: 'MailboxesSmall',
            route: '/mailboxes',
            requireAccess: 'ResourceAdmin-Mailboxes-Page',
            children: [
                {
                    title: 'View All',
                    route: '/mailboxes',
                },
                {
                    title: 'WorkFlows',
                    route: '/mailboxes/workflows',
                },
            ],
        },
        {
            name: 'People',
            title: 'People',
            icon: 'SharedFoldersSmall',
            route: '/people',
            requireAccess: 'ResourceAdmin-People-Page',
            children: [
                {
                    title: 'View All',
                    route: '/people',
                },
                {
                    title: 'WorkFlows',
                    route: '/people/workflows',
                },
            ],
        },
    ]

    return { routeOptions }
}
