import React, { useState } from 'react'
import EidCardList from './EidCardList'
import EidTable from './EidTable'
import { useViewIndicator } from 'packages/eid-ui'
import { useAppState } from 'appContext'
import { useTranslation } from 'react-i18next'
import { ITEMS_VIEW } from 'utils'

const useEidGrid = ({
    isLoading,
    data,
    isLoadingMore,
    noDataMessage = 'No data available',
    pagination,
    attributes,
    cardViewConfig,
    tableViewConfig,
    handleSelectAllClick,
    defaultView = ITEMS_VIEW.LIST,
    rowSelection = false,
    selected = [],
    handleClick,
    selectAllAction = {},
    selectionIdentifier,
    errorMessage = null,
    onSort,
    showSelectAll,
    highLightSelectedRow,
}) => {
    const [state, dispatch] = useAppState()
    const [currentView, setCurrentView] = useState(
        state && state.itemsView ? state.itemsView : defaultView,
    )

    const { t } = useTranslation()

    const { viewIndicator: viewSwitcher } = useViewIndicator({
        view: currentView,
        onCardViewClick: () => {
            setCurrentView(ITEMS_VIEW.CARD)

            dispatch({
                type: 'SET_ITEMS_VIEW',
                payload: ITEMS_VIEW.CARD,
            })
        },
        onListViewClick: () => {
            setCurrentView(ITEMS_VIEW.LIST)

            dispatch({
                type: 'SET_ITEMS_VIEW',
                payload: ITEMS_VIEW.LIST,
            })
        },
        cardViewLabel: t('Card_View_Label'),
        listViewLabel: t('List_View_Label'),
    })

    const viewToRender =
        currentView === ITEMS_VIEW.CARD ? (
            <EidCardList
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                rowSelection={rowSelection}
                handleSelectAllClick={handleSelectAllClick}
                selected={selected}
                handleClick={handleClick}
                selectAllAction={selectAllAction}
                errorMessage={errorMessage}
                {...cardViewConfig}
            />
        ) : (
            <EidTable
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                rowSelection={rowSelection}
                handleSelectAllClick={handleSelectAllClick}
                selected={selected}
                handleClick={handleClick}
                selectAllAction={selectAllAction}
                selectionIdentifier={selectionIdentifier}
                errorMessage={errorMessage}
                onSort={onSort}
                showSelectAll={showSelectAll}
                highLightSelectedRow={highLightSelectedRow}
                {...tableViewConfig}
            />
        )

    return { viewSwitcher, currentView, setCurrentView, viewToRender }
}

export { useEidGrid, EidCardList, EidTable }
