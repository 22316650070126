import { EidButton } from 'packages/eid-ui'
import { Typography, useTheme } from '@mui/material'
import { getURLTarget } from 'packages/core'

const RequestAccessButton = ({
    label,
    redirectURL,
    parentStyle = {},
    ...rest
}) => {
    const theme = useTheme()

    const styles = {
        normal: {
            width: '100%',
            height: '100%',
            backgroundColor: theme?.palette?.primary?.main,
            color: '#fff !important',
            textDecoration: 'none',
            border: `solid 1px ${theme?.palette?.primary?.main}`,
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
            },
        },
        disabled: {
            backgroundColor: '#c5cbda',
            border: 'solid 1px #c5cbda',
            color: '#ffffff !important',
            opacity: '0.8',
        },

        label: {
            fontSize: '11px',
            lineHeight: 'normal',
        },
    }

    return (
        <EidButton
            style={{ ...styles.normal, ...parentStyle }}
            disabledStyles={styles.disabled}
            {...rest}
        >
            <a
                href={redirectURL}
                target={getURLTarget()}
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <Typography style={styles.label}>{label}</Typography>
            </a>
        </EidButton>
    )
}

export default RequestAccessButton
