export const initialState = {
    searchTerm: '',
    tags: null,
    page: 1,
    startDate: null,
    endDate: null,
    sortOrder: null,
    sortBy: null,
    sortLabel: null,
    shouldLoadTags: null,
    workflowsCount: 0,
    masterPasswordWidth: 0,
    masterPasswordLeftWidth: 0,
    ownedBy: null,
    showOnlyAzureApplications: false,
    accountStore: null,
    accountStoreUsageType: null,
    advancedSearch: {},
    rights: null,
    application: null,
    location: null,
    businessFunction: null,
    uncPath: null,
    groupType: null,
    highLevelClassification: null,
    includeBasicClaimSet: null,
    enableOwnedByApiCall: false,
    appRightsFieldTypes:{}
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PROP':
            return {
                ...state,
                page: 1,
                [payload.key]: payload.value,
            }

        case 'SET_MASTER_PASSWORD_WIDTH': {
            return {
                ...state,
                ...payload,
            }
        }
        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                page: 1,
                ...payload,
            }
        case 'SET_TAGS':
            return {
                ...state,
                tags: payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                page: payload,
            }
        case 'CLEAR_FILTERS_SORTING':
            const advanceSearchFormFiltersValue = Object.keys(state)
                .filter(function (k) {
                    return k.indexOf('advancedSearch.forms') === 0
                })
                .reduce(function (newData, k) {
                    newData[k] = null
                    return newData
                }, {})

            return {
                ...state,
                ...advanceSearchFormFiltersValue,
                searchTerm: '',
                sortOrder: null,
                sortBy: null,
                sortLabel: null,
                showOnlyAzureApplications: false,
                accountStore: null,
                accountStoreUsageType: null,
                advancedSearch: {},
                includeBasicClaimSet: null,
            }
        case 'UPDATE_APP_RIGHTS_FIELD_TYPES':
            return {
                ...state,
                appRightsFieldTypes: payload,
            }
        default:
            return state
    }
}

export default reducer
