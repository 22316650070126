const tableAttributes = [
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        label: 'FriendlyName',
        moreInfoIcon: true,
        hideValue: 'true',
        isHeader: true,
        showInCard: false,
        //NOTE : Keeping the commented out code for subcomponent traceability
        // requireAccess: {
        //     control: 'ResourceAdmin-ManagementRole-List-FriendlyName',
        // },
        component: {
            componentName: 'HighlightedClickableLabel',
            queryParam: 'managementRoleId',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
            fontWeight: 'bold',
        },
    },

    {
        name: 'isHighSecurity',
        sortable: true,
        sortBy: 'isHighSecurity',
        label: 'IsHighSecurity',
        component: {
            componentName: 'BooleanValue',
        },

        headerCellStyles: {},
        styles: {
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-ManagementRole-List-IsHighSecurity',
        },
    },
    {
        name: 'managementRoleTypeFriendlyName',
        sortable: true,
        sortBy: 'managementRoleTypeFriendlyName',
        label: 'Type',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-ManagementRole-List-Type',
        },
    },
    {
        name: 'description',
        sortable: true,
        sortBy: 'description',
        label: 'Description',
        headerCellStyles: {
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-ManagementRole-List-ActionButton',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        hideValue: true,
        showInCard: false,
        requireAccess: {
            control: 'ResourceAdmin-ManagementRole-List-Actions',
        },
        component: {
            componentName: 'actionButtons',
        },
    },
]

const detailAttributes = [
    {
        label: 'TechnicalName',
        value: 'name',
    },
    {
        label: 'Type',
        value: 'managementRoleTypeFriendlyName',
    },
    {
        label: 'Description',
        value: 'description',
    },
    {
        label: 'IsHighSecurity',
        value: 'isHighSecurity',
        name: 'isHighSecurity',
        hideValue: true,
        component: {
            componentName: 'BooleanValue',
        },
    },
    {
        label: 'ManagementRoleDefinition',
        value: 'managementRoleDefinitionFriendlyName',
    },
]

const attributes = {
    tableAttributes,
    detailAttributes,
}

export default attributes
