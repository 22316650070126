import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { ListingGrid, GridContainer, Filters } from 'components'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useMailBoxInventoriedPermissions } from 'hooks'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'

const InventoriedPermissions = (props) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useMailBoxInventoriedPermissions(props?.mailBoxId)

    const attributes = [
        {
            sortable: true,
            sortBy: 'isDenied',
            label: 'Denied',
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-InventoriedPermissions-Denied',
            },
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <>
                        <BooleanIcon
                            data={data}
                            attribute={{ name: 'isDenied' }}
                            nullAsFalse={true}
                        />
                    </>
                ),
            },
        },
        {
            sortable: true,
            sortBy: 'isInherited',
            label: 'Inherited',

            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-InventoriedPermissions-Inherited',
            },
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <>
                        <BooleanIcon
                            data={data}
                            attribute={{ name: 'isInherited' }}
                            nullAsFalse={true}
                        />
                    </>
                ),
            },
        },
        {
            label: 'InheritanceType',
            name: 'inheritanceType',
            sortBy: 'inheritanceType',
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-InventoriedPermissions-InheritanceType',
            },
        },
        {
            label: 'AssigneeType',
            name: 'assigneeId', //AssigneeType Missing from backend
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-InventoriedPermissions-AssigneeType',
            },
        },
        {
            name: 'assigneeTechnicalPreviewName',
            label: 'AssigneeTechincalName',
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-InventoriedPermissions-AssigneeTechincalName',
            },
        },
        {
            name: 'friendlyName',
            label: 'Permission',
            sortable: true,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-MailboxDetails-InventoriedPermissions-Permission',
            // },
        },
    ]

    return (
        <ListingGrid>
            <Box display="flex" justifyContent="end">
                <Box width="308px">
                    <Filters.TextSearch
                        outlined
                        placeholder={'SearchAndFilter'}
                    />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default InventoriedPermissions
