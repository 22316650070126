import { Fragment } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useAccessRequestPolicy, useOwnersApproversDeputies } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material';
import DynamicComponents from 'components/DynamicComponents'
import { PeopleListCard } from 'components/PeopleListCard/PeopleListCard'
import { ResponsibleParty } from 'components/ApplicationBanner/ResponsibleParties'
import useSubcomponents from 'useSubcomponents'

const smallScreenWidth = 960
const canSeeOwnersControlName =
    'ResourceAdmin-ManagementRolesDetails-Overview-Owners-Control'
const canSeeApproversControlName =
    'ResourceAdmin-ManagementRolesDetails-Overview-Approvers-Control'
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
    }),
)

export const MainOverview = (props) => {
    const classes = useStyles()
    const { hasAccessToControl } = useSubcomponents()
    const { basicAttributes, detailsLoading, managementRole } = props
    const policy = useAccessRequestPolicy(
        managementRole && managementRole.requestPolicyId,
    )

    const { data: ownersAndApprovers, isLoading: loading } =
        useOwnersApproversDeputies(
            hasAccessToControl(canSeeOwnersControlName) && managementRole
                ? managementRole.id
                : null,
        )

    const { t } = useTranslation()

    const renderComponent = (a, item) => {
        const DynamicComponentToRender =
            DynamicComponents[a.component.componentName]
        return <DynamicComponentToRender attribute={a} data={{ item }} />
    }

    const managementRoleDetails = (
        <Fragment>
            <Box className={classes.basicInfo}>
                {basicAttributes &&
                    basicAttributes.map((att, i) => {
                        return (
                            <Attribute key={att.value + i}>
                                <AttributeLabel>{t(att.label)}</AttributeLabel>
                                <AttributeValue>
                                    {att.component &&
                                        renderComponent(att, managementRole)}
                                    {managementRole &&
                                        !att.hideValue &&
                                        managementRole[att.value]}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
            </Box>
        </Fragment>
    )
    const policyDetails = (
        <Box>
            <Attribute>
                <AttributeLabel>{t('PublishInIAMShop')}</AttributeLabel>
                <AttributeValue>
                    {managementRole?.requestable ? t('Yes') : t('No')}
                </AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('AccessRequestPolicy')}</AttributeLabel>
                <AttributeValue>{policy && policy.name}</AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>
                    {t('AllowsTimeConstrainedAccess')}
                </AttributeLabel>
                <AttributeValue>
                    {policy && policy.isTimeConstrained ? t('Yes') : t('No')}
                </AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('DefaultAccessDuration')}</AttributeLabel>
                <AttributeValue>
                    {policy && policy.defaultValueInMinutes}
                </AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('MaxAccessDuration')}</AttributeLabel>
                <AttributeValue>
                    {policy && policy.maximumValueInMinutes}
                </AttributeValue>
            </Attribute>
        </Box>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box className={classes.section}>{managementRoleDetails}</Box>
                <Box className={classes.section}>{policyDetails}</Box>

                {(hasAccessToControl(canSeeApproversControlName) ||
                    hasAccessToControl(canSeeOwnersControlName)) && (
                    <Box className={classes.section}>
                        {hasAccessToControl(canSeeOwnersControlName) && (
                            <Box
                                data-protectedsubcomponent={
                                    canSeeOwnersControlName
                                }
                            >
                                <Attribute>
                                    <AttributeLabel>
                                        {t('ManagementRoles_Owners')}
                                    </AttributeLabel>
                                    <PeopleListCard
                                        data={ownersAndApprovers?.owners}
                                        isLoading={loading}
                                        pageSize={3}
                                    />
                                </Attribute>
                            </Box>
                        )}
                        {hasAccessToControl(canSeeApproversControlName) && (
                            <Box
                                data-protectedsubcomponent={
                                    canSeeApproversControlName
                                }
                            >
                                <Attribute>
                                    <AttributeLabel>
                                        {t('Approvers')}
                                    </AttributeLabel>
                                    <PeopleListCard
                                        data={ownersAndApprovers?.approvers}
                                        isLoading={loading}
                                        pageSize={3}
                                    />
                                </Attribute>
                            </Box>
                        )}
                    </Box>
                )}
                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel>{t('ResponsibleParty')}</AttributeLabel>
                        {managementRole?.ownerIsPerson ? (
                            <ResponsibleParty
                                responsiblePersonId={managementRole?.ownerId}
                            />
                        ) : (
                            <AttributeValue>
                                {managementRole?.ownerFriendlyName || '-'}
                            </AttributeValue>
                        )}
                    </Attribute>
                </Box>
            </Box>
        </Fragment>
    )

    return (
        <>
            {detailsLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Fragment>
            )}
        </>
    )
}
