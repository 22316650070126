import React, { Fragment, useEffect } from 'react'
import GroupsLayout from './GroupsLayout'
import { useAuthState } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { usePageContext } from 'pageContext'

const allFilters = [
    {
        name: 'OwnedByFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-OwnedBy',
    },

    {
        name: 'targetSystemFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-TargetSystem',
    },
    {
        name: 'applicationsFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-Applications',
    },
    {
        name: 'groupsLocationFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-Location',
    },
    {
        name: 'businessFunctionFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-BusinessFunctions',
    },
    {
        name: 'globalRightsFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-Rights',
    },
    {
        name: 'groupsAdvancedSearch',
        requireAccess: 'ResourceAdmin-Groups-Filter-AdvancedSearch',
    },
]

const RequestViewAllGroups = () => {
    const [, dispatch] = usePageContext()

    const [{ currentUserId, friendlyName }] = useAuthState()

    const { trimControlsBasedOnAccess } = useSubcomponents()

    const filters = trimControlsBasedOnAccess(allFilters)
    const hasOwnedByFilterAccess =
        filters.map((f) => f.name).indexOf('OwnedByFilter') >= 0

    useEffect(() => {
        if (!hasOwnedByFilterAccess) {
            dispatch({
                type: 'SET_MULTIPLE_PROPS',
                payload: {
                    ownedBy: {
                        id: currentUserId,
                        friendlyName: friendlyName,
                    },
                    enableOwnedByApiCall: true,
                },
            })
        } else {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'enableOwnedByApiCall',
                    value: true,
                },
            })
        }
    }, [currentUserId])

    return (
        <Fragment>
            <GroupsLayout filters={filters} />
        </Fragment>
    )
}

export { RequestViewAllGroups }
