import { styled, Box } from '@mui/material'
import { isNilOrEmpty } from 'packages/core'
import { useHistory } from 'react-router-dom'
import { ResourceListingInfoIcon } from './ResourceListingInfoIcon'

const AttributeValue = styled(Box)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: 1.33,
    color: theme?.palette?.primary?.main,
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
}))

const HighlightedClickableLabel = ({ attribute, data }) => {
    const history = useHistory()

    return (
        <Box
            className="font-size-large"
            style={{
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
            }}
            paddingY="10px"
            onClick={() => {
                history.push(
                    `?${attribute.component.queryParam}=${data?.item?.id}`,
                )
            }}
        >
            <AttributeValue margin={'0px'}>
                <div style={{ display: 'flex' }}>
                    {' '}
                    {isNilOrEmpty(data.item[attribute.name])
                        ? '-'
                        : data.item[attribute.name]}
                    {attribute.moreInfoIcon && (
                        <ResourceListingInfoIcon
                            attribute={attribute}
                            data={data}
                        ></ResourceListingInfoIcon>
                    )}
                </div>
            </AttributeValue>
        </Box>
    )
}

export default HighlightedClickableLabel
