import { Box, Card, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import GenericButton from 'components/Button/GenericButton'
import { useTranslation } from 'react-i18next'
import {
    getFullPathFromUrl,
    useIsSmallScreenFalse,
    useQuery,
} from 'packages/core'
import { Fragment } from 'react'
import { useClaimsMappingPolicy } from 'hooks'
import { useHistory } from 'react-router'
import config from 'config'
import useSubcomponents from 'useSubcomponents'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: '16px',
            position: 'unset !important',
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
            borderRadius: '8px !important',
        },
        actionArea: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '35px',
        },
        borderBox: {
            marginBottom: '-16px',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0)) !important',
            borderImageSlice: `1 !important`,
            borderBottom: '1px solid !important',
            opacity: '0.25',
        },
        friendlyName: {
            fontWeight: 'bold',
            fontSize: '14px',
        },
        attributeLabel: {
            fontSize: '12px',
            color: '#9b9b9b',
        },
        attributeValue: {
            fontSize: '14px',
            color: '#000',
        },

        attribute: {
            paddingTop: 4,
            paddingBottom: 4,
            marginRight: '10px',
            width: '50%',
        },
    }),
)

const AddRemoveClaimsControlName =
    'ResourceAdmin-ApplicationDetails-ClaimsMappingPolicy-AddRemoveClaims-Control'

const Policy = ({ policyId, applicationResourceId }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isSmallScreen = useIsSmallScreenFalse()
    const { hasAccessToControl } = useSubcomponents()

    const { data: claimsMappingPolicy, isLoading: loading } =
        useClaimsMappingPolicy(policyId)

    const query = useQuery()
    const history = useHistory()

    const policyTableView = (
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box width="80%">
                <Box display="flex">
                    <Attribute
                        className={classes.attribute}
                        orientation="vertical"
                    >
                        <AttributeLabel className={classes.attributeLabel}>
                            {t('FriendlyName')}
                        </AttributeLabel>
                        <AttributeValue className={classes.attributeValue}>
                            {claimsMappingPolicy?.friendlyName}
                        </AttributeValue>
                    </Attribute>
                    <Attribute
                        className={classes.attribute}
                        orientation="vertical"
                    >
                        <AttributeLabel className={classes.attributeLabel}>
                            {t('TechnicalName')}
                        </AttributeLabel>
                        <AttributeValue className={classes.attributeValue}>
                            {claimsMappingPolicy?.name}
                        </AttributeValue>
                    </Attribute>
                </Box>
                <Box display="flex">
                    <Attribute
                        className={classes.attribute}
                        orientation="vertical"
                    >
                        <AttributeLabel className={classes.attributeLabel}>
                            {t('ResourceSystem')}
                        </AttributeLabel>
                        <AttributeValue className={classes.attributeValue}>
                            {claimsMappingPolicy?.resourceSystemName}
                        </AttributeValue>
                    </Attribute>
                    <Attribute
                        className={classes.attribute}
                        orientation="vertical"
                    >
                        <AttributeLabel className={classes.attributeLabel}>
                            {t('Location')}
                        </AttributeLabel>
                        <AttributeValue className={classes.attributeValue}>
                            {claimsMappingPolicy?.locationName}
                        </AttributeValue>
                    </Attribute>
                </Box>
            </Box>
            {hasAccessToControl(AddRemoveClaimsControlName) && (
                <Box data-protectedsubcomponent={AddRemoveClaimsControlName}>
                    <GenericButton
                        onClick={() => {
                            query.delete('applicationId')
                            query.set('workflowName', 'ManageAzureAppWizard')
                            query.set('returnUrl', getFullPathFromUrl())
                            query.set(
                                'SelectedResourceID',
                                applicationResourceId,
                            )
                            query.set('WizardAction', 'editazapptokenclaims')
                            history.push(
                                `${
                                    config.APP_SUBPATH
                                }/applications/workflows?${query.toString()}`,
                            )
                        }}
                        color={theme?.palette?.primary?.main}
                        rootStylesProp={{
                            borderRadius: '4px',
                            width: '157px',
                            height: '32px',

                            fontSize: '14px',
                            padding: '6px',
                            border: `solid 1px ${theme?.palette?.primary?.main}`,
                        }}
                    >
                        <>{t('AddRemoveClaims')} </>
                    </GenericButton>
                </Box>
            )}
        </Box>
    )

    const policyCardView = (
        <Card className={classes.root}>
            <Attribute style={{ marginTop: 10 }}>
                <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                <AttributeValue>
                    {claimsMappingPolicy?.friendlyName}
                </AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('TechnicalName')}</AttributeLabel>
                <AttributeValue>{claimsMappingPolicy?.name}</AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('Tenant')}</AttributeLabel>
                <AttributeValue>
                    {claimsMappingPolicy?.azureTenantId}
                </AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('Location')}</AttributeLabel>
                <AttributeValue>
                    {claimsMappingPolicy?.locationName}
                </AttributeValue>
            </Attribute>
            {hasAccessToControl(AddRemoveClaimsControlName) && (
                <Box
                    className={classes.actionArea}
                    data-protectedsubcomponent={AddRemoveClaimsControlName}
                >
                    <Box>
                        <AttributeValue>
                            <GenericButton
                                onClick={() => {
                                    query.delete('applicationId')
                                    query.set(
                                        'workflowName',
                                        'ManageAzureAppWizard',
                                    )
                                    query.set('returnUrl', getFullPathFromUrl())
                                    query.set(
                                        'SelectedResourceID',
                                        applicationResourceId,
                                    )
                                    query.set(
                                        'WizardAction',
                                        'editazapptokenclaims',
                                    )
                                    history.push(
                                        `${
                                            config.APP_SUBPATH
                                        }/applications/workflows?${query.toString()}`,
                                    )
                                }}
                                color={theme?.palette?.primary?.main}
                                rootStylesProp={{
                                    borderRadius: '4px',
                                    height: '32px',
                                    width: '157px',
                                    fontSize: '14px',
                                    padding: '6px',
                                    border: `solid 1px ${theme?.palette?.primary?.main}`,
                                }}
                            >
                                <>{t('AddRemoveClaims')} </>
                            </GenericButton>
                        </AttributeValue>
                    </Box>
                </Box>
            )}

            <Box className={classes.borderBox} />
        </Card>
    )

    return <>{isSmallScreen && !loading ? policyCardView : policyTableView}</>
}

export default Policy
