import React, { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import DropdownFilter from './DropdownFilter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import useSubcomponents from 'useSubcomponents'
import { useQuery } from 'packages/core'
import { usePageContext } from 'pageContext'
import config from 'config'
import { SubPathLevel } from 'appConfiguration'
import { useAppState } from 'appContext'

const DropdownMenu = ({
    routeOptions,
    selectedParentRoute,
    onChange,
    totalCount,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const {
        location: { pathname },
    } = history

    const [{ devFeaturesEnabled }] = useAppState()

    const [open, setOpen] = useState(false)
    const pathnames = pathname.split('/')
    const areaName = pathnames[1 + SubPathLevel]
    const view = pathnames[2 + SubPathLevel]
    const [state] = usePageContext()

    const { trimPagesBasedOnAccess } = useSubcomponents()
    const allowedRoutes = trimPagesBasedOnAccess(routeOptions)
    const query = useQuery()
    const applicationId = query.get('applicationId')
    const groupId = query.get('groupId')
    const workflowName = query.get('workflowName')
    return (
        <>
            <DropdownFilter
                label={t(selectedParentRoute?.title)}
                setOpen={setOpen}
                open={open}
                style={{ height: '60px', minWidth: 'fit-content' }}
                anchorPosition={{ top: '60px' }}
                totalCount={
                    view === 'workflows' ? state.workflowsCount : totalCount
                }
                showTotalCount={!groupId && !applicationId && !workflowName}
            >
                {allowedRoutes
                    .filter(
                        (route) => !route.isDevFeature || devFeaturesEnabled,
                    )
                    .map((routeOption, index) => (
                        <DropdownFilter.Item
                            selected={
                                routeOption.route ===
                                `${config.APP_SUBPATH}/${areaName}`
                            }
                            onClick={(e) => {
                                setOpen((prev) => !prev)

                                if (onChange) {
                                    onChange(routeOption.name)
                                    return
                                }

                                let path
                                if (!view) {
                                    path = ''
                                } else {
                                    if (view.toLowerCase() === 'applications') {
                                        path = 'applications'
                                    }
                                }

                                history.push(
                                    path && path.length > 0
                                        ? `${config.APP_SUBPATH}${routeOption.route}/${path}`
                                        : `${config.APP_SUBPATH}${routeOption.route}`,
                                )
                            }}
                            key={index}
                        >
                            {routeOption.icon && (
                                <Icon name={routeOption.icon} color="#919193" />
                            )}
                            {routeOption.title
                                ? t(routeOption.title)
                                : 'routeOption.title'}
                        </DropdownFilter.Item>
                    ))}
            </DropdownFilter>
        </>
    )
}

export { DropdownMenu }
