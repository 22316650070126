import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreenFalse } from 'packages/core'
import { ItemDetails } from 'components'
import ApplicationOwner from '../../DynamicComponents/ApplicationOwner'
import DummyPasswordBox from 'components/DynamicComponents/DummyPasswordBox'
import moment from 'moment'
import MasterPasswordLock from 'components/MasterPasswordLock'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ certificate }) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreenFalse()
        ? 'vertical'
        : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Application')}
                    value={certificate.azureApplicationName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <Box position="relative">
                    <MasterPasswordLock
                        style={{
                            height: '100%',
                            width: useIsSmallScreenFalse() ? '120%' : '110%',
                            marginLeft: '-35px',
                        }}
                    />
                    <ItemDetails.Attribute
                        label={t('PublicKey')}
                        value={
                            <Box display="flex">
                                <DummyPasswordBox
                                    data={{ item: certificate }}
                                    isDetailed
                                />
                            </Box>
                        }
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                </Box>
                <ItemDetails.Attribute
                    label={t('Tenant')}
                    value={certificate.azureTenantName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CreatedBy')}
                    value={
                        certificate && (
                            <div style={{ marginLeft: '2px' }}>
                                <ApplicationOwner
                                    data={{ item: certificate }}
                                />
                            </div>
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CreatedDate')}
                    value={moment
                        .utc(certificate?.createdDateUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('ExpirationDate')}
                    value={moment
                        .utc(certificate?.endDateTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('description')}
                    value={certificate?.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
