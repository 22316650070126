import { useTranslation } from 'react-i18next'
import { useClaimsByClaimsMappingPolicyId, useUnassignClaim } from 'hooks'
import { usePageContext } from 'pageContext'
import { useEffect } from 'react'
import { useModalState } from 'packages/core'
import GenericButton from 'components/Button/GenericButton'
import { WarningModal } from 'packages/eid-ui'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'

const DeleteButton = ({ item, claimMappingPolicyId }) => {
    const { t } = useTranslation()

    const [modalOpen, openModal, closeModal] = useModalState()

    const [unassignClaim, { isLoading: isUnassigning }] = useUnassignClaim(
        claimMappingPolicyId,
        item.id,
    )

    return (
        <>
            <WarningModal
                title={t('Remove') + ' ' + item.friendlyName}
                description={t('DeleteClaimConfirmationMessage')}
                yesLabel={t('Yes')}
                noLabel={t('No')}
                open={modalOpen}
                cancel={closeModal}
                proceeding={isUnassigning}
                proceed={() => {
                    unassignClaim()
                    closeModal()
                }}
            />
            <GenericButton
                onClick={openModal}
                color="#ffffff"
                rootStylesProp={{
                    borderRadius: '4px',
                    height: '32px',
                    fontSize: '14px',
                    padding: '6px',
                    border: 'solid 1px #ff4d5f',
                    color: '#ff4d5f',
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#ff4d5f',
                        color: '#ffffff',
                        cursor: 'pointer',
                    },
                }}
            >
                {t('Remove')}
            </GenericButton>
        </>
    )
}

export const Claims = ({ policyId, queryData }) => {
    const { t } = useTranslation()
    const [, dispatch] = usePageContext()
    const { getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'name',
            label: t('Claim'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Claims-Claim',
            },
        },
        {
            name: 'description',
            label: t('Description'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Claims-Description',
            },
        },
        {
            name: 'jwtClaimType',
            label: t('JWTClaimType'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Claims-JWTClaimType',
            },
        },
        {
            name: 'samlClaimType',
            label: t('SAMLClaimType'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Claims-SAMLClaimType',
            },
        },
        {
            name: 'samlNameFormat',
            label: t('SAMLNameFormat'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Claims-SAMLNameFormat',
            },
        },
        {
            label: '',
            name: 'actions',
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Claims-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <DeleteButton claimMappingPolicyId={policyId} item={item} />
                ),
            },
            styles: {
                textAlign: 'end',
            },
        },
    ]

    const {
        list: claims,
        loading: claimsLoading,
        pagination: claimsPagination,
    } = useClaimsByClaimsMappingPolicyId(policyId, true)

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
    }, [queryData])

    return (
        <>
            <EidTableGrid
                noDataMessage={t('NoClaimsMessage')}
                pagination={claimsPagination}
                isLoading={claimsLoading}
                attributes={getAccessBasedAttributes(attributes)}
                list={claims}
                tableViewOnly
            />
        </>
    )
}
