import { Fragment } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useApplication } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material';
import useSubcomponents from 'useSubcomponents'
import { AzureApplicationOwners } from 'components/ApplicationBanner/AzureApplicationOwners'
import { AzureApplicationDeputies } from 'components/ApplicationBanner/AzureApplicationDeputies'
import { isApplicationAzureApp } from 'utils'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            minHeight: '500px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
        },
        azureApplicationDetailsIdValues: {
            color: theme?.palette?.primary?.main,
        },
    }),
)

const allSections = [
    {
        name: 'applicationDetails',
        requireAccess: 'ResourceAdmin-ApplicationDetails-GeneralDetails-Column',
    },
    {
        name: 'azureApplicationDetails',
        requireAccess:
            'ResourceAdmin-AzureApplicationDetails-GeneralDetails-Column',
    },
]

export const OverviewSection = (props) => {
    const classes = useStyles()

    const { applicationId } = props

    const { data, isLoading } = useApplication(applicationId)

    const { t } = useTranslation()

    const { trimControlsBasedOnAccess } = useSubcomponents()
    const sections = trimControlsBasedOnAccess(allSections).map((f) => f.name)

    const isAzureApplication = isApplicationAzureApp(data?.applicationType)

    const ownersDetails = (
        <Box>
            <Attribute>
                <AttributeLabel>{t('Applications_Owners')}</AttributeLabel>
                <AzureApplicationOwners pageSize={3} data={data} />
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('Deputies')}</AttributeLabel>
                <AzureApplicationDeputies pageSize={3} data={data} />
            </Attribute>
        </Box>
    )

    const azureApplicationDetails = data && (
        <Box>
            <Attribute>
                <AttributeLabel
                    className={classes.azureApplicationDetailsLabel}
                >
                    {t('AzureApplicationDetails')}
                </AttributeLabel>
            </Attribute>
            {data.azureApplicationFriendlyName && (
                <Attribute>
                    <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                    <AttributeValue>
                        {data.azureApplicationFriendlyName}
                    </AttributeValue>
                </Attribute>
            )}

            {data.azureApplicationDescription && (
                <Attribute>
                    <AttributeLabel>{t('Description')}</AttributeLabel>
                    <AttributeValue>
                        {data.azureApplicationDescription}
                    </AttributeValue>
                </Attribute>
            )}

            {data.azureIntegrationType && (
                <Attribute>
                    <AttributeLabel>{t('Type_Of_Integration')}</AttributeLabel>
                    <AttributeValue>{data.azureIntegrationType}</AttributeValue>
                </Attribute>
            )}

            {data.azureApplicationLine && (
                <Attribute>
                    <AttributeLabel>{t('Application_Line')}</AttributeLabel>
                    <AttributeValue>{data.azureApplicationLine}</AttributeValue>
                </Attribute>
            )}

            {data.azureUpxId && (
                <Attribute>
                    <AttributeLabel>{t('Upx_ID')}</AttributeLabel>
                    <AttributeValue
                        className={classes.azureApplicationDetailsIdValues}
                    >
                        {data.azureUpxId}
                    </AttributeValue>
                </Attribute>
            )}

            {data.azureAuthenticationLevel && (
                <Attribute>
                    <AttributeLabel>{t('Authentication_Level')}</AttributeLabel>
                    <AttributeValue>
                        {data.azureAuthenticationLevel}
                    </AttributeValue>
                </Attribute>
            )}

            {data.applicationId && (
                <Attribute>
                    <AttributeLabel>
                        {t('AzureApplicationAppId')}
                    </AttributeLabel>
                    <AttributeValue
                        className={classes.azureApplicationDetailsIdValues}
                    >
                        {data.applicationId}
                    </AttributeValue>
                </Attribute>
            )}

            {data.azureApplicationId && (
                <Attribute>
                    <AttributeLabel>
                        {t('AzureApplicationObjectId')}
                    </AttributeLabel>
                    <AttributeValue
                        className={classes.azureApplicationDetailsIdValues}
                    >
                        {data.azureApplicationId}
                    </AttributeValue>
                </Attribute>
            )}

            {data.servicePrincipleId && (
                <Attribute>
                    <AttributeLabel>{t('ServicePrincipleId')}</AttributeLabel>
                    <AttributeValue
                        className={classes.azureApplicationDetailsIdValues}
                    >
                        {data.servicePrincipleId}
                    </AttributeValue>
                </Attribute>
            )}
        </Box>
    )

    const applicationDetails = (
        <Box className={classes.basicInfo}>
            <Attribute>
                <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                <AttributeValue>{data && data.friendlyName}</AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('TechnicalName')}</AttributeLabel>
                <AttributeValue>{data && data.name}</AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('Description')}</AttributeLabel>
                <AttributeValue>{data && data.description}</AttributeValue>
            </Attribute>
        </Box>
    )

    const showApplicationDetails = sections.includes('applicationDetails')
    const showAzureApplicationDetails =
        sections.includes('azureApplicationDetails') &&
        data &&
        isAzureApplication

    const renderContent = () => (
        <Fragment>
            <Box>
                {showApplicationDetails && (
                    <Box
                        data-protectedsubcomponent={
                            'ResourceAdmin-ApplicationDetails-GeneralDetails-Column'
                        }
                    >
                        {applicationDetails}
                    </Box>
                )}
                {showAzureApplicationDetails && (
                    <Box
                        data-protectedsubcomponent={
                            'ResourceAdmin-AzureApplicationDetails-GeneralDetails-Column'
                        }
                        marginTop="10px"
                    >
                        {azureApplicationDetails}
                    </Box>
                )}
                {data && isAzureApplication && (
                    <Box marginTop="10px">{ownersDetails}</Box>
                )}
            </Box>
        </Fragment>
    )

    return (
        <Paper className={classes.root}>
            {isLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box>{renderContent()}</Box>
                </Fragment>
            )}
        </Paper>
    )
}
