import { Box, Typography } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { EidButton } from 'packages/eid-ui'

export const BulkRemoveButton = ({
    selectedCount,
    label,
    onClick,
    dataProtectedsubcomponent = '',
}) => (
    <Box
        data-protectedsubcomponent={dataProtectedsubcomponent}
    >
        <Box>
            <EidButton.DrawerActionButton
                disabled={selectedCount <= 0}
                fontColor="#ffffff"
                bgColor="#E40000"
                width="100%"
                onClick={onClick}
                loading={false}
                height={'40px'}
            >
                <Box display="flex" alignItems="center">
                    <Icon
                        style={{
                            height: '12px',
                            width: '12px',
                            marginLeft: '10px',
                        }}
                        name="DeleteX"
                        fill="#ffffff"
                        color="#ffffff"
                    />
                </Box>
                <Box display="flex" alignItems="center" marginLeft="10px">
                    <Typography style={{ color: '#ffffff' }}>
                        {label}
                    </Typography>
                </Box>
            </EidButton.DrawerActionButton>
        </Box>
    </Box>
)
