import { ChangeEvent } from 'react'
import { Tabs as MuiTabs } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

export type PillTabsProps = {
    value: string | number
    onChange: (event: ChangeEvent<{}>, newValue: string) => void
}

export const PillTabs = withStyles({
    root: {
        minHeight: '4rem',
        height: '4rem',
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            backgroundColor: 'transparent',
            width: '100%',
        },
    },
})((props: PillTabsProps) => (
    <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />
))
