import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ItemDetails from 'components/ItemDetails'
import { useIsSmallScreenFalse } from 'packages/core'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'
import { Divider } from 'packages/eid-ui'

const useStyles = makeStyles((theme) =>
    createStyles({
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'column',
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                display: 'none',
            },
        },
        content: {
            width: '100%',
            display: 'flex',
            padding: '32px',
            gap: '30px',
            backgroundColor: '#ffffff',
            boxShadow: ' 0 2px 16px 0 rgba(0, 0, 0, 0.11)',
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
    }),
)

const valueStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
    fontSize: '14px',
    width: '50% !important',
}

const lableStyleProps = {
    width: '50% !impotant',
}

const OptionalClaimDetails = ({ additionalProperties, item }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const attributeOrientation = useIsSmallScreenFalse()
        ? 'vertical'
        : 'horizontal'

    let additionalPropertiesValue = ''

    additionalProperties.forEach(
        (property) =>
            (additionalPropertiesValue += `\n${property.friendlyName}`),
    )

    return (
        <Box className={classes.content}>
            <Box className={classes.section}>
                <ItemDetails.Attribute
                    label={t('Claim')}
                    value={item.name || '-'}
                    orientation={attributeOrientation}
                    valueProps={valueStyleProps}
                    labelProps={lableStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('TokenType')}
                    value={item.claimTypeFriendlyName || '-'}
                    orientation={attributeOrientation}
                    valueProps={valueStyleProps}
                    labelProps={lableStyleProps}
                />

                <Divider style={{ margin: '20px 0px' }} />

                <ItemDetails.Attribute
                    label={t('AdditionalProperties')}
                    value={additionalPropertiesValue || '-'}
                    orientation={attributeOrientation}
                    valueProps={valueStyleProps}
                    labelProps={lableStyleProps}
                />
            </Box>
        </Box>
    )
}

export default OptionalClaimDetails
