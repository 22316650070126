import React from 'react'
import { Box, Typography } from '@mui/material'
import { Avatar } from 'packages/eid-ui'
import { imagesDisabled } from 'utils'

const nameStyle = {
    fontSize: '14px',
    color: '#5d6870',
}

const Person = ({ imgSrc, name }) => (
    <Box display="flex" alignItems="center">
        {!imagesDisabled && (
            <Box component="span" paddingRight="8px">
                <Avatar src={imgSrc} />
            </Box>
        )}
        <Typography style={nameStyle}>{name}</Typography>
    </Box>
)

export default Person
