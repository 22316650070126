import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import {
    useDeleteIAMShopAssignees,
    useIAmShopAssignees,
    useRequestAssigneeTypesByResourceTypeId,
} from 'hooks'
import { WarningModal } from 'packages/eid-ui'
import DeleteAssignee from './DeleteAssignee'
import AddIAMShopAssignee from './AddIAMShopAssignee'
import { PageContextProvider } from 'pageContext'
import useSubcomponents from 'useSubcomponents'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Filters } from 'components'

const canAddIAMShopAssigneeName =
    'ResourceAdmin-SharedFolderDetails-IAMShopAssignees-AddAssignee-Control'
const canDeleteIAMShopAssigneeName =
    'ResourceAdmin-SharedFolderDetails-IAMShopAssignees-DeleteAssignee-Control'

const EnforceEligibilityHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',

    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})

export const IAmShopAssignees = ({ sharedFolder, id }) => {
    const { t } = useTranslation()
    const [selected, setSelected] = useState([])
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)

    const [deleteAssignee, { isLoading: isDeletingAssignee }] =
        useDeleteIAMShopAssignees(id)

    const { data: permissionLevels, refetch } =
        useRequestAssigneeTypesByResourceTypeId(
            sharedFolder?.resourceTypeId,
            sharedFolder?.id,
        )

    const handleConfirmDelete = () => {
        deleteAssignee(selectedItemsToDelete.map((x) => x.id)).then(() => {
            setSelectedItemsToDelete(null)
            setSelected([])
            refetch()
        })
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x.id === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x.id !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            setSelected(list)
        }
    }

    const {
        list,
        loading: isLoading,
        isLoadingMore,
        pagination,
    } = useIAmShopAssignees(id)

    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }

    const attributes = [
        {
            label: t('AssigneeTechnicalName'),
            name: 'assigneeTechnicalPreviewName',
            sortable: true,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-SharedFolderDetails-IAMShopAssignees-AssigneeTechnicalName',
            // },
        },
        {
            label: t('IAmShopPermissionLevel'),
            name: 'localizedAccessLevelFriendlyName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignees-PermissionLevel',
            },
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('AccessFullfilledByAssignee'),
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignees-AccessFulfilledByAssignee',
            },
        },
        {
            name: 'azLocalRoleFriendlyName',
            label: t('LocalRole'),
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignees-LocalRole',
            },
        },
        {
            name: 'checkAssigneeEligibility',
            label: t('EnforceAssigneeEligibility'),
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignees-EnforceAssigneeEligibility',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <EnforceEligibilityHeader>
                        <BooleanIcon
                            data={{ item: data }}
                            attribute={{
                                name: 'checkAssigneeEligibility',
                                reverse: false,
                            }}
                        />
                    </EnforceEligibilityHeader>
                ),
            },
        },
        {
            label: t(''),
            name: 'actions',
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignees-Actions',
            },
            resolve: (item) =>
                hasAccessToControl(canDeleteIAMShopAssigneeName) && (
                    <DeleteAssignee
                        applicationId={id}
                        item={item}
                        drawerView={false}
                    />
                ),
            styles: {
                textAlign: 'end',
            },
        },
    ]

    const selectAllAction = {
        render: () => {
            return (
                <BulkRemoveButton
                    selectedCount={selected.length}
                    label={t('RemoveSelectedAssignees', {
                        count: selected.length,
                    })}
                    onClick={handleDeleteBulk}
                />
            )
        },
    }

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                {hasAccessToControl(canAddIAMShopAssigneeName) &&
                permissionLevels?.length ? (
                    <PageContextProvider key="AddNewIAmShopAssignees">
                        <AddIAMShopAssignee
                            refetch={refetch}
                            sharedFolder={sharedFolder}
                        />
                    </PageContextProvider>
                ) : (
                    // Adding empty in order to maintain the UI
                    <Box></Box>
                )}

                <Box width="308px" display="flex-end" alignItems="center">
                    <Filters.TextSearch outlined />
                </Box>
            </Box>

            <EidTableGrid
                selectAllAction={selectAllAction}
                rowSelection
                handleSelectAllClick={handleSelectAllClick}
                selected={selected.map((s) => s.id)}
                handleClick={handleRowSelection}
                noDataMessage={t('NoDataMessage')}
                pagination={pagination}
                isLoading={isLoading}
                isLoadingMore={isLoadingMore}
                attributes={getAccessBasedAttributes(attributes)}
                list={list}
            />
            {selectedItemsToDelete && (
                <WarningModal
                    title={
                        selectedItemsToDelete.length === 1
                            ? `${t('Delete')} ${
                                  selectedItemsToDelete[0]
                                      ?.assigneeTechnicalPreviewName
                              }`
                            : t('DeleteSelectedItems')
                    }
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceed={handleConfirmDelete}
                    proceeding={isDeletingAssignee}
                />
            )}
        </>
    )
}
