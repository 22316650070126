import { useQuery, useMutation, queryCache } from 'react-query'
import { useAxios } from 'packages/core'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import { useNotification } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { usePageContext } from 'pageContext'

export const CURRENT_PERSON_KEY = 'CURRENT_PERSON'
export const PERSON_KEY = 'PERSON'

export const useCurrentPerson = () => {
    const callApi = useAxios()
    return useQuery(
        CURRENT_PERSON_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/people',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
export const usePerson = (personId, enabled) => {
    const callApi = useAxios()
    return useQuery(
        [PERSON_KEY, personId, enabled],
        () =>
            callApi({
                method: 'GET',
                url: `/api/people?personId=${personId}`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
            enabled: enabled,
        },
    )
}

export const useUpdatePersonLocale = () => {
    const callApi = useAxios()
    return useMutation(
        (locale) =>
            callApi({
                method: 'PATCH',
                url: '/api/user/language',
                data: locale,
            }),
        {
            onSuccess: () => {
                window.location.reload()
            },
        },
    )
}

export const useValidateMasterPassword = (password) => {
    const callApi = useAxios()
    return useQuery(
        [PERSON_KEY, password],
        () =>
            callApi({
                method: 'POST',
                url: `/api/common/validateMasterPassword`,
                data: `"${password}"`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
            enabled: password,
        },
    )
}
export const useResourceOwners = (resourceId) => {
    const callApi = useAxios()
    return useQuery(
        [PERSON_KEY, 'OWNERS', resourceId],
        () =>
            callApi({
                method: 'GET',
                url: `api/people/resourceOwners?resourceId=${resourceId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(resourceId),
        },
    )
}
export const useResourceDeputies = (resourceId) => {
    const callApi = useAxios()
    return useQuery(
        [PERSON_KEY, 'DEPUTIES', resourceId],
        () =>
            callApi({
                method: 'GET',
                url: `api/people/resourceDeputies?resourceId=${resourceId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(resourceId),
        },
    )
}

export const useResourceOwnersPaginated = (resourceId) => {
    const callApi = useAxios()
    const [state] = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData = {}
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    return useApiWithInfiniteScroll(
        [`${PERSON_KEY}_OWNERS_PAGINATED`, resourceId, queryData],
        (skip, take) => {
            return callApi({
                url: `api/people/resourceOwners/${resourceId}`,
                method: 'POST',
                data: {
                    skip,
                    take,
                    resourceId,
                    ...queryData,
                },
            })
        },
    )
}

export const useModalSearch = (url, queryFilters, enableApi, showCount) => {
    const callApi = useAxios()
    const queryData = { ...queryFilters }
    return useApiWithInfiniteScroll(
        [`MODAL_SEARCH_KEY`, queryData, url, enableApi],
        (skip) => {
            return callApi({
                url: url,
                method: 'POST',
                data: {
                    skip,
                    ...queryData,
                },
            })
        },
        { enabled: enableApi },
        // using defaultTake param and removing above from data
        queryData?.take ? queryData.take : 5,
        {
            variant: 'rounded',
            siblingCount: 0,
            showPageSelection: false,
            showItemsPerPageSelection: false,
        },
        showCount,
    )
}
export const useOwnersApproversDeputies = (id) => {
    const callApi = useAxios()
    return useQuery(
        [PERSON_KEY, id, 'OWNERS_APPROVERS_DEPUTIES'],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/ownersApprovers?roleId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useUpdatePersonDetails = (refetch) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (peopleDetails) =>
            callApi({
                method: 'PUT',
                url: '/api/people/update',
                data: peopleDetails,
            }),
        {
            onSuccess: (data) => {
                const fieldsUpdateResponse = data?.data
                if (!fieldsUpdateResponse?.length) {
                    showSuccessMessage(t('PeopleDetailsUpdatedSuccessfully'))
                }
                refetch()
            },
            onError: (err) => {
                showWarningMessage(t('PeopleNotUpdated'))
            },
        },
    )
}
