import React from 'react'
import { styled, Box, InputBase } from '@mui/material'
import { isNilOrEmpty } from 'packages/core'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    input: {
        padding: 0,
        color: 'black !important',
    },
}))
const AttributeLabel = styled(Box)({
    fontSize: '12px',
    lineHeight: 1.33,
    color: '#9b9b9b',
    textTransform: 'uppercase',
    minWidth: '204px',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
})

const AttributeValue = styled(Box)({
    fontSize: '12px',
    lineHeight: 1.33,
    color: '#000000',
    width: '100%',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
})

const Attribute = (props) => {
    const { label, value, orientation, labelProps, valueProps, type, length } =
        props
    const classes = useStyles()
    return (
        <Box
            display={orientation === 'vertical' ? 'inherit' : 'flex'}
            paddingY="8.5px"
        >
            <AttributeLabel {...labelProps}>{label}</AttributeLabel>
            <AttributeValue
                marginTop={orientation === 'vertical' ? '12px' : '0px'}
                {...valueProps}
            >
                {type === 'password' ? (
                    <InputBase
                        value={'*'.repeat(length ? length : 5)}
                        type={'password'}
                        classes={{ input: classes.input }}
                        disabled
                    />
                ) : isNilOrEmpty(value) ? (
                    '-'
                ) : (
                    value
                )}
            </AttributeValue>
        </Box>
    )
}

Attribute.defaultProps = {
    orientation: 'horizontal',
}

export default Attribute
