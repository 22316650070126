import { RiskIndicator } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useApplicationSensitiveFunctions } from 'hooks'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Filters } from 'components'
import { SearchMenuBarFilter } from 'components/WrapperComponents/SearchMenuBar'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '80px',
        },
    }),
)

export const LocalSensitiveFunctions = ({ groupId }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'friendlyName',
            label: t('FriendlyName'),
            sortable: true,

            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-Groups-Details-Overview-LocalSensitiveFunctions-List-FriendlyName',
            // },
        },
        {
            name: 'functionType',
            label: t('FunctionType'),
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Overview-LocalSensitiveFunctions-List-FunctionType',
            },
        },
        {
            name: 'description',
            label: t('Description'),
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Overview-LocalSensitiveFunctions-List-Description',
            },
        },
        {
            label: t('RiskLevel'),

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Overview-LocalSensitiveFunctions-List-RiskLevel',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <RiskIndicator
                        label={item.riskLevel}
                        variant={item.riskLevel}
                        className={classes.root}
                    />
                ),
            },
        },
    ]

    const {
        list: sensitiveFunctions,
        loading: sensitiveFunctionsLoading,
        pagination: sensitiveFunctionsPagination,
    } = useApplicationSensitiveFunctions(groupId, Boolean(groupId))

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                padding="1.6rem 3.2rem"
                bgcolor="white"
                borderTop="1px solid #eef0f4"
            >
                <Box style={{ width: '24rem' }}>
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <EidTableGrid
                noDataMessage={t('NoFunctionFound')}
                pagination={sensitiveFunctionsPagination}
                isLoading={sensitiveFunctionsLoading}
                attributes={getAccessBasedAttributes(attributes)}
                list={sensitiveFunctions}
                tableViewOnly
            />
        </>
    )
}
