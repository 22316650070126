import { Fragment, useEffect } from 'react'
import { Box } from '@mui/material'
import { OverviewSection } from './OverviewSection'
import { Skeleton } from '@mui/material'
import DynamicFieldTypesSelector from './RoleFieldTypesInfo'
import { useAppState } from 'appContext'
import { useTranslation } from 'react-i18next'

const AppRoleDefinitionDetails = ({
    isLoading,
    assigneePerson,
    appRoleRight,
    keyForAssignee,
    keyForRoleRightId,
    isRole,
}) => {
    const { t } = useTranslation()
    const [, dispatch] = useAppState()

    useEffect(() => {
        if (assigneePerson) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: assigneePerson[keyForRoleRightId],
                    targetAssigneeFriendlyName:
                        assigneePerson?.[keyForAssignee],
                    targetAssignee_ResourceTypeFriendlyName: t(
                        assigneePerson?.assigneeResourceTypeFriendlyName,
                    ),
                },
            })
            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: assigneePerson[keyForRoleRightId],
                    targetAssigneeFriendlyName:
                        assigneePerson?.[keyForAssignee],
                    targetAssignee_ResourceTypeFriendlyName: t(
                        assigneePerson?.assigneeResourceTypeFriendlyName,
                    ),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assigneePerson, dispatch, keyForAssignee])
    return (
        <Fragment>
            {!assigneePerson ? (
                <Box>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={20}
                        style={{ marginTop: 8 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={20}
                        style={{ marginTop: 8 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={20}
                        style={{ marginTop: 8 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={20}
                        style={{ marginTop: 8 }}
                    />
                </Box>
            ) : (
                <Box>
                    <Box>
                        <OverviewSection
                            isAppRole={isRole}
                            assigneePerson={assigneePerson}
                        />
                    </Box>
                </Box>
            )}
            <DynamicFieldTypesSelector
                isLoading={isLoading}
                assigneePerson={assigneePerson}
                appRoleRight={appRoleRight}
                isAppRole={isRole}
                keyForRoleRightId={keyForRoleRightId}
            />
        </Fragment>
    )
}

export default AppRoleDefinitionDetails
