import React, { useState } from 'react'
import { Divider, Loader } from 'packages/eid-ui'
import {
    MenuItem,
    ListItemText,
    ListItemIcon,
    Paper,
    Typography,
    Box,
    ListSubheader,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useUiActionsByResource } from 'hooks'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { isApplicationAzureApp, isApplicationAzureAppWithPbac } from 'utils'

const useMenuStyles = makeStyles((theme) => ({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
            borderLeft: `2px solid ${theme?.palette?.primary?.main}`,
            '& svg': {
                opacity: 1,
                filter: 'grayscale(0%) ',
                color: 'blue',
            },
        },
    },
    icon: {
        minWidth: '40px !important',
        color: 'black',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    noActionMessage: {
        padding: '6px 30px',
        minHeight: '40px',
        textAlign: 'center',
    },
}))

const useHeaderStyles = makeStyles((theme) => ({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        borderRadius: '4px',
    },
    icon: {
        minWidth: '40px !important',
        color: 'black',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    selected: {
        backgroundColor: `${theme?.palette?.primary?.main} !important`,
        color: '#fff',
    },
}))

const ActionsList = (props) => {
    const {
        resource,
        resourceType,
        activeTab,
        tabVerb,
        tabNoun,
        activeTabLabel,
    } = props
    const classes = useMenuStyles()
    const { t } = useTranslation()

    const query = useQuery()

    const isResourceApplication = resourceType === 'applications'
    const UI_ACTIONS_NOUN = isResourceApplication
        ? 'ResourceAdminApplication'
        : 'ResourceAdmin'

    const isAzureApplication = isApplicationAzureApp(resource?.applicationType)
    const isAzurePbacApplication = isApplicationAzureAppWithPbac(
        resource?.applicationType,
    )
    const UI_ACTIONS_VERB = isResourceApplication
        ? isAzureApplication || isAzurePbacApplication
            ? 'AzureApps'
            : 'Apps'
        : ''
    const tab = activeTab?.charAt(0).toUpperCase() + activeTab.slice(1)

    const isOverviewTab = tab.toLowerCase() === 'overview'

    //This hook to be called in all cases to fetch resource related actions
    const { data: overviewActions, isFetching: overviewActionsLoading } =
        useUiActionsByResource(
            resource.id,
            `${UI_ACTIONS_NOUN}`,
            UI_ACTIONS_VERB,
            true,
        )

    //This hook to be called in case of Azure+Pbac apps to fetch Pbac actions in addition to Azure actions
    const {
        data: pbacOverviewActions,
        isFetching: pbacOverviewActionsLoading,
    } = useUiActionsByResource(
        resource.id,
        `${UI_ACTIONS_NOUN}`,
        'Apps',
        Boolean(isAzurePbacApplication),
    )

    //This hook to be called to fetch actions related to subtabs
    const { data: tabActions, isFetching: tabActionsLoading } =
        useUiActionsByResource(
            resource.id,
            tabNoun ? tabNoun : UI_ACTIONS_NOUN,
            tabVerb ? tabVerb : UI_ACTIONS_VERB,
            Boolean(!isOverviewTab),
        )

    const history = useHistory()

    const handleClick = (name) => {
        query.set('workflowName', name)
        const retunUrl = getFullPathFromUrl() + '&returnedFromWorkflow=true'
        query.set('returnUrl', retunUrl)
        query.set('SelectedResourceID', `${resource.resourceId}`)
        history.push(
            `${
                config.APP_SUBPATH
            }/${resourceType}/workflows?${query.toString()}`,
        )
    }
    const getUniqueActions = () => {
        if (tabVerb === 'Apps') {
            return tabActions?.filter(
                (item) =>
                    !pbacOverviewActions?.some(
                        (action) =>
                            action.requestWorkflowName ===
                            item.requestWorkflowName,
                    ),
            )
        } else {
            return tabActions?.filter(
                (item) =>
                    !overviewActions?.some(
                        (action) =>
                            action.requestWorkflowName ===
                            item.requestWorkflowName,
                    ),
            )
        }
    }
    const isActionExists = () =>
        !(
            overviewActions?.length === 0 &&
            (!Boolean(isAzurePbacApplication) ||
                pbacOverviewActions?.length === 0) &&
            (!Boolean(!isOverviewTab) || tabActions?.length === 0)
        )
    return (
        <>
            <Paper
                style={{
                    borderRadius: '8px',
                    paddingBottom:
                        isResourceApplication && isActionExists()
                            ? '16px'
                            : '0px',
                }}
            >
                {tabActionsLoading ||
                overviewActionsLoading ||
                pbacOverviewActionsLoading ? (
                    <Loader />
                ) : !isActionExists() ? (
                    <Typography className={classes.noActionMessage}>
                        {t('NoActionFound')}
                    </Typography>
                ) : (
                    <>
                        {getUniqueActions()?.length > 0 && (
                            <ListSubheader>{activeTabLabel}</ListSubheader>
                        )}
                        {tabActions &&
                            getUniqueActions()?.map((d, index) => (
                                <MenuItem
                                    key={d.id + index}
                                    onClick={() =>
                                        handleClick(d.requestWorkflowName)
                                    }
                                    classes={classes}
                                >
                                    <ListItemIcon className={classes.icon}>
                                        <Icon name="Arrow" direction="right" />
                                    </ListItemIcon>
                                    <ListItemText sx={{ whiteSpace: 'normal' }}>
                                        {d.friendlyName}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        {overviewActions?.length > 0 &&
                            isResourceApplication && (
                                <ListSubheader>
                                    {t(
                                        isAzureApplication ||
                                            isAzurePbacApplication
                                            ? 'Azure'
                                            : 'PBAC',
                                    )}
                                </ListSubheader>
                            )}

                        {overviewActions &&
                            overviewActions.map((d, index) => (
                                <MenuItem
                                    key={d.id + index}
                                    onClick={() =>
                                        handleClick(d.requestWorkflowName)
                                    }
                                    classes={classes}
                                >
                                    <ListItemIcon className={classes.icon}>
                                        <Icon name="Arrow" direction="right" />
                                    </ListItemIcon>
                                    <ListItemText sx={{ whiteSpace: 'normal' }}>
                                        {d.friendlyName}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        {isAzurePbacApplication &&
                            pbacOverviewActions?.length > 0 && (
                                <ListSubheader>{t('PBAC')}</ListSubheader>
                            )}

                        {pbacOverviewActions &&
                            pbacOverviewActions.map((d, index) => (
                                <MenuItem
                                    key={d.id + index}
                                    onClick={() =>
                                        handleClick(d.requestWorkflowName)
                                    }
                                    classes={classes}
                                >
                                    <ListItemIcon className={classes.icon}>
                                        <Icon name="Arrow" direction="right" />
                                    </ListItemIcon>
                                    <ListItemText sx={{ whiteSpace: 'normal' }}>
                                        {d.friendlyName}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                    </>
                )}
            </Paper>
        </>
    )
}

const LeftPaneActions = (props) => {
    const {
        resource,
        resourceType,
        activeTab,
        tabVerb,
        tabNoun,
        activeTabLabel,
    } = props
    const classes = useHeaderStyles()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const header = (
        <Paper style={{ marginBottom: '5px' }}>
            <MenuItem
                classes={classes}
                selected={open}
                onClick={() => {
                    setOpen((prev) => !prev)
                }}
            >
                <ListItemIcon className={classes.icon}>
                    <Icon name="Actions" color={open ? 'white' : 'black'} />
                </ListItemIcon>
                <ListItemText>{t('Actions')}</ListItemText>

                <Typography style={{ display: 'inherit' }}>
                    <Icon
                        name="Arrow"
                        direction="down"
                        color={open ? 'white' : 'black'}
                    />
                </Typography>
            </MenuItem>
        </Paper>
    )

    return (
        <>
            {header}
            {open && (
                <>
                    <ActionsList
                        resource={resource}
                        resourceType={resourceType}
                        activeTab={activeTab}
                        tabVerb={tabVerb}
                        tabNoun={tabNoun}
                        activeTabLabel={activeTabLabel}
                    ></ActionsList>
                </>
            )}
        </>
    )
}

export default LeftPaneActions
