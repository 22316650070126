import { Typography, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { ASSIGNMENT_TYPES } from 'utils'

const useStyles = makeStyles(() => ({
    drawer: {
        '& .MuiDrawer-paper': {
            backgroundColor: '#fbfbfd',
            top: '55px !important',
            '@media (max-width:768px)': {
                width: '100%',
            },
        },
    },
    roleReceiverInfoIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    roleReceiverMain: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: ' 0 4px 17px 0 rgba(0, 0, 0, 0.07)',
        padding: '16px 32px',
    },
    roleReceiverIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: '64px',
            height: '64px',
        },
    },
    roleReceiver: {
        textTransform: 'uppercase',

        '@media (max-width:768px)': {
            padding: '16px 16px',
        },
    },
    roleReceiverDivider: {
        height: '1px',
        backgroundColor: '#d8d8dd;',
    },
    lineManagerContainer: {
        background: '#ffffff',
        width: '100%',
        borderTop: '1px solid #d8d8dd',
        boxShadow: ' 0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
    lineManager: {
        background: '#ffffff',
        padding: '24px 30px',
        maxWidth: '480px',

        '@media (max-width:768px)': {
            padding: '12px 16px',
        },
    },
    box: {
        padding: '25px 30px',
        backgroundColor: '#fbfbfd',
        width: '100%',
        borderTop: '1px solid #d8d8dd',
        boxShadow: ' 0 4px 17px 0 rgba(0, 0, 0, 0.07)',

        '@media (max-width:768px)': {
            padding: '25px 16px',
        },
    },
    formContainer: {
        display: 'flex',

        '@media (max-width:768px)': {
            flexDirection: 'column',
        },
    },
    requestName: {
        flex: 0.75,
        paddingRight: '8px',

        '@media (max-width:768px)': {
            paddingRight: 0,
        },
    },
    dueDate: {
        flex: 0.25,
        paddingLeft: '8px',

        '@media (max-width:768px)': {
            paddingLeft: 0,
            maxWidth: '200px',
        },
    },
    cartComment: {
        marginTop: '8px',

        '@media (max-width:768px)': {
            flexDirection: 'column',
            marginTop: '24px',
        },
    },
}))

const MyContainer = withStyles({
    root: {
        backgroundColor: '#fbfbfd',
        padding: '8px 32px',

        '@media (max-width:768px)': {
            padding: '8px 16px',
        },
    },
})(Container)

const MyTypography = withStyles({
    root: { overflowWrap: 'anywhere' },
    h1: {
        color: '#000000',
        fontSize: '24px',
        padding: '14px 30px 9px 30px',
        fontWeight: 'normal',
    },
    h2: {
        fontSize: '50px',
        '& span': {
            width: '330px',
            display: 'inline-block',
        },
        '& b': {
            fontWeight: '700',
        },
    },
    h3: {
        fontSize: '18px',
        fontWeight: '500px',
        color: '#FFF',
    },
    h5: {
        fontSize: '30px',
    },

    caption: {
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#8a8989',
    },
})(Typography)

const useCartItemStyles = makeStyles((theme) => ({
    cartItem: {
        margin: '36px 0',
        position: 'relative',
        overflow: 'visible',
        borderRadius: '8px !important',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
    },
    borderBox: {
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0)) !important',
        borderImageSlice: `${1} !important`,
        borderBottom: '1px solid !important',
        opacity: '0.25',
    },
    riskBorderBox: {
        borderImageSource:
            'linear-gradient(to right, rgba(208, 2, 27, 0), #d0021b 8%, #d00238 91%, rgba(208, 2, 69, 0)) !important',
        borderImageSlice: `${1} !important`,
        borderBottom: '1px solid !important',
        opacity: '0.25',
    },

    cartItemHeader: ({ bgColor }) => ({
        background: bgColor,
        padding: '16px',
        position: 'relative',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& svg.delete': {
            color: '#d0021b',
        },
    }),
    deleteIconContainer: {
        backgroundColor: '#faf7f6',
        borderRadius: '8px',
        height: '32px',
        width: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#d8d8dd',
        },
    },
    itemLabel: {
        position: 'absolute',
        top: '-14px',
        display: 'flex',

        '@media (max-width:768px)': {
            left: '-8px',
        },
    },

    addOrRemoveLabel: ({ assignmentType }) => ({
        backgroundImage:
            assignmentType === ASSIGNMENT_TYPES.ADD ||
            assignmentType === ASSIGNMENT_TYPES.UPDATE_TIME_CONSTRAIN
                ? 'linear-gradient(101deg, #01ae8f, #30c17c)'
                : 'linear-gradient(102deg, #ae0142, #c14830)',
        borderRadius: '5px',
        padding: '2px 8px',
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
    }),
    resourceTypeLabel: {
        marginLeft: '2rem',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        border: '1px solid #aab0b4',
        padding: '4px 10px',
        textTransform: 'uppercase',
        color: '#3b454d',
        fontSize: '12px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    cartItemContent: {
        padding: '16px 16px',
    },
    cartCommentContainer: {
        display: 'flex',
        marginBottom: '10px',
        padding:'0 1.6rem',
        '@media (max-width:660px)': {
            flexDirection: 'column',
        },
    },
    cartComment: {
        flex: 0.75,
        marginTop: '-8px',
    },
    timeConstraints: {
        flex: 0.25,
        marginLeft: '16px',
        marginRight: '16px',

        '@media (max-width:660px)': {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    moreAttributes: {
        '@media (max-width:660px)': {
            marginTop: '8px',
        },
    },

    horizontalOrVertical: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& > div:last-child': {
            marginLeft: '16px',
        },

        '@media (max-width:660px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            '& > div:last-child': {
                marginLeft: 0,
            },
        },
    },
    additionalField: {
        display: 'flex',
        flexDirection: 'column',
    },
   
    additionalFieldWrapper:{
        padding:'0 1.6rem',
    },
    fieldHeaderTitle: {
        background:
            'repeating-linear-gradient(#fff, #fff 3px, rgba(0, 0, 0, 0.02) 3px, rgba(0, 0, 0, 0.02) 4px)',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '0.1rem solid #efeff1',
        padding:'0 1.6rem'

    },
    expandButton: {
        textTransform: 'uppercase',
        zIndex: 9999,
        marginTop: '0.2rem',
        color: '#307fc1',
        fontSize:'1.2rem',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    expandIcon: {
        width: '1.8rem',
        height: '1.8rem',
        marginLeft: '0.8rem',
        marginTop: '-0.2rem',
        borderRadius: '50%',
        border: `0.1rem solid ${theme?.palette?.primary?.main}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme?.palette?.primary?.main,
        lineHeight:'0',
        fontSize:'1.5rem'

    },
    cartItemContentWithFieldTypes: {
        paddingBottom: '0 !important',
        paddingLeft:'0 !important',
        paddingRight:'0 !important',
    },
    fieldTypeAttribute: {
        '& div': {
            fontSize: '1.4rem',
            fontWeight: '600',
            color: theme?.palette?.common?.black,
        }
    },
    darkBg: {
        marginLeft: '-1rem',
        background: '#3b454d',
        color: theme?.palette?.common?.white,
        borderColor:  '#3b454d',
        zIndex:'9',
        paddingLeft:'2rem',
    }
   
}))

const useShowMoreButtonStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        bottom: '0px',
        right: '5px',
        minWidth: '120px',
        minHeight: '32px',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        color: theme?.palette?.primary?.main,
        textTransform: 'uppercase !important',
        borderRadius: '16px',
        fontSize: '11px',
        lineHeight: '1.45',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: theme?.palette?.primary?.main,
            '& span': {
                color: theme?.palette?.primary?.main,
            },
        },
    },
}))

export {
    useStyles,
    MyContainer,
    MyTypography,
    useCartItemStyles,
    useShowMoreButtonStyles,
}
