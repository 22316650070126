import { Box, Paper } from '@mui/material'
import { useAccessRequestPolicy } from 'hooks'
import { useIsSmallScreenFalse } from 'packages/core'
import { MainAccessRequestPolicyView } from './MainAccessRequestPolicyView'

export const AccessRequestPolicy = ({
    resourceId,
    accessRequestAttributes,
    requestPolicyId,
    refetch,
}) => {
    const data = useAccessRequestPolicy(requestPolicyId)

    const isSmallScreen = useIsSmallScreenFalse()

    return (
        <Box>
            <Paper
                style={{
                    padding: isSmallScreen ? '2.3rem' : '3.2rem',
                    paddingBottom: 0,
                }}
            >
                <MainAccessRequestPolicyView
                    acccessRequestPolicy={data}
                    detailsLoading={!data}
                    accessRequestAttributes={accessRequestAttributes}
                    resourceId={resourceId}
                    refetch={refetch}
                />
            </Paper>
        </Box>
    )
}

AccessRequestPolicy.defaultProps = {
    accessRequestAttributes: {
        general: [
            {
                labelKey: 'FriendlyName',
                value: 'friendlyName',
            },
            {
                labelKey: 'DisplayName',
                value: 'name',
            },
            {
                labelKey: 'Description',
                value: 'description',
            },
            {
                labelKey: 'AllowAccessRequests',
                value: 'isRequestAllowed',
            },
            {
                labelKey: 'ApprovalPolicy',
                value: 'approvalPolicy',
            },
            {
                labelKey: 'FullfillmentDelay(Hrs)',
                value: 'delay',
            },
        ],
        timeRestriction: [
            {
                labelKey: 'TimeRestrictAccess',
                value: 'isTimeConstrained',
            },
            {
                labelKey: 'DefaultAccessDuration(Min)',
                value: 'defaultValueInMinutes',
            },
            {
                labelKey: 'MaxDuration(Min)',
                value: 'maximumValueInMinutes',
            },
            {
                labelKey: 'AllowDurationSelection',
                value: 'isDurationSelectable',
            },
        ],
    },
}
