import { Box, Paper, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { Fragment } from 'react'
import { smallScreenWidth } from 'utils'
import moment from 'moment'
import { PersonDetails } from 'components/ApplicationBanner/PersonDetails'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: 'auto',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        attribute: {
            marginTop: '-8px',
        },
        attributeLabel: {
            minWidth: '265px',
        },
        attributeValue: {
            wordBreak: 'unset',
        },
    }),
)

export const OverviewSection = ({ assigneePerson, isAppRole }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const bindAssigneeName = (data) => {
        try {
            if (!isAppRole && data?.value) {
                const refinedAssignee = data?.assignee?.split(data?.value + ':')
                return refinedAssignee?.length > 1
                    ? refinedAssignee[1]
                    : refinedAssignee[0]
            }
        } catch (err) {}
        return data?.assigne
    }

    const attributes = [
        {
            label: 'Role',
            value: 'role',
            show: isAppRole,
        },
        {
            label: 'AppRight',
            value: 'appRightFriendlyName',
            show: !isAppRole,
        },
        {
            label: 'Assignee',
            show: true,
            render: (data) => (
                <>
                    {data?.isPersonAssignee ? (
                        <PersonDetails
                            size="large"
                            person={{
                                friendlyName: bindAssigneeName(data),
                                email: data?.email,
                                telephone: data?.telephone,
                                imageThumbUrl: data?.imageThumbUrl,
                            }}
                        />
                    ) : (
                        bindAssigneeName(data)
                    )}
                </>
            ),
        },
        {
            label: 'RightType',
            value: 'rightType',
            show: !isAppRole,
        },
        {
            label: 'AssignedTo',
            value: 'assignedTo',
            show: true,
        },
        {
            label: 'ResourceSystem',
            value: 'resourceSystem',
            show: !isAppRole,
        },
        {
            label: 'Tenant',
            value: 'tenant',
            show: isAppRole,
        },
        {
            label: 'EndDate',
            value: 'endDateUtc',
            show: isAppRole,
            render: (data) => (
                <Typography style={{ fontSize: '13px', fontWeight: 'normal' }}>
                    {data?.endDate
                        ? moment.utc(data.endDate).local().format('lll')
                        : '-'}
                </Typography>
            ),
        },
    ]

    const renderContent = (
        <Fragment>
            <Box className={classes.basicInfo}>
                {attributes &&
                    attributes
                        .filter((item) => item.show)
                        .map((att, i) => {
                            return (
                                <Attribute
                                    className={classes.attribute}
                                    orientation="horizontal"
                                    key={att.value + i}
                                >
                                    <AttributeLabel
                                        className={classes.attributeLabel}
                                    >
                                        {t(att.label)}
                                    </AttributeLabel>
                                    <AttributeValue
                                        className={classes.attributeValue}
                                    >
                                        {att.render
                                            ? att.render(assigneePerson)
                                            : assigneePerson &&
                                              assigneePerson[att.value]}
                                    </AttributeValue>
                                </Attribute>
                            )
                        })}
            </Box>
        </Fragment>
    )
    return (
        <>
            <Paper
                style={{
                    padding: '32px',
                    width: 'fit-content',
                    minWidth: '100%',
                }}
            >
                <Box className={classes.section}>{renderContent}</Box>
            </Paper>
        </>
    )
}
