import { Fragment } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'
import DynamicComponents from 'components/DynamicComponents'

const useStyles = makeStyles((theme) =>
    createStyles({
        section: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            width: '100%',
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                display: 'none',
            },
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
    }),
)

export const AttributeSections = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const {
        data,
        attributes,
        labelClassName,
        valueClassName,
        isLoading,
        orientation = 'vertical',
    } = props

    const renderComponent = (a, item) => {
        if (a.component.componentName === 'renderer') {
            return <Fragment>{a.component.render(item)}</Fragment>
        } else {
            const DynamicComponentToRender =
                DynamicComponents[a.component.componentName]
            return <DynamicComponentToRender attribute={a} data={{ item }} />
        }
    }

    return (
        <>
            {isLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Box className={classes.content}>
                    {attributes.map((section, si) => {
                        return (
                            <Box
                                key={`section_${si}`}
                                className={classes.section}
                            >
                                {section.map((att, ai) => {
                                    return (
                                        <Attribute
                                            orientation={orientation}
                                            key={`section${si}_attributes_${ai}_${att.label}`}
                                        >
                                            <AttributeLabel
                                                className={labelClassName}
                                            >
                                                {t(att.label)}
                                            </AttributeLabel>
                                            <AttributeValue
                                                className={valueClassName}
                                            >
                                                {data
                                                    ? att.component
                                                        ? renderComponent(
                                                              att,
                                                              data,
                                                          )
                                                        : data[att.name]
                                                    : '-'}
                                            </AttributeValue>
                                        </Attribute>
                                    )
                                })}
                            </Box>
                        )
                    })}
                </Box>
            )}
        </>
    )
}
