import { Box, useTheme } from '@mui/material'
import { Button } from 'components'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import { ApplicationTypes } from 'utils'
import { PageContextProvider } from 'pageContext'
import EditAssignmentsWithFieldTypes from 'components/Popups/EditAssignmentsWithFieldTypes'
import DialogHeader from 'components/Popups/EditAssignmentsWithFieldTypes/DialogHeader'
const AssignUnAssignAppRight = ({
    roleId,
    item,
    applicationType,
    resourceId,
    resourceSystemModuleID,
}) => {
    const { t } = useTranslation()
    const query = useQuery()
    const theme = useTheme()
    const applicationId = query.get('applicationId')

    const history = useHistory()

    const [openAssigneeModal, setOpenAssigneeModal] = useState(false)

    const handleButtonClick = () => {
        query.delete('applicationId')
        query.delete('appRoleId')
        query.set('workflowName', 'AssignAZLocalRightScope')
        query.set('returnUrl', getFullPathFromUrl())
        query.set('SelectedResourceID', resourceId)
        history.push(
            `${config.APP_SUBPATH}/applications/workflows?${query.toString()}`,
        )
    }
    return (
        <Fragment>
            {applicationType ===
            ApplicationTypes.PbacHasAppResourcesWithoutFieldType ? (
                <Button
                    onClick={handleButtonClick}
                    style={{
                        borderRadius: '4px',
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                        fontSize: '14px',
                        height: '100%',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                >
                    <Box style={{ display: 'flex' }}>
                        {t(item?.localRightAssigned ? 'UnAssign' : 'Assign')}
                    </Box>
                </Button>
            ) : (
                <PageContextProvider
                    key={'ApplicationRightsPeopleEditAssignmentWithFieldType'}
                >
                    <DialogHeader
                        labelKey={'Edit'}
                        headerText={{
                            dynamicText: item?.friendlyName,
                            text: 'AssignRights',
                        }}
                        setShowAssignmentModal={setOpenAssigneeModal}
                        showAssignmentModal={openAssigneeModal}
                    >
                        <EditAssignmentsWithFieldTypes
                            showAssignmentModal={openAssigneeModal}
                            handleClose={() => setOpenAssigneeModal(false)}
                            resourceType={'Applications'}
                            assignedUrl={`api/LocalRights/appRoles/membersByApplicationID`}
                            unAssignedUrl={`api/LocalRights/GetEligibleAppRightsByApplicationByAssignee`}
                            selectedApplicationId={applicationId}
                            searchPlaceHolder="SearchRights"
                            assignedQueryParams={{
                                resourceSystemModuleID: resourceSystemModuleID,
                                assigneeId: item?.id,
                                localRightId: roleId,
                            }}
                            unAssignedQueryParams={{
                                applicationId,
                                eligibilityAssigneeId: item?.id,
                                localRightId: roleId,
                            }}
                            showAccessRequestPolicy={true}
                            fetchRequestPolicyItemWise={true}
                            showStartDate={false}
                            fieldTypeProps={{
                                assignee: item,
                                isRole: false,
                                keyForRoleRightId: 'localRightId',
                            }}
                            requireRemappingInAssignedList={true}
                            newMappingForAssignedList={{
                                friendlyName: 'appRightFriendlyName',
                                name: 'appRightName',
                            }}
                        />
                    </DialogHeader>
                </PageContextProvider>
            )}
        </Fragment>
    )
}

export default AssignUnAssignAppRight
