import React from 'react'
import { Box, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Spinner } from 'packages/eid-ui'
import classNames from 'classnames'

const useStyles = makeStyles({
    link: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    disabledLink: {
        '&:hover': {
            cursor: 'default !important',
        },
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        border: 'none',
        opacity: 1,
        padding: '10px 16px',
    },
    disabledRoot: {
        opacity: 0.7,
    },
    label: {
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: 'normal',
    },
})

const CartButton = (props: any) => {
    const { label, icon, onClick, loading, disabled, bgcolor } = props

    const classes = useStyles()
    return (
        <Link
            component="button"
            className={classNames(classes.link, {
                [classes.disabledLink]: disabled,
            })}
            underline="none"
            onClick={onClick}
            disabled={disabled}
        >
            <Box
                className={classNames(classes.root, {
                    [classes.disabledRoot]: disabled,
                })}
                bgcolor={bgcolor}
            >
                <Box display={'flex'} alignItems={'center'} marginRight="8px">
                    {icon}
                </Box>

                <Typography className={classes.label}>{label}</Typography>

                {loading && (
                    <Box marginLeft={'8px'}>
                        <Spinner size={0.6} color={'#ffffff'} />
                    </Box>
                )}
            </Box>
        </Link>
    )
}

export default CartButton
