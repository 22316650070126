import { useTranslation } from 'react-i18next'
import {
    useApplicationsByClaimsMappingPolicyId,
    useUnassignApplication,
} from 'hooks'
import { useModalState } from 'packages/core'
import GenericButton from 'components/Button/GenericButton'
import { WarningModal } from 'packages/eid-ui'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'

const UnassignButton = ({ item, claimMappingPolicyId }) => {
    const { t } = useTranslation()

    const [modalOpen, openModal, closeModal] = useModalState()

    const [unassignApplication, { isLoading: isUnassigning }] =
        useUnassignApplication(claimMappingPolicyId, item.id)

    return (
        <>
            <WarningModal
                title={t('Unassign') + ' ' + item.friendlyName}
                description={t('UnassignApplicationConfirmationMessage')}
                yesLabel={t('Yes')}
                noLabel={t('No')}
                open={modalOpen}
                cancel={closeModal}
                proceeding={isUnassigning}
                proceed={() => {
                    unassignApplication()
                    closeModal()
                }}
            />
            <GenericButton
                onClick={openModal}
                color="#ffffff"
                rootStylesProp={{
                    borderRadius: '4px',
                    height: '32px',
                    fontSize: '14px',
                    padding: '6px',
                    border: 'solid 1px #ff4d5f',
                    color: '#ff4d5f',
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#ff4d5f',
                        color: '#ffffff',
                        cursor: 'pointer',
                    },
                }}
            >
                {t('UnassignClaimPolicy')}
            </GenericButton>
        </>
    )
}

export const Applications = ({ policyId }) => {
    const { t } = useTranslation()

    const { getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'friendlyName',
            label: t('FriendlyName'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Applications-FriendlyName',
            },
        },
        {
            name: 'name',
            label: t('TechnicalName'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Applications-TechnicalName',
            },
        },
        {
            name: 'azureApplicationId',
            label: t('ApplicationID'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Applications-ApplicationID',
            },
        },
        {
            name: 'ownerFriendlyName',
            label: t('ResponsibleParty'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Applications-ResponsibleParty',
            },
        },
        {
            name: 'id',
            label: t('TypeOfIntegration'),
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Applications-TypeOfIntegration',
            },
        },
        {
            label: '',
            hideValue: true,
            name: 'actions',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-ClaimMapping-Overview-Applications-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <UnassignButton
                        claimMappingPolicyId={policyId}
                        item={item}
                    />
                ),
            },
            styles: {
                textAlign: 'end',
            },
        },
    ]

    const {
        list: applications,
        loading: applicationsLoading,
        pagination: applicationsPagination,
    } = useApplicationsByClaimsMappingPolicyId(policyId, true)

    return (
        <>
            <EidTableGrid
                noDataMessage={t('NoApplicationsMessage')}
                pagination={applicationsPagination}
                isLoading={applicationsLoading}
                attributes={getAccessBasedAttributes(attributes)}
                list={applications}
                tableViewOnly
            />
        </>
    )
}
