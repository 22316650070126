import React, { useEffect } from 'react'
import { useQuery, useIsSmallScreenFalse } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper } from '@mui/material'
import { useApplicationWorkflows } from 'hooks/applicationsHooks'
import { Loader, WorkflowIFrame } from 'packages/eid-ui'
import { useHistory } from 'react-router'
import { usePageContext } from 'pageContext'
import { MobileStickyContentListing, Button, Filters } from 'components'
import StickyContainer from 'components/StickyContainer'
import './workFlow.css'
import config from 'config'
import WorkFlowsItems from 'containers/Workflows/WorkflowItems.tsx'
import { SubPathLevel } from 'appConfiguration'

const Workflows: React.FC<any> = (props) => {
    const { noun, verb } = props
    const [state, dispatch] = usePageContext() as any
    const { t } = useTranslation()
    const query = useQuery()
    const workflowName = query.get('workflowName')
    const resourceID = query.get('SelectedResourceID')
    const wizardAction = query.get('WizardAction')
    const targetAssigneeId = query.get('TargetAssigneeId')
    const returnUrl = query.get('returnUrl')
    const selectedApplicationID = query.get('SelectedApplicationID')
    const selectedAssigneeID = query.get('SelectedAssigneeID')
    const assigneeLocalRoleScopeID = query.get('AzAssigneeLocalRoleScopeID')
    const assigneeLocalRightScopeID = query.get('AzAssigneeLocalRightScopeID')
    const azGlobalRightID = query.get('AzGlobalRightID')

    const history = useHistory()

    const { data, isLoading } = useApplicationWorkflows(noun, verb)
    useEffect(() => {
        if (data) {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'workflowsCount',
                    value: data.length,
                },
            })
        }
    }, [data])

    const isSmallScreen = useIsSmallScreenFalse()

    const wfParams: any = []
    if (resourceID) {
        wfParams.push(`SelectedResourceID=${resourceID}`)
    }
    if (wizardAction) {
        wfParams.push(`WizardAction=${wizardAction}`)
    }
    if (selectedApplicationID) {
        wfParams.push(`SelectedApplicationID=${selectedApplicationID}`)
    }
    if (selectedAssigneeID) {
        wfParams.push(`SelectedAssigneeID=${selectedAssigneeID}`)
    }
    if (targetAssigneeId) {
        wfParams.push(`TargetAssigneeId=${targetAssigneeId}`)
    }
    if (assigneeLocalRoleScopeID) {
        wfParams.push(`AzAssigneeLocalRoleScopeID=${assigneeLocalRoleScopeID}`)
    }
    if (assigneeLocalRightScopeID) {
        wfParams.push(
            `AzAssigneeLocalRightScopeID=${assigneeLocalRightScopeID}`,
        )
    }
    if (azGlobalRightID) {
        wfParams.push(`AzGlobalRightID=${azGlobalRightID}`)
    }

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const MobileSubHeader = (
        <>
            <MobileStickyContentListing
                totalCount={state.workflowsCount}
                title={areaName}
                subTitle={routeName}
                showSearch={false}
                handleApplyFilters={''}
            />
        </>
    )

    const MobileHeaderButton = (
        <StickyContainer
            color="#ffffff"
            styles={{
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Box paddingY="10px" width="100%" maxWidth="260px">
                    <Button.BackButton
                        onClick={() => {
                            query.delete('workflowName')
                            history.push(
                                returnUrl
                                    ? returnUrl
                                    : history.location.pathname,
                                query.toString(),
                            )
                        }}
                    >
                        {t('BackToWorkflows')}
                    </Button.BackButton>
                </Box>
            </Box>
        </StickyContainer>
    )

    if (workflowName) {
        return (
            <>
                {isSmallScreen ? MobileHeaderButton : <></>}
                <Box className="iframe">
                    <WorkflowIFrame
                        baseEidUrl={config.BASE_EID_URL}
                        workflowName={workflowName}
                        onComplete={() => {
                            query.delete('workflowName')
                            history.push(
                                returnUrl
                                    ? returnUrl
                                    : history.location.pathname,
                                query.toString(),
                            )
                        }}
                        workflowParams={wfParams}
                        height={'80vh'}
                        loadingMessage={t('WorkflowLoadingMessage')}
                    />
                </Box>
            </>
        )
    }
    const WorkflowSearchBox = (
        <Grid container style={{ justifyContent: 'start', gap: '24px' }}>
            <Grid
                className="workflowsearchgrid hideonlowresolution"
                md={4}
                xs={12}
                spacing={2}
            />
            <Grid className="workflowsearchgrid" md={4} xs={12} spacing={2} />
            <Grid className="workflowsearchgrid" md={4} xs={12} spacing={2}>
                <Filters.TextSearch />
            </Grid>
        </Grid>
    )
    return (
        <>
            {isSmallScreen ? MobileSubHeader : <></>}
            <div
                style={{
                    width: '100%',
                    paddingBottom: '2.5rem',
                }}
            >
                <Paper
                    className={'root workflow'}
                    style={{
                        paddingTop: '0',
                        marginTop: '2.8rem',
                    }}
                >
                    {WorkflowSearchBox}
                    <Grid
                        container
                        style={{
                            justifyContent:
                                isLoading || data.length <= 0
                                    ? 'center'
                                    : 'start',
                            gap: '2.4rem',
                        }}
                    >
                        {isLoading ? (
                            <div style={{ alignSelf: 'center' }}>
                                <Loader />
                            </div>
                        ) : (
                            <>
                                {data.length ? (
                                    data.map((w: any, index: number) => (
                                        <WorkFlowsItems
                                            onClick={() => {
                                                w.requestWorkflowName
                                                    ? history.push(
                                                        `${history.location.pathname}?workflowName=${w.requestWorkflowName}`,
                                                    )
                                                    : history.push(
                                                        `${history.location.pathname}`,
                                                    )
                                            }}
                                            key={`work-flow-${index}`}
                                            title={w.friendlyName}
                                            description={w.description}
                                            isEnabled={w.requestWorkflowName}
                                        />
                                    ))
                                ) : (
                                    <Box> {t('NoWorkflowsFound')} </Box>
                                )}
                            </>
                        )}
                    </Grid>
                </Paper>
            </div>
        </>
    )
}
export default Workflows
