import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { Collapse, Box, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from 'react-i18next'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
} from 'packages/core'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'

import {
    AppLayout,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
    Button,
} from 'components'
import { usePageContext } from 'pageContext'
import { SubPathLevel } from 'appConfiguration'
import { useMailboxes } from 'hooks/mailBoxesHooks'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import ViewSwitcher from 'components/ViewSwitcher'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { MailboxDetails } from './MailboxDetails'
import ResourceMoreInformationTile from 'components/ResourceMoreInformationTile'
import useSubcomponents from 'useSubcomponents'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'
import { useAppState } from 'appContext'
import { ITEMS_VIEW, RESOURCE_TYPE_NAMES } from 'utils'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useApplicationSettings } from 'hooks'

const canSeeMoreInformation = 'ResourceAdmin-MailBoxes-MoreInfo-Control'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})

const useStyles = makeStyles(() =>
    createStyles({
        actionButtonsList: {
            display: 'flex',
        },
        actionButtonsCard: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 0px',
            '& #detailsButton': {
                width: '80%',
            },
        },
    }),
)

const MailboxesLayout = ({ filters }) => {
    const filterContainer = useRef(null)
    const [{ itemsView }, dispatchAppState] = useAppState()
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const classes = useStyles()
    const [filterWidth, setFilterWidth] = useState(0)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        rest,
    } = useMailboxes()

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'FriendlyName',
            hideValue: 'true',
            isHeader: true,
            showInCard: false,

            component: {
                componentName: 'HighlightedClickableLabel',
                queryParam: 'mailboxId',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
                fontWeight: 'bold',
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control: 'ResourceAdmin-MailBoxes-List-FriendlyName',
            // },
        },
        {
            label: 'TechnicalName',
            name: 'name',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-MailBoxes-List-TechnicalName',
            },
        },
        {
            label: 'Alias',
            name: 'alias',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-MailBoxes-List-Alias',
            },
        },
        {
            label: 'Email',
            name: 'primaryEmailAddress',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-MailBoxes-List-Email',
            },
        },
        {
            label: 'ResourceSystem',
            name: 'resourceSystemFriendlyName',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-MailBoxes-List-ResourceSystem',
            },
        },
        {
            label: 'MailboxType',
            name: 'mailboxType',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-MailBoxes-List-MailboxType',
            },
        },
        {
            label: 'EmpowerIDLogin',
            name: 'empowerIdLogIn',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-MailBoxes-List-EmpowerIDLogin',
            },
        },

        {
            requireAccess: {
                control: 'ResourceAdmin-MailBoxes-List-Actions',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            display="flex"
                            className={
                                itemsView === ITEMS_VIEW.CARD
                                    ? classes.actionButtonsCard
                                    : classes.actionButtonsList
                            }
                        >
                            <Button.Details
                                onClick={() => {
                                    history.push(`?mailboxId=${data?.id}`)
                                }}
                                id={'detailsButton'}
                            />
                            <span style={{ marginRight: '10px' }} />
                            <UIActionsGearButton
                                item={data}
                                resourcePath="mailboxes"
                                noun='ResourceAdmin'
                            />
                        </Box>
                    )
                },
            },
        },
    ]

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])

    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const query = useQuery()
    const mailBoxId = query.get('mailboxId')
    const workflowName = query.get('workflow')

    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig

    const accessibleStaticWorkflows =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) =>
                      w.resourceTypeName === RESOURCE_TYPE_NAMES.MAILBOXES &&
                      hasAccessToControl(w.protectedSubcomponentName),
              )
            : []

    const showDetailsPage = Boolean(mailBoxId)

    useEffect(() => {
        if (!mailBoxId) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: null,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailBoxId])

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let detailContent
    if (mailBoxId) {
        detailContent = (
            <>
                {isSmallScreen ? (
                    <></>
                ) : (
                    <DesktopContainer>
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <MailboxDetails mailBoxId={mailBoxId} />
                        </Box>
                    </DesktopContainer>
                )}
            </>
        )
    }

    let listingContent
    listingContent = (
        <>
            {isSmallScreen ? (
                <SmallScreenContainer>
                    <Box display={showFilters ? '' : 'none'}>
                        {mobileFiltersScreen}
                    </Box>

                    <Box display={!showFilters ? '' : 'none'}>
                        <Box display={''}>
                            <StickyContainer>
                                <Box width="100%">
                                    {mobileStickyContentListing}
                                </Box>
                            </StickyContainer>
                            <TableOverView>
                                <EidTableGrid
                                    rowSelection={false}
                                    selected={[]}
                                    noDataMessage={t('NoDataMessage')}
                                    pagination={pagination}
                                    isLoading={isLoading}
                                    isLoadingMore={isLoadingMore}
                                    attributes={attributes}
                                    list={list}
                                />
                            </TableOverView>
                        </Box>
                    </Box>
                </SmallScreenContainer>
            ) : (
                <DesktopContainer>
                    <Fragment>
                        {((filters && filters.length > 0) ||
                            hasAccessToControl(canSeeMoreInformation)) && (
                            <FiltersColumn ref={filterContainer}>
                                <FilterContainer>
                                    <Box display="flex">
                                        <Box width="100%">
                                            <Card.CardHeader
                                                collapsible
                                                fontColor="black"
                                                cardTitle={
                                                    openFilters
                                                        ? t('Hide_My_Filters')
                                                        : t('Show_My_Filters')
                                                }
                                                handleExpandClick={() =>
                                                    setOpenFilters(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                expanded={openFilters}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                                style={styles.filterCardHeader}
                                            />
                                        </Box>
                                    </Box>
                                </FilterContainer>

                                <Collapse
                                    in={openFilters}
                                    timeout={300}
                                    unmountOnExit
                                >
                                    {accessibleStaticWorkflows.length > 0 &&
                                        accessibleStaticWorkflows.map((wf) => (
                                            <Box
                                                style={{
                                                    margin: '0 0 15px 0px',
                                                }}
                                                data-protectedsubcomponent={
                                                    wf.protectedSubcomponentName
                                                }
                                            >
                                                <EidWorkflowButton
                                                    title={t(
                                                        wf.displayName_LocaleKey,
                                                    )}
                                                    name={
                                                        wf.requestWorkflowName
                                                    }
                                                    path={`/mailboxes/workflows`}
                                                    disabled={workflowName}
                                                />
                                            </Box>
                                        ))}

                                    <ResourceMoreInformationTile
                                        protectedsubcomponent={
                                            canSeeMoreInformation
                                        }
                                        moreInfoKey="Mailbox_MoreInfo_Html"
                                        resourceId={mailBoxId}
                                    />
                                    <>
                                        {filters.map((f) => {
                                            const FilterToRender =
                                                Filters.filtersMap[f.name]
                                            return (
                                                <FilterContainer
                                                    data-protectedsubcomponent={
                                                        f.requireAccess
                                                    }
                                                    key={f.name}
                                                >
                                                    <FilterToRender
                                                        config={f.config}
                                                    />
                                                </FilterContainer>
                                            )
                                        })}
                                    </>
                                    <ListingPageUIActionByNounAndVerb
                                        noun="ResourceAdmin"
                                        verb="Mailbox"
                                    />
                                </Collapse>
                            </FiltersColumn>
                        )}

                        <ListingGrid>
                            <Box display="flex">
                                <Box width="100%">
                                    <Filters.TextSearch />
                                </Box>
                            </Box>
                            <GridContainer
                                filterWidth={filterWidth}
                                openFilters={openFilters}
                            >
                                <TableOverView>
                                    <EidTableGrid
                                        rowSelection={false}
                                        selected={[]}
                                        noDataMessage={t('NoDataMessage')}
                                        pagination={pagination}
                                        isLoading={isLoading}
                                        isLoadingMore={isLoadingMore}
                                        attributes={getAccessBasedAttributes(
                                            attributes,
                                        )}
                                        list={list}
                                    />
                                </TableOverView>
                            </GridContainer>
                        </ListingGrid>
                    </Fragment>
                </DesktopContainer>
            )}
        </>
    )

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={
                <ViewSwitcher
                    itemsView={itemsView}
                    dispatch={dispatchAppState}
                    listViewLabel={t('List_View_Label')}
                    cardViewLabel={t('Card_View_Label')}
                />
            }
            showNavTabs={!showDetailsPage}
            showBackButton={showDetailsPage}
        >
            <Fragment>
                {showDetailsPage ? detailContent : listingContent}
            </Fragment>
        </AppLayout>
    )
}

export default MailboxesLayout
