import { Box, Dialog, styled, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Button } from 'components'
import BusinesRoleAndLocationTree from 'components/BusinesRoleAndLocationTree/BusinesRoleAndLocationTree'
import { InputBox } from 'components/Popups/EditAssignments'
import {
    useBusinessLocationsTree,
    useUpdateDirectAssignedLocations,
} from 'hooks'
import { useDebounce } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { Loader } from 'packages/eid-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    AddAccessModalMain: {
        '& .MuiDialog-paper': {
            maxWidth: '44rem',
        },
    },
    NewAccessAssignmentModal: {
        '& h2': {
            fontSize: '2.4rem',
            color: '#1b1f1f',
            fontWeight: 'normal',
        },
    },

    AssignmentModalHeader: {
        padding: '3.2rem 4.0rem',
        '& svg': {
            top: '-1.4rem !important',
        },
    },

    AssignmentModalFooter: {
        display: 'flex',
        padding: '3.2rem 4.0rem',
        gap: '1.6rem',

        '& button': {
            border: `solid 0.1rem ${theme?.palette?.primary?.main}`,
            padding: '0.8rem 1.2rem',
            height: '3.2rem',
            fontSize: '1.4rem',
            '&:nth-child(1)': {
                background: theme?.palette?.primary?.main,
                color: '#fff',
            },
            '&:nth-child(2)': {
                color: theme?.palette?.primary?.main,
                maxWidth: '9.0rem',
            },
        },
    },

    newDirectAssigned: {
        padding: ' 0 4.0rem',
        borderBottom: '0.1rem solid #d8d8d8 !important',
    },
    searchInput: {
        ' & input': {
            height: '4.0rem',
            marginTop: '1.2rem',
            fontSize: '1.6rem',
            '&::placeholder': {
                color: '#b4b4b4',
            },
        },
    },
}))

const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '56.0rem',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '83.0rem',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-2.0rem',
        right: '-2.0rem',
        cursor: 'pointer',
    },
})

const RoleBoxCardInner = styled(Box)({
    maxHeight: '20.0rem',
    overflowY: 'auto',
    width: '100%',
    margin: '2.4rem 0',
})

const MIN_CHARACTERS_REQUIRED_TO_SEARCH = 3

const AddDirectAssignedLocation = ({ resourceId, refetch }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const classes = useStyles({})

    const [searchedLocation, setSearchedLocation] = useState('')
    const debouncedSearchLocationValue = useDebounce(searchedLocation)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const { data: locationTree, isLoading } = useBusinessLocationsTree(
        openModal,
        debouncedSearchLocationValue?.length >=
            MIN_CHARACTERS_REQUIRED_TO_SEARCH
            ? debouncedSearchLocationValue
            : '',
    )
    const [updateDirectAssignedLocations, { isLoading: updatingLocation }] =
        useUpdateDirectAssignedLocations()
    const handleSelect = (id) => {
        setSelectedLocation(id)
    }
    const handleSave = () => {
        const dataObject = {
            locationsToAdd: [selectedLocation],
            resourceId: resourceId,
        }
        updateDirectAssignedLocations(dataObject).then((data) => {
            refetch()
            setOpenModal(false)
        })
    }
    return (
        <>
            <Button
                onClick={() => setOpenModal(true)}
                style={{
                    borderRadius: '0.4rem',
                    padding: '1.2rem',
                    height: '4rem',
                    width: 'fit-content',
                    backgroundColor: theme?.palette?.primary?.main,
                    color: '#ffffff',
                    fontSize: '1.4rem',
                    '&:hover': {
                        backgroundColor: theme?.palette?.primary?.main,
                    },
                }}
            >
                {t('AddNewDirectAssignedLocation')}
            </Button>
            <CustomDialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                title={t('')}
                className={classes.AddAccessModalMain}
            >
                <Box className={classes.NewAccessAssignmentModal}>
                    <Box className={classes.AssignmentModalHeader}>
                        <CloseIconeBox onClick={() => setOpenModal(false)}>
                            <Icon
                                name="Close"
                                width="1.6rem"
                                height="1.6rem"
                                color="#959598"
                            />
                        </CloseIconeBox>
                        <Typography variant="h2">
                            {t('AddNewDirectAssignedLocation')}
                        </Typography>
                    </Box>
                    <Box className={classes.newDirectAssigned}>
                        <Box className={classes.searchInput}>
                            <InputBox>
                                <Typography variant="subtitle1">
                                    {t('SelectLocation')}
                                </Typography>
                                <input
                                    type="text"
                                    placeholder={t('SearchLocation')}
                                    value={searchedLocation}
                                    onChange={(e) =>
                                        setSearchedLocation(e.target.value)
                                    }
                                ></input>
                            </InputBox>
                        </Box>
                        <Box>
                            <RoleBoxCardInner>
                                {isLoading ? (
                                    <Box>
                                        <Loader />
                                    </Box>
                                ) : locationTree ? (
                                    <BusinesRoleAndLocationTree
                                        explorer={locationTree}
                                        handleSelect={handleSelect}
                                        value={selectedLocation}
                                        isExpanded={
                                            searchedLocation?.length >=
                                            MIN_CHARACTERS_REQUIRED_TO_SEARCH
                                        }
                                        highlightText={searchedLocation}
                                    />
                                ) : (
                                    <Box>{t('NoLocationFound')}</Box>
                                )}
                            </RoleBoxCardInner>
                        </Box>
                    </Box>
                    <Box className={classes.AssignmentModalFooter}>
                        <Button
                            backgroundColor={theme?.palette?.primary?.main}
                            onClick={() => handleSave()}
                            loading={updatingLocation}
                            disabled={!selectedLocation}
                            disabledStyles={{
                                backgroundColor: '#aab0b4 !important',
                                borderColor: '#aab0b4 !important',
                                color: '#ffffff !important',
                            }}
                        >
                            {t('AddNewDirectAssignedLocation')}
                        </Button>
                        <Button onClick={() => setOpenModal(false)}>
                            {t('Cancel')}
                        </Button>
                    </Box>
                </Box>
            </CustomDialog>
        </>
    )
}

export default AddDirectAssignedLocation
