import React, { useState, useEffect, Fragment } from 'react'
import { Box, Grid, Avatar, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import GenericCard from './Cards/GenericCard'
import GenericCardContent from './Cards/GenericCardContent'
import { DefaultAvatarIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl, imagesDisabled } from 'utils'
import PeopleEditForm from './PeopleEditForm/PeopleEditForm'
import useSubcomponents from 'useSubcomponents'
import { usePeopleDynamicCardForm } from 'hooks'

const useStyles = makeStyles({
    title: {
        fontSize: '24px',
        fontWeight: 'normal',
    },
    boldText: { fontWeight: 'bold', fontSize: '24px' },
    overlayWrapper: {
        width: '195px',
        height: '195px',
        marginBottom: 30,
        marginTop: '2em',
        position: 'relative',
    },
    bigAvatar: {
        width: '195px',
        height: '195px',
        '&:hover': {
            '& + span': {
                display: 'flex',
            },
        },
    },
    editOverlay: {
        display: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderRadius: '50%',
        top: 0,
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            display: 'flex',
        },
    },
})

const BasicInfoCard = (props) => {
    const { t } = useTranslation()

    const { config, personDetails } = props
    const { data: dynamicForm, isLoading } = usePeopleDynamicCardForm(
        Boolean(personDetails),
    )
    const [formData, setFormData] = useState(null)
    const classes = useStyles()
    const { hasAccessToControl } = useSubcomponents()

    const handleBasicInfoExpand = () => {
        // No action item for now.
    }
    useEffect(() => {
        setFormData(null)
    }, [personDetails])

    useEffect(() => {
        if (!formData && dynamicForm) {
            const dynamicFormData = JSON.parse(dynamicForm.value)
            prepareFormDataFromJson(dynamicFormData?.dynamicFields)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, dynamicForm])

    const bindInitialValue = (fieldName) => {
        return personDetails[fieldName] || ''
    }
    const checkAccessToField = (fieldName) => {
        return hasAccessToControl(
            `ResourceAdmin-PersonDetails-${fieldName}-Control`,
        )
    }
    const prepareFormDataFromJson = (dynamicFormData) => {
        let formFields = []
        Object?.keys(dynamicFormData).forEach((key) => {
            if (dynamicFormData?.[key]?.length) {
                const columns = dynamicFormData[key]
                columns.forEach((item) => {
                    if (item?.subColumn) {
                        const subColumns = item.subColumn
                        subColumns.forEach((item) => {
                            formFields.push({
                                name: item.fieldName,
                                value: bindInitialValue(item.fieldName),
                                isRequired:
                                    checkAccessToField(item.fieldName) &&
                                    item.required
                                        ? item.required
                                        : false,
                                localeKey: item.localeKey,
                                validationRegex: item.validationRegex,
                                validationMessage: item.validationMessage,
                            })
                        })
                    } else {
                        formFields.push({
                            name: item.fieldName,
                            value: bindInitialValue(item.fieldName),
                            isRequired:
                                checkAccessToField(item.fieldName) &&
                                item.required
                                    ? item.required
                                    : false,
                            localeKey: item.localeKey,
                            validationRegex: item.validationRegex,
                            validationMessage: item.validationMessage,
                        })
                    }
                })
            }
        })
        setFormData(formFields)
    }
    return (
        <GenericCard
            onClick={() => handleBasicInfoExpand()}
            editable={config.editable}
            editIcon={DefaultAvatarIcon}
            showEditOption={config.showEditOption}
        >
            {!imagesDisabled && (
                <Grid container justifyContent="center" alignItems="center">
                    <Box className={classes.overlayWrapper}>
                        <Avatar
                            alt="Basic Info Avatar"
                            src={getFullImageUrl(personDetails?.imageUrl)}
                            className={classes.bigAvatar}
                        />
                    </Box>
                </Grid>
            )}

            <GenericCardContent avatarCard>
                <Box
                    style={{
                        width: '100%',
                        margin: '0 4rem',
                    }}
                >
                    {isLoading && (
                        <Fragment>
                            <Skeleton height={20} />
                            <Skeleton height={20} />
                            <Skeleton height={20} />
                        </Fragment>
                    )}
                    {formData && (
                        <PeopleEditForm
                            dynamicFormData={
                                JSON.parse(dynamicForm?.value)?.dynamicFields
                            }
                            personDetails={personDetails}
                            updateFormDataOnChange={() => {}}
                            formData={formData}
                        />
                    )}
                </Box>
            </GenericCardContent>
        </GenericCard>
    )
}

export default BasicInfoCard
