import React from 'react'
import { InputBase, InputLabel, FormControl, Box } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ReactComponent as ResizeSvg } from './resize.svg'
import { isNilOrEmpty, isWhitespace } from 'packages/core'

const CustomInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: ({ paddingLeft }) => ({
        borderRadius: '5px',
        position: 'relative',
        backgroundColor: '#ffffff !important',
        border: '1px solid #d8d8dd;',
        fontSize: '14px',
        padding: '10px 12px',
        paddingLeft,
        lineHeight: 1.25,
        boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1)',
        '&:hover': {
            borderColor: theme?.palette?.primary?.main,
            backgroundColor: theme.palette.common.white,
        },
        '&:focus': {
            borderColor: theme?.palette?.primary?.main,
            backgroundColor: theme.palette.common.white,
        },

        // for IE
        overflowY: 'auto',
        '&::placeholder': {
            color: '#b4b4b4',
            opacity: 1,
        },
    }),
    multiline: {
        padding: 0,
    },
    focused: {
        backgroundColor: theme.palette.common.white,
    },
    error: {
        '& input, & textarea': {
            border: '1px solid #f44336 !important',
        },
    },
}))(({ paddingLeft, ...rest }) => <InputBase {...rest} />)

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        position: 'relative',
    },
})

const TextField = ({
    paddingLeft,
    label,
    multiline,
    value,
    maxCharacters,
    required,
    ...rest
}) => {
    const classes = useStyles()

    const error =
        value?.length > maxCharacters ||
        isWhitespace(value) ||
        (required && isNilOrEmpty(value))
    return (
        <>
            <FormControl className={classes.root}>
                {required && (
                    <Box
                        position="absolute"
                        right={6}
                        top={4}
                        color="#D0021b"
                        zIndex={1}
                        fontSize="17px"
                    >
                        *
                    </Box>
                )}
                {label && (
                    <InputLabel
                        shrink
                        style={{
                            textTransform: 'uppercase',
                            color: '#b4b4b4',
                            paddingLeft: '15px',
                        }}
                    >
                        {label}
                    </InputLabel>
                )}
                <CustomInput
                    multiline={multiline}
                    value={value}
                    error={error}
                    paddingLeft={paddingLeft}
                    {...rest}
                />
                {multiline && (
                    <Box position="absolute" right="4px" bottom="-2px">
                        <ResizeSvg />
                    </Box>
                )}
            </FormControl>
            {maxCharacters && (
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    fontSize="10px"
                    color={error ? '#f44336' : 'inherit'}
                >
                    {value?.length}/{maxCharacters}
                </Box>
            )}
        </>
    )
}

export default TextField
