import { Box, useTheme } from '@mui/material'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import { ApplicationTypes } from 'utils'
import { Fragment, useState } from 'react'
import EditAssignmentsWithFieldTypes from 'components/Popups/EditAssignmentsWithFieldTypes'
import { PageContextProvider } from 'pageContext'
import DialogHeader from 'components/Popups/EditAssignmentsWithFieldTypes/DialogHeader'

const AssignUnAssignRoleDefinition = ({
    roleId,
    item,
    applicationType,
    resourceId,
    resourceSystemModuleID,
}) => {
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const theme = useTheme()

    const applicationId = query.get('applicationId')

    const [openAssigneeModal, setOpenAssigneeModal] = useState(false)

    const handleButtonClick = () => {
        if (
            applicationType ===
            ApplicationTypes.PbacHasAppResourcesWithoutFieldType
        ) {
            query.delete('applicationId')
            query.delete('roleDefinitionId')
            query.set('workflowName', 'AssignAzLocalRoleDefinitionScope')
            query.set('returnUrl', getFullPathFromUrl())
            query.set('SelectedResourceID', resourceId)
            history.push(
                `${
                    config.APP_SUBPATH
                }/applications/workflows?${query.toString()}`,
            )
        }
    }

    return (
        <Fragment>
            {applicationType ===
            ApplicationTypes.PbacHasAppResourcesWithoutFieldType ? (
                <Button
                    onClick={handleButtonClick}
                    style={{
                        borderRadius: '4px',
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                        fontSize: '14px',
                        height: '100%',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                >
                    <Box style={{ display: 'flex' }}>
                        {t(item?.localRoleAssigned ? 'UnAssign' : 'Assign')}
                    </Box>
                </Button>
            ) : (
                <PageContextProvider
                    key={'ApplicationRolesPeopleEditAssignmentWithFieldType'}
                >
                    <DialogHeader
                        labelKey={'Edit'}
                        headerText={{
                            dynamicText: item?.friendlyName,
                            text: 'ChooseRoleDefinitions',
                        }}
                        setShowAssignmentModal={setOpenAssigneeModal}
                        showAssignmentModal={openAssigneeModal}
                    >
                        <EditAssignmentsWithFieldTypes
                            showAssignmentModal={openAssigneeModal}
                            handleClose={() => setOpenAssigneeModal(false)}
                            assignedUrl={`/api/AzureRoles/assignmentsByApplication/${resourceSystemModuleID}`}
                            unAssignedUrl={`/api/AzureRoles/GetEligibleAppRolesByApplicationByAssignee`}
                            selectedApplicationId={applicationId}
                            unAssignedQueryParams={{
                                applicationId,
                                eligibilityAssigneeId: item?.id,
                                localRoleId: roleId,
                            }}
                            assignedQueryParams={{
                                assigneeId: item?.id,
                                localRoleId: roleId,
                            }}
                            searchPlaceHolder={'SearchFilterRoleDefinitions'}
                            showAccessRequestPolicy={true}
                            fetchRequestPolicyItemWise={true}
                            requestPolicyIdProperty="requestPolicyId"
                            showStartDate={false}
                            fieldTypeProps={{
                                assignee: item,
                                isRole: true,
                                keyForRoleRightId: 'localRoleId',
                            }}
                            requireRemappingInAssignedList={true}
                            newMappingForAssignedList={{
                                friendlyName: 'role',
                            }}
                        />
                    </DialogHeader>
                </PageContextProvider>
            )}
        </Fragment>
    )
}

export default AssignUnAssignRoleDefinition
