import { Box, Typography, styled, useTheme } from '@mui/material'
import { Button } from 'components'
import { Autocomplete, Select } from 'packages/eid-ui'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinesRoleAndLocationTree from '../../BusinesRoleAndLocationTree/BusinesRoleAndLocationTree'
import {
    CloseIconeBox,
    CustomDialog,
    InputBox,
    ModalFooter,
} from '../../Popups/EditAssignments'
import {
    useAllowedAssignmentTypes,
    useBusinessLocationsTree,
    useBusinessRolesTree,
    useManagementRolesAll,
    useManagementRolesAndDefination,
    useQueryBasedCollection,
} from 'hooks'
import { Skeleton } from '@mui/material';

const AssigneesModal = styled(Box)({
    maxWidth: '700px',
    width: '100%',
    background: '#fff',
    border: 'solid 1px #ebebed',
    padding: '40px',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})

const Divider = styled(Box)({
    background: '#d8d8d8 !important',
    height: '1px',
    margin: '24px 0',
})

const RoleBoxCard = styled(Box)({
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
        color: '#000',
        height: '40px',
        '&::placeholder': {
            color: '#b4b4b4',
        },
    },
})

const RoleBoxCardSection = styled(Box)({
    width: '100%',
    maxWidth: '48% !important',
    marginBottom: '15px',
})
const RoleBoxCardInner = styled(Box)({
    maxHeight: '200px',
    overflowY: 'auto',
    width: '100%',
    marginTop: '24px',
})

const IAMShopPermnissionLevelOptions = [
    {
        id: 'full',
        value: 'Full Control',
    },
]

const AddIAMShopAssignee = () => {
    const [openAssigneeModal, setOpenAssigneeModal] = useState(false)
    const [poilicyAssigne, setPolicyAssignee] = useState(null)
    const [permissionLevel, setPermissionLevel] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([])
    const { t } = useTranslation()
    const theme = useTheme()
    const { data: allowedAssigneeType } =
        useAllowedAssignmentTypes(openAssigneeModal)
    const { data: businessRoles, loading: businessRoleLoading } =
        useBusinessRolesTree(
            poilicyAssigne?.id === 'Business Role and Location',
        )

    const { data: locationTree, isLoading: businessLocationLoading } =
        useBusinessLocationsTree(
            poilicyAssigne?.id === 'Business Role and Location',
        )

    const [
        getManagementRolesAll,
        { data: managementRolesAll, loading: managementRoleLoading },
    ] = useManagementRolesAll()
    const [
        getManagementRolesAndDefination,
        {
            data: managementRolesAndDefination,
            loading: managementRoleAndDefinationLoading,
        },
    ] = useManagementRolesAndDefination()
    const [
        getQueryBasedCollection,
        { data: queryBasedCollection, loading: queryBasedCollectionLoading },
    ] = useQueryBasedCollection()

    const getOptionLabel = (option) => option.value

    const handlePolicyAssigneChange = (evt) => {
        setPolicyAssignee(evt)
        setSelectedItem(null)
        setAutoCompleteOptions([])
        if (evt?.id === 'Management Role') {
            getManagementRolesAll()
        }
        if (evt?.id === 'Management Role Definition') {
            getManagementRolesAndDefination()
        }
        if (evt?.id === 'Query-Based Collection') {
            getQueryBasedCollection()
        }
    }

    useEffect(() => {
        if (managementRolesAll && poilicyAssigne?.id === 'Management Role') {
            setAutoCompleteOptions(bindAutoCompleteOptions(managementRolesAll))
        }
        if (
            managementRolesAndDefination &&
            poilicyAssigne?.id === 'Management Role Definition'
        ) {
            setAutoCompleteOptions(
                bindAutoCompleteOptions(managementRolesAndDefination),
            )
        }
        if (
            queryBasedCollection &&
            poilicyAssigne?.id === 'Query-Based Collection'
        ) {
            setAutoCompleteOptions(
                bindAutoCompleteOptions(queryBasedCollection),
            )
        }
    }, [
        managementRolesAll,
        poilicyAssigne,
        managementRolesAndDefination,
        queryBasedCollection,
    ])

    const bindAutoCompleteOptions = (data) => {
        if (!data?.length) {
            return []
        }

        return data.map((item) => {
            return {
                id: item.id,
                friendlyName: item.friendlyName,
            }
        })
    }

    const handlePermissionLevelChange = (evt) => {
        setPermissionLevel(evt)
    }

    const bindOptionsForAllowedAssigneeType = () => {
        if (!allowedAssigneeType?.length) {
            return []
        }

        return allowedAssigneeType.map((item) => {
            return {
                id: item.name,
                value: item.name,
            }
        })
    }

    const bindAutoCompleteLabel = () => {
        if (poilicyAssigne?.id === 'Business Role and Location') {
            return 'SelectedBusinessRoleandLocation'
        }
        if (poilicyAssigne?.id === 'Management Role') {
            return 'SelectManagementRole'
        }
        if (poilicyAssigne?.id === 'Management Role Definition') {
            return 'SelectManagementRoleDefinition'
        }
        if (poilicyAssigne?.id === 'Query-Based Collection') {
            return 'SelectQueryBasedCollection'
        }
        if (poilicyAssigne?.id === 'Group') {
            return 'SelectGroupToReceivePolicy'
        }
        if (poilicyAssigne?.id === 'Person') {
            return 'SelectPeople'
        }
    }

    return (
        <>
            <Button
                onClick={() => setOpenAssigneeModal(true)}
                style={{
                    borderRadius: '4px',
                    padding: '0px 20px',
                    backgroundColor: theme?.palette?.primary?.main,
                    border: 'solid 1px #01ae8f',
                    color: '#ffffff',
                    fontSize: '14px',
                    '&:hover': {
                        backgroundColor: theme?.palette?.primary?.main,
                    },
                }}
            >
                {t('AddNewIAmShopAssignees')}
            </Button>
            <CustomDialog
                open={openAssigneeModal}
                onClose={() => setOpenAssigneeModal(false)}
                title={t('')}
            >
                <AssigneesModal>
                    <CloseIconeBox onClick={() => setOpenAssigneeModal(false)}>
                        <Icon
                            name="Close"
                            width="16px"
                            height="16px"
                            color="#959598"
                        />
                    </CloseIconeBox>

                    <Typography variant="h2">
                        {t('AddNewIAmShopAssignees')}
                    </Typography>

                    <Box marginTop="30px">
                        <InputBox>
                            <Typography variant="subtitle1">
                                {t('IAmShopPermissionLevel')}
                            </Typography>
                            <Select
                                options={IAMShopPermnissionLevelOptions}
                                getOptionLabel={getOptionLabel}
                                onChange={(e) => handlePermissionLevelChange(e)}
                                value={permissionLevel}
                            ></Select>
                        </InputBox>
                        <Divider />
                        <InputBox>
                            <Typography variant="subtitle1">
                                {t('TypeOfAssigneeForPolicy')}
                            </Typography>
                            <Select
                                value={poilicyAssigne}
                                options={bindOptionsForAllowedAssigneeType()}
                                getOptionLabel={getOptionLabel}
                                onChange={(e) => handlePolicyAssigneChange(e)}
                            ></Select>
                        </InputBox>
                        <Divider />
                        {poilicyAssigne &&
                        poilicyAssigne.id !== 'Business Role and Location' ? (
                            <>
                                <Autocomplete
                                    label={t(bindAutoCompleteLabel())}
                                    placeholder={t(bindAutoCompleteLabel())}
                                    options={autoCompleteOptions}
                                    loading={
                                        managementRoleLoading ||
                                        businessRoleLoading ||
                                        managementRoleAndDefinationLoading ||
                                        queryBasedCollectionLoading
                                    }
                                    loadingText=""
                                    noOptionsText=""
                                    multiple={false}
                                    onChange={(_, selectedItem) =>
                                        setSelectedItem(selectedItem)
                                    }
                                    value={selectedItem}
                                    getOptionLabel={(option) =>
                                        option.friendlyName
                                    }
                                    filterOptions={undefined}
                                    style={{
                                        maxWidth: '360px',
                                    }}
                                />
                                <Divider />
                            </>
                        ) : null}

                        {poilicyAssigne?.id === 'Business Role and Location' ? (
                            <>
                                <RoleBoxCard>
                                    <RoleBoxCardSection>
                                        <InputBox>
                                            <Typography variant="subtitle1">
                                                {t('SelectBusinessRole')}
                                            </Typography>
                                            <input
                                                type="text"
                                                placeholder={t(
                                                    'SearchBusinessRole',
                                                )}
                                            ></input>
                                        </InputBox>
                                        <Box>
                                            <RoleBoxCardInner>
                                                {businessRoleLoading ? (
                                                    <Skeleton height={20} />
                                                ) : (
                                                    <BusinesRoleAndLocationTree
                                                        explorer={businessRoles}
                                                    />
                                                )}
                                            </RoleBoxCardInner>
                                        </Box>
                                    </RoleBoxCardSection>
                                    <RoleBoxCardSection>
                                        <InputBox>
                                            <Typography variant="subtitle1">
                                                {t('SelectLocation')}
                                            </Typography>
                                            <input
                                                type="text"
                                                placeholder={t(
                                                    'SearchLocation',
                                                )}
                                            ></input>
                                        </InputBox>
                                        <Box>
                                            <RoleBoxCardInner>
                                                {businessLocationLoading ? (
                                                    <Skeleton height={20} />
                                                ) : (
                                                    <BusinesRoleAndLocationTree
                                                        explorer={locationTree}
                                                    />
                                                )}
                                            </RoleBoxCardInner>
                                        </Box>
                                    </RoleBoxCardSection>
                                </RoleBoxCard>
                            </>
                        ) : null}

                        <ModalFooter>
                            <Button
                                backgroundColor={theme?.palette?.primary?.main}
                            >
                                {t('AddNewIAmShopAssignees')}
                                <ArrowSmallIcon
                                    color="#fff"
                                    fill="#fff"
                                    direction="right"
                                />
                            </Button>
                            <Button onClick={() => setOpenAssigneeModal(false)}>
                                {t('Cancel')}
                                <ArrowSmallIcon
                                    color={theme?.palette?.primary?.main}
                                    fill={theme?.palette?.primary?.main}
                                    direction="right"
                                />
                            </Button>
                        </ModalFooter>
                    </Box>
                </AssigneesModal>
            </CustomDialog>
        </>
    )
}

export default AddIAMShopAssignee
