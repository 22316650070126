import { useTranslation } from 'react-i18next'
import { Box, styled, useTheme } from '@mui/material'
import { useIsSmallScreenFalse } from 'packages/core'
import { ListingGrid, GridContainer, Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { usePbacAppResources, usePbacResourceTypesForDropdown } from 'hooks'

import AddNewResource from './AddNewResource'
import PersonDetailsLazy from 'components/PersonDetailsLazy'
import { PageContextProvider } from 'pageContext'
import { useAppState } from 'appContext'
import { Icon } from 'packages/eid-icons'

import DeletePBACResource from './DeletePbacResource'
import { useState } from 'react'

const ButtonsBox = styled(Box)({
    display: 'flex',
    paddingRight: '10px',
    justifyContent: 'flex-end',
})

const canAddNewPbacResourceTypeControlName =
    'ResourceAdmin-ApplicationDetails-PbacAppResources-AddNewPbacResource-Control'
const canEditPbacResourceTypeControlName =
    'ResourceAdmin-ApplicationDetails-PbacAppResources-EditPbacResource-Control'
const canDeletePbacResourceTypeControlName =
    'ResourceAdmin-ApplicationDetails-PbacAppResources-DeletePbacResource-Control'

const EditAddPbacResouce = ({
    data,
    applicationId,
    resourceSystemModuleId,
    labelKey,
    headerKey,
    startIcon,
    resourceTypes,
    loadingResourceTypes,
}) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <>
            <span
                title={
                    resourceTypes && resourceTypes.length <= 0
                        ? t('NeedToAddResourceTypes')
                        : ''
                }
            >
                <Button
                    onClick={() => setOpen(true)}
                    style={{
                        borderRadius: '4px',
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                        fontSize: '14px',
                        height: '100%',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                    disabledStyles={{
                        backgroundColor: '#aab0b4 !important',
                        color: '#ffffff !important',
                        borderColor: '#aab0b4 !important',
                    }}
                    disabled={
                        loadingResourceTypes ||
                        (resourceTypes && resourceTypes.length <= 0)
                    }
                >
                    <Box style={{ display: 'flex' }}>
                        {startIcon}
                        {t(labelKey)}
                    </Box>
                </Button>
            </span>
            {open && (
                <AddNewResource
                    data={data}
                    applicationId={applicationId}
                    resourceSystemModuleId={resourceSystemModuleId}
                    headerKey={headerKey}
                    open={open}
                    resourceTypes={resourceTypes}
                    setOpenModal={(d) => {
                        setOpen(d)
                    }}
                />
            )}
        </>
    )
}

const PbacAppResources = ({ applicationId, resourceSystemModuleId }) => {
    const { t } = useTranslation()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = usePbacAppResources(applicationId, resourceSystemModuleId)
    const { list: resourceTypes, loading: loadingResourceTypes } =
        usePbacResourceTypesForDropdown(
            applicationId,
            resourceSystemModuleId,
            Boolean(applicationId && resourceSystemModuleId),
        )
    const isSmallScreen = useIsSmallScreenFalse()
    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            label: 'FriendlyName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Name',
            },
        },
        {
            name: 'description',
            sortable: true,
            label: 'Description',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Description',
            },
        },
        {
            name: 'systemModuleResourceType',
            sortable: true,
            label: 'Type',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Type',
            },
        },
        {
            name: 'ownerFriendlyName',
            hideValue: true,
            sortable: true,
            label: 'Owner',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Owner',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <PersonDetailsLazy
                            personId={data.ownerAssigneeId}
                            friendlyName={data.ownerFriendlyName}
                            imageThumbUrl={data.ownerAssigneeImageUrl}
                        />
                    )
                },
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <ButtonsBox data-protectedsubcomponent="ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Actions">
                            {hasAccessToControl(
                                canEditPbacResourceTypeControlName,
                            ) && (
                                <PageContextProvider
                                    key={data?.id + 'EditPbacResource'}
                                >
                                    <Box
                                        data-protectedsubcomponent={
                                            canEditPbacResourceTypeControlName
                                        }
                                    >
                                        <EditAddPbacResouce
                                            resourceSystemModuleId={
                                                data.resourceSystemModuleResourceTypeId
                                            }
                                            data={data}
                                            applicationId={applicationId}
                                            headerKey={'EidPBACResource'}
                                            labelKey={'Edit'}
                                        />
                                    </Box>
                                </PageContextProvider>
                            )}
                            <Box margin="5px" />

                            {hasAccessToControl(
                                canDeletePbacResourceTypeControlName,
                            ) && (
                                <Box
                                    data-protectedsubcomponent={
                                        canDeletePbacResourceTypeControlName
                                    }
                                >
                                    <DeletePBACResource
                                        item={data}
                                        applicationId={applicationId}
                                    />
                                </Box>
                            )}
                        </ButtonsBox>
                    )
                },
            },
        },
    ]

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                {hasAccessToControl(canAddNewPbacResourceTypeControlName) && (
                    <Box
                        data-protectedsubcomponent={
                            canAddNewPbacResourceTypeControlName
                        }
                    >
                        <PageContextProvider
                            key={applicationId + 'AddNewPbacResource'}
                        >
                            <EditAddPbacResouce
                                headerKey={'AddNewPbacResources'}
                                labelKey={'AddNewPbacResources'}
                                applicationId={applicationId}
                                resourceSystemModuleId={resourceSystemModuleId}
                                loadingResourceTypes={loadingResourceTypes}
                                resourceTypes={resourceTypes}
                                startIcon={
                                    <Icon
                                        name={'Plus'}
                                        fill="white"
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    />
                                }
                            />
                        </PageContextProvider>
                    </Box>
                )}

                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default PbacAppResources
