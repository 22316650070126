import { MenuItem, ListItemText, ListItemIcon, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIsSmallScreenFalse } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { Dropdown } from 'packages/eid-ui'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        height: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        borderRadius: '4px',
    },
    icon: {
        minWidth: '40px',
        color: 'black',

        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    selected: {
        backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        borderLeft: `2px solid ${theme?.palette?.primary?.main}`,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        '& img': {
            opacity: 1,
            filter: 'grayscale(0%) ',
        },
    },
}))
const menuStyles = {
    paddingRight: '0px',
    marginTop: '5px',
}
const listStyles = {
    paddingTop: '0px',
    paddingBottom: '0px',
}

const ListingTabItem = ({
    label,
    icon,
    selected,
    onClick,
    showCount,
    isSmallScreen,
    isDropDownMenuItem,
    showBorder,
    count,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    return (
        <MenuItem
            style={{ border: showBorder && `1px solid ${theme?.palette?.primary?.main}` }}
            onClick={onClick}
            classes={classes}
            selected={selected}
        >
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <ListItemText>
                <>{t(label)}</>
                {(selected || isSmallScreen) &&
                    showCount &&
                    !isDropDownMenuItem && (
                        <div
                            style={{
                                marginRight: isSmallScreen ? 0 : '16px',
                                float: 'right',
                                fontSize: '14px',
                                color: '#919193',
                                display: 'flex',
                            }}
                        >
                            {count}

                            {isSmallScreen && !isDropDownMenuItem && (
                                <Icon
                                    style={{
                                        float: 'right',
                                        width: '40px',
                                        height: '22px',
                                    }}
                                    name="Arrow"
                                />
                            )}
                        </div>
                    )}
            </ListItemText>
        </MenuItem>
    )
}

const ListingTabs = ({ active, onChange, tabs, activeTab, count }) => {
    const isSmallScreen = useIsSmallScreenFalse()
    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [menuWidth, setMenuWidth] = useState('fit-content')
    useEffect(() => {
        if (anchorRef && anchorRef.current)
            setMenuWidth(anchorRef.current.offsetWidth)
    })
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }
    return isSmallScreen ? (
        <>
            <div ref={anchorRef}>
                <ListingTabItem
                    key={`${activeTab?.path}`}
                    label={activeTab?.label}
                    contextProp={activeTab?.contextProp}
                    showCount={activeTab?.showCount}
                    onClick={() => {
                        setOpen((pre) => !pre)
                    }}
                    icon={<Icon name={activeTab?.icon} />}
                    isSmallScreen={isSmallScreen}
                    showBorder={open}
                ></ListingTabItem>
            </div>
            <Dropdown
                open={open}
                anchorEl={anchorRef.current}
                handleClose={handleClose}
                showCone={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                width={'max-content'}
                rootStyles={{ width: menuWidth, ...menuStyles }}
                listStyles={listStyles}
            >
                {tabs
                    .filter((x) => x.display === true)
                    .map((tab, index) => (
                        <ListingTabItem
                            key={`${tab.path}_${index}`}
                            label={tab.label}
                            onClick={() => {
                                setOpen(false)
                                if (tab.path !== activeTab.path) {
                                    onChange(tab)
                                }
                            }}
                            selected={active === tab.path}
                            showCount={tab.showCount}
                            icon={<Icon name={tab.icon} />}
                            isDropDownMenuItem={true}
                            count={count}
                        />
                    ))}
            </Dropdown>
        </>
    ) : (
        <>
            {tabs
                .filter((x) => x.display === true)
                .map((tab, index) => (
                    <ListingTabItem
                        key={`${tab.path}_${index}`}
                        label={tab.label}
                        onClick={() => {
                            onChange(tab)
                        }}
                        selected={active === tab.path}
                        contextProp={tab.contextProp}
                        showCount={tab.showCount}
                        icon={<Icon name={tab.icon} />}
                        count={count}
                    />
                ))}
        </>
    )
}

export default ListingTabs
