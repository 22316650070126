import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIAmShopAssignments } from 'hooks'

import { useDebounce } from 'packages/core'
import TableGrid from 'components/TableGrid'
import Search from 'components/Filters/Search'

import { PageContextProvider } from 'pageContext'
import AddNewAssignment from 'components/Popups/AddNewAssignment/AddNewAssignment'
import { RESOURCE_TYPE_NAMES } from 'utils'

const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 16px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const IAmShopAssignments = ({ id }) => {
    const { t } = useTranslation()
    const tableClasses = useTableStyles()

    const [selected, setSelected] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })

    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const handleSelect = (selectedArray) => {
        setSelected(selectedArray)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
        sorting,
    }

    const {
        list,
        loading: isLoading,
        pagination,
    } = useIAmShopAssignments(id, queryData)

    const attributes = [
        {
            label: t('IAmShopPermissionLevel'),
            name: 'localizedAccessLevelFriendlyName',
            sortable: true,
        },
        {
            label: t('Assignee'),
            name: 'assigneeTechnicalPreviewName',
            sortable: true,
        },
        {
            name: 'azLocalRoleFriendlyName',
            label: t('LocalRole'),
            sortable: true,
        },
        {
            name: 'assignmentPointFriendlyName',
            label: t('AssignmentPoint'),
            sortable: true,
        },
        {
            name: 'assignmentPointId',
            label: t('AssignmentPointId'),
            sortable: true,
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('ResourceDisplayName'),
            sortable: true,
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                <PageContextProvider key="AddNewIAmShopAssignments">
                    <AddNewAssignment
                        resourceType={RESOURCE_TYPE_NAMES.SHARED_FOLDER}
                        labelKey={'AddNewAccessAssignment'}
                        searchUrl={`api/applicationRoles/assignableMembers/${id}`}
                        targetAssigneeId={id}
                    />
                </PageContextProvider>

                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchFilterAssignments')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>

            <TableGrid
                loading={isLoading}
                data={list}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
                sorting={sorting}
                onSort={handleSort}
                onSelect={handleSelect}
                selected={selected}
                tableClasses={tableClasses}
            />

            <Box display="flex" paddingTop="40px" justifyContent="center">
                <Box style={{ backgroundColor: '#fff', height: '100%' }}>
                    {pagination}
                </Box>
            </Box>
        </>
    )
}
