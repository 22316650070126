import React, { Fragment } from 'react'
import FormRowFields from './RowFields'

const PeopleEditForm = ({
    dynamicFormData,
    formData,
    validateDynamicForm,
    updateFormDataOnChange,
    personDetails
}) => {
    

    return (
        <Fragment>
            {dynamicFormData &&
                formData &&
                Object.keys(dynamicFormData).map((key) => {
                    return dynamicFormData?.[key]?.length ? (
                        <FormRowFields
                            updateFormDataOnChange={updateFormDataOnChange}
                            validateDynamicForm={validateDynamicForm}
                            formData={formData}
                            columns={dynamicFormData[key]}
                            personDetails={personDetails}
                        />
                    ) : null
                })}
        </Fragment>
    )
}

export default PeopleEditForm
