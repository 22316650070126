import { FC, Fragment } from 'react'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import { PersonDetails } from '../PersonDetails'
import { usePerson } from 'hooks'

export type ResponsiblePartyProps = {
    responsiblePersonId: any
}

const useStyles = makeStyles(() =>
    createStyles({
        root: { position: 'relative' },
        ownersContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '300px',
            overflow: 'auto',
            padding: '5px 4px',
        },
        collapsibleContent: {
            marginTop: '10px',
            position: 'relative',
            backgroundColor: '#f5f6f7',
            borderRadius: '8px',
            border: 'solid 1px #f9f9f9',
            backdropFilter: 'blur(2px)',
            width: '27rem',
        },
        personContainer: {
            padding: '0px 12px',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: '#ffffff',
                '& p': {
                    color: '#000000',
                    fontWeight: 'bold',
                },
            },
        },
    }),
)

export const ResponsibleParty: FC<ResponsiblePartyProps> = (props) => {
    const classes = useStyles()
    const { responsiblePersonId } = props

    const { data: responsiblePartyData, isLoading } = usePerson(
        responsiblePersonId,
        Boolean(responsiblePersonId),
    )

    if (isLoading) {
        return (
            <Fragment>
                <Skeleton height={40} />
                <Skeleton height={40} />
            </Fragment>
        )
    } else {
        if (!responsiblePartyData?.id) {
            return <Box paddingLeft={'4px'}>-</Box>
        }

        return (
            <Box className={classes.root}>
                <Box className={classes.collapsibleContent}>
                    <Box className={classes.ownersContainer}>
                        <Box className={classes.personContainer}>
                            <PersonDetails
                                size="small"
                                person={{
                                    friendlyName:
                                        responsiblePartyData?.friendlyName,
                                    email: responsiblePartyData?.email,
                                    telephone: responsiblePartyData?.telephone,
                                    imageThumbUrl:
                                        responsiblePartyData?.imageThumbUrl,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
}
