import { useTranslation } from 'react-i18next'
import { getFullImageUrl, imagesDisabled } from 'utils'
import { useManagementRoleLinkedApplications } from 'hooks'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Box } from '@mui/material'
import { SearchMenuBarFilter } from 'components/WrapperComponents/SearchMenuBar'
import { Filters } from 'components'

export const LinkedApplications = ({ managementRoleId, queryData }) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()
    const defaultApplicationImageUrl = '/webcdn/Images/AppLogos/Genric-1.png'
    const attributes = [
        {
            label: '',
            styles: {
                width: '40px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-LinkedApplication-ApplicationImage',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) =>
                    !imagesDisabled ? (
                        <img
                            style={{ maxHeight: '40px' }}
                            src={getFullImageUrl(
                                item.applicationLogoImageUrl ||
                                    defaultApplicationImageUrl,
                            )}
                            alt={t('ApplicationImage')}
                        />
                    ) : (
                        <></>
                    ),
            },
        },
        {
            name: 'friendlyName',
            label: t('FriendlyName'),
            sortable: true,

            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ManagementRoleDetails-Overview-LinkedApplication-FriendlyName',
            // },
        },
    ]

    const {
        list: linkedApplications,
        loading: linkedApplicationsLoading,
        pagination: linkedApplicationsPagination,
    } = useManagementRoleLinkedApplications(
        managementRoleId,
        Boolean(managementRoleId),
    )

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                padding="1.6rem 3.2rem"
                bgcolor="white"
                borderTop="1px solid #eef0f4"
            >
                <Box style={{ width: '24rem' }}>
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <EidTableGrid
                noDataMessage={t('NoDataMessage')}
                pagination={linkedApplicationsPagination}
                isLoading={linkedApplicationsLoading}
                attributes={getAccessBasedAttributes(attributes)}
                list={linkedApplications}
                tableViewOnly
            />
        </>
    )
}
