import { Box, Dialog, styled, Typography, useTheme } from '@mui/material'
import { Skeleton } from '@mui/material'
import { Button } from 'components'
import {
    CloseIconeBox,
    InputBox,
    ModalFooter,
} from 'components/Popups/EditAssignments'
import BusinesRoleAndLocationTree from 'components/BusinesRoleAndLocationTree/BusinesRoleAndLocationTree'
import cartHelpers from 'containers/Cart/cartHelpers'
import { findObjectByValue } from 'containers/People/PeopleDetails/PeopleEditForm/FormElements/Tree'
import {
    useAddItemToCart,
    useBusinessLocationsTree,
    useBusinessRolesTree,
} from 'hooks'
import { useDebounce } from 'packages/core'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ASSIGNMENT_TYPES } from 'utils'

export const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '830px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const AssigneesModal = styled(Box)({
    maxWidth: '700px',
    width: '100%',
    background: '#fff',
    border: 'solid 1px #ebebed',
    padding: '40px',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})
const RoleBoxCard = styled(Box)({
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
        color: '#000',
        height: '40px',
        '&::placeholder': {
            color: '#b4b4b4',
        },
    },
})

const RoleBoxCardSection = styled(Box)({
    width: '100%',
    maxWidth: '48% !important',
    marginBottom: '15px',
})

const RoleBoxCardInner = styled(Box)({
    maxHeight: '200px',
    overflowY: 'auto',
    width: '100%',
    marginTop: '24px',
})

const MIN_CHARACTERS_REQUIRED_TO_SEARCH = 3

const AddNewBusinessRoleAndLocation = ({
    protectedsubcomponent,
    managementRoleId,
    assignedRoleAndLocation,
}) => {
    const theme = useTheme()
    const [locationList, setLocationList] = useState([])
    const [searchedRole, setSearchedRole] = useState('')
    const [searchedLocation, setSearchedLocation] = useState('')
    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedBusinessRole, setSelectedBusinessRole] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const { t } = useTranslation()
    const debouncedSearchRolValue = useDebounce(searchedRole)
    const debouncedSearchLocationValue = useDebounce(searchedLocation)
    const [addItemToCart] = useAddItemToCart()
    const { data: businessRoles, isLoading: businessRoleLoading } =
        useBusinessRolesTree(
            showAddModal,
            debouncedSearchRolValue?.length >= MIN_CHARACTERS_REQUIRED_TO_SEARCH
                ? debouncedSearchRolValue
                : '',
        )

    const { data: locationTree, isLoading: businessLocationLoading } =
        useBusinessLocationsTree(
            showAddModal,
            debouncedSearchLocationValue?.length >=
                MIN_CHARACTERS_REQUIRED_TO_SEARCH
                ? debouncedSearchLocationValue
                : '',
        )

    useEffect(() => {
        if (selectedBusinessRole) {
            const alreadyAssignedRole = assignedRoleAndLocation.filter(
                (x) => x.businessRoleId === selectedBusinessRole,
            )
            if (locationTree) {
                if (alreadyAssignedRole?.length) {
                    let tree = { ...locationTree }
                    alreadyAssignedRole.forEach((element) => {
                        tree = findItemByIdAndReplaceKey(
                            tree,
                            element?.locationId,
                            'isAssignable',
                            false,
                        )
                    })
                    setLocationList(tree)
                } else {
                    setLocationList(locationTree)
                }
            }
        } else {
            setLocationList([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationTree, selectedBusinessRole])

    const findItemByIdAndReplaceKey = (
        currentNode,
        targetId,
        keyToReplace,
        newValue,
    ) => {
        try {
            if (currentNode.id === targetId) {
                return {
                    ...currentNode,
                    [keyToReplace]: newValue,
                    alreadyAssigned: true,
                }
            }

            if (currentNode.children) {
                const updatedChildren = currentNode.children.map((child) =>
                    findItemByIdAndReplaceKey(
                        child,
                        targetId,
                        keyToReplace,
                        newValue,
                    ),
                )

                return { ...currentNode, children: updatedChildren }
            }
        } catch (err) {}
        return currentNode
    }

    const handleBusinessRoleSelect = (id) => {
        setSelectedBusinessRole(id)
    }

    const findSelectedObject = (treeData) => {
        const recursiveSearch = (node) => {
            if (node.id === selectedBusinessRole) {
                return node
            }

            if (node.children) {
                for (const child of node.children) {
                    const result = recursiveSearch(child)
                    if (result) {
                        return result
                    }
                }
            }

            return null
        }

        return recursiveSearch(treeData)
    }

    const handleLocationSelect = (id) => {
        setSelectedLocation(id)
    }

    const handleSubmit = () => {
        const selectedRole = findSelectedObject(businessRoles)
        const selectedLocationObject = findObjectByValue(
            locationList,
            'id',
            selectedLocation,
        )
        const cartItem = cartHelpers.addRemoveBusinessRoleAndLocation({
            assignmentType: ASSIGNMENT_TYPES.ADD,
            targetAssigneeId: managementRoleId,
            businessRole: selectedRole,
            locationId: selectedLocation,
            title: `${selectedRole.title} ${t('In')} ${
                selectedLocationObject.title
            }`,
        })
        addItemToCart(cartItem).then(() => {
            handleClose()
        })
    }

    const handleClose = () => {
        setSearchedLocation('')
        setSearchedRole('')
        setSelectedBusinessRole(null)
        setSelectedLocation(null)
        setShowAddModal(false)
    }
    return (
        <Fragment>
            <Button
                onClick={() => setShowAddModal(!showAddModal)}
                data-protectedsubcomponent={protectedsubcomponent}
                style={{
                    borderRadius: '4px',
                    padding: '0px 20px',
                    backgroundColor: theme?.palette?.primary?.main,
                    border: 'solid 1px #01ae8f',
                    color: '#ffffff',
                    fontSize: '14px',
                    '&:hover': {
                        backgroundColor: theme?.palette?.primary?.main,
                    },
                }}
            >
                <Box style={{ display: 'flex' }}>
                    <Icon
                        name={'Plus'}
                        fill="white"
                        style={{
                            width: '16px',
                            height: '16px',
                            marginTop: '0px',
                            marginRight: '5px',
                        }}
                    />
                    {t('AddBusinessRoleAndLocationAsMember')}
                </Box>
            </Button>

            <CustomDialog
                open={showAddModal}
                onClose={() => handleClose()}
                title={t('')}
            >
                <AssigneesModal>
                    <CloseIconeBox onClick={() => handleClose()}>
                        <Icon
                            name="Close"
                            width="16px"
                            height="16px"
                            color="#959598"
                        />
                    </CloseIconeBox>

                    <Typography variant="h2">
                        {t('AddBusinessRoleAndLocationAsMember')}
                    </Typography>
                    <Box marginTop="30px">
                        <RoleBoxCard>
                            <RoleBoxCardSection>
                                <InputBox>
                                    <Typography variant="subtitle1">
                                        {t('SelectBusinessRole')}
                                    </Typography>
                                    <input
                                        type="text"
                                        placeholder={t('SearchBusinessRole')}
                                        value={searchedRole}
                                        onChange={(e) =>
                                            setSearchedRole(e.target.value)
                                        }
                                    ></input>
                                </InputBox>
                                <Box>
                                    <RoleBoxCardInner>
                                        {businessRoleLoading ? (
                                            <Skeleton height={20} />
                                        ) : (
                                            <BusinesRoleAndLocationTree
                                                explorer={businessRoles}
                                                handleSelect={
                                                    handleBusinessRoleSelect
                                                }
                                                value={selectedBusinessRole}
                                                isExpanded={
                                                    searchedRole?.length >=
                                                    MIN_CHARACTERS_REQUIRED_TO_SEARCH
                                                }
                                                highlightText={searchedRole}
                                            />
                                        )}
                                    </RoleBoxCardInner>
                                </Box>
                            </RoleBoxCardSection>
                            {selectedBusinessRole && (
                                <RoleBoxCardSection>
                                    <InputBox>
                                        <Typography variant="subtitle1">
                                            {t('SelectLocation')}
                                        </Typography>
                                        <input
                                            type="text"
                                            placeholder={t('SearchLocation')}
                                            value={searchedLocation}
                                            onChange={(e) =>
                                                setSearchedLocation(
                                                    e.target.value,
                                                )
                                            }
                                        ></input>
                                    </InputBox>
                                    <Box>
                                        <RoleBoxCardInner>
                                            {businessLocationLoading ? (
                                                <Skeleton height={20} />
                                            ) : (
                                                <BusinesRoleAndLocationTree
                                                    explorer={locationList}
                                                    handleSelect={
                                                        handleLocationSelect
                                                    }
                                                    value={selectedLocation}
                                                    isExpanded={
                                                        searchedLocation?.length >=
                                                        MIN_CHARACTERS_REQUIRED_TO_SEARCH
                                                    }
                                                    highlightText={
                                                        searchedLocation
                                                    }
                                                />
                                            )}
                                        </RoleBoxCardInner>
                                    </Box>
                                </RoleBoxCardSection>
                            )}
                        </RoleBoxCard>
                        <ModalFooter>
                            <Tooltip
                                title={
                                    selectedLocation && selectedBusinessRole
                                        ? ''
                                        : t('SelectBusinessRoleAndLocation')
                                }
                            >
                                <Box>
                                    <Button
                                        backgroundColor={
                                            theme?.palette?.primary?.main
                                        }
                                        onClick={() => handleSubmit()}
                                        style={{
                                            maxWidth: 'fit-content !important',
                                        }}
                                        disabledStyles={{
                                            backgroundColor:
                                                '#ebebed !important',
                                            borderColor: '#ebebed !important',
                                            cursor: 'no-drop',
                                            '&:hover': {
                                                boxShadow: 'none',
                                            },
                                        }}
                                        disabled={
                                            !selectedLocation ||
                                            !selectedBusinessRole
                                        }
                                    >
                                        {t(
                                            'AddBusinessRoleAndLocationAsMember',
                                        )}
                                        <ArrowSmallIcon
                                            color="#fff"
                                            fill="#fff"
                                            direction="right"
                                        />
                                    </Button>
                                </Box>
                            </Tooltip>
                            <Button onClick={() => setShowAddModal(false)}>
                                {t('Cancel')}
                                <ArrowSmallIcon
                                    color={theme?.palette?.primary?.main}
                                    fill={theme?.palette?.primary?.main}
                                    direction="right"
                                />
                            </Button>
                        </ModalFooter>
                    </Box>
                </AssigneesModal>
            </CustomDialog>
        </Fragment>
    )
}

export default AddNewBusinessRoleAndLocation
