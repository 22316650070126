import { Paper } from '@mui/material'
import { Box } from '@mui/system'
import DynamicFieldTypesSelector from 'components/ApplicationBanner/PbacAccessAssignments/ApplicationRoleDefinitionAssignments/ApplicationRoleDefinitionDetails/RoleFieldTypesInfo'
import { useRoleDefinitionFieldTypes } from 'hooks'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const FieldTypeValueDetails = ({
    assigneePerson,
    isRole,
    titleKey,
    keyForRoleRightId,
}) => {
    const { t } = useTranslation()
    const { data: appRoleRight, isLoading } = useRoleDefinitionFieldTypes(
        assigneePerson?.id,
        assigneePerson?.assigneeId,
        assigneePerson?.[keyForRoleRightId],
        isRole,
    )
    return (
        <Box>
            <Paper
                style={{
                    padding: '32px',
                    width: 'fit-content',
                    minWidth: '100%',
                }}
            >
                <Box>
                    <Box display="flex" flexWrap="wrap">
                        <Box paddingRight="32px">
                            <Attribute orientation="horizontal">
                                <AttributeLabel>
                                    {t('TechnicalName')}
                                </AttributeLabel>
                                <AttributeValue>
                                    {assigneePerson?.[titleKey]}
                                </AttributeValue>
                            </Attribute>

                            <Attribute orientation="horizontal">
                                <AttributeLabel>
                                    {t('Description')}
                                </AttributeLabel>
                                <AttributeValue>
                                    {assigneePerson.description}
                                </AttributeValue>
                            </Attribute>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <DynamicFieldTypesSelector
                isLoading={isLoading}
                assigneePerson={assigneePerson}
                appRoleRight={appRoleRight}
                isAppRole={isRole}
                keyForRoleRightId={keyForRoleRightId}
                showInfoMessage={true}
            />
        </Box>
    )
}

export default FieldTypeValueDetails
