import { Theme, Tab as MuiTab } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

interface StyledTabProps {
    value: string | number
    label: any
}

export const Tab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#3b454d', //Color not available in theme
            background: 'transparent',
            fontSize: '13px',
            maxWidth: '200px',
            minWidth: 'fit-content !important',
            borderBottom: 'solid 1px #ebebed',
            marginBottom: '-1px',
            padding: '0px 10px',
            borderTopLeftRadius: '0.4rem',
            borderTopRightRadius: '0.4rem',
            transition: `all 0.5s ease-out`,
            minHeight: '4rem',
            height: '4rem',
            '&:focus': {
                opacity: 1,
            },

            '& p': {
                fontSize: '13px',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
                overflow: 'auto',
                textAlign: 'left',
                textTransform: 'none !important',
            },
            '& .tab-count': {
                fontWeight: 'normal',
                fontSize: '13px',
                marginLeft: '0.8rem',
            },
        },

        selected: {
            border: 'solid 1px #ebebed',
            borderBottom: 'solid 1px transparent',
            background: '#fff',
            boxShadow: '0 0 1.6rem 0 rgba(0,0,0,0.11)',
            '& p': {
                color: theme?.palette.common.black,
                fontWeight: 'bolder',
            },
        },
    }),
)((props: StyledTabProps) => <MuiTab disableRipple {...props} />)
