import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EidButton, WarningModal } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { Button } from 'components'
import { useDeleteIAMShopAssignees } from 'hooks/applicationsHooks'

const DeleteAssignee = ({ item, drawerView, applicationId }) => {
    const { t } = useTranslation()

    const [showConfirm, setShowConfirm] = useState(false)
    const [deleteAssignees, { isLoading: isDeletingAssignee }] =
        useDeleteIAMShopAssignees(applicationId)
    const handleDelete = () => {
        deleteAssignees([item.id])
    }

    return (
        <>
            {!drawerView ? (
                <Button.Delete
                    item={item}
                    onClick={() => setShowConfirm(true)}
                    disabled={item.isExpired}
                />
            ) : (
                <Box width="150px" marginLeft={'40px'}>
                    <EidButton.DrawerActionButton
                        disabled={item.isExpired}
                        fontColor="#ffffff"
                        bgColor="#E40000"
                        width="100%"
                        onClick={() => setShowConfirm(true)}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            height={'12px'}
                            width={'12px'}
                        >
                            <Icon
                                name="DeleteX"
                                fill="#ffffff"
                                color="#ffffff"
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t('Delete')}
                            </Typography>
                        </Box>
                    </EidButton.DrawerActionButton>
                </Box>
            )}
            <WarningModal
                title={t('Delete') + ' ' + item?.assigneeTechnicalPreviewName}
                description={t('AssignmentDeleteMessage')}
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={showConfirm}
                cancel={() => setShowConfirm(false)}
                proceeding={isDeletingAssignee}
                proceed={handleDelete}
            />
        </>
    )
}

DeleteAssignee.defaultProps = {
    drawerView: false,
}
export default DeleteAssignee
