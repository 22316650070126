import { useTranslation } from 'react-i18next'
import { Box, Paper } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useApplicationsOptionalClaims } from 'hooks'
import { PageContextProvider } from 'pageContext'
import { useIsSmallScreenFalse, useQuery } from 'packages/core'
import Button from 'components/Button'
import { useHistory } from 'react-router'
import OptionalClaimDrawer from './OptionalClaimDrawer'
import { smallScreenWidth } from 'utils'
import Filters from 'components/Filters'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'

export const getItemFromList = (id, list) => {
    let item = null
    item = list.find((i) => i.id.toString() === id)
    return item
}

const useStyles = makeStyles(() =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

export const OptionalClaims = ({ id }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const classes = useStyles()
    const history = useHistory()
    const query = useQuery()
    const optionalClaimId = query.get('optionalClaimId')
    const { getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'name',
            label: t('Claim'),
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control: 'ResourceAdmin-Application-Token-Claims-List-Claim',
            // },
        },
        {
            name: 'claimTypeFriendlyName',
            label: t('TokenType'),
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Token-Claims-List-TokenType',
            },
        },
        {
            name: 'definitionDescription',
            label: t('Description'),
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Token-Claims-List-Description',
            },
        },

        {
            name: 'actions',
            label: '',
            hideValue: true,
            styles: {
                textAlign: 'end',
            },
            requireAccess: {
                control: 'ResourceAdmin-Application-Token-Claims-List-Action',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <Button.Details
                        onClick={() => {
                            history.push(
                                `${history.location.search}&optionalClaimId=${item?.id}`,
                            )
                        }}
                    />
                ),
            },
        },
    ]

    const {
        list: optionalClaims,
        loading: optionalClaimsLoading,
        pagination: optionalClaimsPagination,
    } = useApplicationsOptionalClaims(id, true)

    return (
        <>
            {optionalClaimId && optionalClaims && (
                <PageContextProvider key="RequestViewOptionalClaims">
                    <OptionalClaimDrawer
                        id={optionalClaimId}
                        item={getItemFromList(optionalClaimId, optionalClaims)}
                        onClose={() => {
                            query.delete('optionalClaimId')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}

            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                <Box
                    style={{
                        paddingBottom: '17px',
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    {!isSmallScreen && (
                        <Box width="308px" display="flex" alignItems="center">
                            <Filters.TextSearch outlines />
                        </Box>
                    )}
                </Box>
            </Paper>
            <EidTableGrid
                noDataMessage={t('NoDataMessage')}
                pagination={optionalClaimsPagination}
                isLoading={optionalClaimsLoading}
                attributes={getAccessBasedAttributes(attributes)}
                list={optionalClaims}
            />
        </>
    )
}
