import React from 'react'
import { styled } from '@mui/material'
import { Skeleton } from '@mui/material'
import { TableBody, TableCell, TableRow, Box } from '@mui/material'
import { MasterPasswordLock } from 'components'

const ListItemSkeleton = styled(Skeleton)({
    width: '100%',
    margin: '8px',
    height: 20,
    borderRadius: '10px',
})

const SkeletonContainer = styled('div')({
    height: 'max-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
})

const TableRowLoader = ({ attributes }) => {
    const loader = (
        <Box width="100%" height="100%">
            <SkeletonContainer>
                <ListItemSkeleton animation="wave" variant="rect" />
            </SkeletonContainer>
        </Box>
    )

    return (
        <TableRow>
            <TableCell colSpan={attributes.length}>{loader}</TableCell>
        </TableRow>
    )
}

const EidTable = ({
    data,
    isLoading,
    LoadingIndicatorComponent = TableRowLoader,
    isLoadingMore,
    noDataMessage = 'No data available',
    attributes,
    pagination,
    showMasterPasswordLock,
    masterPasswordLockStyle,
    TableComponent,
    TableHeadComponent,
    TableRowComponent,
    selected,
    handleSelectAllClick,
    rowSelection,
    handleClick,
    selectAllAction,
    selectionIdentifier,
    errorMessage,
    onSort,
    showSelectAll,
    highLightSelectedRow,
}) => {
    const loadingIndicator = (
        <LoadingIndicatorComponent attributes={attributes} />
    )
    return (
        <>
            <TableComponent>
                <TableHeadComponent
                    rowSelection={rowSelection}
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected}
                    data={data}
                    attributes={attributes}
                    onSort={onSort}
                    showSelectAll={showSelectAll}
                />
                <TableBody style={{ position: 'relative' }}>
                    {isLoading ? (
                        loadingIndicator
                    ) : data?.length === 0 ? (
                        <TableRow>
                            <TableCell
                                style={{ backgroundColor: 'white' }}
                                colSpan={attributes.length + 1}
                            >
                                <Box textAlign="center">{noDataMessage}</Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {showMasterPasswordLock && (
                                <MasterPasswordLock
                                    style={masterPasswordLockStyle}
                                />
                            )}
                            {data?.map((item, index) => (
                                <TableRowComponent
                                    key={index}
                                    item={item}
                                    attributes={attributes}
                                    handleClick={handleClick}
                                    rowSelection={rowSelection}
                                    selected={selected}
                                    selectionIdentifier={selectionIdentifier}
                                    showSelectAll={showSelectAll}
                                    highLightSelectedRow={highLightSelectedRow}
                                />
                            ))}
                        </>
                    )}
                    {isLoadingMore && loadingIndicator}
                </TableBody>
            </TableComponent>
            {Boolean(errorMessage) && errorMessage}
            {(selectAllAction?.render || pagination) &&
                !isLoading &&
                data?.length > 0 && (
                    <Box
                        style={{
                            padding: '0px',
                            display: 'flex',
                            justifyContent: !selectAllAction?.render
                                ? 'center'
                                : 'space-between',
                            margin: '3.2rem 0',
                            paddingBottom: '3.2rem',
                            ...selectAllAction?.style,
                        }}
                    >
                        {selectAllAction?.render && selectAllAction.render()}
                        <Box>{pagination}</Box>
                    </Box>
                )}
        </>
    )
}

export default EidTable
