import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { Box } from '@mui/material'
import { Card } from 'packages/eid-ui'

const NullableBooleanFilterBase = ({ value, onChange, optionsConfig }) => {
    const { t } = useTranslation()

    const nullableBooleanOptions = [
        { id: 1, name: t(optionsConfig.trueLabelKey), value: 'true' },
        { id: 0, name: t(optionsConfig.falseLabelKey), value: 'false' },
        { id: -1, name: t(optionsConfig.nullLabelKey), value: 'null' },
    ]

    const defaultValue = nullableBooleanOptions.find((a) => a?.value === 'null')
    const [currentOption, setCurrentOption] = useState()

    useEffect(() => {
        if (!value) {
            setCurrentOption(defaultValue)
        } else if (value !== currentOption?.value) {
            const selectedOption = nullableBooleanOptions.find(
                (a) => a?.value === value,
            )
            setCurrentOption(selectedOption)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if (currentOption) {
            onChange(currentOption.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOption])

    const handleSelectionChange = (option) => {
        setCurrentOption(option)
    }

    return (
        <>
            {currentOption && (
                <Select
                    options={nullableBooleanOptions}
                    onChange={handleSelectionChange}
                    value={currentOption}
                    getOptionLabel={(option) => t(option.name)}
                />
            )}
        </>
    )
}

const NullableBooleanFilter = ({
    cardTitleLocaleKey,
    filterTitleLocaleKey,
    contextProp,
    optionsConfig,
}) => {
    const [state, dispatch] = usePageContext()
    const { t } = useTranslation()

    const dispatchContextChange = (value) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: contextProp,
                value: value === 'null' ? null : value,
            },
        })
    }

    const filter = (
        <Box
            padding="18px"
            style={{
                borderTop: 'solid 1px #efeff1',
                backgroundColor: '#fbfbfd',
            }}
        >
            <p
                style={{
                    fontSize: '10px',
                    color: '#b4b4b4',
                    margin: '0 0 8px 10px',
                    textTransform: 'uppercase',
                }}
            >
                {t(filterTitleLocaleKey)}
            </p>
            <NullableBooleanFilterBase
                value={state[contextProp]}
                onChange={dispatchContextChange}
                contextProp={contextProp}
                optionsConfig={optionsConfig}
            />
        </Box>
    )
    return (
        <Card
            cardTitle={t(cardTitleLocaleKey)}
            color="#fff"
            collapsible
            expanded={Boolean(state.includeBasicClaimSet)}
        >
            {filter}
        </Card>
    )
}

export default NullableBooleanFilter
