import { styled } from '@mui/styles'
import { HorizontalTabs } from 'packages/eid-ui'

const Tabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: '61px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme?.palette?.primary?.main,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },
    borderBottom: 'solid 1px #d8dadd',
}))

export default Tabs
