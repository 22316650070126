import { Box, Typography, styled, Dialog, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from 'components'
import { Icon } from 'packages/eid-icons'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TimeConstraintComp from './TimeConstraint'
import { Spinner, Tooltip } from 'packages/eid-ui'
import { isNilOrEmpty } from 'packages/core'

const useStyles = makeStyles((theme) => ({
    NewAccessAssignmentModal: {
        '& h2': {
            fontSize: '1.8rem',
            color: '#1b1f1f',
            fontWeight: '600',
        },
    },
    ConstraintModalHeader: {
        padding: '3.2rem',
    },
    AssignmentModalHeader: {
        padding: '3.2rem',
    },
    AssignmentModalHeading: {
        padding: '1.6rem 3.2rem',
        borderTop: '0.1rem solid #0000002e !important',
        borderBottom: '0.1rem solid #0000002e !important',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%) !important',
        backgroundSize: '0.8rem 0.8rem !important',
    },
    AssignmentModalInner: {
        padding: '3.2rem',
    },
    AssignmentModalFooter: {
        padding: '2.4rem 3.2rem',
        boxShadow: '0 0.3rem 0.7rem 0.4rem rgba(0, 0, 0, 0.08)',
        '& button': {
            border: `solid 0.1rem ${theme?.palette?.primary?.main}`,
            padding: '0.9rem 2.4rem',
            '&:nth-child(1)': {
                background: theme?.palette?.primary?.main,
                color: theme?.palette?.common?.white,
                maxWidth: '24rem',
                '& svg': {
                    marginRight: '1rem',
                },
            },
            '&:nth-child(2)': {
                color: theme?.palette?.primary?.main,
                marginLeft: '3rem',
                maxWidth: '9rem',
            },
        },
    },
    AssignmentTimeInner: {
        padding: '2rem 3.2rem',
        paddingTop: '0',
    },
}))

const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '43rem',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '83rem',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-1rem',
        right: '-1rem',
        cursor: 'pointer',
    },
})

const AddTimeConstraint = ({
    btnLabel,
    iconName,
    startTime,
    endTime,
    onSave = () => {},
    saving = false,
    disabledTooltip = '',
}) => {
    const theme = useTheme()
    const [openAddTimeConstraintModal, setOpenAddTimeConstraintModal] =
        useState(false)
    const { t } = useTranslation()
    const classes = useStyles({})
    const [{ selectedStartDate, selectedEndDate }, setSelectedDate] = useState({
        selectedStartDate: startTime,
        selectedEndDate: endTime,
    })
    const [timeConstrained, setTimeConstrained] = useState(
        selectedEndDate && selectedStartDate ? true : false,
    )

    useEffect(() => {
        if (timeConstrained) {
            const currentDatetime = startTime
                ? startTime
                : moment.utc().second(0)
            const endDatetime = endTime ? endTime : null
            setSelectedDate({
                selectedStartDate: currentDatetime,
                selectedEndDate: endDatetime,
            })
        } else {
            setSelectedDate({
                selectedStartDate: null,
                selectedEndDate: null,
            })
        }
    }, [startTime, endTime, timeConstrained])

    const handleStartDate = (startDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedStartDate: startDateToSet }
        })
    }

    const handleEndDate = (endDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedEndDate: endDateToSet }
        })
    }

    const hasInvalidDates =
        timeConstrained &&
        (isNilOrEmpty(selectedEndDate) ||
            moment.utc(selectedStartDate).isAfter(moment.utc(selectedEndDate)))

    let invalidDatesMessage = ''

    if (hasInvalidDates) {
        if (isNilOrEmpty(selectedEndDate)) {
            invalidDatesMessage = t('InvalidEndDateTime')
        }
        if (
            moment.utc(selectedStartDate).isAfter(moment.utc(selectedEndDate))
        ) {
            invalidDatesMessage = t('EndDateGreaterThanStartDate')
        }
    }

    const handleModalClose = () => {
        setSelectedDate((prev) => {
            return {
                ...prev,
                selectedEndDate: endTime,
                selectedStartDate: startTime,
            }
        })

        setTimeConstrained(endTime && startTime ? true : false)

        setOpenAddTimeConstraintModal(false)
    }

    return (
        <>
            <Tooltip title={disabledTooltip}>
                <Box>
                    <Button
                        disabled={Boolean(disabledTooltip)}
                        onClick={() => setOpenAddTimeConstraintModal(true)}
                        style={{
                            borderRadius: '0.4rem',
                            padding: '0.6rem 0.7rem',
                            backgroundColor: theme?.palette?.primary?.main,
                            color: theme?.palette?.common?.white,
                            fontSize: '1.4rem',
                            minWidth: '2.4rem',
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.main,
                            },
                            '& svg': {
                                width: '1.6rem',
                                height: '1.6rem',

                                '& path': {
                                    stroke: Boolean(disabledTooltip)
                                        ? '#aab0b4'
                                        : theme?.palette?.common?.white,
                                },
                            },
                        }}
                    >
                        {iconName ? (
                            <Icon name={iconName} />
                        ) : btnLabel ? (
                            t(btnLabel)
                        ) : (
                            ''
                        )}
                    </Button>
                </Box>
            </Tooltip>
            <CustomDialog
                open={openAddTimeConstraintModal}
                onClose={() => handleModalClose()}
                title={t('')}
            >
                <Box className={classes.NewAccessAssignmentModal}>
                    <Box className={classes.ConstraintModalHeader}>
                        <CloseIconeBox onClick={() => handleModalClose()}>
                            <Icon
                                name="Close"
                                width="1.6rem"
                                height="1.6rem"
                                color="#959598"
                            />
                        </CloseIconeBox>
                        <Typography variant="h2">
                            {t('TimeConstraint')}
                        </Typography>
                    </Box>
                    <TimeConstraintComp
                        handleStartDate={handleStartDate}
                        handleEndDate={handleEndDate}
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        hasInvalidDates={hasInvalidDates}
                        validationMessage={invalidDatesMessage}
                        timeConstrained={timeConstrained}
                        handleTimeConstraint={setTimeConstrained}
                    />
                    <Box className={classes.AssignmentModalFooter}>
                        <Button
                            onClick={() => {
                                onSave({
                                    selectedStartDate: selectedStartDate
                                        ? moment(selectedStartDate)
                                              .utc()
                                              .format()
                                        : null,
                                    selectedEndDate: selectedEndDate
                                        ? moment(selectedEndDate).utc().format()
                                        : null,
                                })
                                setOpenAddTimeConstraintModal(false)
                            }}
                            backgroundColor={theme?.palette?.primary?.main}
                            disabled={hasInvalidDates}
                            disabledStyles={{
                                backgroundColor: '#aab0b4 !important',
                                borderColor: '#aab0b4 !important',
                                color: `${theme?.palette?.common?.white} !important`,
                                display: 'inline-block',
                            }}
                        >
                            {!saving ? (
                                <Icon
                                    name="Save"
                                    width="1.6rem"
                                    height="1.6rem"
                                    color={theme?.palette?.common?.white}
                                />
                            ) : (
                                <Spinner
                                    style={{ marginRight: '1rem' }}
                                    size={0.8}
                                    color={theme?.palette?.common?.white}
                                />
                            )}
                            {t('Save')}
                        </Button>
                        <Button onClick={() => handleModalClose()}>
                            {t('Cancel')}
                        </Button>
                    </Box>
                </Box>
            </CustomDialog>
        </>
    )
}

export default AddTimeConstraint
