import React from 'react'
import Button from './index'
import { ArrowIcon } from 'packages/eid-icons'
import { useTheme } from '@mui/material'



const BackButton = ({ ...rest }) => {
    const theme = useTheme()

    const styles = {
        normal: {
            width: '100%',
            minWidth: '95px',
            backgroundColor: '#ffffff',
            color: theme?.palette?.primary?.main,
            border: `1px solid ${theme?.palette?.primary?.main}`,
            '&:hover': {
                filter: 'brightness(0.99)',
            },
            '&:active': {},
        },
        disabled: {
            backgroundColor: '#aab0b4 !important',
            color: '#ffffff !important',
        },
    }
    
    return (
        <Button
            size="medium"
            style={styles.normal}
            disabledStyles={styles.disabled}
            startIcon={
                <ArrowIcon
                    style={{ transform: 'rotate(-90deg)' }}
                    color={theme?.palette?.primary?.main}
                />
            }
            {...rest}
        />
    )
}

export default BackButton
