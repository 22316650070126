import React, { useState } from 'react'
import { ArrowSmallIcon } from 'packages/eid-icons'
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useTranslation } from 'react-i18next'

const useGreenStyles = makeStyles(
    createStyles({
        root: {
            // minWidth: '177px',
            height: '31px',
            lineHeight: 'initial',
            borderRadius: '4px',
            padding: '8px 12px',
            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',

            backgroundColor: '#01ae8f',
            border: 'solid 1px #01ae8f',

            color: 'white',
            '&:hover': {
                cursor: 'hand',
                backgroundColor: '#fff',
                color: '#01ae8f',
            },
        },
    }),
)
const DetailsButton = ({ onClick, showEndIcon = false, ...rest }) => {
    const greenClasses = useGreenStyles()
    const { t } = useTranslation()

    const [detailsButtonIconColor, setDetailsButtonIconColor] =
        useState('#01ae8f')

    return (
        <Button
            onMouseEnter={() => {
                setDetailsButtonIconColor('white')
            }}
            onMouseLeave={() => {
                setDetailsButtonIconColor('#01ae8f')
            }}
            classes={greenClasses}
            onClick={onClick}
            endIcon={
                showEndIcon && (
                    <ArrowSmallIcon
                        color={detailsButtonIconColor}
                        direction={'right'}
                        fill={detailsButtonIconColor}
                    />
                )
            }
            {...rest}
        >
            {t('Details')}
        </Button>
    )
}

export default DetailsButton
