import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useManagementRoleAllMembers } from 'hooks'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl, imagesDisabled } from 'utils'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Filters } from 'components'

export const AllMembers = ({ managementRole }) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        pagination,
    } = useManagementRoleAllMembers(managementRole?.id)

    const attributes = [
        {
            label: t(''),
            name: 'image',
            image: true,
            headerCellStyles: {
                width: '8%',
            },
            styles: {
                width: '8%',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-AllMembers-List-Image',
            },
            hideValue: true,
            showInCard: false,
            component: {
                componentName: 'renderer',
                render: (item) =>
                    !imagesDisabled ? (
                        <Avatar
                            size="medium"
                            src={getFullImageUrl(item.imageThumbUrl)}
                            style={{
                                boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
                            }}
                        />
                    ) : (
                        <></>
                    ),
            },
        },
        {
            hideValue: true,
            showInCard: false,
            showInTable: false,
            image: true,
            component: {
                componentName: 'ResourceImage',
                name: 'imageThumbUrl',
                defaultUrl: '/webcdn/Images/AppLogos/Genric-1.png',
            },
        },
        {
            label: t('DisplayName'),
            name: 'friendlyName',
            sortable: true,
            styles: {
                fontWeight: 'bold',
                width: '46%',
            },
            headerCellStyles: {
                width: '46%',
            },
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ManagementRoleDetails-Overview-AllMembers-List-DisplayName',
            // },
        },
        {
            label: t('Login'),
            name: 'login',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-AllMembers-List-Login',
            },
        },
    ]

    const filteredAttributes = getAccessBasedAttributes(attributes).filter(
        (x) => !x.image || !imagesDisabled,
    )

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                paddingBottom="16px"
            >
                {/* TODO: Need to incorporate logic for adding members when endpoint available */}
                <Box width="308px" display="flex" alignItems="center">
                    <Filters.TextSearch outlined />
                </Box>
            </Box>

            <EidTableGrid
                noDataMessage={t('NoDataMessage')}
                pagination={pagination}
                isLoading={isLoading}
                attributes={filteredAttributes}
                list={list}
            />
        </>
    )
}
