import { FC } from 'react'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { getFullImageUrl } from 'utils'
import { Tooltip } from 'packages/eid-ui'
import { Instructions } from 'components/ApplicationBanner/Instructions'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'

export interface IApplicationImageProps {
    attribute: any
    data: any
    imageClass?: any
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '32px',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
        icon: {
            position: 'absolute',
            right: '-6px',
            top: '-6px',
        },
    }),
)

const ResourceImage: FC<IApplicationImageProps> = (props) => {
    const classes = useStyles()

    const { t } = useTranslation()

    const { attribute, data, imageClass } = props
    const url = data.item[attribute.component.name]
    const defaultUrl = attribute.component.defaultUrl
    const getTooltipTitle = () => t('MoreInfo')
    return (
        <Box className={classes.root}>
            <img
                alt={t('ResourceImage')}
                className={imageClass}
                src={
                    isNilOrEmpty(url)
                        ? getFullImageUrl(defaultUrl)
                        : getFullImageUrl(url)
                }
            />

            {data.instructions && (
                <Instructions
                    title={data.friendlyName}
                    instructions={data.instructions}
                    button={(onClick: any) => (
                        <Tooltip title={getTooltipTitle()}>
                            <Box
                                className={classes.icon}
                                onClick={() => onClick(true)}
                            >
                                <Icon
                                    name="Info"
                                    color="#8b909a"
                                    width="12px"
                                    height="12px"
                                />
                            </Box>
                        </Tooltip>
                    )}
                />
            )}
        </Box>
    )
}
export default ResourceImage
