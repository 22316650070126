import { Box, styled } from '@mui/material'
import { useApplicationSettings } from 'hooks'
import ItemCard from 'components/ItemCard'
import { mobileScreenWidth, imagesDisabled } from 'utils'

const Container = styled(Box)({
    display: 'inline-block',
    width: '33%',

    overflow: 'hidden',
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

const CardView = ({
    item,
    attributes,
    renderLeftButton,
    renderRightButton,
}) => {
    const { data: appConfig } = useApplicationSettings()

    return (
        <Container>
            <ItemCard
                key={item.key}
                cardStyles={{
                    margin: '14px',
                }}
                headerStyles={{
                    backgroundImage: appConfig.style.colors.primary?.gradient,
                    backgroundColor: appConfig.style.colors.primary?.header,
                    padding: '20px',
                }}
                attributes={attributes}
                item={item}
                showImage={!imagesDisabled}
                rightButton={
                    renderRightButton ? renderRightButton(item) : <></>
                }
                leftButton={renderLeftButton ? renderLeftButton(item) : <></>}
            />
        </Container>
    )
}

export default CardView
