import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAllApplications } from 'hooks'
import LanguageMenu from '../AppHeader/LanguageMenu'
import { GlobalMenu, IconButton } from 'packages/eid-ui'
import { Icon, CloseIcon, ArrowIcon } from 'packages/eid-icons'
import {
    List,
    Divider as MuiDivider,
    styled,
    Box,
    Typography,
    Link,
    Accordion as MuiExpansionPanel,
    AccordionSummary as MuiExpansionPanelSummary,
    AccordionDetails as MuiExpansionPanelDetails,
    Collapse,
    useTheme,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ListItem from './ListItem'
import { useRouteOptions } from 'hooks'
import { useHistory } from 'react-router-dom'

const StyledList = styled(List)({
    width: '100%',
    padding: '0px 8px',
})

const Divider = styled(MuiDivider)({
    margin: '10px 0px',
})

const StyledPanel = withStyles({
    root: {
        border: 'none !important',
        '&:not(:last-child)': {
            borderBottom: '0px !important',
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {
        margin: '0 !important',
    },
    disabled: {
        backgroundColor: '#ffffff !important',
        pointerEvents: 'none',
        opacity: 0.66,
    },
})(MuiExpansionPanel)

const StyledPanelSummary = withStyles({
    root: {
        minHeight: '0 !important',
        height: '35px',
        margin: '0 !important',
        borderBottom: 'none !important',
        padding: '0px',
    },
    expanded: {
        minHeight: '0 !important',
        marginTop: '0 !important',
        marginBottom: '0 !important',
    },
    content: { margin: '0px' },
})(MuiExpansionPanelSummary)

const StyledPanelDetails = withStyles({
    root: {
        display: 'block',
        padding: 0,
    },
})(MuiExpansionPanelDetails)

const MobileMenu = ({ ...rest }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { data, isLoading } = useAllApplications()

    const { routeOptions } = useRouteOptions()

    const eidApp = data?.find((a) => a.applicationName === 'EIDEmpowerIDWeb')

    const styles = {
        closeIcon: {
            margin: '14px 0px 10px 15px',
            width: '12px',
            padding: 0,
        },
        appTitle: {
            fontSize: '16px',
            color: theme?.palette?.primary?.main,
            fontWeight: 'bold',
            textTransform: 'capitalize',
            padding: '0 8px',
        },
        appIcon: {},
        listTitle: {
            fontSize: 13,
            lineHeight: 1.33,
            color: '#000000',
            margin: '8px ',
            padding: '0px 6px',
        },

        settingsTitle: {
            fontSize: 20,
            margin: '20px 16px',
            color: '#363636',
            fontWeight: 'bold',
        },

        logOutIcon: {
            width: '20px',
            height: '20px',
        },

        logOutlabel: {
            fontSize: 14,
            padding: '0 10px',
            color: '#000000',
        },

        languageLabel: {
            fontSize: 12,
            padding: '0 20px',
            color: '#000000',
        },

        helpLabel: {
            fontSize: 14,
            padding: '0 20px',
            color: '#000000',
        },

        listContainer: {
            width: '100%',
            height: 'calc(100% - 30px)',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    }

    const appNameMaps = {
        EIDEmpowerIDWeb: 'EmpowerID',
        EIDAzureAnalyticsMS: 'AzureAnalytics',
        EIDITShopMS: 'ITShop',
        EIDMyIdentity: 'MyIdentity',
        EIDMyTasks: 'MyTasks',
        EIDResourceAdminMS: 'ResourceAdmin',
        EIDRiskAnalyticsMS: 'RiskAnalytics',
    }

    const Title = ({ title, icon }) => (
        <Box
            display="flex"
            alignItems="center"
            padding="30px 10px 20px 10px"
            width="100%"
        >
            <Icon name={icon} color={theme?.palette?.primary?.main} fill="none" />
            <Typography style={styles.appTitle}>{title}</Typography>
        </Box>
    )

    const Section = ({ section, handleClose }) => {
        const [_expanded, setExpanded] = React.useState(false)

        const { t } = useTranslation()
        const theme = useTheme()
        const history = useHistory()
        const {
            location: { pathname },
        } = history

        const { routeOptions } = useRouteOptions()

        let selectedRoute

        for (let i = 0; i < routeOptions.length; i++) {
            const foundRoute = routeOptions[i].children.find((c) => {
                return c.route.toLowerCase() === pathname.toLowerCase()
            })

            if (foundRoute) {
                selectedRoute = foundRoute
                break
            }
        }

        // TODO : To be configured when pages views are restricted under subcomponents
        // const checkAccess = (areaName, route) => {
        //     const name = route.split('/')[2]?.toLowerCase()

        //     if (name === 'businessrequestitems') {
        //         return hasAccessToItemView(areaName)
        //     } else if (name === 'businessrequests') {
        //         return hasAccessToRequestView(areaName)
        //     }
        // }

        return (
            <Box style={{ padding: '0px 8px' }}>
                <StyledPanel
                    expanded={_expanded}
                    onChange={() => setExpanded(!_expanded)}
                    style={{ boxShadow: 'none' }}
                >
                    <StyledPanelSummary
                        expandIcon={
                            <ArrowIcon
                                direction="down"
                                color={theme?.palette?.primary?.main}
                            />
                        }
                    >
                        <Typography style={styles.listTitle}>
                            {t(section.title)}
                        </Typography>
                    </StyledPanelSummary>

                    <Collapse in={_expanded} timeout={400} unmountOnExit>
                        <StyledPanelDetails>
                            <StyledList>
                                {section.children.map((c) => (
                                    <ListItem
                                        key={c.title}
                                        to={c.route}
                                        onClick={handleClose}
                                        active={selectedRoute.route === c.route}
                                    >
                                        {t(c.title)}
                                    </ListItem>
                                ))}
                            </StyledList>
                        </StyledPanelDetails>
                    </Collapse>
                </StyledPanel>
                <Divider variant="middle" />
            </Box>
        )
    }

    const SignOut = ({ label }) => (
        <Link href={'/signout'} underline="none">
            <Box paddingLeft="16px" display="flex" alignItems="center">
                <Icon
                    name={'LogOut'}
                    color={theme?.palette?.primary?.main}
                    style={styles.logOutIcon}
                />
                <Typography style={styles.logOutlabel}>{label}</Typography>
            </Box>
        </Link>
    )

    const Help = ({ label }) => (
        <Box paddingLeft="16px" display="flex" alignItems="center">
            <Icon name={'Help'} color={theme?.palette?.primary?.main} style={styles.logOutIcon} />
            <Typography style={styles.helpLabel}>{label}</Typography>
        </Box>
    )

    const Language = ({ label }) => (
        <Box paddingLeft="16px" display="flex" alignItems="center">
            <LanguageMenu
                display={
                    <>
                        <Icon
                            name={'Language'}
                            width="20px"
                            height="20px"
                            color={theme?.palette?.primary?.main}
                            style={styles.logOutIcon}
                        />
                        <Typography style={styles.languageLabel}>
                            {label}
                        </Typography>
                    </>
                }
                anchorPosition={{ bottom: '30px' }}
                anchorOriginPosition={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorTransformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                iconPosition={{
                    top: '272px',
                    right: '167px',
                    transform: 'rotate(180deg)',
                }}
            />
        </Box>
    )

    const Settings = () => {
        const { t } = useTranslation()
        return (
            <Box Box width="100%" marginY="30px">
                <Typography style={styles.settingsTitle}>
                    {t('Settings')}
                </Typography>
                <Language label={t('Language')} />
                <div
                    style={{
                        width: '100%',
                        padding: '0px 8px',
                    }}
                >
                    <Divider variant="middle" style={{ margin: '10px 10px' }} />
                </div>
                <Help label={t('Help')} />
            </Box>
        )
    }

    return (
        <GlobalMenu.Mobile
            loading={isLoading}
            eid={
                eidApp
                    ? {
                          name: appNameMaps[eidApp.applicationName],
                          displayName: eidApp.applicationDisplayName,
                          url: eidApp.applicationAbsoluteUrl,
                      }
                    : undefined
            }
            apps={data
                ?.filter((f) => f.applicationName !== 'EIDEmpowerIDWeb')
                .map((a) => ({
                    name: appNameMaps[a.applicationName],
                    displayName: a.applicationDisplayName,
                    url: a.applicationAbsoluteUrl,
                }))}
            current="ResourceAdmin"
            {...rest}
        >
            {(handleClose) => (
                <>
                    <Box style={{ width: '100%' }} position="relative">
                        <IconButton onClick={handleClose} style={styles.closeIcon} size="large">
                            <CloseIcon color="#919193" />
                        </IconButton>
                        <Box style={styles.listContainer}>
                            <Box>
                                <Title
                                    title={t('ResourceAdmin')}
                                    icon={'ResourceAdmin'}
                                />

                                <div
                                    style={{
                                        width: '100%',
                                        padding: '0px 8px',
                                    }}
                                >
                                    <Divider variant="middle" />
                                </div>

                                {routeOptions.map((section, sectionIndex) => (
                                    <span key={section.name}>
                                        <Section
                                            section={section}
                                            sectionIndex={sectionIndex}
                                            handleClose={handleClose}
                                        />
                                    </span>
                                ))}
                            </Box>
                            <Box width="100%" paddingY="24px">
                                <Settings />
                                <SignOut label={t('Logout')} />
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </GlobalMenu.Mobile>
    );
}

export default MobileMenu
