import { Box, styled } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAppManagementRoleAccessGranted } from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import DateTimeGenericComponent from 'components/DynamicComponents/DateTimeGenericComponent'
import Filters from 'components/Filters'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const AccessGranted = ({ roleId, resourceTypeId }) => {
    const { t } = useTranslation()

    const { list, loading, loadingMore, pagination } =
        useAppManagementRoleAccessGranted(roleId, resourceTypeId)
    const { getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            name: 'assigneeType',
            sortable: true,
            sortBy: 'assigneeType',
            label: 'AssigneeType',

            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-AssigneeType',
            },
        },
        {
            name: 'assigneeFriendlyName',
            sortable: true,
            sortBy: 'assigneeFriendlyName',
            label: 'Assignee',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-Assignee',
            },
        },
        {
            name: 'assigneeTechnicalName',
            sortable: true,
            sortBy: 'assigneeTechnicalName',
            label: 'AssigneeTechnicalName',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-AssigneeTechnicalName',
            },
        },
        {
            name: 'resourceFriendlyName',
            sortable: true,
            sortBy: 'resourceFriendlyName',
            label: 'Resource',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-Resource',
            },
        },
        {
            name: 'resourceTypeFriendlyName',
            sortable: true,
            sortBy: 'resourceTypeFriendlyName',
            label: 'ResourceType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-ResourceType',
            },
        },

        {
            label: 'TimeConstraint',
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-TimeConstraint',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 20 }}>
                            <Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 10,
                                    }}
                                >
                                    <strong>{t('Starts')}:</strong>
                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'startDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 17,
                                        marginTop: '5px',
                                    }}
                                >
                                    <strong>{t('Ends')}:</strong>

                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'endDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                            </Box>
                            {/* Unhide this when backend functionality is implemented */}
                            {/* <AddTimeConstraint
                                data={data}
                                iconName={'EditFile'}
                                onSave={(data) => {
                                    console.log(data)
                                }}
                                startTimeProp="startDateUtc"
                                endTimeProp="endDateUtc"
                            /> */}
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
                padding="16px 30px"
            >
                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default AccessGranted
