import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import { useIsSmallScreenFalse } from 'packages/core'
import { useAppManagementRoles } from 'hooks'
import { Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../../CreateWorkflowButton'
import { useHistory } from 'react-router'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'

const hasAccessToDetailsButtonControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-DetailsButton-Control'
const hasAccessToUIActionsControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-UIActionsButton-Control'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})

const AppManagementRoles = ({ applicationId, applicationResourceId }) => {
    const { t } = useTranslation()
    const {
        canAddNewAppManagementRoles,
        hasAccessToControl,
        getAccessBasedAttributes,
    } = useSubcomponents()
    const history = useHistory()
    const isSmallScreen = useIsSmallScreenFalse()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useAppManagementRoles(applicationId)

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            label: 'FriendlyName',
            moreInfoIcon: true,
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-AppManagementRole-FriendlyName',
            // },
            styles: {
                fontWeight: 'bold',
            },
        },

        {
            name: 'isHighSecurity',
            sortable: true,
            label: 'IsHighSecurity',
            component: {
                componentName: 'BooleanValue',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-IsHighSecurity',
            },
        },
        {
            name: 'managementRoleTypeFriendlyName',
            sortable: true,
            label: 'Type',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-Type',
            },
        },
        {
            name: 'description',
            sortable: true,
            label: 'Description',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-Description',
            },
        },
        {
            hideValue: true,
            showInCard: false,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            {hasAccessToControl(
                                hasAccessToDetailsButtonControlName,
                            ) && (
                                <Box
                                    data-protectedsubcomponent={
                                        hasAccessToDetailsButtonControlName
                                    }
                                >
                                    <Button.Details
                                        item={item}
                                        onClick={() => {
                                            history.push(
                                                `${history.location.search}&appManagementRoleId=${item?.id}`,
                                            )
                                        }}
                                    />
                                </Box>
                            )}
                            <span style={{ marginRight: '10px' }} />

                            {hasAccessToControl(
                                hasAccessToUIActionsControlName,
                            ) && (
                                <Box
                                    data-protectedsubcomponent={
                                        hasAccessToUIActionsControlName
                                    }
                                >
                                    <UIActionsGearButton item={item} noun='ResourceAdminPbacDefinitions' />
                                </Box>
                            )}
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
            >
                {canAddNewAppManagementRoles && (
                    <Box
                        data-protectedsubcomponent={canAddNewAppManagementRoles}
                    >
                        <CreateWorkflowButton
                            label={t('AddNewAppManagemetRoles')}
                            workflowName="OnboardManagementRole"
                            resourceId={applicationResourceId}
                            selectedApplicationId={applicationId}
                        />
                    </Box>
                )}
                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                    onClickDetailButton={(item) => {
                        history.push(
                            `${history.location.search}&appManagementRoleId=${item?.id}`,
                        )
                    }}
                />
            </TableOverView>
        </Box>
    )
}

export default AppManagementRoles
