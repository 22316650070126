import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import { isNilOrEmpty, useIsSmallScreenFalse } from 'packages/core'
import { ListingGrid, GridContainer, Filters } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Icon } from 'packages/eid-icons'
import { EidAutocomplete } from 'packages/eid-controls'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useTotalAccessGrantedByApplication } from 'hooks'
import { useAppState } from 'appContext'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'friendlyName',
})
export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))

const canAddNewAccessAssignmentControlName =
    'ResourceAdmin-ApplicationDetails-AccessGranted-AddNewAccessAssignment-Control'
const canSelectAppManagementRoleControlName =
    'ResourceAdmin-ApplicationDetails-AccessGranted-SelectAppManagementRole-Control'
const EditTimeConstraint = styled(Box)(({ theme }) => ({
    height: '28px',
    width: '28px',
    borderRadius: '4px',
    padding: '4px 7px',
    backgroundColor: theme?.palette?.primary?.main,

    '& svg': {
        marginRight: '8px',
        '& path': {
            stroke: '#fff',
        },
    },
}))
const AccessGranted = ({ applicationId }) => {
    const { t } = useTranslation()
    const [{ devFeaturesEnabled }] = useAppState()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [selectedRole, setSelectedRole] = useState(null)
    const queryParams = {
        take: 10,
    }
    const getOptionLabel = (option) => option && option.friendlyName
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useTotalAccessGrantedByApplication(
        applicationId,
        selectedRole?.id ? selectedRole.id : null,
    )

    const isSmallScreen = useIsSmallScreenFalse()
    const attributes = [
        {
            name: 'managementRoleName',
            sortable: true,
            label: 'ManagementRoleName',
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-ManagementRoleName',
            // },
        },
        {
            name: 'resourceTypeRoleName',
            sortable: true,
            label: 'AccessLevel',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-AccessLevel',
            },
        },
        {
            name: 'friendlyName',
            sortable: true,
            label: 'Resource',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-Resource',
            },
        },
        {
            name: 'resourceSystemFriendlyName',
            sortable: true,
            label: 'ResourceSystem',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-ResourceSystem',
            },
        },
    ]

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    {hasAccessToControl(
                        canSelectAppManagementRoleControlName,
                    ) && (
                        <Box
                            data-protectedsubcomponent={
                                canSelectAppManagementRoleControlName
                            }
                            width={'100%'}
                        >
                            <EidAutocomplete.New
                                placeholder={t('AppManagementRole')}
                                url={`api/ManagementRoles/application/${applicationId}`}
                                queryParams={queryParams}
                                dedupingInterval={60000}
                                value={selectedRole ? selectedRole : null}
                                getOptionLabel={getOptionLabel}
                                clearIcon={
                                    !isNilOrEmpty(selectedRole) && (
                                        <Icon
                                            name="Close"
                                            color="#959598"
                                            onClick={() =>
                                                setSelectedRole(null)
                                            }
                                        />
                                    )
                                }
                                onChange={(_, data) => {
                                    setSelectedRole(
                                        data?.value === 'all' ? null : data,
                                    )
                                }}
                                rootStyleProps={{ height: '40px !important' }}
                                inputWrapperStyleProps={{
                                    height: '36px',
                                    width: 'fit-content',
                                }}
                                optionComponent={OptionComponent}
                                clearFilterOption={{
                                    friendlyName: 'All',
                                    value: 'all',
                                }}
                            />
                        </Box>
                    )}
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default AccessGranted
