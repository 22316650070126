import { FC } from 'react'
import { Box, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment'
import { isNilOrEmpty } from 'packages/core'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers'

export interface EndDateTimePickerProps {
    selectedEndDate: any
    setSelectedEndDate: any
    minEndDate?: any
    maxEndDate?: any
    hasInvalidDates: any
    validationMessage: any
}

const useStyles = makeStyles((theme) =>
    createStyles({
        policyViolationMessage: {
            position: 'absolute',
            fontSize: '12px',
            color: theme.palette.error.main,
            marginRight: '12px',
            wordBreak: 'break-word',
        },
    }),
)

export const EndDateTimePicker: FC<EndDateTimePickerProps> = ({
    selectedEndDate,
    setSelectedEndDate,
    minEndDate,
    maxEndDate,
    hasInvalidDates,
    validationMessage,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <>
            <NativeDateTimePicker
                minDate={
                    minEndDate
                        ? minEndDate.format('YYYY-MM-DDTHH:mm')
                        : moment(new Date()).format('YYYY-MM-DDTHH:mm')
                }
                maxDate={maxEndDate?.format('YYYY-MM-DDTHH:mm')}
                value={moment(selectedEndDate)
                    .clone()
                    .local()
                    .format('YYYY-MM-DDTHH:mm')}
                handleChange={(e: any) => {
                    if (isNilOrEmpty(e)) {
                        setSelectedEndDate(moment().utc().format())
                    } else {
                        const date = moment(e)
                        const dateToSet = date.clone().utc().format()
                        setSelectedEndDate(dateToSet)
                    }
                }}
                pickerStyles={{ top: '22px', right: '10px', width: 'auto' }}
                customTimeControl={false}
                disabledTimeSlots={[]}
                clearBtnLabel={t('Clear')}
                todayBtnLabel={t('Today')}
            >
                <TextField
                    type="text"
                    value={
                        selectedEndDate
                            ? moment(selectedEndDate).local().format('L LT')
                            : ''
                    }
                    label={t('EndDateTime')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </NativeDateTimePicker>
            {hasInvalidDates && validationMessage && (
                <Box className={classes.policyViolationMessage}>
                    {validationMessage}
                </Box>
            )}
        </>
    )
}
