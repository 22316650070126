import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box, useTheme } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import GenericButton from 'components/Button/GenericButton'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { useHistory } from 'react-router-dom'
import config from 'config'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            width: '100%',
        },

        basicInfo: {
            display: 'flex',
        },
        attributeWrapper: {
            paddingTop: '0 !important',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: theme?.palette?.primary?.main,
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
        actionButtons: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'self-end',
        },
    }),
)

export const MainOverview = ({ policy }) => {
    const query = useQuery()
    const history = useHistory()

    const basicAttributes = [
        {
            label: 'FriendlyName',
            value: 'friendlyName',
        },
        {
            label: 'TechnicalName',
            value: 'name',
        },
        {
            label: 'ResourceSystem',
            value: 'resourceSystemName',
        },
    ]

    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()

    const buttonsStyles = {
        borderRadius: '4px',
        height: '32px',
        fontSize: '14px',
        padding: '6px',
        border: `solid 1px ${theme?.palette?.primary?.main}`,
    }

    const policyDetails = (
        <Box display="flex" justifyContent="space-between">
            <Box className={classes.basicInfo}>
                {basicAttributes &&
                    basicAttributes.map((att, i) => {
                        return (
                            <Attribute
                                key={att.value + i}
                                className={classes.attributeWrapper}
                            >
                                <AttributeLabel>{t(att.label)}</AttributeLabel>
                                <AttributeValue>
                                    {policy && policy[att.value]}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
            </Box>
            <Box className={classes.actionButtons}>
                <GenericButton
                    onClick={() => {
                        query.set(
                            'workflowName',
                            'UpdateAzureClaimsMappingPolicy',
                        )
                        query.set('SelectedResourceID', policy.resourceId)
                        query.set('returnUrl', getFullPathFromUrl())
                        history.push(
                            `${
                                config.APP_SUBPATH
                            }/applications/workflows?${query.toString()}`,
                        )
                    }}
                    color={theme?.palette?.primary?.main}
                    rootStylesProp={buttonsStyles}
                >
                    {t('AddRemoveClaims')}
                </GenericButton>
                <GenericButton
                    onClick={() => {
                        query.set(
                            'workflowName',
                            'UpdateAzAppClaimsMappingPolicyAssignments',
                        )
                        query.set('SelectedResourceID', policy.resourceId)
                        query.set('returnUrl', getFullPathFromUrl())
                        history.push(
                            `${
                                config.APP_SUBPATH
                            }/applications/workflows?${query.toString()}`,
                        )
                    }}
                    color={theme?.palette?.primary?.main}
                    rootStylesProp={{ ...buttonsStyles, marginTop: '16px' }}
                >
                    {t('AssignUnassignApplications')}
                </GenericButton>
            </Box>
        </Box>
    )

    return (
        <Box className={classes.content}>
            <Box className={classes.section}>{policyDetails}</Box>
        </Box>
    )
}
