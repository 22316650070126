import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDeleteIAMShopAssignees, useIAmShopAssignees } from 'hooks'
import { EidButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useDebounce } from 'packages/core'
import TableGrid from 'components/TableGrid'
import Search from 'components/Filters/Search'

import DeleteAssignee from './DeleteAssignee'
import AddIAMShopAssignee from './AddIAMShopAssignee'
import { PageContextProvider } from 'pageContext'

const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 16px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const IAmShopAssignees = ({ id }) => {
    const { t } = useTranslation()
    const tableClasses = useTableStyles()

    const [selected, setSelected] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })
    const selectedCount = selected.length
    const isSelected = selectedCount > 0
    const [deleteAssignee, { isLoading: isDeletingAssignee, isSuccess }] =
        useDeleteIAMShopAssignees(id)
    useEffect(() => {
        if (isSuccess) {
            setSelected([])
        }
    }, [isSuccess, isDeletingAssignee])

    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const handleSelect = (selectedArray) => {
        setSelected(selectedArray)
    }

    const handleDelete = () => {
        deleteAssignee(selected)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
        sorting,
    }

    const {
        list,
        loading: isLoading,
        pagination,
    } = useIAmShopAssignees(id, queryData)

    const attributes = [
        {
            label: t('AssigneeTechnicalName'),
            name: 'assigneeTechnicalPreviewName',
            sortable: true,
        },
        {
            label: t('IAmShopPermissionLevel'),
            name: 'localizedAccessLevelFriendlyName',
            sortable: true,
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('AccessFullfilledByAssignee'),
            sortable: true,
        },
        {
            name: 'azLocalRoleFriendlyName',
            label: t('LocalRole'),
            sortable: true,
        },

        {
            label: t(''),
            name: 'actions',
            resolve: (item) => (
                <DeleteAssignee
                    applicationId={id}
                    item={item}
                    drawerView={false}
                />
            ),
            style: {
                textAlign: 'end',
            },
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                <PageContextProvider key="AddNewIAmShopAssignees">
                    <AddIAMShopAssignee />
                </PageContextProvider>

                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchFilterAssignees')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>

            <TableGrid
                loading={isLoading}
                data={list}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
                sorting={sorting}
                onSort={handleSort}
                onSelect={handleSelect}
                selected={selected}
                tableClasses={tableClasses}
                rowSelection
            />

            <Box
                display="flex"
                paddingTop="40px"
                justifyContent="space-between"
            >
                <Box width="300px">
                    <EidButton.DrawerActionButton
                        disabled={!isSelected}
                        fontColor="#ffffff"
                        bgColor="#E40000"
                        width="100%"
                        onClick={handleDelete}
                        loading={isDeletingAssignee}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            height={'12px'}
                            width={'12px'}
                        >
                            <Icon
                                name="DeleteX"
                                fill="#ffffff"
                                color="#ffffff"
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t('RemoveSelectedAssignees', {
                                    count: selectedCount,
                                })}
                            </Typography>
                        </Box>
                    </EidButton.DrawerActionButton>
                </Box>
                <Box style={{ backgroundColor: '#fff', height: '100%' }}>
                    {pagination}
                </Box>
            </Box>
        </>
    )
}
