import { Box, FormLabel, Input, styled, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import classNames from 'classnames'
import BusinesRoleAndLocationTree from 'components/BusinesRoleAndLocationTree/BusinesRoleAndLocationTree'
import { useDynamicFormTreeTree } from 'hooks'
import { isNilOrEmpty, useDebounce } from 'packages/core'
import { ArrowIcon, Icon } from 'packages/eid-icons'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'

const StyledTreeContainer = styled(Box)({
    maxHeight: '200px',
    overflowY: 'auto',
    width: '100%',
    position: 'absolute',
    top: '60px',
    background: '#fff',
    borderRadius: '5px',
    zIndex: '9',
    padding: '10px',
    boxShadow: '0 4px 6px 0 #00000012',
    border: '1px solid #d4d4d8',
    '& div': {
        marginTop: '0',
    },
    '& .MuiInputBase-adornedEnd': {
        width: '100%',
        marginBottom: '20px',
        padding: '4px 10px',
        boxShadow: '0 4px 6px 0 #00000012',
        border: '1px solid #d4d4d8',
        borderRadius: '5px',

        '&:before': {
            border: 'none',
        },
    },
})
const TreeSelect = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #d8d8dd',
    boxShadow: '#0000001a 0px 4px 6px -2px',
    width: '100%',
    padding: '7px 12px',
    borderRadius: '5px',
    height: '38px',
    '& p': {
        fontSize: '14px',
    },
    '& svg': {
        color: '#0000008a',
    },
    '&:hover': {
        borderColor: `${theme?.palette?.primary?.main} !important`,
    },
}))
const TreeSelectRoot = styled(Box)({
    position: 'relative',
    width: '100%',
    '& label': {
        fontSize: '12px',
        textTransform: 'uppercase',
        marginBottom: '8px',
        display: 'block',
        color: '#b4b4b4',
    },
})

const useStyles = makeStyles({
    errorLabel: {
        color: 'red !important',
        '& label': {
            color: 'red !important',
        },
    },
    disabledTree: {
        '& div': {
            pointerEvents: 'none',
            backgroundColor: '#f5f5f5 !important',
            color: '#c1bcbc !important',
        },
    },
})

export const findObjectByValue = (obj, key, value) => {
    try {
        if (!obj || !value) {
            return null
        }

        if (obj[key] === value) {
            return obj
        }

        if (Array.isArray(obj.children)) {
            for (let i = 0; i < obj.children.length; i++) {
                const child = obj.children[i]
                const result = findObjectByValue(child, key, value)
                if (result) {
                    return result
                }
            }
        }

        for (let prop in obj) {
            if (typeof obj[prop] === 'object' && obj[prop] !== null) {
                const result = findObjectByValue(obj[prop], key, value)
                if (result) {
                    return result
                }
            }
        }
    } catch (err) {
        return null
    }

    return null
}

const MIN_CHARACTERS_REQUIRED_TO_SEARCH = 3

const FormTreeElement = ({ item, errorMessage, handleSelect, value }) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedValue, setSelectedvalue] = useState('')
    const debouncedSearchValue = useDebounce(searchTerm, 500)
    const { hasAccessToControl } = useSubcomponents()

    const checkAccessToField = (fieldName) => {
        return hasAccessToControl(
            `ResourceAdmin-PersonDetails-${fieldName}-Control`,
        )
    }

    const classes = useStyles()
    const { data, isLoading } = useDynamicFormTreeTree(
        item?.apiUrl,
        debouncedSearchValue?.length >= MIN_CHARACTERS_REQUIRED_TO_SEARCH
            ? debouncedSearchValue
            : '',
    )

    useEffect(() => {
        if (value && data) {
            const selValue = findSelectedItem(value)
            setSelectedvalue(selValue)
        } else if (data && !value) {
            setSelectedvalue(`${t('Select')} ${t(item?.localeKey)}`)
        } else {
            setSelectedvalue(`${t('Loading')}...`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, data])

    const findSelectedItem = (id) => {
        const selectedNode = findObjectByValue(
            data,
            item?.dataAttributes?.value,
            id,
        )
        if (selectedNode && typeof selectedNode === 'object') {
            return selectedNode[item?.dataAttributes?.label]
        } else {
            return selectedNode
        }
    }

    const handleTreeSelect = (id, fieldName) => {
        setSelectedvalue(findSelectedItem(id))
        handleSelect(id, fieldName)
        setIsOpen(!isOpen)
    }

    return (
        <Fragment>
            <TreeSelectRoot
                className={classNames({
                    [classes.disabledTree]:
                        item?.disabled || !checkAccessToField(item.fieldName),
                })}
                data-protectedsubcomponent={`ResourceAdmin-PersonDetails-${item.fieldName}-Control`}
            >
                {item?.localeKey && (
                    <FormLabel
                        className={classNames({
                            [classes.errorLabel]: !isNilOrEmpty(errorMessage),
                        })}
                        component="label"
                    >{`${t(item?.localeKey)} ${
                        item?.required &&
                        checkAccessToField(item.fieldName) &&
                        !item?.disabled
                            ? '*'
                            : ''
                    }`}</FormLabel>
                )}
                <TreeSelect
                    onClick={() =>
                        setIsOpen(
                            checkAccessToField(item.fieldName)
                                ? !isOpen
                                : isOpen,
                        )
                    }
                >
                    <Typography>
                        {selectedValue || t(item?.localeKey)}
                    </Typography>
                    <ArrowIcon direction={isOpen ? 'up' : 'down'} />
                </TreeSelect>
                {isOpen && (
                    <StyledTreeContainer>
                        <Fragment>
                            <Fragment>
                                <Input
                                    endAdornment={<Icon name="Search" />}
                                    placeholder={t('Search')}
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                />
                                {isLoading ? (
                                    <Skeleton height={20} />
                                ) : (
                                    <BusinesRoleAndLocationTree
                                        handleSelect={(id) => {
                                            handleTreeSelect(
                                                id,
                                                item?.fieldName,
                                            )
                                        }}
                                        explorer={data}
                                        value={value}
                                        isExpanded={
                                            searchTerm?.length >=
                                            MIN_CHARACTERS_REQUIRED_TO_SEARCH
                                        }
                                        highlightText={searchTerm}
                                    />
                                )}
                            </Fragment>
                        </Fragment>
                    </StyledTreeContainer>
                )}
            </TreeSelectRoot>
        </Fragment>
    )
}

export default FormTreeElement
