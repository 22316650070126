import { Box } from '@mui/material'
import { CardViewLoader, useEidGrid } from 'components'
import { useEffect } from 'react'
import CardView from './CardView'
import { ListTable, ListTableHead, ListTableRow } from './ListView'
import { useAppState } from 'appContext'
import { ITEMS_VIEW } from 'utils'

const EidTableGrid = ({
    rowSelection = false,
    selected,
    handleSelectAllClick,
    handleClick,
    isLoading,
    list,
    isLoadingMore,
    noDataMessage,
    pagination,
    attributes,
    defaultView,
    selectAllAction,
    onClickDetailButton,
    tableViewConfig,
    cardViewConfig,
    selectionIdentifier = 'id',
    tableViewOnly,
    errorMessage = null,
    onSort,
    showSelectAll = true,
    highLightSelectedRow,
}) => {
    const [state] = useAppState()

    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: noDataMessage,
        pagination,
        attributes: attributes,
        rowSelection: rowSelection,
        selected: selected,
        handleSelectAllClick: handleSelectAllClick,
        handleClick: handleClick,
        selectAllAction: selectAllAction,
        onClickDetailButton: onClickDetailButton,
        cardViewConfig,
        tableViewConfig,
        defaultView,
        selectionIdentifier: selectionIdentifier,
        errorMessage,
        onSort,
        showSelectAll,
        highLightSelectedRow,
    })

    useEffect(() => {
        if (tableViewOnly) {
            setCurrentView(ITEMS_VIEW.LIST)
        } else setCurrentView(state?.itemsView || ITEMS_VIEW.LIST)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.itemsView])

    return <Box>{viewToRender}</Box>
}

EidTableGrid.defaultProps = {
    cardViewConfig: {
        LoadingIndicatorComponent: CardViewLoader,
        CardComponent: CardView,
    },
    tableViewConfig: {
        TableComponent: ListTable,
        TableHeadComponent: ListTableHead,
        TableRowComponent: ListTableRow,
    },
}

export default EidTableGrid
