import { Box } from '@mui/material'
import { ClaimsMappingPolicies } from './ClaimsMappingPolicy'
import { OptionalClaims } from './OptionalClaims'
import { PageContextProvider } from 'pageContext'

export const TokenConfiguration = ({
    subActiveTab,
    id,
    applicationResourceId,
}) => {
    return (
        <Box>
            {subActiveTab === 'optionalClaims' && (
                <PageContextProvider key="RequestViewOptionalClaims">
                    <OptionalClaims id={id} />
                </PageContextProvider>
            )}
            {subActiveTab === 'claims' && (
                <PageContextProvider key="RequestViewClaimsMappingPolicies">
                    <ClaimsMappingPolicies
                        id={id}
                        applicationResourceId={applicationResourceId}
                    />
                </PageContextProvider>
            )}
        </Box>
    )
}
