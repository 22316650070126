import EditAssignmentsWithFieldTypes from 'components/Popups/EditAssignmentsWithFieldTypes'
import DialogHeader from 'components/Popups/EditAssignmentsWithFieldTypes/DialogHeader'
import { PageContextProvider } from 'pageContext'
import React, { useState } from 'react'

export const AssignRolesToManagementRole = ({
    targetAssigneeId,
    resourceId,
    dynamictext,
    resourceSystemModuleID,
    showAssignmentModal,
    setAssignmentModalValue,
    unAssignedFilterFn = null,
    assignedUrl,
    unAssignedPredefinedList,
}) => {
    const [assignee, setAssignee] = useState(null)
    return (
        <>
            <PageContextProvider key={'AssignRolesToManagementRolesPopup'}>
                <DialogHeader
                    headerText={{
                        dynamicText: dynamictext,
                        text: 'AssignRolesToManagementRole',
                    }}
                    setShowAssignmentModal={(val) => {
                        if (val === false) {
                            setAssignmentModalValue('')
                            setAssignee(null)
                        }
                    }}
                    showAssignmentModal={showAssignmentModal}
                    assigneePerson={assignee}
                    setAssigneePerson={setAssignee}
                    personSelectorLabelKey={'SelectManagementRoleToAssignRoles'}
                    personSelectorUrl={`api/ManagementRoles/all`}
                    personSelectorPlaceholderKey={'SelectManagementRole'}
                    showPersonSelector={true}
                >
                    {assignee && (
                        <EditAssignmentsWithFieldTypes
                            resourceType={'Applications'}
                            assignedUrl={
                                assignedUrl
                                    ? assignedUrl
                                    : `/api/AzureRoles/assignmentsByApplication/${resourceSystemModuleID}`
                            }
                            unAssignedUrl={`api/AzureRoles/GetEligibleAppRolesByApplicationByAssignee`}
                            targetAssigneeId={targetAssigneeId}
                            searchPlaceHolder="SearchRoles"
                            assignedQueryParams={{
                                assigneeId: assignee?.id,
                            }}
                            unAssignedQueryParams={{
                                applicationId: resourceId,
                                isAssigned: false,
                            }}
                            showAccessRequestPolicy={true}
                            fetchRequestPolicyItemWise={true}
                            showStartDate={false}
                            fieldTypeProps={{
                                assignee: assignee,
                                isRole: false,
                                keyForRoleRightId: 'localRoleId',
                            }}
                            requireRemappingInAssignedList={true}
                            newMappingForAssignedList={{
                                friendlyName: 'role',
                            }}
                            showAssignmentModal={showAssignmentModal}
                            handleClose={() => {
                                setAssignmentModalValue('')
                                setAssignee(null)
                            }}
                            unAssignedFilterFn={unAssignedFilterFn}
                            unAssignedPredefinedList={unAssignedPredefinedList}
                        />
                    )}
                </DialogHeader>
            </PageContextProvider>
        </>
    )
}
