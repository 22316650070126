import { Box, Paper } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { Fragment } from 'react'
import {
    useAccessRequestPolicy,
    useApplicationAppRightsDetails,
    useResourceDeputies,
    useResourceOwners,
} from 'hooks'
import { Skeleton } from '@mui/material'
import { ResponsibleParty } from '../ResponsibleParties'
import useSubcomponents from 'useSubcomponents'
import { PolicyDetails } from 'components'
import {
    isApplicationAzureApp,
    isApplicationPbacApp,
    smallScreenWidth,
} from 'utils'
import { PeopleListCard } from 'components/PeopleListCard/PeopleListCard'

const canSeeOwnersControlName =
    'ResourceAdmin-ApplicationDetails-AzureApplicationAppRights-Overview-Owners-Control'
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: 'auto',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: theme?.palette?.primary?.main,
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
        attribute: {
            marginTop: '-8px',
        },
        attributeLabel: {
            minWidth: '265px',
        },
        attributeValue: {
            wordBreak: 'unset',
        },
    }),
)

const canSeeDeputiesControlName =
    'ResourceAdmin-ApplicationDetails-AzureApplicationAppRights-Overview-Deputies-Control'

export const OverviewSection = ({ role, applicationType }) => {
    const classes = useStyles()
    const { hasAccessToControl } = useSubcomponents()
    const { t } = useTranslation()

    const { data, isLoading } = useApplicationAppRightsDetails(role.id)
    const policy = useAccessRequestPolicy(role.accessRequestPolicyId)
    const { data: appRightOwners, isLoading: ownersLoading } =
        useResourceOwners(
            hasAccessToControl(canSeeOwnersControlName) ? role.id : null,
        )
    const { data: appRightDeputies, isLoading: deputiesLoading } =
        useResourceDeputies(
            hasAccessToControl(canSeeDeputiesControlName) ? role.id : null,
        )

    const isAzureApplication = isApplicationAzureApp(applicationType)
    const isPbacApplication = isApplicationPbacApp(applicationType)

    const attributes = [
        {
            label: 'AllowedMemberTypes',
            value: 'rightAllowedAssigeeTypeFriendlyName',
            display: isAzureApplication,
        },
        {
            label: 'Name',
            value: 'friendlyName',
            display: true,
        },
        {
            label: 'Description',
            value: 'description',
            display: true,
        },
        {
            label: 'PublishInIAMShop',
            value: 'publishedInIIShop',
            display: true,
            render: (data) =>
                data && data.publishedInIIShop ? t('Yes') : t('No'),
        },
        {
            name: 'Policy',
            display: true,
        },
        {
            label: 'PbacApprovalRight',
            value: 'pbacApprovalRight',
            display: isPbacApplication,
        },
        {
            label: 'SplitByValueForApproval',
            value: 'splitByValueForApproval',
            display: true,
            render: (data) =>
                data && data.splitByValueForApproval ? t('Yes') : t('No'),
        },
        {
            label: 'AllowExport',
            value: 'allowExport',
            display: true,
            render: (data) => (data && data.allowExport ? t('Yes') : t('No')),
        },
        {
            label: 'FlowToPersonValues',
            value: 'flowToPersonValues',
            display: true,
            render: (data) =>
                data && data.flowToPersonValues ? t('Yes') : t('No'),
        },
        {
            label: 'FulfillmentGroup',
            value: 'fulfillmentGroupFriendlyName',
            display: true,
        },
        {
            label: 'ID',
            value: 'globalRightId',
            display: true,
        },
    ]

    const rightDetail = (
        <Box>
            {attributes &&
                attributes
                    .filter((x) => x.display)
                    .map((att, i) => {
                        if (att.name === 'Policy') {
                            return <PolicyDetails policy={policy} />
                        }
                        return (
                            <Attribute
                                className={classes.attribute}
                                orientation="horizontal"
                                key={att.value + i}
                            >
                                <AttributeLabel
                                    className={classes.attributeLabel}
                                >
                                    {t(att.label)}
                                </AttributeLabel>
                                <AttributeValue
                                    className={classes.attributeValue}
                                >
                                    {att.render
                                        ? att.render(data)
                                        : data && role[att.value]}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
        </Box>
    )

    const renderContent = () => (
        <Box className={classes.content}>
            <Box className={classes.section} flex={1}>
                {isLoading ? (
                    <Box minWidth={600}>
                        {[...Array(5)].map(() => (
                            <Skeleton height={40} />
                        ))}
                    </Box>
                ) : (
                    <>{rightDetail}</>
                )}
            </Box>

            <Box className={classes.section} flex={1} gap={'5rem'}>
                {hasAccessToControl(canSeeOwnersControlName) && (
                    <Box data-protectedsubcomponent={canSeeOwnersControlName}>
                        <Attribute>
                            <AttributeLabel>
                                {t('AppRights_Owners')}
                            </AttributeLabel>
                            <PeopleListCard
                                data={appRightOwners}
                                isLoading={ownersLoading}
                                pageSize={3}
                            />
                        </Attribute>
                    </Box>
                )}

                {hasAccessToControl(canSeeDeputiesControlName) && (
                    <Box
                        justifyContent="flex-end"
                        data-protectedsubcomponent={canSeeDeputiesControlName}
                    >
                        <Attribute>
                            <AttributeLabel>
                                {t('AppRightsDeputies')}
                            </AttributeLabel>
                            <PeopleListCard
                                data={appRightDeputies}
                                isLoading={deputiesLoading}
                                pageSize={3}
                            />
                        </Attribute>
                    </Box>
                )}
                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel>{t('ResponsibleParty')}</AttributeLabel>
                        {data?.ownerIsPerson ? (
                            <ResponsibleParty
                                responsiblePersonId={data?.responsiblePartyId}
                            />
                        ) : (
                            <AttributeValue>
                                {data?.responsiblePartyFriendlyName || '-'}
                            </AttributeValue>
                        )}
                    </Attribute>
                </Box>
            </Box>
        </Box>
    )
    return (
        <>
            <Paper
                style={{
                    padding: '32px',
                    width: 'fit-content',
                    minWidth: '100%',
                }}
            >
                <Box className={classes.section}>{renderContent()}</Box>
            </Paper>
        </>
    )
}
