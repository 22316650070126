import { ViewIndicator } from 'packages/eid-ui'
import React from 'react'
import { ITEMS_VIEW } from 'utils'

const ViewSwitcher = ({
    itemsView,
    dispatch,
    listViewLabel,
    cardViewLabel,
}) => {
    const onCardViewClick = () => {
        dispatch({
            type: 'SET_ITEMS_VIEW',
            payload: ITEMS_VIEW.CARD,
        })
    }

    const onListViewClick = () => {
        dispatch({
            type: 'SET_ITEMS_VIEW',
            payload: ITEMS_VIEW.LIST,
        })
    }
    return (
        <ViewIndicator
            view={itemsView}
            onCardViewClick={onCardViewClick}
            onListViewClick={onListViewClick}
            cardViewLabel={cardViewLabel}
            listViewLabel={listViewLabel}
        />
    )
}

export default React.memo(ViewSwitcher)
