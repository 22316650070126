import { Box } from '@mui/material'
import { useAppState } from 'appContext'
import { Button } from 'components'
import cartHelpers from 'containers/Cart/cartHelpers'
import { useAddItemToCart, useRemoveItemsFromCart } from 'hooks'
import { Tooltip, WarningModal } from 'packages/eid-ui'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const DeleteApplicationRoleRightAssignment = ({
    carts,
    item,
    application,
    keyForRoleRightId,
    keyForAssignee,
}) => {
    const [, dispatch] = useAppState()
    const [isAlreadyInCart, setIsAlreadyInCart] = useState(false)
    const [cartItem, setCartItem] = useState(null)
    const [openDelete, setOpenDelete] = useState(false)
    const [{ targetResource }] = useAppState()
    const [isDeleteProceed, setIsDeleteProceed] = useState()
    const [currentTarget, setCurrentTarget] = useState(
        targetResource?.targetAssigneeId,
    )
    const { t } = useTranslation()

    const [removeItemsFromCart, { isLoading: isRemoving }] =
        useRemoveItemsFromCart()

    const [addItemToCart, { isLoading: addToCartLoading }] = useAddItemToCart()

    useEffect(() => {
        let itemInCart = cartHelpers.isItemAlreadyInCart(
            carts?.find(
                (cart) =>
                    cart.targetAssigneeId === item[keyForRoleRightId] &&
                    cart.targetAssigneeFriendlyName ===
                        item?.[keyForAssignee] &&
                    cart.targetAssignee_ResourceTypeFriendlyName ===
                        item?.assigneeResourceTypeFriendlyName,
            ) || null,
            item?.assigneeId,
            null,
            true,
        )
        itemInCart = itemInCart.filter((x) => x.assignmentId === item.id)
        setIsAlreadyInCart(itemInCart?.length ? true : false)
        setCartItem(itemInCart?.length ? itemInCart : null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts])

    const preAdd = () => {
        const itemToAdd = cartHelpers.applicationsFieldTypeJsonCartItem({
            targetResourceId: item?.assigneeId,
            assignmentType: 'Remove',
            application: application,
            assigneePerson: item,
            targetAssigneeId: item[keyForRoleRightId],
            fieldTypeValueJson: undefined,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const handleDelete = () => {
        try {
            const itemToAdd = preAdd()
            if (itemToAdd === undefined) return

            addItemToCart(itemToAdd).then(() => {
                setOpenDelete(false)
                setIsDeleteProceed(false)
            })
        } catch (err) {}
    }

    useEffect(() => {
        if (currentTarget !== targetResource?.targetAssigneeId) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: item[keyForRoleRightId],
                    targetAssigneeFriendlyName: item?.[keyForAssignee],
                    targetAssignee_ResourceTypeFriendlyName: t(
                        item?.assigneeResourceTypeFriendlyName,
                    ),
                },
            })
            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: item[keyForRoleRightId],
                    targetAssigneeFriendlyName: item?.[keyForAssignee],
                    targetAssignee_ResourceTypeFriendlyName: t(
                        item?.assigneeResourceTypeFriendlyName,
                    ),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTarget])

    const handleDeleteConfirm = () => {
        try {
            setCurrentTarget(item[keyForRoleRightId])
            setTimeout(() => {
                // Adding timer so that cart is created on the BE
                setIsDeleteProceed(true)
            }, 1000)
        } catch (err) {}
    }

    useEffect(() => {
        if (
            isDeleteProceed &&
            currentTarget === targetResource?.targetAssigneeId
        ) {
            if (isAlreadyInCart) {
                if (cartItem?.length) {
                    removeAndAddItemsToCart()
                } else {
                    handleDelete()
                }
            } else {
                handleDelete()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetResource?.targetAssigneeId, isDeleteProceed])

    const removeAndAddItemsToCart = async () => {
        try {
            const cartItemIds = cartItem.map((cartItem) => cartItem.id)
            removeItemsFromCart(cartItemIds).then(() => {
                handleDelete()
            })
        } catch (err) {}
    }

    const checkIfAlreadyExistInCart = () => {
        try {
            if (!cartItem?.length) {
                return false
            }
            const itemToDelete = cartItem.filter(
                (itemCart) => itemCart.assignmentType === 'Remove',
            )
            if (itemToDelete?.length) {
                return true
            }
        } catch (err) {}
        return false
    }

    return (
        <Fragment>
            <Tooltip
                title={
                    checkIfAlreadyExistInCart()
                        ? t('Common_ItemAlreadyInCart')
                        : ''
                }
            >
                <Box>
                    <Button.Delete
                        showEndIcon={false}
                        onClick={() => setOpenDelete(true)}
                        disabled={checkIfAlreadyExistInCart()}
                    />
                </Box>
            </Tooltip>
            <WarningModal
                title={`${t('Delete')} ${item?.assignee}`}
                description={
                    isAlreadyInCart
                        ? t('DeleteAlreadyInCartRoleDefinitionAssignment')
                        : t('DeleteRoleDefinitionAssignment')
                }
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={openDelete}
                cancel={() => {
                    setOpenDelete(false)
                    setIsDeleteProceed(false)
                }}
                proceeding={isRemoving || addToCartLoading}
                proceed={() => handleDeleteConfirm()}
            />
        </Fragment>
    )
}

export default DeleteApplicationRoleRightAssignment
