import { Box, styled, useTheme } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAuthFieldTypeValues } from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import { Button, Filters } from 'components'
import { Icon } from 'packages/eid-icons'
import { useHistory } from 'react-router'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import config from 'config'
const canAddNewFieldTypeValueControlName =
    'ResourceAdmin-ApplicationDetails-AppOwnedFieldTypes-AddNewFieldTypeValue-Control'
const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '.3rem',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '4rem',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const FieldTypeValues = ({ fieldType }) => {
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const { list, loading, loadingMore, pagination } = useAuthFieldTypeValues(
        fieldType?.id,
    )
    const theme = useTheme()
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()
    const attributes = [
        {
            name: 'valueFriendlyName',
            sortable: true,
            label: 'ValueDisplayName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppOwnedFieldTypes-FieldTypeValues-ValueDisplayName',
            },
        },
        {
            name: 'valueName',
            sortable: true,
            label: 'Value',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppOwnedFieldTypes-FieldTypeValues-Value',
            },
        },
        {
            name: 'selectionRuleName',
            sortable: true,
            label: 'UISelectionControl',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppOwnedFieldTypes-FieldTypes-UISelectionControl',
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="1.6rem 3.2rem"
            >
                {hasAccessToControl(canAddNewFieldTypeValueControlName) && (
                    <Button
                        data-protectedsubcomponent={
                            canAddNewFieldTypeValueControlName
                        }
                        onClick={() => {
                            query.set('workflowName', 'ManageFieldTypeValues')
                            query.set(
                                'SelectedResourceID',
                                `${fieldType?.resourceId}`,
                            )
                            query.set('returnUrl', getFullPathFromUrl())
                            query.delete('applicationId')
                            query.delete('activeTab')
                            query.delete('subActiveTab')
                            history.push(
                                `${
                                    config.APP_SUBPATH
                                }/applications/workflows?${query.toString()}`,
                            )
                        }}
                        style={{
                            borderRadius: '4px',
                            padding: '0px 20px',
                            backgroundColor: theme?.palette?.primary?.main,
                            border: `solid 1px ${theme?.palette?.primary?.main}`,
                            color: '#ffffff',
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.main,
                            },
                        }}
                    >
                        <Box style={{ display: 'flex' }}>
                            <Icon
                                name={'Plus'}
                                fill="white"
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    marginRight: '5px',
                                }}
                            />
                            {t('AddFieldTypeValue')}
                        </Box>
                    </Button>
                )}

                <Box width="24rem" display="flex" alignItems="center">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default FieldTypeValues
