import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WarningModal } from 'packages/eid-ui'
import { Button } from 'components'
import { useDeletePBACAppResource } from 'hooks'

const DeletePBACResource = ({ item, applicationId }) => {
    const { t } = useTranslation()

    const [showConfirm, setShowConfirm] = useState(false)
    const [deleteResource, { isLoading: isDeleting }] =
        useDeletePBACAppResource(['LIST_PBAC_APP_RESOURCES', applicationId])
    const handleDelete = () => {
        deleteResource([item.id]).then(() => {
            setShowConfirm(false)
        })
    }

    return (
        <>
            <Button.Delete item={item} onClick={() => setShowConfirm(true)} />

            <WarningModal
                title={`${t('Delete')} ${item?.friendlyName}`}
                description={t('AppRoleDeleteMessage')} //NOTE: This is a generic message
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={showConfirm}
                cancel={() => setShowConfirm(false)}
                proceeding={isDeleting}
                proceed={handleDelete}
            />
        </>
    )
}

export default DeletePBACResource
