import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { Collapse, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
} from 'packages/core'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'

import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'
import { useApplicationSettings, usePeoples } from 'hooks'
import { usePageContext } from 'pageContext'
import { SubPathLevel } from 'appConfiguration'
import useSubcomponents from 'useSubcomponents'
import {
    PeopleRow,
    PeopleTable,
    PeopleTableHead,
} from './PeopleTableComponents'
import PeopleDetails from './PeopleDetails'

import ResourceMoreInformationTile from 'components/ResourceMoreInformationTile'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import GenericButton from 'components/Button/GenericButton'
import CardView from 'components/EidTableGrid/CardView'
import { ITEMS_VIEW, RESOURCE_TYPE_NAMES } from 'utils'
import { EidWorkflowButton } from 'components/EidWorkflowButton'

const canSeeMoreInfornation = 'ResourceAdmin-People-MoreInfo-Control'
const detailsButtonControlName =
    'ResourceAdmin-People-Listing-DetailsButton-Control'
const uiActionsControlName =
    'ResourceAdmin-People-Listing-UIActionsButton-Control'

const PeopleLayout = ({ filters }) => {
    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [filterWidth, setFilterWidth] = useState(0)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        rest,
    } = usePeoples()

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])
    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? ITEMS_VIEW.CARD : ITEMS_VIEW.LIST

    const { tableAttributes } = attributes
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()
    const accessBasedAttributes = getAccessBasedAttributes(tableAttributes)

    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('No_People_Message'),
        pagination,
        attributes: accessBasedAttributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: CardView,
            renderLeftButton: (item) => {
                return hasAccessToControl(detailsButtonControlName) ? (
                    <Box
                        data-protectedsubcomponent={detailsButtonControlName}
                        width="80%"
                    >
                        <GenericButton
                            onClick={() => {
                                history.push(`?peopleId=${item?.id}`)
                            }}
                            color="#01ae8f"
                            rootStylesProp={{
                                borderRadius: '4px',
                                width: '100%',
                                height: '32px',
                            }}
                        >
                            {t('Details')}
                        </GenericButton>
                    </Box>
                ) : (
                    <></>
                )
            },
            renderRightButton: (item) => {
                return hasAccessToControl(uiActionsControlName) ? (
                    <Box data-protectedsubcomponent={uiActionsControlName}>
                        <UIActionsGearButton
                            item={item}
                            noun={'ResourceAdmin'}
                            resourcePath="people"
                        />
                    </Box>
                ) : (
                    <></>
                )
            },
        },
        tableViewConfig: {
            TableComponent: PeopleTable,
            TableHeadComponent: PeopleTableHead,
            TableRowComponent: PeopleRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView(ITEMS_VIEW.CARD)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmallScreen])

    const query = useQuery()

    const peopleId = query.get('peopleId')
    const showDetailsPage = Boolean(peopleId)

    const workflowName = query.get('workflow')
    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig

    const accessibleStaticWorkflows =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) =>
                      w.resourceTypeName === RESOURCE_TYPE_NAMES.PEOPLE &&
                      hasAccessToControl(w.protectedSubcomponentName),
              )
            : []

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let detailContent
    if (peopleId) {
        detailContent = (
            <>
                {isSmallScreen ? (
                    <></>
                ) : (
                    <DesktopContainer>
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <PeopleDetails peopleId={peopleId} />
                        </Box>
                    </DesktopContainer>
                )}
            </>
        )
    }

    let listingContent
    listingContent = (
        <>
            {isSmallScreen ? (
                <SmallScreenContainer>
                    <Box display={showFilters ? '' : 'none'}>
                        {mobileFiltersScreen}
                    </Box>

                    <Box display={!showFilters ? '' : 'none'}>
                        <Box display={''}>
                            <StickyContainer>
                                <Box width="100%">
                                    {mobileStickyContentListing}
                                </Box>
                            </StickyContainer>
                            {viewToRender}
                        </Box>
                    </Box>
                </SmallScreenContainer>
            ) : (
                <DesktopContainer>
                    <Fragment>
                        {((filters && filters.length > 0) ||
                            hasAccessToControl(canSeeMoreInfornation)) && (
                            <FiltersColumn ref={filterContainer}>
                                <FilterContainer>
                                    <Box display="flex">
                                        <Box width="100%">
                                            <Card.CardHeader
                                                collapsible
                                                fontColor="black"
                                                cardTitle={
                                                    openFilters
                                                        ? t('Hide_My_Filters')
                                                        : t('Show_My_Filters')
                                                }
                                                handleExpandClick={() =>
                                                    setOpenFilters(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                expanded={openFilters}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                                style={styles.filterCardHeader}
                                            />
                                        </Box>
                                    </Box>
                                </FilterContainer>

                                <Collapse
                                    in={openFilters}
                                    timeout={300}
                                    unmountOnExit
                                >
                                    {!peopleId &&
                                        accessibleStaticWorkflows.length > 0 &&
                                        accessibleStaticWorkflows.map((wf) => (
                                            <Box
                                                style={{
                                                    margin: '0 0 15px 0px',
                                                }}
                                                data-protectedsubcomponent={
                                                    wf.protectedSubcomponentName
                                                }
                                            >
                                                <EidWorkflowButton
                                                    title={t(
                                                        wf.displayName_LocaleKey,
                                                    )}
                                                    name={
                                                        wf.requestWorkflowName
                                                    }
                                                    path={`/people/workflows`}
                                                    disabled={workflowName}
                                                />
                                            </Box>
                                        ))}

                                    <ResourceMoreInformationTile
                                        protectedsubcomponent={
                                            canSeeMoreInfornation
                                        }
                                        moreInfoKey="People_MoreInfo_Html"
                                        resourceId={peopleId}
                                    />
                                    <>
                                        {filters.map((f) => {
                                            const FilterToRender =
                                                Filters.filtersMap[f.name]
                                            return (
                                                <FilterContainer
                                                    data-protectedsubcomponent={
                                                        f.requireAccess
                                                    }
                                                    key={f.name}
                                                >
                                                    <FilterToRender />
                                                </FilterContainer>
                                            )
                                        })}
                                    </>
                                    <ListingPageUIActionByNounAndVerb
                                        noun="ResourceAdmin"
                                        verb="People"
                                    />
                                </Collapse>
                            </FiltersColumn>
                        )}

                        <ListingGrid>
                            <Box display="flex">
                                <Box width="100%">
                                    <Filters.TextSearch />
                                </Box>
                            </Box>
                            <GridContainer
                                filterWidth={filterWidth}
                                openFilters={openFilters}
                            >
                                {viewToRender}
                            </GridContainer>
                        </ListingGrid>
                    </Fragment>
                </DesktopContainer>
            )}
        </>
    )

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showBackButton={showDetailsPage}
            showNavTabs={!peopleId}
        >
            <Fragment>
                {showDetailsPage ? detailContent : listingContent}
            </Fragment>
        </AppLayout>
    )
}

export default PeopleLayout
