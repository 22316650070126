import { useAxios } from 'packages/core'
import { usePageContext } from 'pageContext'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import { queryCache, useMutation, useQuery } from 'react-query'
import { useNotification } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
export const SHARED_FOLDERS_KEY = 'SHARED FOLDERS'

export const useSharedFolder = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [SHARED_FOLDERS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/SharedFolders/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useSharedFolders = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        sortOrder,
        sortBy,
        ownedBy,
        uncPath,
        shouldLoadTags,
        advancedSearchTags,
        enableOwnedByApiCall,
    } = state
    const description = state['advancedSearch.forms.description']
    const friendlyName = state['advancedSearch.forms.friendlyName']
    const computer = state['advancedSearch.forms.computer']
    const dnsHostName = state['advancedSearch.forms.dnsHostName']
    const callApi = useAxios()
    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (shouldLoadTags) {
        queryData.includeTags = true
    }
    if (ownedBy) {
        queryData.ownerPersonId = ownedBy.id
    }
    if (uncPath) {
        queryData.uncPath = uncPath.value
    }
    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }

    const advancedSearch = []
    if (description) {
        advancedSearch.push({
            name: 'Description',
            type: 'string',
            value: description,
        })
    }
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }
    if (computer) {
        advancedSearch.push({
            name: 'computerFriendlyName',
            type: 'string',
            value: computer,
        })
    }
    if (dnsHostName) {
        advancedSearch.push({
            name: 'DNSHostName',
            type: 'string',
            value: dnsHostName,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            SHARED_FOLDERS_KEY,
            'LIST_MY_SHAREDFOLDERS',
            searchTerm,
            sortBy,
            sortOrder,
            advancedSearch,
            ownedBy,
            uncPath,
            advancedSearchTags,
            shouldLoadTags,
            enableOwnedByApiCall,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `api/SharedFolders/all`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: enableOwnedByApiCall,
        },
    )
}

export const useAccessAssignmentsToFolder = (
    resourceId: any,
    resourceType: string,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `api/AssigneeAssignments/rbacAccessAssignments/${resourceId}/${resourceType}`

    return useApiWithInfiniteScroll(
        [
            SHARED_FOLDERS_KEY,
            'ACCESS_ASSIGNMENTS_FOLDERS',
            sortBy,
            sortOrder,
            searchTerm,
            resourceId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useDirectAssignedLocations = (resourceId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/Common/directLocations`

    return useApiWithInfiniteScroll(
        [
            'DIRECT_ASSIGNED_LOCATIONS',
            sortBy,
            sortOrder,
            searchTerm,
            resourceId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    resourceId,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useAccessPolicy = () => {
    const callApi = useAxios()
    return useQuery([SHARED_FOLDERS_KEY, 'ACCESS_POLICY_LIST'], () =>
        callApi({
            method: 'GET',
            url: `/api/SharedFolders/accessrequestpolicies`,
        }).then((data) => data.data),
    )
}

export const useChangeAccessRequestPolicy = (sharedFolderId: string) => {
    const callApi = useAxios()
    const { t } = useTranslation()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    return useMutation(
        (policyId) =>
            callApi({
                method: 'POST',
                url: `/api/SharedFolders/changePolicy/${sharedFolderId}/${policyId}`,
            }).then((data) => data.data),
        {
            onSuccess: () => {
                showSuccessMessage(t('AccessRequestPolicyChangedSuccessfully'))
            },
            onError: () => {
                showWarningMessage(t('ErrorChangingAccessRequestPolicy'))
            },
        },
    )
}

export const useSaveAccessAssignmentToFolders = () => {
    const callApi = useAxios()
    const { t } = useTranslation()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/AssigneeAssignments/updateRbacAccessAssignments`,
                data: data,
            }).then((data) => data),
        {
            onSuccess: () => {
                showSuccessMessage(t('AccessAssignmentAddedSuccessfully'))
            },
            onError: () => {
                showWarningMessage(t('ErrorAddingAccessAssignmentToFolder'))
            },
        },
    )
}

export const refreshAccessAssignmentList = (shareFolderId: any) => {
    queryCache.invalidateQueries(
        (c) =>
            c.queryKey.includes('ACCESS_ASSIGNMENTS_FOLDERS') &&
            c.queryKey.includes(shareFolderId),
    )
}

export const useDeleteAccessAssignmentToFolder = (shareFolderId: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (ids) =>
            callApi({
                method: 'POST',
                url: `/api/AssigneeAssignments/deleteAssignments`,
                data: ids,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('SharedFolder_AssignnmentDeleted'))
                refreshAccessAssignmentList(shareFolderId)
            },
            onError: () => {
                showWarningMessage(t('SharedFolder_AssignmentNotDeleted'))
            },
        },
    )
}

export const refreshAssigneeList = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('APPLICATIONS_IAMSHOP_ASSIGNEES'),
    )
}

// TODO: Need to update API end points
export const useAddIamShopAssignee = (shareFolderId: string) => {
    const callApi = useAxios()
    const { t } = useTranslation()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    return useMutation(
        (data: {
            assigneeId: string
            resourceAccessRequestAssigneeTypeId: number
            orgRoleId: string
            orgZoneId: string
        }) =>
            callApi({
                method: 'POST',
                url: '/api/ResourceAssignments/addResourceAccessRequestAssignee',
                data: {
                    assigneeId: data.assigneeId,
                    resourceId: shareFolderId,
                    resourceAccessRequestAssigneeTypeId:
                        data.resourceAccessRequestAssigneeTypeId,
                    orgRoleId: data.orgRoleId,
                    orgZoneId: data.orgZoneId,
                },
            }).then((data) => data),
        {
            onSuccess: () => {
                showSuccessMessage(t('AssigneeAddedSuccessfully'))
                refreshAssigneeList()
            },
            onError: () => {
                showWarningMessage(t('ErrorAddingAssigneeToFolder'))
            },
        },
    )
}

export const useUpdateDirectAssignedLocations = (isDelete = false) => {
    const callApi = useAxios()
    const { t } = useTranslation()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/SharedFolders/updateShareFolderLocations',
                data: data,
            }).then((data) => data),
        {
            onSuccess: () => {
                showSuccessMessage(t(isDelete ? 'DirectAssignedLocationRemovedSuccessfully' : 'DirectAssignedLocationAddedSuccessfully'))
                refreshAssigneeList()
            },
            onError: () => {
                showWarningMessage(t('ErrorAddingDirectAssignedLocation'))
            },
        },
    )
}

export const useSharedFolderInvertedPermissions = (shareFolderId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {
        shareFolderId: shareFolderId,
    }

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `api/SharedFolders/inventoriedPermissions`

    return useApiWithInfiniteScroll(
        [
            SHARED_FOLDERS_KEY,
            'INVERTED_PERMISSIONS',
            sortBy,
            sortOrder,
            searchTerm,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}
