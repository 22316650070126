import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import {
    useDirectAssignedLocations,
    useUpdateDirectAssignedLocations,
} from 'hooks'
import { PageContextProvider } from 'pageContext'
import AddDirectAssignedLocation from './AddDirectAssignedLocation'
import { WarningModal } from 'packages/eid-ui'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Button, Filters } from 'components'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import useSubcomponents from 'useSubcomponents'

const canAddLocation =
    'ResourceAdmin-ManagementRoleDetails-DirectAssignedLocation-AddLocation-Control'
const canRemoveMultipleControlName =
    'ResourceAdmin-ManagementRoleDetails-DirectAssignedLocation-DeleteMultiple-Control'
const DeleteButton = styled(Box)({
    paddingRight: '1.0rem',
    textAlign: 'end',
    '& button': {
        padding: '0.8rem 1.2rem',
        borderRadius: ' 0.4rem',
        border: 'solid 0.1rem #ff4d5f',
        backgroundColor: '#fff',
    },
})

const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '0.3rem',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1) !important',
        border: ' solid 0.1rem #d8d8dd',
        paddingLeft: '0',
        height: '4.0rem',
    },
    '& ::placeholder': {
        color: '#a7a7a9',
    },
})

export const DirectAssignedLocations = ({ id }) => {
    const { t } = useTranslation()
    const [selected, setSelected] = useState([])
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        refetch,
    } = useDirectAssignedLocations(id)

    const [updateDirectAssignedLocations, { isLoading: updatingLocation }] =
        useUpdateDirectAssignedLocations()

    const attributes = [
        {
            label: t('LocationName'),
            name: 'friendlyName',
            sortable: true,
            isHeader: true,
            showInCard: false,
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-DirectAssignedLocation-Action',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <DeleteButton>
                            <Button.Delete
                                showEndIcon={false}
                                onClick={() =>
                                    setSelectedItemsToDelete([data?.id])
                                }
                            />
                        </DeleteButton>
                    )
                },
            },
        },
    ]

    const handleDelete = () => {
        const dataObject = {
            locationsToRemove: selectedItemsToDelete,
            resourceId: id,
        }
        updateDirectAssignedLocations(dataObject).then((data) => {
            refetch()
            setSelectedItemsToDelete(null)
            setSelected([])
        })
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.id)
            setSelected(filteredList)
        }
    }

    const selectAllAction = hasAccessToControl(canRemoveMultipleControlName)
        ? {
              render: () => {
                  return (
                      <BulkRemoveButton
                          selectedCount={selected.length}
                          label={t('RemoveSelectedAssignedLocations', {
                              count: selected.length,
                          })}
                          onClick={handleDeleteBulk}
                          dataProtectedsubcomponent={
                              canRemoveMultipleControlName
                          }
                      />
                  )
              },
          }
        : null

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }

    const bindSelectedItemName = () => {
        try {
            if (selectedItemsToDelete?.length === 1) {
                const deleteTitle =
                    list.find((x) => x.id === selectedItemsToDelete[0])
                        ?.friendlyName || ''
                if (deleteTitle) {
                    return `${t('Delete')} ${deleteTitle}`
                }
            }
        } catch (err) {}

        return t('DeleteAssignedLocations')
    }

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="1.6rem"
            >
                <PageContextProvider key="AddAccessAssignmentsFodler">
                    {hasAccessToControl(canAddLocation) ? (
                        <Box dat-protectedsubcomponent={canAddLocation}>
                            <AddDirectAssignedLocation
                                resourceId={id}
                                refetch={refetch}
                            />
                        </Box>
                    ) : (
                        <Box></Box>
                    )}
                </PageContextProvider>

                <Box width="30.9rem" display="flex" alignItems="center">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>

            <Box>
                <EidTableGrid
                    selectAllAction={selectAllAction}
                    rowSelection={list?.length > 0}
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </Box>
            {selectedItemsToDelete && (
                <WarningModal
                    title={bindSelectedItemName()}
                    description={t('ItemsDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceeding={updatingLocation}
                    proceed={handleDelete}
                />
            )}
        </>
    )
}
