import React, { Fragment } from 'react'
import {
    InputBase,
    FormControl,
    Box,
    FormLabel,
    FormHelperText,
} from '@mui/material'

import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import { isNilOrEmpty } from 'packages/core'
import { PhoneNumberInputField } from 'packages/eid-ui'

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
    },
    input: ({ paddingLeft }) => ({
        borderRadius: '5px',
        position: 'relative',
        backgroundColor: '#ffffff !important',
        border: '1px solid #d8d8dd;',
        fontSize: '14px',
        padding: '10px 12px',
        paddingLeft,
        lineHeight: 1.25,
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        '&:hover': {
            borderColor: theme?.palette?.primary?.main,
            backgroundColor: theme.palette.common.white,
        },
        '&:focus': {
            borderColor: theme?.palette?.primary?.main,
            backgroundColor: theme.palette.common.white,
        },

        // for IE
        overflowY: 'auto',
        '&::placeholder': {
            color: '#b4b4b4',
            opacity: 1,
        },
    }),
    multiline: {
        padding: 0,
    },
    focused: {
        backgroundColor: theme.palette.common.white,
    },
    error: {
        '& input, & textarea': {
            border: '1px solid #f44336 !important',
        },
    },
}))(({ paddingLeft, ...rest }) => <InputBase {...rest} />)

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        position: 'relative',
    },
    rootcontainer: {
        width: '100%',
        '& label': {
            fontSize: '12px',
            color: 'rgb(180, 180, 180)',
            textTransform: 'uppercase',
        },
    },
    errorLabel: {
        color: 'red !important',
        '& label': {
            color: 'red !important',
        },
    },
    disabledInput: {
        pointerEvents: 'none',
        '& .Mui-disabled': {
            pointerEvents: 'none',
            backgroundColor: '#f5f5f5 !important',
            color: '#c1bcbc !important',
        },
    },
    PhoneNumberInputField: {
        paddingTop: '0.8rem',
        boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1)',
    },
})

const FormTextFieldElement = ({
    item,
    value,
    handleChange,
    errorMessage,
    isPhoneInput,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()

    const checkAccessToField = (fieldName) => {
        return hasAccessToControl(
            `ResourceAdmin-PersonDetails-${fieldName}-Control`,
        )
    }

    return (
        <Fragment>
            <Box
                className={classNames(classes.rootcontainer, {
                    [classes.errorLabel]: !isNilOrEmpty(errorMessage),
                    [classes.disabledInput]:
                        item?.disabled || !checkAccessToField(item.fieldName),
                })}
                data-protectedsubcomponent={`ResourceAdmin-PersonDetails-${item.fieldName}-Control`}
            >
                {item?.localeKey && (
                    <FormLabel component="label">{`${t(item?.localeKey)} ${
                        item?.required &&
                        checkAccessToField(item.fieldName) &&
                        !item?.disabled
                            ? '*'
                            : ''
                    }`}</FormLabel>
                )}

                {isPhoneInput && (
                    <Box className={classes.PhoneNumberInputField}>
                        <PhoneNumberInputField
                            name={item?.fieldName}
                            value={value}
                            onChange={handleChange(item?.fieldName)}
                            disabled={
                                item?.disabled ||
                                !checkAccessToField(item.fieldName)
                            }
                        />
                    </Box>
                )}

                {!isPhoneInput && (
                    <FormControl className={classes.root}>
                        <StyledInput
                            name={item?.fieldName}
                            value={value}
                            onChange={handleChange}
                            disabled={
                                item?.disabled ||
                                !checkAccessToField(item.fieldName)
                            }
                        />
                    </FormControl>
                )}

                {errorMessage && (
                    <FormHelperText error={true}>{errorMessage}</FormHelperText>
                )}
            </Box>
        </Fragment>
    )
}

export default FormTextFieldElement
