import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import { useIsSmallScreenFalse } from 'packages/core'
import { ListingGrid, GridContainer, Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAddItemsToCart,
    useCart,
    useOtherManagementRoleBusinessRolesAndLocations,
} from 'hooks'
import { useState } from 'react'
import { Tooltip, WarningModal } from 'packages/eid-ui'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import AddNewBusinessRoleAndLocation from './AddNewBusinessRoleAndLocations'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ASSIGNMENT_TYPES } from 'utils'

const canDeleteMultiple =
    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-CanDeletMultiple'

const canAddBusinessRoleAndLocation =
    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-CanAddBusinessRoleAndLocation'

const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const OtherTypesOfManagementRoleMembersBusinessRoleAndLocations = ({
    managementRoleId,
}) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()
    const [selectedIds, setSelectedIds] = useState([])
    const { data: cart } = useCart()
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)
    const [addItemsToCart, { isLoading: isDeleting }] = useAddItemsToCart()
    const { hasAccessToControl } = useSubcomponents()
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useOtherManagementRoleBusinessRolesAndLocations(managementRoleId)

    const isSmallScreen = useIsSmallScreenFalse()

    const attributes = [
        {
            name: 'businessRoleLocationFriendlyName',
            sortable: true,
            label: 'FriendlyName',
            isHeader: true,
            showInCard: false,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-RoleFriendlyName',
            },
        },
        {
            name: 'locationFriendlyName',
            sortable: true,
            label: 'Location',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-LocationFriendlyName',
            },
        },
        {
            name: 'businessRoleFriendlyName',
            sortable: true,
            label: 'RoleName',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-RoleName',
            },
        },
        {
            name: 'businessLocationFriendlyName',
            sortable: true,
            label: 'LocationName',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-LocationName',
            },
        },
        {
            name: 'businessRoleDescription',
            sortable: true,
            label: 'RoleDescription',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-RoleDescription',
            },
        },
        {
            name: 'locationDescription',
            sortable: true,
            label: 'LocationDescription',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-LocationDescription',
            },
        },

        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-OtherManagementRoles-BusinessRoleAndLocation-DeleteAction',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Tooltip
                            title={
                                checkIfAlreadyAddedToCart(data?.businessRoleId)
                                    ? t('Common_ItemAlreadyInCart')
                                    : ''
                            }
                        >
                            <DeleteButton>
                                <Button.Delete
                                    showEndIcon={false}
                                    onClick={() => handleSingleDelete(data)}
                                    disabled={checkIfAlreadyAddedToCart(
                                        data?.businessRoleId,
                                    )}
                                />
                            </DeleteButton>
                        </Tooltip>
                    )
                },
            },
        },
    ]

    const handleSingleDelete = (item) => {
        setSelectedItemsToDelete([item])
    }

    const checkIfAlreadyAddedToCart = (itemrequestableResourceId) => {
        return cartHelpers.isItemAlreadyInCart(cart, itemrequestableResourceId)
    }

    const getCartItem = (item) => {
        const cartItem = cartHelpers.addRemoveBusinessRoleAndLocation({
            assignmentType: ASSIGNMENT_TYPES.REMOVE,
            targetAssigneeId: managementRoleId,
            businessRole: {
                ...item,
                id: item.businessRoleId,
            },
            locationId: item.locationId,
            title: item.businessRoleLocationFriendlyName,
        })
        return cartItem
    }

    const handleDeleteConfirm = () => {
        try {
            let cartItems = []
            selectedItemsToDelete.forEach((element) => {
                cartItems.push(getCartItem(element))
            })
            addItemsToCart(cartItems).then(() => {
                setSelectedItemsToDelete(null)
                setSelectedIds([])
            })
        } catch (err) {}
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selectedIds]
        const selectedIndex = selectedIds.findIndex((id) => id === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelectedIds(filteredList)
        } else {
            setSelectedIds([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selectedIds.length === list.length) {
            setSelectedIds([])
        } else {
            const filteredListNotAssigned = list.filter((item) => {
                if (!checkIfAlreadyAddedToCart(item?.businessRoleId)) {
                    return item
                }
            })
            const filteredList = filteredListNotAssigned?.length
                ? filteredListNotAssigned.map((item) => item.id)
                : []

            setSelectedIds(filteredList)
        }
    }

    const selectAllAction = hasAccessToControl(canDeleteMultiple)
        ? {
              style: {
                  padding: '0px !important',
              },
              render: () => {
                  return (
                      <BulkRemoveButton
                          selectedCount={selectedIds.length}
                          label={t('RemoveSelectedBusinessRoleAndLocation', {
                              count: selectedIds.length,
                          })}
                          onClick={handleBulkAction}
                          dataProtectedsubcomponent={canDeleteMultiple}
                      />
                  )
              },
          }
        : null

    const handleBulkAction = () => {
        const selectedItems = list.filter((i) => selectedIds.includes(i.id))
        setSelectedItemsToDelete(selectedItems)
    }

    return (
        <Box>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    justifyContent="space-between"
                >
                    {hasAccessToControl(canAddBusinessRoleAndLocation) ? (
                        <AddNewBusinessRoleAndLocation
                            protectedsubcomponent={
                                canAddBusinessRoleAndLocation
                            }
                            managementRoleId={managementRoleId}
                            assignedRoleAndLocation={list}
                        />
                    ) : (
                        <Box></Box>
                    )}
                    <Box width="240px" display="flex" justifyContent="flex-end">
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '16px 0px',
                        marginTop: '0px',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        selectAllAction={selectAllAction}
                        rowSelection={hasAccessToControl(canDeleteMultiple)}
                        handleClick={handleRowSelection}
                        handleSelectAllClick={handleSelectAllClick}
                        selected={selectedIds}
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={
                            (list?.length &&
                                list.map((item) => {
                                    return {
                                        ...item,
                                        isAlreadyAssigned:
                                            checkIfAlreadyAddedToCart(
                                                item?.businessRoleId,
                                            ),
                                    }
                                })) ||
                            []
                        }
                    />
                </GridContainer>
            </ListingGrid>
            {selectedItemsToDelete && (
                <WarningModal
                    title={
                        selectedItemsToDelete.length === 1
                            ? `${t('Delete')} ${
                                  selectedItemsToDelete[0]
                                      ?.businessRoleLocationFriendlyName
                              }`
                            : t('DeleteSelectedItems')
                    }
                    description={
                        selectedItemsToDelete.length === 1
                            ? t(
                                  'OtherManagementRoleDeleteBusinessRoleAndLocation',
                              )
                            : t(
                                  'OtherManagemnetRoleBusinessRoleAndLocationDeleteSelectedMessage',
                              )
                    }
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete}
                    cancel={() => {
                        setSelectedItemsToDelete(null)
                    }}
                    proceeding={isDeleting}
                    proceed={handleDeleteConfirm}
                />
            )}
        </Box>
    )
}

export default OtherTypesOfManagementRoleMembersBusinessRoleAndLocations
