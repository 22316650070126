/**
 * To override small screen, if the screen is less than or equal to 959.px
 *
 * @returns (bool)
 */
const useIsSmallScreenFalse = () => {
    return false
}

export default useIsSmallScreenFalse
