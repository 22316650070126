import React, { useState } from 'react'
import { Loader, Tooltip } from 'packages/eid-ui'
import {
    MenuItem,
    ListItemText,
    ListItemIcon,
    Paper,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useUiActionsByNounAndVerb } from 'hooks'
import { useTranslation } from 'react-i18next'
import config from 'config'

const useMenuStyles = makeStyles((theme) => ({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        borderLeft: '2px solid transparent',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
            borderLeft: `2px solid ${theme?.palette?.primary?.main}`,
            '& svg': {
                opacity: 1,
                filter: 'grayscale(0%) ',
                color: 'blue',
            },
        },
        '& span': {
            width: '300px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    icon: {
        minWidth: '20px !important',
        color: 'black',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    noActionMessage: {
        padding: '6px 30px',
        minHeight: '40px',
        textAlign: 'center',
    },
}))

const useHeaderStyles = makeStyles((theme) => ({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '60px',
        borderLeft: '2px solid transparent',
        borderRadius: '4px',
    },
    icon: {
        minWidth: '40px',
        color: 'black',
        '& svg': {
            height: '18px',
            width: '18px',
        },
    },
    selected: {
        backgroundColor: `${theme?.palette?.primary?.main} !important`,
        color: '#fff',
    },
}))

const ActionsList = (props) => {
    const { noun = 'ResourceAdmin', verb = '' } = props
    const classes = useMenuStyles()
    const { t } = useTranslation()

    const query = useQuery()

    const { data, isLoading } = useUiActionsByNounAndVerb(noun, verb)

    const history = useHistory()

    const handleClick = (name) => {
        query.set('workflowName', name)
        query.set('returnUrl', getFullPathFromUrl())
        history.push(
            `${history.location.pathname}/workflows?${query.toString()}`,
        )
    }

    return (
        <>
            <Paper style={{ borderRadius: '8px' }}>
                {isLoading ? (
                    <Loader />
                ) : data && data.length === 0 ? (
                    <Typography className={classes.noActionMessage}>
                        {t('NoActionFound')}
                    </Typography>
                ) : (
                    data &&
                    data.map((d, index) => (
                        <MenuItem
                            key={d.id + index}
                            onClick={() => handleClick(d.requestWorkflowName)}
                            classes={classes}
                        >
                            <ListItemIcon className={classes.icon}>
                                <Icon name="Arrow" direction="right" />
                            </ListItemIcon>
                            <Tooltip title={d.friendlyName}>
                                <ListItemText className={classes.text}>
                                    {d.friendlyName}
                                </ListItemText>
                            </Tooltip>
                        </MenuItem>
                    ))
                )}
            </Paper>
        </>
    )
}

const ListingPageUIActionByNounAndVerb = (props) => {
    const { noun, verb } = props
    const classes = useHeaderStyles()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const header = (
        <Paper style={{ marginBottom: '5px' }}>
            <MenuItem
                classes={classes}
                selected={open}
                onClick={() => {
                    setOpen((prev) => !prev)
                }}
            >
                <ListItemIcon className={classes.icon}>
                    <Icon name="Actions" color={open ? 'white' : 'black'} />
                </ListItemIcon>
                <ListItemText>{t('Actions')}</ListItemText>

                <Typography style={{ display: 'inherit' }}>
                    <Icon
                        name="Arrow"
                        direction="down"
                        color={open ? 'white' : 'black'}
                    />
                </Typography>
            </MenuItem>
        </Paper>
    )

    return (
        <>
            {header}
            {open && (
                <>
                    <ActionsList noun={noun} verb={verb}></ActionsList>
                </>
            )}
        </>
    )
}

export default ListingPageUIActionByNounAndVerb
