const attributes = [
    {
        hideValue: true,
        showInCard: false,
        image: true,
        component: {
            componentName: 'ResourceImage',
            name: 'applicationLogoImageUrl',
            defaultUrl: '/webcdn/Images/AppLogos/Genric-1.png',
        },
        requireAccess: {
            control: 'ResourceAdmin-Application-List-LogoImage',
        },
    },
    {
        name: 'friendlyName',
        sortable: true,
        showInCard: false,

        sortBy: 'friendlyName',
        hideValue: true,
        label: 'FriendlyName',
        isHeader: true,
        component: {
            componentName: 'HighlightedClickableLabel',
            queryParam: 'applicationId',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        //NOTE : Keeping the commented out code for subcomponent traceability
        // requireAccess: {
        //     control: 'ResourceAdmin-Application-List-FriendlyName',
        // },
    },

    {
        name: 'friendlyName',
        sortable: true,
        showInCard: false,

        sortBy: 'friendlyName',
        hideValue: true,
        label: 'FriendlyName',
        isHeader: true,
        isClaimPolicyAttributeOnly: true,
        //NOTE : Keeping the commented out code for subcomponent traceability
        // requireAccess: {
        //     control:
        //         'ResourceAdmin-Application-List-ClaimMappingPolicy-FriendlyName',
        // },
        component: {
            componentName: 'HighlightedClickableLabel',
            queryParam: 'claimsMappingPolicyId',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'name',
        sortable: true,
        sortBy: 'Name',
        label: 'TechnicalName',
        isClaimPolicyAttribute: true,
        styles: {
            fontSize: '13px',
        },
        className: 'bold',
        requireAccess: {
            control: 'ResourceAdmin-Application-List-TechnicalName',
        },
    },
    {
        name: 'includeBasicClaimSet',
        sortable: false,
        sortBy: 'description',
        label: 'IncludeBasicClaimSet',
        cardStyles: {
            minHeight: '60px',
            alignItems: 'start',
        },
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        isClaimPolicyAttributeOnly: true,
        requireAccess: {
            control: 'ResourceAdmin-Application-List-IncludeBasicClaimSet',
        },
        component: {
            componentName: 'BooleanValue',
        },
    },
    {
        name: 'resourceSystemFriendlyName',
        sortable: true,
        sortBy: 'resourceSystemFriendlyName',
        label: 'ResourceSystem',
        showInCard: false,
        isClaimPolicyAttribute: true,
        styles: {
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-Application-List-ResourceSystem',
        },
    },
    {
        name: 'ownerFriendlyName',
        sortable: true,
        hideValue: true,
        sortBy: 'ownerFriendlyName',
        label: 'ResponsibleParty',
        component: {
            componentName: 'ApplicationOwner',
        },
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-Application-List-ResponsibleParty',
        },
        cardStyles: {
            minHeight: '55px',
        },
    },
    {
        name: 'description',
        sortable: true,
        sortBy: 'description',
        label: 'Description',
        cardStyles: {
            minHeight: '60px',
            alignItems: 'start',
        },
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-Application-List-Description',
        },
    },

    {
        hideValue: true,
        showInCard: false,
        component: {
            componentName: 'actionButtons',
        },
        requireAccess: {
            control: 'ResourceAdmin-Application-List-Actions',
        },
    },
    {
        hideValue: true,
        showInCard: false,
        requireAccess: {
            control:
                'ResourceAdmin-Application-List-ClaimMappingPolicy-Actions',
        },
        component: {
            componentName: 'actionButtons',
        },
        isClaimPolicyAttributeOnly: true,
    },
]

export default attributes
