import React from 'react'
import ApplicationAppRightsPeople from './ApplicationAppRightsPeople/ApplicationAppRightsPeople'
import ApplicationAppRightsAssignments from './ApplicationsAppRightAssignments/ApplicationAppRightsAssignments'
import ApplicationRights from './ApplicationRights'
import { PageContextProvider } from 'pageContext'

const ApplicationAppRights = ({
    subActiveTab,
    applicationId,
    applicationFriendlyName,
    resourceId,
    resourceSystemModuleID,
}) => {
    if (subActiveTab === 'appRights') {
        return (
            <PageContextProvider key="RequestViewAppRightsListing">
                <ApplicationRights
                    applicationId={applicationId}
                    resourceId={resourceId}
                />
            </PageContextProvider>
        )
    } else if (subActiveTab === 'assignments') {
        return (
            <PageContextProvider key="RequestViewAppRightsAssignments">
                <ApplicationAppRightsAssignments
                    resourceSystemModuleID={resourceSystemModuleID}
                    resourceId={resourceId}
                />
            </PageContextProvider>
        )
    } else if (subActiveTab === 'people') {
        return (
            <PageContextProvider key="RequestViewAppRightsPeople">
                <ApplicationAppRightsPeople
                    applicationFriendlyName={applicationFriendlyName}
                    applicationId={applicationId}
                />
            </PageContextProvider>
        )
    }
    return <></>
}

export default ApplicationAppRights
