import { Box } from '@mui/material'
import AddTimeConstraint from 'components/AddTimeConstraint/AddTimeConstraint'
import { useTranslation } from 'react-i18next'
import DateTimeGenericComponent from './DateTimeGenericComponent'

const AddEditTimeConstraint = ({
    startDate,
    endDate,
    isEditable = true,
    onSave,
    saving,
    disabledTooltip = ''
}) => {
    const { t } = useTranslation()

    if (!startDate && !endDate && !isEditable) {
        return <>-</>
    }

    return (
        <Box>
            <Box
                style={{
                    display: 'flex',
                    gap: 20,
                    alignItems: 'center',
                }}
            >
                {(startDate || endDate) && (
                    <Box minWidth={'200px'}>
                        <Box
                            style={{
                                display: 'flex',
                                gap: 10,
                            }}
                        >
                            <strong>{t('Starts')}:</strong>
                            <span>
                                <DateTimeGenericComponent
                                    data={{ startDateUtc: startDate }}
                                    attribute={{ name: 'startDateUtc' }}
                                    format={'lll'}
                                />
                            </span>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                gap: 17,
                                marginTop: '5px',
                            }}
                        >
                            <strong>{t('Ends')}:</strong>

                            <span>
                                <DateTimeGenericComponent
                                    data={{ endDateUtc: endDate }}
                                    attribute={{ name: 'endDateUtc' }}
                                    format={'lll'}
                                />
                            </span>
                        </Box>
                    </Box>
                )}

                {isEditable && (
                    <AddTimeConstraint
                        iconName={startDate || endDate ? 'EditFile' : ''}
                        btnLabel="AddTimeConstraint"
                        startTime={startDate}
                        endTime={endDate}
                        onSave={onSave}
                        saving={saving}
                        disabledTooltip={disabledTooltip}
                    />
                )}
            </Box>
        </Box>
    )
}

export default AddEditTimeConstraint
