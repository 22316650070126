import { useState } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper, styled } from '@mui/material'
import { useApplicationSettings, useClaimsMappingPolicy } from 'hooks'
import { LeftPaneActions, LeftPaneTabs } from 'components'
import { useIsSmallScreenFalse } from 'packages/core'
import StickyContainer from 'components/StickyContainer'
import { ItemDetails, Button, TextFormatter } from 'components'
import bgHeader from '../../../assets/images/bgHeader.png'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import { useHistory } from 'react-router'
import { PageContextProvider } from 'pageContext'
import { PolicyOverview } from './PolicyOverview'
import { Icon } from 'packages/eid-icons'
import { smallScreenWidth } from 'utils'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
    textAlign: 'center',
})

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        nameContainer: {
            width: '100%',
            minHeight: '50px',
            marginBottom: '20px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            overflow: 'hidden',
            fontSize: '22px',
            fontWeight: 'bold',
            borderRadius: '8px',
            color: '#3b454d',
            wordBreak: 'break-word',
            padding: '5px',
        },

        tabsContainer: {
            width: '100%',
            borderRadius: '0px',
            marginBottom: '20px',
        },
    }),
)

export const TabsColumn = styled(Box)({
    display: 'flex',
    minWidth: '340px',
    flexDirection: 'column',
    margin: '0px 12px 0px 10px',
    marginRight: '20px',
    width: '30%',
    flex: '36%',
})

const ClaimsMappingPolicyDetails = ({ id }) => {
    const classes = useStyles()
    const { data: claimsMappingPolicy } = useClaimsMappingPolicy(id)
    const [activeTab, setActiveTab] = useState('overview')
    const isSmallScreen = useIsSmallScreenFalse()
    const { data: appConfig } = useApplicationSettings()
    const { t } = useTranslation()

    const handleTabChange = (tab) => {
        setActiveTab(tab)
    }
    const { controls } = useSubcomponents()
    const history = useHistory()

    const tabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
    ]

    const isAccessible = (tab) => {
        return controls.includes(tab.requireAccess?.control)
    }

    //TODO : Enable when Shopping Cart is enabled for Claim Mapping Policies
    // useEffect(() => {
    //     if (id && claimsMappingPolicy?.friendlyName) {
    //         dispatch({
    //             type: 'SET_CURRENT_RESOURCE',
    //             payload: {
    //                 targetAssigneeId: id,
    //                 targetAssigneeFriendlyName:
    //                     claimsMappingPolicy?.friendlyName,
    //             },
    //         })

    //         dispatch({
    //             type: 'SET_TARGET_RESOURCE',
    //             payload: {
    //                 targetAssigneeId: id,
    //                 targetAssigneeFriendlyName:
    //                     claimsMappingPolicy?.friendlyName,
    //                 targetAssignee_ResourceTypeFriendlyName: 'Applications',
    //             },
    //         })
    //     }
    // }, [id, claimsMappingPolicy?.friendlyName])

    if (isSmallScreen) {
        return (
            <>
                <StickyContainer
                    color="#ffffff"
                    styles={{
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box paddingY="10px" width="100%" maxWidth="260px">
                            <Button.BackButton
                                onClick={() => {
                                    history.push(history.location.pathname)
                                }}
                            >
                                {t('BackToClaimsMappingPoliciesList')}
                            </Button.BackButton>
                        </Box>

                        <HeaderContainer
                            height="80px"
                            textAlign="start"
                            bgcolor={appConfig.style.colors.primary?.header}
                            style={{
                                backgroundImage: `url(${bgHeader})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <TextFormatter
                                value={
                                    claimsMappingPolicy?.friendlyName ??
                                    claimsMappingPolicy?.name
                                }
                                maxCharacters={75}
                            />
                        </HeaderContainer>
                    </Box>
                </StickyContainer>
                <ItemDetails.Tabs
                    variant="scrollable"
                    value={activeTab}
                    onChange={(_, value) => setActiveTab(value)}
                    style={{ background: '#fbfbfd' }}
                >
                    {tabs &&
                        tabs
                            .filter((x) => x.display === true)
                            .filter((y) => {
                                if (y.requireAccess) {
                                    return isAccessible(y)
                                } else {
                                    return true
                                }
                            })
                            .map((tab, index) => (
                                <ItemDetails.Tabs.Tab
                                    key={index + tab.value}
                                    value={tab.value}
                                    label={t(tab.label)}
                                />
                            ))}
                </ItemDetails.Tabs>
                <Box className={classes.root}>
                    {activeTab === 'overview' && claimsMappingPolicy && (
                        <PageContextProvider
                            key={'ClaimsMappingPolicyOverview'}
                        >
                            <PolicyOverview policy={claimsMappingPolicy} />
                        </PageContextProvider>
                    )}
                </Box>
            </>
        )
    }

    return (
        <>
            <Box display="flex">
                <TabsColumn>
                    <Paper className={classes.nameContainer}>
                        {claimsMappingPolicy?.friendlyName}
                    </Paper>
                    <Paper className={classes.tabsContainer}>
                        <LeftPaneTabs
                            active={activeTab}
                            onChange={handleTabChange}
                            tabs={tabs}
                        />
                    </Paper>
                    <LeftPaneActions
                        resource={claimsMappingPolicy}
                        resourceType="claimsMappingPolicy"
                        activeTab={activeTab}
                        verb={'ClaimsMappingPolicy'}
                    />
                </TabsColumn>
                <Box className={classes.root}>
                    {activeTab === 'overview' && claimsMappingPolicy && (
                        <PageContextProvider
                            key={'ClaimsMappingPolicyOverview'}
                        >
                            <PolicyOverview policy={claimsMappingPolicy} />
                        </PageContextProvider>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default ClaimsMappingPolicyDetails
