import { Box, Typography, useTheme } from '@mui/material'

export const TabLabel = ({
    label,
    count,
    countColor = '',
}: {
    label: string
    count: number | undefined
    countColor: string
}) => {
    const theme = useTheme()
    return (
        <Box
            component="span"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: 'transparent' }}
        >
            <Typography
                style={{
                    maxWidth: '11rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                title={label}
            >
                {label}
            </Typography>
            <Typography
                style={{
                    color: count === 0 ? theme.palette.grey['900'] : countColor,
                }}
                className="tab-count"
            >
                {count}
            </Typography>
        </Box>
    )
}
