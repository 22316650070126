import { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from 'react-i18next'
import { Loader } from 'packages/eid-ui'

const useRedStyles = makeStyles(
    createStyles({
        root: (styles) => ({
            position: 'relative',
            height: '31px',
            padding: '8px 12px',
            borderRadius: '4px',
            width: 'max-content',
            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',
            lineHeight: 'initial',
            backgroundColor: 'transparent',
            border: 'solid 1px #ff4d5f',

            color: '#ff4d5f',
            '&:hover': {
                backgroundColor: '#ff4d5f',
                color: 'white',
                cursor: 'hand',
            },
            ...styles,
        }),
        disabled: {
            backgroundColor: '#efeff1 !important',
            color: '#8b909a !important',
            border: 'solid 1px #8b909a !important',
        },
    }),
)

const DeleteButton = ({
    onClick,
    label,
    disabled,
    showEndIcon = false,
    styles,
    loading,
}) => {
    const { t } = useTranslation()
    const [actionsButtonIconColor, setActionsButtonIconColor] =
        useState('#ff4d5f')

    const buttonClasses = useRedStyles(styles)

    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            onMouseEnter={() => {
                setActionsButtonIconColor('white')
            }}
            onMouseLeave={() => {
                setActionsButtonIconColor('#ff4d5f')
            }}
            classes={buttonClasses}
            endIcon={
                showEndIcon && (
                    <Icon
                        name="DeleteX"
                        style={{ width: '6px', height: '6px' }}
                        color={disabled ? '#8b909a' : actionsButtonIconColor}
                        fill={disabled ? '#8b909a' : actionsButtonIconColor}
                    />
                )
            }
        >
            {!loading ? (
                t(label ? label : 'Delete')
            ) : (
                <Loader
                    color={actionsButtonIconColor}
                    size={0.6}
                    height="0.5inh"
                    style={{ backgroundColor: 'transparent' }}
                />
            )}
        </Button>
    )
}

export default DeleteButton
