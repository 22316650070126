import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import { useIsSmallScreenFalse } from 'packages/core'
import { ListingGrid, GridContainer, Button, Filters } from 'components'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAddItemsToCart,
    useAppManagementRolesGrantedByManagementRoles,
    useCart,
} from 'hooks'
import BooleanValue from 'components/DynamicComponents/BooleanValue'
import { Tooltip, WarningModal } from 'packages/eid-ui'
import { useState } from 'react'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import { PageContextProvider } from 'pageContext'
import { Icon } from 'packages/eid-icons'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ASSIGNMENT_TYPES, RESOURCE_TYPE_NAMES } from 'utils'
import AddNewAssignment from 'components/Popups/AddNewAssignment/AddNewAssignment'

const canDeleteRole =
    'ResourceAdmin-ManagementRoleDetails-ManagementRoleGrantedByManagementRole-DeleteRole-Control'
const canRemoveMultipleRolesControlName =
    'ResourceAdmin-ManagementRoleDetails-ManagementRoleGrantedByManagementRole-DeleteMultipleRoles-Control'

const DeleteButton = styled(Box)({
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const ManagementRoleGrantedByManagementRole = ({ managementRole }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const [selected, setSelected] = useState([])
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)
    const [addItemsToCart, { isLoading: addingToCart }] = useAddItemsToCart()
    const { data: cart } = useCart()

    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useAppManagementRolesGrantedByManagementRoles(managementRole?.id)

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            label: 'MemberOf',
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ManagementRoleDetails-ManagementRoleGrantedByManagementRole-MemberOf',
            // },
        },
        {
            name: 'highSecurity',
            hideValue: true,
            sortable: true,
            label: 'HighSecurity',
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-ManagementRoleGrantedByManagementRole-HighSecurity',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanValue
                        data={{ item }}
                        attribute={{ name: 'highSecurity' }}
                    />
                ),
            },
        },
        {
            name: 'type',
            sortable: true,
            label: 'Type',

            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-ManagementRoleGrantedByManagementRole-Type',
            },
        },
        {
            name: 'description',
            sortable: true,
            label: 'Description',

            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-ManagementRoleGrantedByManagementRole-Description',
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(canDeleteRole) && (
                            <Tooltip
                                title={
                                    checkIfAlreadyAddedToCart(data?.id)
                                        ? t('Common_ItemAlreadyInCart')
                                        : ''
                                }
                            >
                                <DeleteButton>
                                    <Button.Delete
                                        showEndIcon={false}
                                        onClick={() => handleSingleDelete(data)}
                                        disabled={checkIfAlreadyAddedToCart(
                                            data?.id,
                                        )}
                                    />
                                </DeleteButton>
                            </Tooltip>
                        )
                    )
                },
            },
        },
    ]
    const checkIfAlreadyAddedToCart = (itemRequestableResourceId) => {
        return cartHelpers.isItemAlreadyInCart(cart, itemRequestableResourceId)
    }
    const handleSingleDelete = (item) => {
        setSelectedItemsToDelete([item])
    }

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }

    const postAdd = () => {
        setSelectedItemsToDelete(null)
        setSelected([])
    }

    const handleConfirmDelete = () => {
        const cartItems = cartHelpers.removeAssignmentToCartItem({
            targetAssigneeId: managementRole?.id,
            resource: managementRole,
            selectedList: selectedItemsToDelete,
            requestableResourceIdentifier: 'id',
            assignmentType: ASSIGNMENT_TYPES.UN_GRANT,
        })

        addItemsToCart(cartItems).then(() => postAdd())
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x.id === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x.id !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredListNotAssigned = list.filter((item) => {
                if (!checkIfAlreadyAddedToCart(item?.id)) {
                    return item
                }
            })
            if (
                containsAll(filteredListNotAssigned, selected) &&
                containsAll(selected, filteredListNotAssigned)
            ) {
                setSelected([])
            } else {
                setSelected(filteredListNotAssigned)
            }
        }
    }

    const containsAll = (arr1, arr2) =>
        arr2.every((arr2Item) => arr1.includes(arr2Item))

    const selectAllAction = hasAccessToControl(
        canRemoveMultipleRolesControlName,
    )
        ? {
              render: () => {
                  return (
                      <BulkRemoveButton
                          selectedCount={selected.length}
                          label={t('RemoveSelectedManagementRoles', {
                              count: selected.length,
                          })}
                          onClick={handleDeleteBulk}
                          dataProtectedsubcomponent={
                              canRemoveMultipleRolesControlName
                          }
                      />
                  )
              },
          }
        : null

    return (
        <Box>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="space-between"
                    height="40px"
                >
                    <PageContextProvider
                        key={'ManagementRolesGrantedByManagementRoles'}
                    >
                        <AddNewAssignment
                            resourceType={RESOURCE_TYPE_NAMES.MANAGEMENT_ROLES}
                            labelKey={'GrantManagementRoles'}
                            searchUrl={`api/ManagementRoles/unassignedManagementRoles/${managementRole?.id}`}
                            showPermissionLevel={false}
                            targetAssigneeId={managementRole?.id}
                            secondaryAttributes={['description']}
                            hideDurationSelection={true}
                            startIcon={
                                <Icon
                                    name={'Plus'}
                                    fill="white"
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '5px',
                                    }}
                                />
                            }
                            assignmentType={ASSIGNMENT_TYPES.GRANT}
                        />
                    </PageContextProvider>

                    <Box width="240px" display="flex" justifyContent="flex-end">
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '16px 0px',
                        marginTop: '0px',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={
                            (list?.length &&
                                list.map((item) => {
                                    return {
                                        ...item,
                                        isAlreadyAssigned:
                                            checkIfAlreadyAddedToCart(item?.id),
                                    }
                                })) ||
                            []
                        }
                        selectAllAction={selectAllAction}
                        rowSelection={hasAccessToControl(
                            canRemoveMultipleRolesControlName,
                        )}
                        handleSelectAllClick={handleSelectAllClick}
                        selected={selected.map((s) => s.id)}
                        handleClick={handleRowSelection}
                    />
                </GridContainer>
            </ListingGrid>
            {selectedItemsToDelete && (
                <WarningModal
                    title={
                        selectedItemsToDelete.length === 1
                            ? `${t('Delete')} ${
                                  selectedItemsToDelete[0]?.friendlyName
                              }`
                            : t('DeleteSelectedItems')
                    }
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceed={handleConfirmDelete}
                    proceeding={addingToCart}
                />
            )}
        </Box>
    )
}

export default ManagementRoleGrantedByManagementRole
