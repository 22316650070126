import { Box, Typography } from '@mui/material'
import { Skeleton } from '@mui/material';
import { Button } from 'components'
import {
    useAssignRoleDefinitionForPeople,
    usePeopleAssignedApplication,
    useUnassignRoleDefinitionsForPeople,
    useUpdatePersonDetails,
} from 'hooks'
import { Divider } from 'packages/eid-ui'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import useSubcomponents from 'useSubcomponents'
import PeopleApplicationList from './PeopleEditForm/PeopleApplications/PeopleApplicationList'
import PeopleEditForm from './PeopleEditForm/PeopleEditForm'
import StatusInfoMessage from '../../../components/Popups/StatusMessage/StatusInfoMessage'
import { isNilOrEmpty } from 'packages/core'

const DynamicInfoSection = ({
    classes,
    isLoading,
    personDetailsLoading,
    dynamicFormData,
    personDetails,
    refetch,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [formData, setFormData] = useState(null)

    const {
        data: applications,
        isLoading: isLoadingApps,
        refetch: refetchApps,
    } = usePeopleAssignedApplication(personDetails?.id)
    const [selectedApplicationList, setSelectedApplicationList] = useState([])
    const { hasAccessToControl } = useSubcomponents()
    const [updateInfoMessages, setUpdateInfoMessages] = useState([])
    const checkAccessToField = (fieldName) => {
        return hasAccessToControl(
            `ResourceAdmin-PersonDetails-${fieldName}-Control`,
        )
    }
    const [updatePeopleDetails, { isLoading: isUpdateLoading }] =
        useUpdatePersonDetails(refetch)
    const [assignRoles, { isLoading: isAssigning }] =
        useAssignRoleDefinitionForPeople(refetchApps)

    const [unAssignRoles, { isLoading: isUnAssigning }] =
        useUnassignRoleDefinitionsForPeople(refetchApps)

    useEffect(() => {
        if (!formData && dynamicFormData) {
            prepareFormDataFromJson()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, dynamicFormData])

    const bindInitialValue = (fieldName) => {
        return personDetails[fieldName] || ''
    }

    const prepareFormDataFromJson = () => {
        let formFields = []
        Object?.keys(dynamicFormData).forEach((key) => {
            if (dynamicFormData?.[key]?.length) {
                const columns = dynamicFormData[key]
                columns.forEach((item) => {
                    if (item?.subColumn) {
                        const subColumns = item.subColumn
                        subColumns.forEach((item) => {
                            formFields.push({
                                name: item.fieldName,
                                value: bindInitialValue(item.fieldName),
                                isRequired:
                                    checkAccessToField(item.fieldName) &&
                                    item.required
                                        ? item.required
                                        : false,
                                localeKey: item.localeKey,
                                validationRegex: item.validationRegex,
                                validationMessage: item.validationMessage,
                            })
                        })
                    } else {
                        formFields.push({
                            name: item.fieldName,
                            value: bindInitialValue(item.fieldName),
                            isRequired:
                                checkAccessToField(item.fieldName) &&
                                item.required
                                    ? item.required
                                    : false,
                            localeKey: item.localeKey,
                            validationRegex: item.validationRegex,
                            validationMessage: item.validationMessage,
                        })
                    }
                })
            }
        })
        setFormData(formFields)
    }

    const getErrorMessage = (
        fieldValue,
        regexString,
        validationError,
        isRequired,
    ) => {
        let errorMessage = ''

        if (isRequired && !fieldValue) {
            return (errorMessage = t('FieldIsRequired'))
        } else if (regexString) {
            try {
                var regex = new RegExp(regexString)
                if (!fieldValue.match(regex)) {
                    errorMessage = validationError
                        ? t(validationError)
                        : t('EnterValidValue')
                }
            } catch (e) {
                if (e instanceof SyntaxError) {
                    console.error(
                        'Invalid regex syntax for field validation: ',
                        e.message,
                    )
                } else {
                    console.error(
                        'An unknown error occurred during regex field validation: ',
                        e.message,
                    )
                }
            }
        }
        return errorMessage
    }

    const updateFormDataOnChange = (fieldData) => {
        let mappedFormData = [...formData]
        const findChangedMappingField = mappedFormData.findIndex(
            (x) => x.name === fieldData.name,
        )
        if (findChangedMappingField >= 0) {
            const changedMappingField = mappedFormData[findChangedMappingField]
            changedMappingField.value = fieldData.value
            if (
                changedMappingField.validationRegex ||
                changedMappingField.isRequired
            ) {
                changedMappingField.errorMessage = getErrorMessage(
                    fieldData.value,
                    changedMappingField.validationRegex,
                    changedMappingField.validationMessage,
                    changedMappingField.isRequired,
                )
            }
            setFormData(mappedFormData)
        }
    }

    const checkIfValidForm = () => {
        let isValidForm = true
        let newFormData = [...formData]
        newFormData?.forEach((item) => {
            item.errorMessage = getErrorMessage(item.value, item.validationRegex, item.validationError, item.isRequired)
            if (!isNilOrEmpty(item.errorMessage)) {
                isValidForm = false
            }
        })
        if (!isValidForm) {
            setFormData(newFormData)
        }
        return isValidForm
    }

    const checkErrorMessagesExist = () => {
        let errorMessagesExist = false
        formData?.forEach((item) => {
            if (!isNilOrEmpty(item.errorMessage)) {
                errorMessagesExist = true
            }
        })
        return errorMessagesExist
    }

    const handleSave = () => {
        try {
            if (checkIfValidForm()) {
                // This will invoke api to update the basic information
                if (checkForUpdatedFields()) {
                    let tempDetails = { ...personDetails }
                    formData?.forEach((item) => {
                        tempDetails = {
                            ...tempDetails,
                            [item?.name]: item.value,
                        }
                    })
                    updatePeopleDetails(tempDetails).then((data) => {
                        const fieldsUpdateResponse = data?.data
                        if (fieldsUpdateResponse?.length) {
                            setUpdateInfoMessages(fieldsUpdateResponse)
                        }
                    })
                }
            }
            // Invoke api if there is anychange in application's assignments
            if (selectedApplicationList?.length) {
                // Adding roles
                let assignedItem = []
                let unAssignedItem = []
                selectedApplicationList.forEach((element) => {
                    const assigned = element?.newlyAddedItems || []
                    const unAssigned = element?.newlyRemovedItems || []
                    assignedItem = [...assignedItem, ...assigned]
                    unAssignedItem = [...unAssignedItem, ...unAssigned]
                })

                const uniqueAssignedItems = assignedItem.filter(
                    (item, index) => {
                        return (
                            assignedItem.map((x) => x.id).indexOf(item.id) ===
                            index
                        )
                    },
                )
                const uniqueUnAssignedItems = unAssignedItem.filter(
                    (item, index) => {
                        return (
                            unAssignedItem.map((x) => x.id).indexOf(item.id) ===
                            index
                        )
                    },
                )

                // Check for assigned Only
                const filteredAssignedOnly = uniqueAssignedItems.filter(
                    (item) =>
                        !uniqueUnAssignedItems
                            .map((x) => x.id)
                            .includes(item.id),
                )
                if (filteredAssignedOnly?.length) {
                    assignRoles({
                        localRolesToAssign: filteredAssignedOnly.map((i) => {
                            return {
                                localRoleId: i.id,
                                endDate: i.endDateUtc,
                            }
                        }),
                        assigneeIds: [personDetails.id],
                    }).then(() => {
                        setSelectedApplicationList([])
                    })
                }

                // Removing roles
                const filteredUnAssignedOnly = uniqueUnAssignedItems.filter(
                    (item) => !uniqueAssignedItems.includes(item),
                )

                if (filteredUnAssignedOnly?.length) {
                    unAssignRoles({
                        localRoleIds: filteredUnAssignedOnly.map((i) => i.id),
                        assigneeIds: [personDetails.id],
                    }).then(() => {
                        setSelectedApplicationList([])
                    })
                }
            }
        } catch (err) {}
    }

    const handleCancel = () => {
        prepareFormDataFromJson()
        history.goBack()
    }

    const checkForUpdatedFields = () => {
        let isValueChanged = false
        try {
            formData?.forEach((item) => {
                if (personDetails[item.name] !== item.value) {
                    isValueChanged = true
                }
            })
        } catch (err) {}
        return isValueChanged
    }

    const handleApplicationChange = (applicationData) => {
        try {
            const selectedApplicationIndex = selectedApplicationList.findIndex(
                (x) => x.applicationId === applicationData.applicationId,
            )
            if (selectedApplicationIndex >= 0) {
                const updatedList = selectedApplicationList.map((item) => {
                    if (item.applicationId === applicationData.applicationId) {
                        return applicationData
                    }
                    return item
                })
                setSelectedApplicationList(updatedList)
            } else {
                const tempList = [...selectedApplicationList]
                setSelectedApplicationList([...tempList, applicationData])
            }
        } catch (err) {}
    }

    const bindAssignedPeopleList = (application, isAssigned) => {
        if (!application) {
            return null
        }
        const selectedApplication = selectedApplicationList.find(
            (x) => x.applicationId === application.id,
        )
        if (!selectedApplication) {
            return null
        }
        if (isAssigned) {
            return selectedApplication?.newlyAddedItems || []
        } else {
            return selectedApplication?.newlyRemovedItems || []
        }
    }

    const checkIfButtonIsDisabled = () => {
        if (checkForUpdatedFields()) {
            return false
        }
        if (selectedApplicationList?.length) {
            return false
        }
        return true
    }

    return (
        <Box display="flex" flexWrap="wrap">
            <Box
                className={classes.editPersonSection}
                style={{
                    width: '100%',
                }}
            >
                <Box className={classes.editPersonHeader}>
                    <Typography>{t('EditPerson')}</Typography>
                </Box>
                <Box
                    sx={{
                        padding: '20px',
                    }}
                >
                    <Fragment>
                        {isLoading || personDetailsLoading ? (
                            <Fragment>
                                <Skeleton height={20} />
                                <Skeleton height={20} />
                                <Skeleton height={20} />
                                <Skeleton height={20} />
                                <Skeleton height={20} />
                            </Fragment>
                        ) : (
                            <Fragment>
                                {formData && (
                                    <PeopleEditForm
                                        dynamicFormData={dynamicFormData}
                                        personDetails={personDetails}
                                        updateFormDataOnChange={
                                            updateFormDataOnChange
                                        }
                                        formData={formData}
                                    />
                                )}
                            </Fragment>
                        )}
                        <Box className={classes.Divider}>
                            <Divider />
                        </Box>
                        {personDetails && (
                            <PeopleApplicationList
                                personDetails={personDetails}
                                handleApplicationChange={
                                    handleApplicationChange
                                }
                                bindAssignedPeopleList={(item, isAssigned) =>
                                    bindAssignedPeopleList(item, isAssigned)
                                }
                                selectedApplicationList={
                                    selectedApplicationList
                                }
                                data={applications}
                                isLoading={isLoadingApps}
                            />
                        )}
                    </Fragment>
                </Box>
            </Box>
            <Box className={classes.detailsButtonsection}>
                <Button.Save
                    disabled={checkIfButtonIsDisabled() || checkErrorMessagesExist()}
                    isLoading={isUpdateLoading || isAssigning || isUnAssigning}
                    onClick={() => handleSave()}
                />
                <Button.Cancel onClick={() => handleCancel()} />
            </Box>
            {updateInfoMessages?.length ? (
                <StatusInfoMessage
                    messages={updateInfoMessages}
                    handleClose={() => setUpdateInfoMessages([])}
                />
            ) : null}
        </Box>
    )
}

export default DynamicInfoSection
