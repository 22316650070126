import PropTypes from 'prop-types'
import { Box, Card, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {
    Divider,
    FieldTypeValuesAssignmentInfo,
    Spinner,
    Tooltip,
} from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useApplicationSettings, useRemoveItemFromCart } from 'hooks'
import { Icon } from 'packages/eid-icons'
import { useCartItemStyles } from './styles'
import CartItemViolation from './CartItemViolation'
import classNames from 'classnames'
import { CartItemComment } from './CartItemComment'
import { RESOURCE_TYPES } from 'utils'
import { hasValidJson, updateJsonMappingWithApiFormat } from 'packages/core'
const assignmentTypeLabelKey = {
    Add: 'Common_Add',
    Remove: 'Common_Remove',
    UpdateTimeConstrain: 'Common_Update',
    UnGrant: 'Common_UnGrant',
    Grant: 'Common_Grant',
}

const MyTypography = withStyles({
    h2: {
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '1.2',
    },
    h3: {
        fontSize: '22px',
        color: '#000000',
        textTransform: 'uppercase',
    },
    h4: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#000000',
    },
    caption: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#8a8989',
    },
    subtitle1: {
        position: 'absolute',
        fontSize: '150px',
        right: '17px',
        top: '-100px',
        fontWeight: '300',
        color: '#FFF',
        opacity: '0.1',
    },
})(Typography)

const CartItem = (props) => {
    const { data: appConfig } = useApplicationSettings()
    const classes = useCartItemStyles({
        bgColor: appConfig.style.colors.primary?.header,
        assignmentType: props.item.assignmentType,
    })

    const { t } = useTranslation()
    const { item, index, onCommentChange, risks } = props

    const [removeItemFromCart, { isLoading: isRemoving }] =
        useRemoveItemFromCart()

    const bindIconName = (resourceType) => {
        if (
            resourceType === RESOURCE_TYPES.GROUP ||
            resourceType === RESOURCE_TYPES.SETGROUP
        ) {
            return 'ApplicationRole'
        }
        if (resourceType === RESOURCE_TYPES.PERSON) {
            return 'ViewProfile'
        }

        return resourceType
    }

    const showApplicationName = () => {
        return hasValidJson(
            updateJsonMappingWithApiFormat(
                JSON.parse(item.fieldTypeValueJson),
                false,
            ),
        )
    }

    const getParsedObject = (apiDataObject) => {
        try {
            const parsedObject = Array.isArray(apiDataObject)
                ? apiDataObject[0]
                : apiDataObject
            return parsedObject
        } catch (err) {}
        return apiDataObject
    }

    return (
        <Card className={classes.cartItem} key={`resadmin-cartitem-${index}`}>
            <Box className={classes.cartItemHeader}>
                <Box className={classes.itemLabel}>
                    <Box className={classes.addOrRemoveLabel}>
                        {t(assignmentTypeLabelKey[props.item.assignmentType])}
                    </Box>
                    {showApplicationName() && (
                        <Box className={classes.resourceTypeLabel} zIndex="10">
                            <Icon name={'ApplicationsSmall'} />
                            <Box component="span" marginLeft="0.8rem">
                                {item.resourceTypeRole_FriendlyName}
                            </Box>
                        </Box>
                    )}
                    <Box
                        className={classNames(classes.resourceTypeLabel, {
                            [classes.darkBg]: showApplicationName(),
                        })}
                    >
                        <Icon name={bindIconName(item.resourceType_Name)} />
                        <Box component="span" marginLeft="8px">
                            {t(`${item.resourceType_Name}`)}
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <Box
                        style={{
                            marginRight: '10px',
                        }}
                    >
                        {/* TODO: Need to show icon as per resource type */}
                        {/* <Avatar /> */}
                    </Box>
                    <MyTypography
                        variant="h2"
                        style={{ wordBreak: 'break-word' }}
                    >
                        {item.resource_FriendlyName}
                    </MyTypography>
                </Box>
                <Box>
                    <MyTypography
                        variant="h2"
                        style={{
                            fontSize: '89px',
                            color: '#fff',
                            opacity: '0.1',
                            position: 'absolute',
                            top: '-20px',
                            right: '10%',
                        }}
                    >
                        {index < 10 ? `0${index}` : index}
                    </MyTypography>
                </Box>
                <Tooltip title={t('Common_RemoveFromCart')}>
                    <Box
                        className={classes.deleteIconContainer}
                        onClick={() => {
                            if (isRemoving) return
                            removeItemFromCart(item.id)
                        }}
                    >
                        {isRemoving ? (
                            <Spinner color="#d0021b" size={0.6} />
                        ) : (
                            <Icon className="delete" name="Delete" />
                        )}
                    </Box>
                </Tooltip>
            </Box>
            <Box
                className={classNames(classes.cartItemContent, {
                    [classes.cartItemContentWithFieldTypes]:
                        item?.fieldTypeValueJson &&
                        hasValidJson(
                            updateJsonMappingWithApiFormat(
                                JSON.parse(item.fieldTypeValueJson),
                                false,
                            ),
                        ),
                })}
            >
                <Box className={classes.cartCommentContainer}>
                    <Box className={classes.cartComment}>
                        <CartItemComment
                            item={item}
                            containsRisks={risks && risks.length > 0}
                            onCommentChange={onCommentChange}
                        />
                    </Box>
                    <Box className={classes.timeConstraints}>
                        {item.timeConstraintActive ? (
                            <>
                                {item.startDateUtc && (
                                    <Attribute
                                        label={t('Common_StartDateTime')}
                                        value={moment
                                            .utc(item.startDateUtc)
                                            .local()
                                            .format('L LT')}
                                    />
                                )}
                                {item.endDateUtc && (
                                    <Attribute
                                        marginTop="14px"
                                        label={t('Common_EndDateTime')}
                                        value={moment
                                            .utc(item.endDateUtc)
                                            .local()
                                            .format('L LT')}
                                    />
                                )}
                            </>
                        ) : (
                            <Attribute
                                label={t('Common_ExpiresOn')}
                                value="-"
                            />
                        )}
                    </Box>
                </Box>

                {/* Uncomment when needed to show additional attributes */}
                <Box className={classes.moreAttributes}>
                    {item.targetPermissionLevelType_FriendlyName && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('Common_PermissionLevel')}
                            value={item.targetPermissionLevelType_FriendlyName}
                        />
                    )}
                </Box>
                {item?.fieldTypeValueJson &&
                    hasValidJson(
                        updateJsonMappingWithApiFormat(
                            JSON.parse(item.fieldTypeValueJson),
                            false,
                        ),
                    ) && (
                        <FieldTypeValuesAssignmentInfo
                            fieldTypeValueJson={updateJsonMappingWithApiFormat(
                                getParsedObject(
                                    JSON.parse(item.fieldTypeValueJson),
                                ),
                                false,
                            )}
                        />
                    )}
                {item.resourceType_Name === RESOURCE_TYPES.GROUP &&
                    item?.resourceType_FriendlyName && (
                        <Box
                            display="flex"
                            alignItems="center"
                            style={{
                                gap: '8px',
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: '12px',
                                    color: '#8b909a',
                                    textTransform: 'uppercase',
                                }}
                            >{`${t('GroupType')}:`}</Typography>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                }}
                            >
                                {item?.resourceType_FriendlyName}
                            </Typography>
                        </Box>
                    )}
            </Box>
            {risks && risks.length > 0 && (
                <>
                    <Divider />
                    <Box className={classes.cartItemContent}>
                        {risks.map((r, index) => (
                            <Box key={`${r.id}_${index}`} py={'4px'}>
                                <CartItemViolation risk={r} showReason={true} />
                            </Box>
                        ))}
                    </Box>
                </>
            )}

            <Box
                className={classNames(classes.borderBox, {
                    [classes.riskBorderBox]: risks && risks.length > 0,
                })}
            />
        </Card>
    )
}

const Attribute = ({ label, value, ...rest }) => {
    return (
        <Box {...rest}>
            <Box
                color="#8b909a"
                fontSize="12px"
                style={{ textTransform: 'uppercase' }}
            >
                {label}
            </Box>
            <Box fontSize="14px">{value}</Box>
        </Box>
    )
}

CartItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number,
    handleDelteItem: PropTypes.func,
}

export default CartItem
