import { Box, Button, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useEligibilityAssignments } from 'hooks'
import { PageContextProvider } from 'pageContext'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import {
    getFullPathFromUrl,
    useIsSmallScreenFalse,
    useQuery,
} from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import { Icon } from 'packages/eid-icons'
import Filters from 'components/Filters'
import { ELIGIBILITY_TYPES } from 'utils'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const useButtonStyles = makeStyles((theme) =>
    createStyles({
        root: (isSmallScreen) => ({
            height: '32px',
            borderRadius: '4px',
            padding: '0px 15px 0px 10px',
            backgroundColor: theme?.palette?.primary?.main,
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            margin: isSmallScreen ? '16px 16px 0px 16px' : '',
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
            },
            '& svg': {
                marginRight: '8px',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

const canConfigureEligibilityControlName =
    'ResourceAdmin-ApplicationDetails-Eligibility-ConfigureEligibility-Control'

const RoleDefinitionsEligibility = ({ roleId, resourceTypeId, resourceId }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()

    const query = useQuery()
    const history = useHistory()
    const classes = useButtonStyles(isSmallScreen)

    const dataObject = {
        assignmentPointId: roleId,
        ResourceTypeId: resourceTypeId,
        eligibilityTypeIds: ELIGIBILITY_TYPES[0].value,
    }

    const { list, loading, loadingMore, pagination } =
        useEligibilityAssignments(dataObject)
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'eligibilityType',
            sortable: true,
            sortBy: 'eligibilityType',
            label: 'EligibilityType',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-EligibilityType',
            },
        },
        {
            name: 'assigneeType',
            sortable: true,
            sortBy: 'assigneeType',
            label: 'AssigneeType',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-AssigneeType',
            },
        },
        {
            name: 'assigneeFriendlyName',
            sortable: true,
            sortBy: 'assigneeFriendlyName',
            label: 'Assignee',
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-Eligibility-Assignee',
            // },
        },
        {
            name: 'resourceTypeFriendlyName',
            sortable: true,
            sortBy: 'resourceTypeFriendlyName',
            label: 'ResourceType',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-ResourceType',
            },
        },

        {
            name: 'eligibilityAssignmentType',
            sortable: true,
            sortBy: 'eligibilityAssignmentType',
            label: 'AssignmentType',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-AssignmentType',
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="16px 32px"
            >
                {hasAccessToControl(canConfigureEligibilityControlName) && (
                    <PageContextProvider key="ConfigureEligibility">
                        <Box
                            data-protectedSubcomonent={
                                canConfigureEligibilityControlName
                            }
                        >
                            <Button
                                onClick={() => {
                                    query.delete('applicationId')
                                    query.delete('roleDefinitionId')
                                    query.delete('appRoleId')
                                    query.set(
                                        'workflowName',
                                        'ManageAzLocalRoleWizard',
                                    )
                                    query.set('returnUrl', getFullPathFromUrl())
                                    query.set('SelectedResourceID', resourceId)
                                    query.set(
                                        'WizardAction',
                                        'edititshopsetting',
                                    )
                                    history.push(
                                        `${
                                            config.APP_SUBPATH
                                        }/applications/workflows?${query.toString()}`,
                                    )
                                }}
                                className={classes.root}
                            >
                                {<Icon name="EditFile" color="#fff" />}
                                {t('ConfigureEligibility')}
                            </Button>
                        </Box>
                    </PageContextProvider>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default RoleDefinitionsEligibility
