import {
    Typography,
    Box,
    Backdrop,
    styled,
    MenuItem,
    Dialog,
    useTheme,
} from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { EidButton, Dropdown } from 'packages/eid-ui'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StyledBackdrop = styled(Backdrop)(() => ({
    zIndex: '1201',
}))

const StyledMenuItem = styled(MenuItem)({
    height: '32px',
    minHeight: '32px',
    marginRight: '0.8rem',
    marginLeft: '0.8rem',
})

const ButtonWithDropdown = ({ label = '', options = [] }) => {
    const [open, setOpen] = useState(false)
    const closeMenu = () => {
        setOpen((prev) => !prev)
    }
    const theme = useTheme()
    const { t } = useTranslation()
    const anchorRef = useRef(null)

    const styles = {
        normal: {
            height: '4rem',
            borderRadius: '0.4rem',
            padding: '0 0 0 1rem',
            backgroundColor: theme?.palette?.primary?.main,
            color: '#ffffff',
            fontSize: '1.4rem',
            zIndex: open ? '1202' : '0',
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
            },
            '& .MuiButton-endIcon': {
                borderLeft: '0.1rem solid rgba(255, 255, 255, 0.25)',
                height: '100%',
                marginRight: '0px',
                alignItems: 'center',
            },
            '& .MuiButton-label': {
                height: '100%',
            },
        },

        labelContainer: {
            width: '100%',
        },
        label: {
            fontSize: '1.4rem',
            lineHeight: 'normal',
        },
        icon: {
            borderLeft: '1px solid rgba(255, 255, 255, 0.25)',
            display: 'flex',
            alignItems: 'center',
            width: '15%',
        },
        dropdownAnchor: {
            position: 'absolute',
            top: '4.1rem',
            left: '3.1rem',
        },
    }

    return (
        <>
            <EidButton
                sx={styles.normal}
                endIcon={
                    <Box
                        onClick={() => {
                            setOpen((prev) => !prev)
                        }}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            backgroundColor: theme?.palette?.primary?.main,
                            cursor: 'pointer',
                            borderRadius: '0px 4px 4px 0px',
                            width:'3.2rem',
                            justifyContent:'center'
                        }}
                    >
                        <Icon
                            name="ArrowSmall"
                            color="#d8d8dd"
                            width="10px"
                            fill="#d8d8dd"
                            direction="down"
                        />
                        <span ref={anchorRef} style={styles.dropdownAnchor} />
                    </Box>
                }
            >
                <Box style={styles.labelContainer}>
                    <Typography sx={styles.label}>{t(label)}</Typography>
                </Box>
            </EidButton>
            <StyledBackdrop
                sx={{ color: '#fff' }}
                open={open}
                onClick={closeMenu}
            />

            <Dropdown
                anchorEl={anchorRef.current}
                showCone={true}
                keepMounted
                open={open}
                onClose={closeMenu}
                listStyles={{
                    paddingBottom: '0px',

                    '& > ul': {
                        paddingBottom: '7px',
                        paddingTop: '0px',
                        color: '#534e52',
                        '& li': {
                            borderRadius: '0.5rem',
                            marginBottom: '0.8rem',
                        },
                        '& > li:last-child': {
                            marginBottom: '0rem',
                        },
                        '& :hover': {
                            color: '#1b1f1f',
                        },
                    },
                }}
                iconPosition={{
                    top: '-5px',
                    right: '0px',
                }}
                width="auto"
                disableScrollLock={false}
            >
                {options?.map((o, i) => (
                    <StyledMenuItem
                        key={i + o.label}
                        onClick={() => {
                            o.onClick()
                            closeMenu()
                        }}
                    >
                        {t(o.label)}
                    </StyledMenuItem>
                ))}
            </Dropdown>
        </>
    )
}
export default ButtonWithDropdown
