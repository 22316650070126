import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Icon } from 'packages/eid-icons'
import { Avatar } from 'packages/eid-ui'
import { getAssignmentTypeIcon, getFullImageUrl, imagesDisabled } from 'utils'

const AvatarDetail = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1.2rem',
    '& p': {
        minWidth: '20ch',
        [theme.breakpoints.up('xl')]: {
            whiteSpace: 'nowrap',
        },
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: '#282828',
    },
}))

const AvatarContainer = styled(Box)({
    '& > div': {
        height: '3rem',
        width: '3rem',
    },
})

const supportedAssigneeTypes = [
    'Group',
    'Management Role',
    'Business Role and Location',
    'BusinessRole',
    'Management Role Definition',
    'Person',
]

const AssigneePerson = ({ assigneeName, imageUrl, assigneeType }) => {
    return (
        <AvatarDetail>
            {!imagesDisabled && (
                <>
                    {imageUrl && assigneeType === 'Person' ? (
                        <AvatarContainer>
                            <Avatar
                                size={'small'}
                                src={getFullImageUrl(imageUrl)}
                            />
                        </AvatarContainer>
                    ) : supportedAssigneeTypes.includes(assigneeType) ? (
                        <Box
                            display="flex"
                            width="3rem"
                            justifyContent={'center'}
                        >
                            <Icon
                                color="#919193"
                                name={getAssignmentTypeIcon(assigneeType)}
                                width="3rem"
                                height="3rem"
                            />
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            width="3rem"
                            justifyContent={'center'}
                        >
                            <Box
                                height="1.6rem"
                                width="1.6rem"
                                border="0.4rem solid #d8d8dd"
                                bgcolor="transparent"
                                borderRadius="50%"
                            />
                        </Box>
                    )}
                </>
            )}
            <Typography>{assigneeName}</Typography>
        </AvatarDetail>
    )
}

export default AssigneePerson
