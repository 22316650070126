import { Fragment } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import {
    useAccessRequestPolicy,
    useOwnersApproversDeputies,
    useMailbox,
    useDirectAssignedLocations,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'
import { PeopleListCard } from 'components/PeopleListCard/PeopleListCard'
import { ResponsibleParty } from 'components/ApplicationBanner/ResponsibleParties'
import useSubcomponents from 'useSubcomponents'
import { convertListToFriendlyNameCsv } from 'utils'

const canSeeOwnersControlName = 'ResourceAdmin-Mailboxes-Owners-Control'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
    }),
)

export const MainOverview = (props) => {
    const classes = useStyles()

    const { mailboxId } = props
    const { isLoading: detailsLoading, data: mailbox } = useMailbox(mailboxId)

    const { hasAccessToControl } = useSubcomponents()
    const policy = useAccessRequestPolicy(mailbox && mailbox.requestPolicyId)
    const { data: ownersAndApprovers, isLoading: loading } =
        useOwnersApproversDeputies(
            hasAccessToControl(canSeeOwnersControlName) ? mailbox?.id : null,
        )
    const { list: locations } = useDirectAssignedLocations(mailbox?.id)
    const { t } = useTranslation()

    const firstSection = (
        <Fragment>
            <Box className={classes.basicInfo}>
                <Attribute>
                    <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                    <AttributeValue>
                        {mailbox && mailbox.friendlyName}
                    </AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('TechnicalName')}</AttributeLabel>
                    <AttributeValue>{mailbox && mailbox.name}</AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>{t('Alias')}</AttributeLabel>
                    <AttributeValue>
                        <AttributeValue>
                            {mailbox && mailbox.alias}
                        </AttributeValue>
                    </AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>{t('PrimaryEmail')}</AttributeLabel>
                    <AttributeValue>
                        {mailbox && mailbox.primaryEmailAddress}
                    </AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>{t('LogonName')}</AttributeLabel>
                    <AttributeValue>
                        {mailbox && mailbox.logonName}
                    </AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>{t('EmpowerIDLogin')}</AttributeLabel>
                    <AttributeValue>
                        {mailbox && mailbox.empowerIdLogIn}
                    </AttributeValue>
                </Attribute>
            </Box>
        </Fragment>
    )

    const secondSection = mailbox && (
        <Box>
            <Box display="flex" flexWrap="wrap">
                <Box paddingRight="32px">
                    {mailbox.mailboxTypeName && (
                        <Attribute>
                            <AttributeLabel>{t('MailboxType')}</AttributeLabel>
                            <AttributeValue>
                                {mailbox.mailboxTypeName}
                            </AttributeValue>
                        </Attribute>
                    )}
                    <Attribute>
                        <AttributeLabel>{t('PublishInIAMShop')}</AttributeLabel>
                        <AttributeValue>
                            {mailbox?.requestable ? t('Yes') : t('No')}
                        </AttributeValue>
                    </Attribute>

                    {mailbox.resourceSystemFriendlyName && (
                        <Attribute>
                            <AttributeLabel>
                                {t('ResourceSystem')}
                            </AttributeLabel>
                            <AttributeValue>
                                {mailbox.resourceSystemFriendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    <Attribute>
                        <AttributeLabel>
                            {t('DirectAssignedLocations')}
                        </AttributeLabel>
                        <AttributeValue>
                            {locations &&
                                convertListToFriendlyNameCsv(locations)}
                        </AttributeValue>
                    </Attribute>

                    {policy && (
                        <Attribute>
                            <AttributeLabel>
                                {t('AccessRequestPolicy')}
                            </AttributeLabel>
                            <AttributeValue>
                                {policy.friendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {mailbox.notes && (
                        <Attribute>
                            <AttributeLabel>{t('Notes')}</AttributeLabel>
                            <AttributeValue>{mailbox.notes}</AttributeValue>
                        </Attribute>
                    )}
                </Box>
            </Box>
        </Box>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box
                    mailbox-protectedsubcomponent={''}
                    className={classes.section}
                >
                    {firstSection}
                </Box>
                <Box
                    mailbox-protectedsubcomponent={''}
                    className={classes.section}
                >
                    {secondSection}
                </Box>

                <Box className={classes.section}>
                    {hasAccessToControl(canSeeOwnersControlName) && (
                        <Box
                            mailbox-protectedsubcomponent={
                                canSeeOwnersControlName
                            }
                        >
                            <Attribute>
                                <AttributeLabel>
                                    {t('Mailboxes_Owners')}
                                </AttributeLabel>
                                <PeopleListCard
                                    mailbox={ownersAndApprovers?.owners}
                                    isLoading={loading}
                                    pageSize={3}
                                />
                            </Attribute>
                        </Box>
                    )}
                    <Attribute>
                        <AttributeLabel>{t('ResponsibleParty')}</AttributeLabel>
                        {mailbox?.ownerIsPerson ? (
                            <ResponsibleParty
                                responsiblePersonId={
                                    mailbox?.responsiblePartyId
                                }
                            />
                        ) : (
                            <AttributeValue>
                                {mailbox?.responsiblePartyFriendlyName || '-'}
                            </AttributeValue>
                        )}
                    </Attribute>
                </Box>
            </Box>
        </Fragment>
    )

    return (
        <>
            {detailsLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Fragment>
            )}
        </>
    )
}
