import { Box, styled, Typography } from '@mui/material'
import { useGroupsEligibiltyPeople } from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'

import { Filters } from 'components'
import { useState } from 'react'
import { Avatar, Select } from 'packages/eid-ui'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'

import { usePageContext } from 'pageContext'
import { ELIGIBILITY_TYPES, getFullImageUrl } from 'utils'
import { SearchMenuBarFilter } from 'components/WrapperComponents/SearchMenuBar'

const EligibilityPeople = ({ roleId }) => {
    const { t } = useTranslation()
    const [{}, dispatch] = usePageContext()
    const [eligibilityType, setEligibilityType] = useState(null)

    const { list, loading, pagination } = useGroupsEligibiltyPeople(
        roleId,
        eligibilityType?.id ? [eligibilityType.value] : null,
    )

    const { getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            name: 'friendlyName',
            label: 'PersonFriendlyName',
            sortable: true,
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <Box style={{ display: 'flex', gap: '10px' }}>
                        <Avatar
                            size="medium"
                            src={getFullImageUrl(item.imageThumbUrl)}
                            style={{
                                boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
                            }}
                        />
                        <Typography>{item['friendlyName']}</Typography>
                    </Box>
                ),
            },
        },
        {
            name: 'login',
            label: 'PersonLogin',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-GroupDetails-Eligibility-People-PersonLogin',
            },
        },
        // {
        //     name: 'sourceAssigneeFriendlyName',
        //     label: 'SourceAssignee',
        //     sortable: true,
        //     requireAccess: {
        //         control:
        //             'ResourceAdmin-GroupDetails-Eligibility-People-SourceAssignee',
        //     },
        // },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                padding="16px 32px"
                bgcolor="white"
                borderTop="1px solid #eef0f4"
            >
                <Box
                    style={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            width: '150px',
                            height: '38px',
                            marginTop: '-17px',
                        }}
                    >
                        <Select
                            options={ELIGIBILITY_TYPES}
                            onChange={(data) => {
                                dispatch({
                                    type: 'SET_PAGE',
                                    payload: 1,
                                })
                                setEligibilityType(data)
                            }}
                            value={eligibilityType}
                            getOptionLabel={(option) => t(option.label)}
                            placeholder={t('EligibilityType')}
                            nullableText={t('All')}
                        />
                    </Box>
                    <Box style={{ width: '240px' }}>
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
            </Box>
            <EidTableGrid
                rowSelection={false}
                noDataMessage={t('NoDataMessage')}
                pagination={pagination}
                isLoading={loading}
                attributes={getAccessBasedAttributes(attributes)}
                list={list}
                tableViewOnly
            />
        </Box>
    )
}

export default EligibilityPeople
