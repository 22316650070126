import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useSharedFolderInvertedPermissions } from 'hooks'
import BooleanValue from 'components/DynamicComponents/BooleanValue'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Filters } from 'components'

export const InventoriedPermissions = ({ sharedFolderId }) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()

    const { list, loading, pagination } =
        useSharedFolderInvertedPermissions(sharedFolderId)

    const attributes = [
        {
            label: t('Right'),
            name: 'rightFriendlyName',
            sortable: true,
            style: {
                width: '150px',
                color: '#282828',
                fontSize: '13px',
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-SharedFolderDetails-InvertedPermission-Right',
            // },
        },
        {
            label: t('Assignee'),
            name: 'assigneeFriendlyName',
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-InvertedPermission-Assignee',
            },
        },
        {
            label: t('Type'),
            name: 'type',
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-InvertedPermission-Type',
            },
        },
        {
            label: t('Inherited'),
            name: 'isInherited',
            hideValue: true,
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-InvertedPermission-Inherited',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <BooleanValue
                            data={{ item }}
                            attribute={{ name: 'isInherited' }}
                        />
                    )
                },
            },
        },
        {
            label: t('Allowed'),
            name: 'isAllowed',
            sortable: true,
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-InvertedPermission-Allowed',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <BooleanValue
                            data={{ item }}
                            attribute={{ name: 'isAllowed' }}
                        />
                    )
                },
            },
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                paddingBottom="16px"
            >
                <Box width="308px" display="flex" alignItems="center">
                    <Filters.TextSearch outlined />
                </Box>
            </Box>

            <EidTableGrid
                noDataMessage={t('NoDataMessage')}
                pagination={pagination}
                isLoading={loading}
                attributes={getAccessBasedAttributes(attributes)}
                list={list}
            />
        </>
    )
}
