import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import {
    useAddItemsToCart,
    useCart,
    useDeleteAssigneeAssignment,
    useGroupsRbacAssignments,
} from 'hooks'
import { useIsSmallScreenFalse } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import DeleteAccessGranted from 'containers/Management Roles/Management Role Details/AccessGranted/DeleteAccessGranted'

import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { WarningModal } from 'packages/eid-ui'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import { ListingGrid, GridContainer, Filters } from 'components'
import AddEditTimeConstraint from 'components/DynamicComponents/AddEditTimeConstraint'
import { CreateWorkflowButton } from 'components/ApplicationBanner/CreateWorkflowButton'
import { Icon } from 'packages/eid-icons'
import cartHelpers from 'containers/Cart/cartHelpers'

const canAddGroupAsMember =
    'ResourceAdmin-Groups-Details-RbacAssignments-List--CanAddRbacAssignment-Control'
const canDeleteAccess =
    'ResourceAdmin-Groups-Details-RbacAssignments-List--DeleteAccessAssignment-Control'
const canRemoveMultipleAssignmentsControlName =
    'ResourceAdmin-Groups-Details-RbacAssignments-List--DeleteMultipleAssignments-Control'
const canAccessWorkflow = 
    'ResourceAdmin-Groups-Details-RbacAssignments-List-Workflow-Control'

const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

export const RbacAssignments = ({ groupId, group }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [addItemsToCart, { isLoading: isUpdating }] = useAddItemsToCart()
    const { data: cart } = useCart()
    const [selected, setSelected] = useState([])

    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)

    const {
        list,
        loading: isLoading,
        loadingMore,
        pagination,
        refetch,
    } = useGroupsRbacAssignments(groupId, group?.resourceTypeId)

    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteAssigneeAssignment()

    const attributes = [
        {
            label: t('AccessLevel'),
            name: 'resourceTypeRoleFriendlyName',
            sortable: true,
        },
        {
            label: t('Resource'),
            name: 'resourceFriendlyName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-RbacAssignments-List-Resource',
            },
        },
        {
            label: t('ResourceType'),
            name: 'resourceTypeFriendlyName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-RbacAssignments-List-ResourceType',
            },
        },
        {
            label: t('ResourceSystem'),
            name: 'resourceSystemFriendlyName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-RbacAssignments-List-ResourceSystem',
            },
        },
        {
            label: t('TimeConstraint'),
            hideValue: true,
            styles: {
                minWidth: '200px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-RbacAssignments-List-TimeConstraint',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <AddEditTimeConstraint
                            startDate={data?.startDateUtc}
                            endDate={data?.endDateUtc}
                            onSave={(timeData) =>
                                handleTimeConstraintChange(timeData, data)
                            }
                            disabledTooltip={
                                cartHelpers.isItemAlreadyInCart(
                                    cart,
                                    data?.resourceId,
                                )
                                    ? t('Common_ItemAlreadyInCart')
                                    : ''
                            }
                            saving={isUpdating}
                        />
                    )
                },
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(canDeleteAccess) && (
                            <DeleteAccessGranted
                                item={data}
                                refetch={refetch}
                                handleDeleteCallback={handleDeleteCallback}
                            />
                        )
                    )
                },
            },
        },
    ]

    const handleDeleteCallback = (itemId) => {
        if (selected?.length) {
            setSelected(selected.filter((x) => x !== itemId))
        }
    }

    const preAdd = (timeData, item) => {
        let itemsToAdd = cartHelpers.assignResourceToAssignee({
            assignmentType: 'UpdateTimeConstrain',
            targetAssigneeId: groupId,
            requestableResourceId: item.resourceId,
            resourceFriendlyName: item.resourceFriendlyName,
            resourceTypeId: item.resourceTypeId,
            resourceTypeName: item.resourceTypeFriendlyName,
            selectedList: [item],
            timeConstrained: timeData.selectedEndDate ? true : false,
            startDateUtc: timeData.selectedStartDate,
            endDateUtc: timeData.selectedEndDate,
            resourceTypeRoleId: item.resourceTypeRoleId,
        })
        if (!itemsToAdd) return undefined
        return itemsToAdd
    }

    const handleTimeConstraintChange = (timeData, data) => {
        const itemToAdd = preAdd(timeData, data)
        if (itemToAdd === undefined) return
        addItemsToCart(itemToAdd)
    }

    const handleDelete = () => {
        deleteAssignments(selectedItemsToDelete).then(() => {
            setSelectedItemsToDelete(null)
            setSelected([])
            refetch()
        })
    }

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }
    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.id)
            setSelected(filteredList)
        }
    }

    const selectAllAction = hasAccessToControl(
        canRemoveMultipleAssignmentsControlName,
    )
        ? {
              render: () => {
                  return (
                      <Box>
                          <BulkRemoveButton
                              selectedCount={selected.length}
                              label={t('RemoveSelectedAssignments', {
                                  count: selected.length,
                              })}
                              onClick={handleDeleteBulk}
                              dataProtectedsubcomponent={
                                  canRemoveMultipleAssignmentsControlName
                              }
                          />
                      </Box>
                  )
              },
          }
        : null

    return (
        <Box>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    justifyContent="space-between"
                >
                    {hasAccessToControl(canAddGroupAsMember) && hasAccessToControl(canAccessWorkflow) ? (
                        <Box data-protectedsubcomponent={`${canAddGroupAsMember} && ${canAccessWorkflow}`}>
                            <CreateWorkflowButton
                                label={t('AddNewRbacAssignmentToGroups')}
                                workflowName="GrantActorAccessWF"
                                targetAssigneeId={groupId}
                                resourceName="groups"
                                iconComponent={
                                    <Icon
                                        name={'Plus'}
                                        fill="white"
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    />
                                }
                            />
                        </Box>
                    ) : (
                        <Box></Box>
                    )}
                    <Box width="240px" display="flex" justifyContent="flex-end">
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '16px 0px',
                        marginTop: '0px',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        selectAllAction={selectAllAction}
                        rowSelection={true}
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={loadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={list}
                        handleSelectAllClick={handleSelectAllClick}
                        selected={selected}
                        handleClick={handleRowSelection}
                    />
                </GridContainer>
            </ListingGrid>
            {selectedItemsToDelete && (
                <WarningModal
                    title={t('DeleteRbacAssignments')}
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete.length}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceeding={isDeletingAssignment}
                    proceed={handleDelete}
                />
            )}
        </Box>
    )
}
