import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
    createStyles({
        attribute: ({ styles }) => ({
            marginTop: '-8px',
            ...styles?.attribute,
        }),
        attributeLabel: ({ styles }) => ({
            minWidth: '265px',
            ...styles?.attributeLabel,
        }),
        attributeValue: ({ styles }) => ({
            wordBreak: 'unset',
            ...styles?.attributeValue,
        }),
    }),
)

const PolicyDetails = ({
    policy,
    orientation = 'horizontal',
    showValuesIfUnrestricted = true,
    styles,
}) => {
    const classes = useStyles({ styles })
    const { t } = useTranslation()
    return (
        <Box>
            <Attribute className={classes.attribute} orientation={orientation}>
                <AttributeLabel className={classes.attributeLabel}>
                    {t('AccessRequestPolicy')}
                </AttributeLabel>
                <AttributeValue className={classes.attributeValue}>
                    {policy && policy.friendlyName}
                </AttributeValue>
            </Attribute>
            <Attribute className={classes.attribute} orientation={orientation}>
                <AttributeLabel className={classes.attributeLabel}>
                    {t('AllowsTimeConstrainedAccess')}
                </AttributeLabel>
                <AttributeValue className={classes.attributeValue}>
                    {policy && policy.isTimeConstrained ? t('Yes') : t('No')}
                </AttributeValue>
            </Attribute>
            {(showValuesIfUnrestricted || policy.isTimeConstrained) && (
                <>
                    <Attribute
                        className={classes.attribute}
                        orientation={orientation}
                    >
                        <AttributeLabel className={classes.attributeLabel}>
                            {t('DefaultAccessDuration')}
                        </AttributeLabel>
                        <AttributeValue className={classes.attributeValue}>
                            {policy && policy.defaultValueInMinutes}
                        </AttributeValue>
                    </Attribute>
                    <Attribute
                        className={classes.attribute}
                        orientation={orientation}
                    >
                        <AttributeLabel className={classes.attributeLabel}>
                            {t('MaxAccessDuration')}
                        </AttributeLabel>
                        <AttributeValue className={classes.attributeValue}>
                            {policy && policy.maximumValueInMinutes}
                        </AttributeValue>
                    </Attribute>
                </>
            )}
        </Box>
    )
}

export default PolicyDetails
