import { Box, Typography, styled } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAssignmentsByApplication } from 'hooks'
import { PageContextProvider } from 'pageContext'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { CreateWorkflowButton } from 'components/ApplicationBanner/CreateWorkflowButton'
import UnAssignAssignment from './UnAssignAssignment'
import Filters from 'components/Filters'
import useSubcomponents from 'useSubcomponents'
import AssigneePerson from 'components/AssigneePerson/AssigneePerson'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const ButtonsBox = styled(Box)({
    display: 'flex',
    paddingRight: '10px',
})

const ApplicationRoleDefinitionAssignments = ({
    applicationResourceId,
    resourceSystemModuleId,
}) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()
    const { list, loading, loadingMore, pagination, refetch } =
        useAssignmentsByApplication(resourceSystemModuleId)

    const attributes = [
        {
            name: 'assignee',
            sortable: true,
            label: 'Assignee',
            hideValue: true,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-RoleDefintions-Assignment-Assignee',
            // },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <AssigneePerson
                            assigneeName={data.assignee}
                            imageUrl={data.personImageThumbUrl}
                            assigneeType={data.type}
                        />
                    )
                },
            },
        },
        {
            name: 'role',
            sortable: true,
            label: 'Role',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-Assignment-Role',
            },
        },
        {
            name: 'assignedTo',
            sortable: true,
            label: 'AssignedTo',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-Assignment-AssignedTo',
            },
        },

        {
            name: 'tenant',
            sortable: true,
            label: 'Tenant',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-Assignment-Tenant',
            },
        },
        {
            name: 'endDate',
            sortable: true,
            label: 'EndDate',
            styles: {
                color: '#282828',
            },
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-Assignment-EndDate',
            },
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <Typography
                        style={{ fontSize: '13px', fontWeight: 'normal' }}
                    >
                        {moment.utc(data.endDate).local().format('lll')}
                    </Typography>
                ),
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-Assignment-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <ButtonsBox>
                            <UnAssignAssignment
                                assignmentId={data?.id}
                                refetch={refetch}
                                friendlyName={data?.assignee}
                            />
                        </ButtonsBox>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
            >
                <PageContextProvider key="AddNewRoleDefinitionAssignments">
                    <Box>
                        <CreateWorkflowButton
                            label={t('AddNewRoleDefinitionsAssignments')}
                            workflowName="AssignAzLocalRoleScope"
                            resourceId={applicationResourceId}
                        />
                    </Box>
                </PageContextProvider>

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationRoleDefinitionAssignments
