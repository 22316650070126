import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import {
    useAccessAssignmentsToFolder,
    useAddItemsToCart,
    useAllowedAssignmentTypes,
    useCart,
} from 'hooks'
import { PageContextProvider } from 'pageContext'
import AddAcessAssignentsFolder from './AddAcessAssignentsFolder'
import { Filters, GridContainer } from 'components'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useIsSmallScreenFalse } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import AddEditTimeConstraint from 'components/DynamicComponents/AddEditTimeConstraint'
import { PersonDetails } from 'components/PersonDetails/PersonDetails'
import DeleteAccessAssignment from './DeleteAccessAssignmentToFolder'
import { useEffect, useState } from 'react'
import { SelectWithLabel } from 'packages/eid-ui'
import cartHelpers from 'containers/Cart/cartHelpers'
import PersonDetailsLazy from 'components/PersonDetailsLazy'

const StyledDelete = styled(Box)({
    display: 'flex',
    justifyContent: 'end',
    '& button': {
        borderRadius: '4px',
    },
})

const StyledPersonDetails = styled(Box)({
    '& div:nth-child(1)': {
        margin: '0px',
        gap: '10px',
    },
})

export const AcessAssignmentsFolder = ({ sharedFolder }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const { getAccessBasedAttributes } = useSubcomponents()
    const [actorType, setActorType] = useState(null)
    const { data: cart } = useCart()
    const { data: allowedAssigneeType } = useAllowedAssignmentTypes(true)
    const [addItemsToCart, { isLoading: isUpdating }] = useAddItemsToCart()
    const {
        list,
        loading: isLoading,
        isLoadingMore,
        pagination,
        refetch,
    } = useAccessAssignmentsToFolder(sharedFolder?.id, 'Person')

    useEffect(() => {
        // To assign the default value as Person
        if (!actorType && allowedAssigneeType?.length) {
            const personType = allowedAssigneeType.find(
                (x) => x.name === 'Person',
            )
            if (personType) {
                setActorType({
                    id: personType.name,
                    label: personType.name,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowedAssigneeType])

    const attributes = [
        {
            label: 'Assignee',
            name: 'assigneeDetails',
            sortable: true,
            hideValue: true,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-AssigneeDetails',
            // },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <StyledPersonDetails>
                            <PersonDetailsLazy
                                personId={data?.assigneeId}
                                friendlyName={data?.assigneeFriendlyName}
                            />
                        </StyledPersonDetails>
                    )
                },
            },
        },
        {
            label: 'AccessLevel',
            name: 'resourceTypeRoleFriendlyName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-AccessLevel',
            },
        },
        {
            label: t('TimeConstraint'),
            name: 'timeconstarint',
            sortable: true,
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-TimeConstraint',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <AddEditTimeConstraint
                            startDate={data?.startDateUtc}
                            endDate={data?.endDateUtc}
                            onSave={(time) => handleAccessUpdate(data, time)}
                            disabledTooltip={
                                cartHelpers.isItemAlreadyInCart(
                                    cart,
                                    data?.assigneeId,
                                )
                                    ? t('Common_ItemAlreadyInCart')
                                    : ''
                            }
                            saving={isUpdating}
                        />
                    )
                },
            },
        },
        {
            name: 'requestId',
            label: 'RequestID',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-RequestId',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-ActionButton',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <StyledDelete>
                            <DeleteAccessAssignment
                                item={item}
                                sharedFolder={sharedFolder}
                                refetch={refetch}
                            />
                        </StyledDelete>
                    )
                },
            },
        },
    ]

    const handleAccessUpdate = (item, time) => {
        if (
            item?.startDateUtc !== time?.selectedStartDate ||
            item?.endDateUtc !== time?.selectedEndDate
        ) {
            const cartItem = cartHelpers.addRemoveAccessAssignmentToFolder({
                assignmentType: 'UpdateTimeConstrain',
                resource: sharedFolder,
                accessAssignment: item,
                validFrom: time?.selectedStartDate || null,
                validTo: time?.selectedEndDate || null,
            })

            addItemsToCart([cartItem]).then(() => {
                refetch()
            })
        }
    }

    const bindOptionsForActorType = () => {
        if (!allowedAssigneeType?.length) {
            return []
        }

        return allowedAssigneeType.map((item) => {
            return {
                id: item.name,
                label: item.name,
            }
        })
    }

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                <PageContextProvider key="AddAccessAssignmentsFodler">
                    {actorType?.id === 'Person' ? (
                        <AddAcessAssignentsFolder
                            sharedFolder={sharedFolder}
                            refetch={refetch}
                        />
                    ) : (
                        <Box></Box>
                    )}
                </PageContextProvider>
                <Box
                    display="flex"
                    alignItems="center"
                    style={{
                        gap: '24px',
                    }}
                >
                    <SelectWithLabel
                        value={actorType}
                        getOptionLabel={(option) => t(option.label)}
                        options={bindOptionsForActorType()}
                        label={t('ActorType')}
                        handleChange={setActorType}
                        placeHolder={t('SelectType')}
                    />

                    <Box width="308px" display="flex" alignItems="center">
                        <Filters.TextSearch outlined />
                    </Box>
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </>
    )
}
