import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import { useApplicationCertificates } from 'hooks'
import { CardViewLoader, Filters, Button, DeleteCredential } from 'components'
import { useIsSmallScreenFalse } from 'packages/core'
import CertificateCard from './CertificateCard'
import { usePageContext } from 'pageContext'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../CreateWorkflowButton'
import { useHistory } from 'react-router'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    ListTable,
    ListTableHead,
    ListTableRow,
} from 'components/EidTableGrid/ListView'

const canDeleteCertificateControlName =
    'ResourceAdmin-ApplicationDetails-ClientCertificates-DeleteCertificate-Control'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})

const ApplicationCertificates = ({ azureApplicationId, resourceId }) => {
    const { t } = useTranslation()
    const {
        hasAccessToControl,
        canAddClientCertificate,
        getAccessBasedAttributes,
    } = useSubcomponents()
    const history = useHistory()
    const isSmallScreen = useIsSmallScreenFalse()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationCertificates(azureApplicationId)

    const [state] = usePageContext()

    const masterPasswordLockStyle = {
        height: '100%',
        width: state.masterPasswordWidth,
        left: state.masterPasswordLeftWidth,
    }

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            label: 'FriendlyName',
            isHeader: true,
            showInCard: false,

            headerCellStyles: {
                width: '150px',
                minWidth: '150px',
            },
            styles: {
                width: '150px',
                minWidth: '150px',
                fontWeight: 'bold',
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control: 'ResourceAdmin-Application-Certificate-List-FriendlyName',
            // },
        },
        {
            name: 'ownerFriendlyName',
            sortable: true,
            hideValue: true,
            label: 'Owner',
            component: {
                componentName: 'ApplicationOwner',
            },
            headerCellStyles: {
                width: '160px',
            },
            styles: {
                width: '160px',
            },
            requireAccess: {
                control: 'ResourceAdmin-Application-Certificate-List-Owner',
            },
        },
        {
            name: 'status',
            hideValue: true,
            label: 'Status',
            showMasterLock: true,
            component: {
                componentName: 'CredentialStatusButton',
            },
            headerCellStyles: {
                width: '150px',
                minWidth: '150px',
            },
            styles: {
                width: '150px',
                minWidth: '150px',
                overflow: 'visible',
            },
            requireAccess: {
                control: 'ResourceAdmin-Application-Certificate-List-Status',
            },
        },
        {
            name: 'password',
            sortable: false,
            hideValue: true,
            label: 'PublicKey',
            showMasterLock: true,
            component: {
                componentName: 'CredentialLockedBox',
            },

            headerCellStyles: {
                width: '120px',
            },
            styles: {
                width: '120px',
                padding: '0px',
            },
            requireAccess: {
                control: 'ResourceAdmin-Application-Certificate-List-PublicKey',
            },
        },
        {
            name: 'endDateTimeUtc',
            sortable: true,
            label: 'RemainingTime',
            hideValue: true,
            component: {
                componentName: 'CredentialRemainingTime',
            },
            headerCellStyles: {
                width: '170px',
                minWidth: '170px',
                maxWidth: '170px',
            },
            styles: {
                width: '170px',
                minWidth: '170px',
                maxWidth: '170px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Certificate-List-RemainingTime',
            },
        },
        {
            hideValue: true,
            showInCard: false,
            requireAccess: {
                control: 'ResourceAdmin-Application-Certificate-List-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            <Button.Details
                                item={item}
                                onClick={() => {
                                    history.push(
                                        `${history.location.search}&certificateId=${item?.id}`,
                                    )
                                }}
                            />
                            {hasAccessToControl(
                                canDeleteCertificateControlName,
                            ) && (
                                <Box
                                    style={{ marginLeft: '10px' }}
                                    data-protectedsubcomponent={
                                        canDeleteCertificateControlName
                                    }
                                >
                                    <DeleteCredential item={item} />
                                </Box>
                            )}
                            <Box style={{ marginLeft: '10px' }}>
                                <UIActionsGearButton item={item} noun='ResourceAdminCertificates' />
                            </Box>
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
            >
                {canAddClientCertificate && (
                    <Box data-protectedsubcomponent={canAddClientCertificate}>
                        <CreateWorkflowButton
                            label={t('AddClientCertificate')}
                            workflowName="CreateAzureAppCertificate"
                            resourceId={resourceId}
                        />
                    </Box>
                )}
                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                    tableViewConfig={{
                        TableComponent: ListTable,
                        TableHeadComponent: ListTableHead,
                        TableRowComponent: ListTableRow,
                        showMasterPasswordLock: true,
                        masterPasswordLockStyle,
                    }}
                    cardViewConfig={{
                        LoadingIndicatorComponent: CardViewLoader,
                        CardComponent: CertificateCard,
                    }}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationCertificates
